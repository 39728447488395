// ******************fields Table**********************
.fieldsTable {
  @media screen and (max-width: 1024px) {
    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      width: 100% !important;
      min-width: 100% !important;
    }
  }

  div[class^="v_table_body"] {
    height: calc(
      var(--model-dialog-height) - var(--modal-with-tabs-header-height) -
        var(--modal-with-tabs-footer-height) - 85px - 10px -
        var(--table-header-height)
    ) !important;
  }

  div[class^="v_table_header"],
  div[class^="v_table_row"] {
    display: flex;
    align-items: center;

    > div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }
    }
  }
}

// ******************fields Table**********************

// ******************messageBody Table**********************
.messageBodyTable {
  div[class^="v_table_body"] {
    height: calc(
      var(--model-dialog-height) - var(--modal-with-tabs-header-height) -
        var(--modal-with-tabs-footer-height) - 85px - 10px -
        var(--table-header-height)
    ) !important;
  }

  div[class^="v_table_header"],
  div[class^="v_table_row"] {
    display: flex;
    align-items: center;

    > div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }

      &:nth-child(5) {
        width: var(--col-5);
      }
    }
  }
}

// ******************messageBody Table**********************

// ******************toUsersList Table**********************
.toUsersListTable {
  @media screen and (max-width: 1024px) {
    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      width: 100% !important;
      min-width: 100% !important;
    }
  }

  div[class^="v_table_body"] {
    height: calc(
      var(--model-dialog-height) - var(--modal-with-tabs-header-height) -
        var(--modal-with-tabs-footer-height) - 85px - 10px -
        var(--table-header-height)
    ) !important;
  }

  div[class^="v_table_header"],
  div[class^="v_table_row"] {
    display: flex;
    align-items: center;

    > div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }
    }
  }
}

// ******************toUsersList Table**********************

// ******************ccUsersList Table**********************
.ccUsersListTable {
  @media screen and (max-width: 1024px) {
    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      width: 100% !important;
      min-width: 100% !important;
    }
  }

  div[class^="v_table_body"] {
    height: calc(
      var(--model-dialog-height) - var(--modal-with-tabs-header-height) -
        var(--modal-with-tabs-footer-height) - 85px - 10px -
        var(--table-header-height)
    ) !important;
  }

  div[class^="v_table_header"],
  div[class^="v_table_row"] {
    display: flex;
    align-items: center;

    > div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }
    }
  }
}

// ******************ccUsersList Table**********************
.sendLbl {
  color: var(--title-text) !important;
  font-weight: 600 !important;
  font-size: 1.2rem !important;
  display: flex !important;
  align-items: center !important;

  &::before {
    content: "";
    display: flex;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--primary-color);
    margin: 0 10px 0 0;

    [dir="rtl"] & {
      margin: 0 0 0 10px;
    }
  }
}

.checkboxContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 650px) {
    flex-wrap: wrap;
    > button {
      width: 33%;
      margin-bottom: 10px;
    }
  }
}

.estimationMessagesTemplatesInsart {
  @media screen and (max-width: 1024px) {
    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      width: 100% !important;
      min-width: 100% !important;
    }
  }
}
