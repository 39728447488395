.tabContentStyle {
  height: calc(
    var(--window-height) - var(--side-panel-header-height) -
      var(--side-panel-footer-height) - 30px - var(--tab-container-height)
  ) !important;
  overflow: auto;
  padding: 10px 10px;
}

.estimationTabs {
  padding: 0 !important;

  > button {
    width: 50%;
    margin: 0 !important;

    &::before {
      display: none;
    }

    svg {
      display: none;
    }
  }
}

.insertFieldDialog {
  div[class^="v_table_header"] > div,
  div[class^="v_table_row"] > div {
    &:nth-child(1) {
      width: var(--col-1);
    }

    &:nth-child(2) {
      width: var(--col-2);
    }

    &:nth-child(3) {
      width: var(--col-3);
    }
  }
}

// *******************fieldsTable************************
.fieldsRow {
  > div {
    display: flex;
    justify-content: center;
  }
}

.fieldsTable {
  div[class^="v_table_body"] {
    height: calc(
      var(--window-height) - var(--side-panel-header-height) -
        var(--side-panel-footer-height) - 30px - var(--tab-container-height) -
        53px - var(--table-header-height) - 20px
    );
  }

  @media screen and (max-width: 1024px) {
    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      width: 100%;
      min-width: 100% !important;
    }
  }

  div[class^="v_table_header"],
  div[class^="v_table_row"] {
    display: flex;
    align-items: center;

    > div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }
    }
  }
}

// *******************fieldsTable************************

.estimationQuotationsTypesinsert {
  @media screen and (max-width: 1024px) {
    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      width: 100% !important;
      min-width: 100% !important;
    }
  }
}
