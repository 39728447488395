.estimationInvoicesRegisterRow {
     >div {
          display: flex;
          justify-content: center;
     }
}

.estimationInvoicesRegisterTable {
 @media screen and (max-width: 1024px) {
    div[class^="table_header"],
    div[class^="table_body"],
    div[class^="table-total"] {
      min-width: 3000px !important;
    }
  }

  @media screen and (max-width: 650px) {
    div[class^="table_header"],
    div[class^="table_body"],
    div[class^="table-total"] {
      min-width: 100% !important;
    }
  }

     div[class^=table_header],
     div[class^=table_body]>div,
     div[class^=table-total] {
          >div:nth-child(1) {
               width: var(--col-1);
          }

          >div:nth-child(2) {
               width: var(--col-2);
          }

          >div:nth-child(3) {
               width: var(--col-3);
          }

          >div:nth-child(4) {
               width: var(--col-4);
          }

          >div:nth-child(5) {
               width: var(--col-5);
          }

          >div:nth-child(6) {
               width: var(--col-6);
          }

          >div:nth-child(7) {
               width: var(--col-7);
          }

          >div:nth-child(8) {
               width: var(--col-8);
          }

          >div:nth-child(9) {
               width: var(--col-9);
          }

          >div:nth-child(10) {
               width: var(--col-10);
          }

          >div:nth-child(11) {
               width: var(--col-11);
          }

          >div:nth-child(12) {
               width: var(--col-12);
          }

          >div:nth-child(13) {
               width: var(--col-13);
          }

          >div:nth-child(14) {
               width: var(--col-14);
          }

          >div:nth-child(15) {
               width: var(--col-15);
          }

          >div:nth-child(16) {
               width: var(--col-16);
          }

          >div:nth-child(17) {
               width: var(--col-17);
          }

          >div:nth-child(18) {
               width: var(--col-18);
          }

          >div:nth-child(19) {
               width: var(--col-19);
          }

          >div:nth-child(20) {
               width: var(--col-20);
          }

          >div:nth-child(21) {
               width: var(--col-21);
          }

          >div:nth-child(22) {
               width: var(--col-22);
          }

          >div:nth-child(23) {
               width: var(--col-23);
          }

          >div:nth-child(24) {
               width: var(--col-24);
          }

          >div:nth-child(25) {
               width: var(--col-25);
          }

          >div:nth-child(26) {
               width: var(--col-26);
          }

          >div:nth-child(27) {
               width: var(--col-27);
          }

          >div:nth-child(28) {
               width: var(--col-28);
          }

          >div:nth-child(29) {
               width: var(--col-29);
          }

          >div:nth-child(30) {
               width: var(--col-30);
          }

          >div:nth-child(31) {
               width: var(--col-31);
          }

          >div:nth-child(32) {
               width: var(--col-32);
          }

          >div:nth-child(33) {
               width: var(--col-33);
          }

          >div:nth-child(34) {
               width: var(--col-34);
          }

          >div:nth-child(35) {
               width: var(--col-35);
          }

          >div:nth-child(36) {
               width: var(--col-36);
          }

          >div:nth-child(37) {
               width: var(--col-37);
          }

          >div:nth-child(38) {
               width: var(--col-38);
          }

          >div:nth-child(39) {
               width: var(--col-39);
          }

          >div:nth-child(40) {
               width: var(--col-40);
          }

          >div:nth-child(41) {
               width: var(--col-41);
          }

          >div:nth-child(42) {
               width: var(--col-42);
          }

          >div:nth-child(43) {
               width: var(--col-43);
          }

          >div:nth-child(44) {
               width: var(--col-44);
          }

          >div:nth-child(45) {
               width: var(--col-45);
          }

     }
}
.source{
     color: var(--secondary-1) !important;
}