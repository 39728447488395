@mixin checkboxHeader {
    >label {
        display: flex;
        align-items: center;
        justify-content: center;

        label {
            color: #000 !important;
            text-align: center;
            font-weight: 500;
            width: auto !important;
            font-size: 1.1rem;
        }
    }
}

.valuationFormTable {

    div[class^=v_table_body] {
        height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - var(--table-header-height) - var(--tab-container-height) - 87px);
    }

    div[class^=v_table_header]>div {

        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7) {
            @include checkboxHeader();
        }
    }

    div[class^=v_table_header],
    div[class^=v_table_row] {
        display: flex;
        align-items: center;

        >div {
            text-align: center;
            display: flex;
            justify-content: center;

            &:nth-child(1) {
                width: var(--col-1);
            }

            &:nth-child(2) {
                width: var(--col-2);
            }

            &:nth-child(3) {
                width: var(--col-3);
            }

            &:nth-child(4) {
                width: var(--col-4);
            }

            &:nth-child(5) {
                width: var(--col-5);
            }

            &:nth-child(6) {
                width: var(--col-6);
            }

            &:nth-child(7) {
                width: var(--col-7);
            }
        }
    }

}

.otherSettingsTable {

    div[class^=v_table_header]>div {

        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
            @include checkboxHeader();
        }
    }

    div[class^=v_table_body] {
        height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - var(--table-header-height) - var(--tab-container-height) - 87px);
    }

    div[class^=v_table_header],
    div[class^=v_table_row] {
        display: flex;
        align-items: center;

        >div {
            text-align: center;
            display: flex;
            justify-content: center;

            &:nth-child(1) {
                width: var(--col-1);
            }

            &:nth-child(2) {
                width: var(--col-2);
            }

            &:nth-child(3) {
                width: var(--col-3);
            }

            &:nth-child(4) {
                width: var(--col-4);
            }

            &:nth-child(5) {
                width: var(--col-5);
            }

            &:nth-child(6) {
                width: var(--col-6);
            }

            &:nth-child(7) {
                width: var(--col-7);
            }

            &:nth-child(8) {
                width: var(--col-8);
            }

            &:nth-child(9) {
                width: var(--col-9);
            }

            &:nth-child(10) {
                width: var(--col-10);
            }

            &:nth-child(11) {
                width: var(--col-11);
            }

        }
    }

}

// *****************otherSettings**********************************
.tableCheckBox{
    label{
        visibility: hidden !important;
    }
}