// ****************Start Title*********************
.titleHeader {
    width: 100%;
    justify-content: space-between;
    background: transparent !important;
    border-bottom: 1px solid var(--border-colors);
    border-radius: 0 !important;
    padding: 0 !important;

    >div {
        &:first-of-type {
            justify-content: normal;
        }
    }
}

.blockTitle {
    width: 100%;
    justify-content: space-between;
    background: var(--white);
    border-radius: 5px 5px 0 0 !important;
    position: relative;
    cursor: pointer;
    flex-wrap: wrap;

    * {
        cursor: pointer;
    }

    &[class*=mode2] {
        cursor: default;

        * {
            cursor: default;
        }

        &::after {
            border-color: transparent;
        }
    }

    &[class*=mode2],
    &[class*=close] {
        border-radius: 5px !important;
    }

    &::before {
        content: " ";
        border-top: 1px solid transparent;
        width: 100%;
        height: 1px;
        display: block;
    }

    &::after {
        content: " ";
        border-top: 1px solid var(--border-color-2);
        backface-visibility: hidden;
        transition: all 280ms ease-in-out;
        width: 100%;
        height: 1px;
        display: block;
        margin: 0 auto;
        position: relative;
    }

    &[class*=close] {
        &::after {
            width: 1px;
            border-color: transparent;
        }

        div[class*=headerBtnsPanel] {
            display: none;
        }
    }

}

.headerTitleInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 650px) {
        label {
            font-size: 0.95rem !important;
        }

        button {
            min-width: auto !important;
            font-size: 0.95rem;
        }
    }

    >div[class*=with-num] {
        display: flex;
        align-items: center;

        &:nth-child(n + 0) {
            counter-increment: number;

            &::before {
                margin: 0 10px 0 0;

                [dir=rtl] & {
                    margin: 0 0 0 10px;
                }

                content: counter(number)'';
            }
        }
    }
}

.headerButtons {
    display: flex;
    align-items: center;

    >button {
        &:first-of-type {
            margin: 0 10px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 10px;
            }
        }
    }
}

.titleImg {
    width: 30px;
    height: 30px;
    max-width: 30px;
    max-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    background-color: white;
}

.titleName {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 10px;

    [dir=rtl] & {
        margin: 0 10px 0 0;
    }
}

.name {
    font-weight: 500;
    font-size: 14px;

    [dir=rtl] & {
        font-size: 13px;
        font-weight: 600;
    }
}

.parcode {
    color: var(--text-3);
    margin-top: 3px;
    font-size: 14px;

    [dir=rtl] & {
        font-size: 15px;
    }
}

.dateCard {
    display: flex;
    align-items: center;
    justify-content: end;

    >label {
        font-size: 11px;
        font-weight: 600;
        color: var(--text-2);
        font-family: var(--font-en);
        margin: 0 10px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 10px;
        }
    }

    >svg {
        width: 15px;
        height: 15px;
    }
}

.valCardContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    gap: 10px;
}

.numberOfAttachments {
    width: 50px;
    height: 25px;
    border-radius: 5px;
    background-color: var(--white);
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;

    >label {
        color: var(--text-2);
        font-family: var(--font-en);
        margin: 0 5px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 5px;
        }
    }

    >span {
        display: flex;
        align-items: center;
        justify-content: center;

        >svg {
            width: 13px;
            height: 13px;

            path {
                fill: var(--secondary-1);
            }
        }
    }

    * {
        cursor: pointer;
    }
}

@mixin alert {
    height: 25px;
    border-radius: 5px;
    font-size: 13px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    font-weight: 500;
}

.userNotAuthorizedAlert {
    @include alert();
    color: var(--white);
    background-color: var(--red);

    >svg {
        width: 18px;
        height: 18px;
        margin: 0 10px 0 0;

        >path {
            fill: white;
        }

        [dir=rtl] & {
            margin: 0 0 0 10px;
        }
    }

    @media (min-width: 1025px) and (max-width: 1250px) {
        display: none !important;
    }

    @media screen and (max-width: 950px) {
        display: none !important;
    }
}

.workinAlert {
    @include alert();
    color: var(--black);
    background-color: #EFC74A;

    >button {
        font-size: 13px;
        height: 100%;
        width: 100%;

        >svg {
            width: 18px;
            height: 18px;
            margin: 0 10px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 10px;
            }
        }
    }

    @media (min-width: 1025px) and (max-width: 1250px) {
        display: none !important;
    }

    @media screen and (max-width: 950px) {
        display: none !important;
    }
}

.workinAlertSm {
    display: none;

    @media (min-width: 1025px) and (max-width: 1250px) {
        display: flex;
        @include alert();
        color: var(--black);
        background-color: #EFC74A;
        margin: 10px 0;
        max-width: 300px;

        >button {
            font-size: 13px;
            height: 100%;
            width: 100%;

            >svg {
                width: 18px;
                height: 18px;
                margin: 0 10px 0 0;

                [dir=rtl] & {
                    margin: 0 0 0 10px;
                }
            }
        }
    }

    @media screen and (max-width: 950px) {
        display: flex;
        @include alert();
        color: var(--black);
        background-color: #EFC74A;
        margin: 10px 0;
        max-width: 300px;

        >button {
            font-size: 13px;
            height: 100%;
            width: 100%;

            >svg {
                width: 18px;
                height: 18px;
                margin: 0 10px 0 0;

                [dir=rtl] & {
                    margin: 0 0 0 10px;
                }
            }
        }
    }
}

.userNotAuthorizedAlertSm {
    display: none;

    @media (min-width: 1025px) and (max-width: 1250px) {
        @include alert();
        color: var(--white);
        background-color: var(--red);
        margin: 10px 0;
        max-width: 300px;
        display: flex;

        >svg {
            width: 18px;
            height: 18px;
            margin: 0 10px 0 0;

            >path {
                fill: white;
            }

            [dir=rtl] & {
                margin: 0 0 0 10px;
            }
        }
    }

    @media screen and (max-width: 950px) {
        @include alert();
        color: var(--white);
        background-color: var(--red);
        margin: 10px 0;
        max-width: 300px;

        >svg {
            width: 18px;
            height: 18px;
            margin: 0 10px 0 0;

            >path {
                fill: white;
            }

            [dir=rtl] & {
                margin: 0 0 0 10px;
            }
        }
    }
}

.newTransactionBtn {
    min-width: 100px;
    height: 25px;
    padding: 0 7px;
    font-size: 12px;
    white-space: nowrap;
}

// ****************End Title*********************
.infoTitle {
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;

    [dir=rtl] & {
        font-size: 13px;
        font-weight: 600;
    }

    &::before {
        content: "";
        width: 3px;
        border-radius: 2px;
        height: 25px;
        background-color: #11a804;
        display: block;
        margin: 0 10px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 10px;
        }
    }
}

.circularProgressbar {
    display: flex;
    align-items: center;

    >div {
        width: 35px;

        &:nth-of-type(1) {
            margin: 0 10px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 10px;
            }
        }
    }
}

// ********************************
.blocksDetailsMainPanel {
    background: var(--bg);
    overflow: hidden;
    padding: 8px 15px 0;
    border-radius: 5px;
    position: relative;

    @media screen and (max-width: 765px) {
        margin: 0 !important;
    }
}

.displayPanel {
    padding-bottom: 100px;
    overflow: hidden scroll;
    scroll-behavior: smooth;
    height: calc(100vh - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 20px - var(--display-panel-header-height) - 10px);
    counter-reset: number;
}

@mixin blockHeightMixin {
    overflow: hidden;
    max-height: 99999px;
    transition: max-height 0.5s ease-in-out;

    &[class*=close] {
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        max-height: 0px;
    }
}

.formTemplates {
    margin-bottom: 25px;
    padding: 15px 10px 0 !important;
    background-color: var(--bg) !important;
    box-shadow: none !important;

    @include blockHeightMixin();
}

.selfConstructionBlock {
    @include blockHeightMixin();
    margin-bottom: 40px;
}

.row {
    margin-bottom: 15px;
    align-items: flex-start !important;

    >div {
        margin-bottom: 0 !important;
    }

    @media screen and (max-width: 1025px) {
        >div {
            width: 50%;
        }
    }

    @media screen and (max-width: 650px) {
        >div {
            width: 100%;
        }
    }
}

// ***********Trx Card***************
.trxCard {
    background-color: var(--white);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;

    @media screen and (max-width: 765px) {
        padding: 5px 10px;
        margin-bottom: 5px;
    }

    &[class*=notInCard] {
        padding: 0 !important;
        margin-bottom: 0 !important;

        >div:first-child {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    &[class*=inTable] {
        width: 100%;
        border-radius: 0;
        padding: 0 !important;
        margin-bottom: 0 !important;

        >input,
        input[type=text],
        input[type=number] {
            box-shadow: none !important;
            border-radius: 0;
            padding: 0 !important;

            @media screen and (max-width: 650px) {
                height: 30px !important;
            }
        }
    }
}

.trxCardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.trxHeaderIcon {
    display: flex;
    align-items: center;

    >button {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:nth-of-type(1) {
            margin: 0 10px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 10px;
            }
        }

        svg {
            width: 15px;
            height: 15px;
        }
    }
}

// ***********Trx Card***************

// ***********evaluation Card***************
.evaluationBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--border-colors);
    border-radius: var(--common-border-radius);
    height: var(--common-height);

    >button {
        width: 50%;
        padding: 10px 0;
        font-size: 12px;
        height: 100% !important;
        border: 1px solid transparent;

        &:nth-of-type(1) {
            border-radius: var(--common-border-radius) 0 0 var(--common-border-radius);
            border-right: 1px solid var(--border-colors) !important;
            border-left: 0 !important;

            [dir=rtl] & {
                border-radius: 0 var(--common-border-radius) var(--common-border-radius) 0;
                border-left: 1px solid var(--border-colors) !important;
                border-right: 0 !important;
            }
        }

        &:nth-of-type(2) {
            border-radius: 0 var(--common-border-radius) var(--common-border-radius) 0;

            [dir=rtl] & {
                border-radius: var(--common-border-radius) 0 0 var(--common-border-radius);
            }
        }

        &:focus {
            outline: auto;
            outline: 1px solid var(--secondary-1);
        }

        &:disabled {
            color: var(--x-button-color);
        }

        &.evaluationBtnActive {
            border-color: #11a804 !important;
            background-color: #11a804 !important;
            color: white !important;
            border-right: 1px solid v#11a804 !important;
            border-left: 1px solid #11a804 !important;

            [dir=rtl] & {
                border-left: 1px solid #11a804 !important;
                border-right: 1px solid #11a804 !important;
            }
        }
    }
}

// ***********evaluation Card***************
.trxInput {
    >textarea {
        height: 37px;
        resize: none;
        padding-top: 11px !important;
        font-size: 1.1rem !important;

        @media screen and (max-width: 650px) {
            font-weight: 600;
        }
    }

    >input {
        font-size: 1.10rem !important;
        font-weight: 500 !important;
    }
}

// *************uploud*******************
.imagesPanel {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    background-color: var(--white);
    padding: 15px 10px;

    >div {
        >div {
            &[class*=plus_icon] {
                margin: 5px 0;
            }
        }
    }
}

.mapContainer {
    padding: 20px 10px;
    background-color: var(--white);

    >div {
        border-radius: 5px;
        overflow: hidden;
        height: 600px;
    }
}

.containerMapBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    >button {
        &:nth-of-type(1) {
            margin: 0 10px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 10px;
            }
        }
    }
}

.imagesActions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white);
    padding: 10px;

    >div {
        display: flex;
        align-items: center;

        &:last-of-type {
            gap: 10px;

            >button {
                border: 1px solid var(--border-colors);
                padding: 0 10px;
                height: 36px;

                >svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}

// *************uploud*******************
// *************Table*******************
.tableContainer {
    padding: 20px 10px;
}

.tableContainer {
    width: 100%;
    overflow-y: auto;
    margin-bottom: 20px;
}

.table {
    background-color: var(--white);
    border: 1px solid var(--border-colors);
    border-bottom: 0;

    @media screen and (max-width: 650px) {
        border: 0px;
        width: 100% !important;
    }
}

.th,
.tr {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden !important;
    color: var(--label-color);
    font-size: 1rem !important;

    >div {
        min-height: 40px;
        max-height: 40px;
        width: calc(calc(100% - 180px) / var(--num-of-cells));
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: var(--new-common-height);
        padding: 2px 10px;
        overflow: hidden;
        border-right: 1px solid var(--border-colors);
        border-left: 0;
        border-bottom: 1px solid var(--border-colors);

        [dir=rtl] & {
            border-left: 1px solid var(--border-colors);
            border-right: 0;
        }

        @media screen and (max-width: 650px) {
            border-left: 0;
            border-right: 0;

            [dir=rtl] & {
                border-left: 0;
                border-right: 0;
            }
        }

        &:first-of-type {
            justify-content: flex-start;
            width: 180px;
        }

        &:last-of-type {
            border-right: 0;

            [dir=rtl] & {
                border-left: 0;
            }
        }

        &:focus-within {
            border: 1px solid var(--secondary-1) !important;
        }
    }
}

.tr {
    @media screen and (max-width: 650px) {
        flex-direction: column;
        align-items: flex-start;
        border: 1px solid var(--border-colors);
        margin-bottom: 20px;
        border-radius: 5px;

        >div {
            &:first-of-type {
                align-items: center;
                justify-content: center;
                font-weight: 800 !important;
            }

            &:last-of-type {
                border: 0px;
            }

            min-height: 30px !important;
            max-height: 30px !important;
            width: 100% !important;
        }
    }
}

.th {
    text-align: center;

    @media screen and (max-width: 650px) {
        display: none;
    }
}

.tr {
    input {
        // text-align: center;
        width: 100%;
        height: 40px;
        border: none;
        outline: none;
    }
}

.disabledCell {
    background-color: var(--disable-colors) !important;

    input {
        width: 100% !important;
    }
}

.thLblMobile {
    display: none;

    @media screen and (max-width: 650px) {
        display: flex;
        padding: 5px;

        &::after {
            content: " : ";
            display: block;
            margin: 0 5px;
        }
    }
}

// *************Table*******************
.rowsTable {
    border-bottom: 1px solid var(--border-colors);
}

// *************Table*******************
.doublefiald {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    >div,
    input {
        width: 49%;
    }
}

// ****************Start Self Construction Block Tree*********************
$main-border-radius: 10px;
$labels-padding-top: 10px;
$label-header-height: 50px;
$labels-body-padding-top: 10px;

.classifications_labels {
    margin: 0;
    margin-bottom: 30px;
    padding-bottom: $labels-padding-top;
    height: 100%;

    >div {
        &:nth-child(2) {
            margin: 0;
            padding: 0;
        }
    }

    @media screen and (max-width: 650px) {
        overflow-x: auto;
    }
}

.containerClassificationsTable {
    @media screen and (max-width: 650px) {
        overflow-x: auto;
        min-width: 1500px !important;
    }
}


.classifications_labels_head {
    display: flex;
    align-items: flex-start;
    height: $label-header-height;
    align-items: center;
    justify-content: space-between;

    &+div {
        min-width: 0;
    }

    >div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;

        &:first-of-type {

            >label {
                font-weight: 500;
            }
        }

        >button {
            &:first-of-type {
                margin: 0 10px 0 0;

                [dir=rtl] & {
                    margin: 0 0 0 10px;
                }
            }
        }

        @media screen and (max-width: 650px) {
            min-width: 1500px !important;
        }
    }
}

@mixin ClassificationsTableWidths {
    &:nth-of-type(1) {
        min-width: 17%;
    }

    &:nth-of-type(2) {
        min-width: 10%;
    }

    &:nth-of-type(3) {
        min-width: 10%;
    }

    &:nth-of-type(4) {
        min-width: 10%;
    }

    &:nth-of-type(5) {
        min-width: 10%;
    }

    &:nth-of-type(6) {
        min-width: 11%;
    }

    &:nth-of-type(7) {
        min-width: 11%;
    }

    &:nth-of-type(8) {
        min-width: 13%;
    }

    &:nth-of-type(9) {
        min-width: 8%;
    }
}

.classificationsMainBody {
    display: flex;
    width: 100%;
    background-color: var(--table-bg-color);
    font-family: var(--font-en);
    font-weight: 500;

    [dir=rtl] & {
        font-family: var(--font-ar);
    }

    &:hover {
        background-color: var(--tr-hover);
    }

    >div {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 40px;
        font-size: 15px;
        border-bottom: 1px solid var(--border-colors);
        height: 40px;
        border-right: 1px solid var(--border-colors);
        border-left: 0;


        [dir=rtl] & {
            border-left: 1px solid var(--border-colors);
            border-right: 0;
        }

        label {
            max-height: 40px;
            line-height: 20px;
            max-width: 95%;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
            color: var(--td-color);
            font-family: var(--font-en);

            [dir=rtl] & {
                font-family: var(--font-ar);
            }
        }

        &:nth-of-type(1) {
            justify-content: flex-start;
            align-items: flex-start;
        }

        @include ClassificationsTableWidths();
    }
}

.header {
    background: var(--white);
    height: var(--table-header-height);
    border-bottom: 3px solid var(--primary-color);
    color: var(--white);
    font-weight: 500;
    display: flex;
    align-items: center;

    >div {
        height: 100%;
        display: flex;
        align-items: center;

        label {
            width: 100%;
            color: var(--th-color);
            font-size: 14px;
            text-align: center;
        }

        @include ClassificationsTableWidths();
    }

    button {
        width: 100%;
        height: 100%;
        line-height: var(--table-header-height);
        color: var(--th-color);
        font-size: 15px;
        text-align: center;
        cursor: default;

        label {
            width: 100%;
        }

        * {
            pointer-events: none;
        }
    }

}

.selfConstructionTableTotal {
    height: 40px !important;

    >div {
        // text-align: start !important;
        padding: 0 10px;
        @include ClassificationsTableWidths();
    }
}

.classificationsInputBody {
    width: 100%;
    height: 100%;
    margin: 0;

    >input {
        min-height: 100%;
        border: none;
        border-radius: 0;
        text-align: center !important;
    }
}

.classifications_labels_body {
    width: 100%;
    overflow: hidden !important;
    scroll-behavior: smooth;
}

.classification_block {
    justify-content: space-between;
    align-items: center;
    display: flex;
    width: 100%;
    height: 40px;
    padding: 0 0 0 10px;
    background-color: #fff;

    [dir=rtl] & {
        padding: 0 10px 0 0;
    }
}

.classification {
    height: 100%;
    width: 18px;
    cursor: auto;

    label {
        cursor: pointer;
    }

    display: flex;
    align-items: center;

    [dir=rtl] & {
        flex-direction: row-reverse;
    }

    transition: .5s all;

    svg {
        transition: .5s all;
        transform: rotate(0deg);

        path {
            fill: var(--primary-color);
        }

        [dir=rtl] & {
            transform: rotate(0deg);
        }
    }

    &.rotate {
        svg {
            transition: .5s all;
            transform: rotate(-90deg);

            [dir=rtl] & {
                transform: rotate(90deg);
            }
        }
    }
}

.classificationCode {
    min-width: 50px;
    margin: 0 10px 0 0;
    font-weight: 600;
    font-size: 14px;

    [dir=rtl] & {
        margin: 0 0 0 10px;
    }
}

.classification_btn {
    justify-content: flex-end;
    height: 100%;
    width: 18px;
}

.containerAddBtn {
    display: flex;
    align-items: center;

    >button {
        position: relative;
        background-color: var(--primary-color);
        width: 25px;
        height: 25px;
        border-radius: 50%;

        >svg {
            width: 12px;
            height: 12px;

            path {
                fill: var(--white);
                stroke: var(--white);
            }
        }

        &:nth-of-type(1) {
            margin: 0 20px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 20px;
            }
        }
    }
}

.disabledDeleBtn {
    background-color: #ccc !important;
    cursor: not-allowed;
}

.classification_main {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    height: 100%;
    border-radius: 5px;
    padding: 0 0 0 5px;
    flex-direction: row;
    background-color: transparent;
    cursor: auto;

    [dir=rtl] & {
        padding: 0 5px 0 0;
    }

    &.selected {
        background-color: var(--tree-selected);
    }

    >div {
        &:last-of-type {
            >button {
                opacity: 0;
            }
        }

        >input {
            text-align: start !important;
        }
    }

    &:hover {
        >div {
            &:last-of-type {
                >button {
                    transition: .4s all;
                    opacity: 1;
                }
            }
        }
    }
}

.textBtn {
    height: 100%;
    max-height: 28px;
    justify-content: flex-start;
    font-size: 15px;
    padding: 0 0 0 5px;
    width: 100%;
    font-weight: 500;
    color: var(--label-color);
    overflow: hidden;

    >label {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 14px;
        font-family: var(--font-en);

        [dir=rtl] & {
            font-family: var(--font-ar);
        }
    }

    [dir=rtl] & {
        padding: 0 5px 0 0;
    }

}

.classification_actions {
    display: flex;
    flex-direction: row;

    [dir=rtl] & {
        flex-direction: row-reverse;
    }

    justify-content: space-between;
    background-color: transparent;
}

.addBtn {
    padding: 3px;
    border-radius: 3px;
    margin: 0 5px 0 0;

    svg {
        width: 15px;
        height: 15px;

        * {
            stroke: var(--primary-color);
            stroke-width: 2;
        }
    }
}

.tableSearch {
    padding: 0;
}

.selfConstPanel {
    background-color: var(--white);
    padding: 10px 10px 0px;
    border-radius: 5px;
    margin-bottom: 16px;
}

.selfConstRow {
    padding: 0 10px;

    @media screen and (max-width: 1025px) {
        >div {
            width: 50%;
        }
    }

    @media screen and (max-width: 650px) {
        >div {
            width: 100%;
        }
    }
}

// ****************End Self Construction Block Tree***********************

.generalEstPanel {
    background-color: var(--white);
    padding: 10px 10px 0px;
    border-radius: 5px;
    margin-bottom: 16px;
}

.generalEstRow {
    padding: 0 10px;
}

.dateTimeContainer {
    >input {
        width: 100%;
        height: 37px;
        border: 1px solid var(--border-colors);
        border-radius: 5px;
        padding: 10px;
    }
}

.formTemplatesMode1 {
    padding: 0 10px !important;
    background-color: var(--white) !important;
    border-radius: 0 0 5px 5px !important;
    margin-bottom: 40px;
    overflow: hidden;
    max-height: 99999px;
    transition: max-height 0.5s ease-in-out;

    &[class*=close] {
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        max-height: 0px;
    }
}

.mapBtns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;
    gap: 10px;
    padding: 0 calc(10px + .5rem);

    &[class*=mode1] {
        padding: 0 15px;
    }

    &[class*=mode2] {
        padding: 15px 10px 0;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        margin-bottom: 0;
        background-color: var(--white);
        border-radius: 5px 5px 0 0;
    }
}

.mapPinInTable {
    position: absolute;
    background-color: #fff;
    width: 23px;
    height: 23px;
    top: 50%;
    right: 5px;
    left: auto;
    transform: translateY(-50%);

    [dir=rtl] & {
        right: auto;
        left: 5px;
    }

    svg path {
        fill: var(--secondary-1);
    }
}

.tableDetailsPanel {
    background: var(--white) !important;
    padding-top: inherit;
    padding-bottom: 10px !important;
    margin-bottom: 40px;
    border-radius: 0 0 5px 5px;

    overflow: hidden;
    max-height: 99999px;
    transition: all 0.5s ease-in-out;

    &[class*=close] {
        transition: all 0.5s cubic-bezier(0, 1, 0, 1);
        padding-bottom: 0px !important;
        padding-top: 0px !important;
        max-height: 0px;
    }

    @media screen and (max-width: 650px) {
        overflow-x: auto;
    }
}

.customBlock::after {
    border-color: transparent;
}

.d-flex {
    display: flex;
}

.d-block {
    display: block;
}

.d-block {
    display: block;
}

.hiddenBlock {
    display: none !important;
}

.usersMenu {
    width: 220px !important;
    max-height: 250px;

    >li {
        border-bottom: 1px solid var(--border-colors);

        &:last-of-type {
            border-bottom: none;
        }
    }

    .userRow {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0;
        font-size: 15px;
        font-weight: 600;

        &.selected {
            border: 1px solid var(--primary-1);
        }

        >div {
            width: 40px;
            height: 40px;
        }
    }

    & [class*=rc-dropdown-menu-item-selected] {
        background-color: transparent;

        &::after {
            display: none;
        }
    }
}

.selectMulti {
    height: auto !important;
    min-height: var(--common-height);
}

.spinnerButton {
    background-color: var(--primary-color-2);
}

.selfConstructionSpinnerContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: none;
    background-color: var(--primary-color);

    >div {
        height: 100%;
        width: 100%;
    }
}

.selfConstructionSpinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 100;

    &::after {
        border: 3px solid var(--white) !important;
        border-top-color: transparent !important;
    }

    display: flex !important;
}

.blockSpinner {
    display: none;
}

.backToTop {
    position: fixed;
    bottom: 2%;
    right: 0;
    left: auto;
    z-index: 5;
    height: 35px;
    width: 35px;
    border-radius: 5px 0 0 5px;
    background-image: var(--header-side-panel-bg);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    [dir=rtl] & {
        left: 0;
        right: auto;
        border-radius: 0 5px 5px 0;
    }

    >svg {
        height: 17px;
        width: 17px;
        color: #fff;

        path {
            fill: var(--white);
        }
    }

    &:hover {
        >svg {
            animation: lineUp 1s cubic-bezier(0.0, 0.6, 1.0, 0.4) infinite .5s;
        }
    }

    @keyframes lineUp {
        0% {
            transform: translateY(50px);
        }

        100% {
            transform: translateY(-70px);
        }
    }
}

.uploudImageButton {
    width: 100%;
    height: 40px;
    overflow: hidden;
    position: relative;

    >svg {
        width: 30px;
        height: 30px;
    }

    >span {
        position: absolute;
        width: 6px;
        height: 6px;
        background-color: var(--primary-color-2);
        border-radius: 50%;
        top: 3px;
        right: auto;
        left: calc(calc(100% / 2) - 15px);

        [dir=rtl] & {
            left: auto;
            right: calc(calc(100% / 2) - 15px);
        }
    }
}

.relativeBtn {
    position: relative;
}

.deleteButtonSpinner {
    background-color: white;

    &::after {
        border-color: var(--red);
        border-width: 2px;
        border-top-color: transparent;
        border-bottom-color: transparent;
    }
}