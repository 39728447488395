$attach-width : 200px;
$attach-height : 220px;
$attach-mid-width : 180px;
$attach-mid-height : 180px;
$attach-sm-width : 165px;
$attach-sm-height : 200px;
$attach-xs-width : 133px;
$attach-xs-height : 133px;
$actions-background: #393939;

.container {
    padding: 0 !important;
    background-color: var(--bg) !important;
    box-shadow: none !important;

    overflow: hidden;
    max-height: 99999px;
    transition: max-height 0.5s ease-in-out;

    &[class*=close] {
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        max-height: 0px;
    }
}

.imagesActions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white);
    padding: 10px 20px;

    >div {
        display: flex;
        align-items: center;

        &:last-of-type {
            gap: 10px;

            >button {
                border: 1px solid var(--border-colors);
                padding: 0 10px;
                height: 36px;

                >svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}

.imagesPanel {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: baseline;
    width: 100%;
    background-color: var(--white);
    padding: 15px 10px;
    position: relative;
    min-height: 290px;

    >div {
        >div {
            &[class*=plus_icon] {
                margin: 5px 0;
            }
        }
    }
}

.uploadImgContainer {
    width: 100% !important;
    height: 120px;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 8px;

    svg {
        width: 64.234px;
        height: 40px;
    }

    @media screen and (max-width: 650px) {
        height: 80px;
        gap: 6px;
    }

    @media screen and (max-width: 450px) {
        height: 50px;
        margin-top: 15px !important;

        svg {
            width: 54.234px;
            height: 30px;
        }
    }
}

@mixin sortableImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    background-color: var(--white);
    padding: 15px 10px;
}

.sortableImagesContainerView {
    @include sortableImageContainer();
}

.sortableImagesContainer {
    @include sortableImageContainer();

    @media screen and (max-width: 768px) {
        padding: 15px 0 !important;
    }
}

.imageTypeInput {
    display: flex;
    align-items: center;

    >label {
        display: flex;
        align-items: center;
        white-space: nowrap;
        margin: 0 15px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 15px;
        }
    }

    @media screen and (max-width: 650px) {
        flex-direction: column;
        align-items: flex-start;

        >label {
            display: none;
        }
    }

    @media screen and (max-width: 450px) {
        >div {
            height: 32px;
            font-size: 1rem !important;

            >div {
                >div {
                    >div {
                        >div {
                            padding: 5px !important;
                        }
                    }
                }
            }
        }
    }
}

.uploadOneImg {
    width: 100%;
    height: 120px;
    border-radius: 3px;
    border: 1px dashed var(--text-2);
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 10px;

    @media screen and (max-width: 650px) {
        height: $attach-sm-height;
    }

}

.uploadContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    >div {
        width: 33% !important;
        margin: 0 10px;
    }

    @media screen and (max-width: 650px) {
        flex-direction: column;
        padding: 0 10px;

        >div {
            width: 100% !important;
            margin-bottom: 10px;

            &:nth-of-type(1) {
                margin-top: 25px;
                margin-bottom: 20px;
            }
        }
    }
}

.type130501 {
    background-color: #D8DAFD;

    &[class*=dragging] {
        border: 2px solid #2F40F7;
    }
}

.type130502 {
    background-color: #D8EEF1;

    &[class*=dragging] {
        border: 2px solid #0ABBBF;
    }
}

.type130503 {
    background-color: #D8E4FC;

    &[class*=dragging] {
        border: 2px solid #319CF4;
    }
}

.type130504 {
    background-color: #FBEAD8;

    &[class*=dragging] {
        border: 2px solid #FF9D00;
    }
}

.type130505 {
    background-color: #D6FDE9;

    &[class*=dragging] {
        border: 2px solid #0FF2AC;
    }
}

.type130506 {
    background-color: #EBDBFB;

    &[class*=dragging] {
        border: 2px solid #9107E2;
    }
}

.type130507 {
    background-color: #FBF8D7;

    &[class*=dragging] {
        border: 2px solid #EFAF11;
    }
}

.type130508 {
    background-color: #FCE8FC;

    &[class*=dragging] {
        border: 2px solid #E895E0;
    }
}

.type130509 {
    background-color: #F6F6F6;

    &[class*=dragging] {
        border: 2px solid #686868;
    }
}

.type130510 {
    background-color: #FBECEC;

    &[class*=dragging] {
        border: 2px solid #FC6376;
    }
}

.type130511 {
    background-color: #FFDEED;

    &[class*=dragging] {
        border: 2px solid #FF0068;
    }
}

.type130512 {
    background-color: #FFCCFA;

    &[class*=dragging] {
        border: 2px solid #FF00DB;
    }
}

.imageCardContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: $attach-width;
    height: $attach-height;
    margin-right: 20px;
    margin-left: 0;
    margin-bottom: 20px;
    border-radius: 10px;
    border: 1px solid #EDEDED;

    [dir=rtl] & {
        margin-left: 20px;
        margin-right: 0;
    }
}

.imageContainer {
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 6px 6px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    >img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    svg {
        width: 100px;
        height: 100px;
    }

    &:hover {

        .selected,
        .topActionsContainer {
            top: 5px;
        }

        .bottomActionsContainer {
            bottom: 5px;
        }
    }

    @media screen and (max-width: 765px) {
        margin-right: 10px;
        margin-left: 0;
        margin-bottom: 10px;

        [dir=rtl] & {
            margin-left: 10px;
            margin-right: 0;
        }
    }

    @media screen and (max-width: 650px) {

        .topActionsContainer {
            top: 0;
        }

        .bottomActionsContainer {
            bottom: 0;
        }

        .selected {
            top: 6px;
        }
    }

    >button {
        top: -5px;
        left: auto;
        right: -5px;
        position: absolute;
        background-color: var(--red);
        padding: 3px;

        [dir=rtl] & {
            left: -5px;
            right: auto;
        }

        &:hover {
            transition: .2s scale;
            scale: 1.2;
        }

        svg {
            width: 15px;
            height: 15px;

            g {
                stroke: var(--white);
            }
        }
    }

    @media screen and (max-width: 1366px) {
        width: $attach-mid-width;
        height: $attach-mid-height;
    }

    @media screen and (max-width: 650px) {
        width: $attach-sm-width;
        height: $attach-sm-height;
        margin-left: 0;
        margin-bottom: 10px;

        [dir=rtl] & {
            margin-left: 10px;
            margin-right: 0;
        }
    }

    @media screen and (max-width: 450px) {
        width: $attach-xs-width;
        height: $attach-xs-height;
        margin-left: 0;
        margin-right: 5px;
        margin-bottom: 10px;

        [dir=rtl] & {
            margin-left: 5px;
            margin-right: 0;
        }
    }
}

.selectMenuContainer {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    >button {
        >div>div {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin: 0 6px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 6px;
            }

            &[class*=type-130501] {
                background-color: #2F40F7;
            }

            &[class*=type-130502] {
                background-color: #0ABBBF;
            }

            &[class*=type-130503] {
                background-color: #319CF4;
            }

            &[class*=type-130504] {
                background-color: #FF9D00;
            }

            &[class*=type-130505] {
                background-color: #0FF2AC;
            }

            &[class*=type-130506] {
                background-color: #9107E2;
            }

            &[class*=type-130507] {
                background-color: #EFAF11;
            }

            &[class*=type-130508] {
                background-color: #E895E0;
            }

            &[class*=type-130509] {
                background-color: #686868;
            }

            &[class*=type-130510] {
                background-color: #FC6376;
            }

            &[class*=type-130511] {
                background-color: #FF0068;
            }

            &[class*=type-130512] {
                background-color: #FF00DB;
            }
        }

        >span>svg>path {
            fill: var(--black);
        }
    }
}

.overPanel {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0078d78f;
    cursor: grabbing;

    >svg {
        width: 20px;
        height: 20px;
    }
}

@mixin actionButton {
    height: 30px;
    width: 30px;
    background-color: $actions-background;
    border-radius: 50%;
    box-shadow: #00000070 0px 1px 10px;

    >svg {
        width: 16px;
        height: 16px;

        path {
            fill: #fff;
        }
    }
}

.bottomActionsContainer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-around;
    bottom: -35px;
    width: 100%;
    padding: 0 4px;
    transition: .25s;

    >a {
        @include actionButton();
        display: flex;
        align-items: center;
        justify-content: center;
    }

    >button {
        @include actionButton();

        &[class*=delete] {
            background-color: var(--white);

            >svg>g>path {
                fill: var(--red) !important;
            }
        }

        @media screen and (max-width: 650px) {
            height: 22px;
            width: 22px;

            >svg {
                width: 15px;
                height: 15px;
            }
        }
    }
}

.imageNumber {
    position: absolute;
    top: 5px;
    left: 5px;
    right: auto;
    color: var(--white);
    background-color: $actions-background;
    min-width: 30px;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    [dir=rtl] & {
        right: 5px;
        left: auto;
    }
}

.topActionsContainer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    top: -35px;
    right: 0;
    left: auto;
    transition: .25s;
    height: 28px;

    [dir=rtl] & {
        left: 0;
        right: auto;
    }

    &[class*=selected] {
        top: 5px !important;
    }

    .checkbox {
        width: 26px;
        height: 26px;

        >div {
            border-color: #9EA7B5;
            border-width: 2px;
        }

        &[class*=active] {
            border-color: #4241F4;

            >div {
                background-color: #4241F4;
                border-color: #4241F4;
            }
        }
    }
}

// ***********************Preview*************************

@mixin Img-Preview {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 11;
    background-color: rgba(0, 0, 0, .9);
    display: none;
}

.imgPreviewActive {
    @include Img-Preview();
    display: flex;
}

.imgPreviewinActive {
    @include Img-Preview();
}

.headerBtnAction {
    background-color: var(--black);
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;

    >button {
        position: absolute;
        top: 55px;

        &:nth-of-type(1) {
            right: 10px;
            left: auto;

            [dir=rtl] & {
                left: 10px;
                right: auto;
            }
        }

        &:nth-of-type(2) {
            left: 10px;
            right: auto;

            [dir=rtl] & {
                right: 10px;
                left: auto;
            }
        }

        @media screen and (max-width: 650px) {
            top: 80px;
        }
    }

    svg {
        width: 18px;
        height: 18px;

        g {
            fill: var(--white);
            stroke: var(--white);
        }
    }
}

.closePreview {
    &:hover {
        svg {
            transition: 0.2s;
            transform: scale(1.2);

            path {
                stroke: var(--red);
            }
        }
    }
}

.notifiLeft {
    position: fixed;
    bottom: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    right: 10px;
    left: auto;

    >button {
        background-color: var(--white);
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin-top: 10px;

        svg {
            width: 14px;
            height: 14px;

            g {
                fill: var(--black);
                stroke: var(--black);
            }

            path {
                fill: var(--black);
            }
        }
    }
}

.footerBtnAction {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    width: 100%;
    height: 60px;
    background-color: var(--black);

    svg {
        width: 18px;
        height: 18px;

        g {
            fill: var(--white);
            stroke: var(--white);
        }

        path {
            fill: var(--white);
        }

        @media screen and (max-width: 650px) {
            width: 14px;
            height: 14px;
        }
    }
}

.notifiFooter {
    display: flex;
    align-items: center;

    >button {
        margin: 0 0 0 20px;

        [dir=rtl] & {
            margin: 0 20px 0 0;
        }

        &:last-of-type {
            margin: 0 20px;

            [dir=rtl] & {
                margin: 0 20px;
            }
        }

        svg {
            width: 18px;
            height: 18px;

            g {
                fill: var(--white);
                stroke: var(--white);
            }

            >path {
                fill: var(--white);
            }
        }

        &:disabled {
            background-color: transparent;

            &:hover {
                background-color: transparent !important;
            }
        }
    }
}

.imgNum {
    color: var(--white);
}

.userSectionPreview {
    display: flex;
    align-items: center;

    >div {
        margin: 0 20px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 20px;
        }
    }

    @media screen and (max-width: 650px) {
        width: calc(100% - 20px);
        margin: 0 10px;

        >div {
            width: 33%;
        }
    }
}

.infoSection {
    width: 100%;
    height: 40px;
    background-color: var(--black);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 9999999;

    svg {
        width: 18px;
        height: 18px;

        g {
            fill: var(--white);
            stroke: var(--white);
        }

        path {
            fill: var(--white);
        }

        @media screen and (max-width: 650px) {
            width: 14px;
            height: 14px;
        }
    }

    @media screen and (max-width: 650px) {
        height: 80px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        >div {
            height: 50%;
        }
    }
}

.userImg {
    display: flex;
    align-items: center;
    color: var(--white);

    >label {
        margin: 0 0 0 5px;

        [dir=rtl] & {
            margin: 0 5px 0 0;
        }

        @media screen and (max-width: 650px) {
            font-size: 0.8rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: calc(100% - 20px);
        }
    }
}

.imgType {
    width: 200px;
    height: 92%;
    background-color: var(--pale-primary);
    border-radius: 5px;
    border: 1px solid transparent;
    margin: 0 0 0 10px;

    [dir=rtl] & {
        margin: 0 10px 0 0;
    }

    @media screen and (max-width: 650px) {
        border-radius: 0 !important;

        [dir=rtl] & {
            border-radius: 0 !important;
        }
    }

    &:focus-within {
        border-color: var(--secondary-1);

        svg {
            path {
                fill: var(--secondary-1);
            }
        }
    }

    div[class *=singleValue] {
        color: var(--text-2);
    }

    svg {
        width: 14px;
        height: 14px;

        path {
            fill: var(--text-2);
        }
    }

    input {
        color: var(--text-2) !important;
    }

    @media screen and (max-width: 650px) {
        width: 100%;
    }
}

.datePreview {
    margin: 0 0 0 3px;

    [dir=rtl] & {
        margin: 0 3px 0 0;
    }

    >label {
        margin: 0 3px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 3px;
        }

        @media screen and (max-width: 650px) {
            font-size: 0.8rem;
        }

        @media screen and (max-width: 450px) {
            font-size: 0.7rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

.zoomIn {
    transform: translate(-50%, -50%) !important;
}

@mixin ImageLayer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    width: 100%;
}

.openPreview {
    @include ImageLayer();
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    >svg {
        display: none;
    }

    &[class*=grabbing] {
        cursor: grabbing !important;
    }
}

.sortLayer {
    @include ImageLayer();
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);

    >svg {
        width: 60px;
        height: 60px;

        >g {
            fill: var(--white)
        }
    }
}

.imgPreview {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100% - 40px - 60px - 40px);
    width: calc(100% - 60px);

    img {
        max-height: 100%;
        top: 50%;
        left: 50%;
        position: absolute;
        transition: transform 0.3s ease;
        transform: translate(-50%, -50%) scale(var(--trxZoom));
    }

    @media screen and (max-width: 650px) {
        height: calc(100% - 80px - 80px - 40px);
        width: calc(100% - 80px);
    }

    video {
        height: 100%;
    }
}

.nextArrow {
    position: absolute;
    left: 50px;
    top: 50%;
    transform: translateY(-50%);

    svg {
        width: 30px;
        height: 30px;
    }

    &:hover {
        svg {
            path {
                stroke: var(--white);
            }
        }
    }

    @media screen and (max-width: 650px) {
        left: 0;
    }
}

.previousArrow {
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);

    svg {
        width: 30px;
        height: 30px;
        transform: rotate(180deg);
    }

    &:hover {
        svg {
            path {
                stroke: var(--white);
            }
        }
    }

    @media screen and (max-width: 650px) {
        right: 0;
    }
}

// ***********************Preview*************************

.iconContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    >label {
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-top: 10px;
    }

    >svg {
        width: 100px;
        height: 100px;
    }
}

.imgPreviewSpinner {
    display: flex;
}

.imgViewSpinner {
    display: flex;

    &::after {
        border-top-color: var(--black);
        border-bottom-color: var(--black);
    }
}

$drop-zone-width: 100px;

.modalCustomers {
    width: 700px;
}

.inputRange {
    padding: 0 20px;
}

.smHeader {
    @media screen and (max-width: 768px) {
        >div {
            width: 50% !important;
        }
    }

    @media screen and (max-width: 500px) {
        >div {
            width: 100% !important;
        }
    }
}

.inputRangeLableHide {
    display: none;
}

.inputRangeLable {
    font-size: 12px !important;

    &,
    * {
        z-index: 3;
        display: block !important;
        color: var(--secondary-1) !important;
        font-weight: 500;
        font-family: var(--font-en) !important;

        [dir=rtl] & {
            font-family: var(--font-ar) !important;
        }
    }
}

.inputRangeContainer {
    height: 80px;

    @media screen and (max-width: 650px) {
        height: auto;
    }
}

.slider {
    background-color: #fff !important;
    border-color: var(--primary-color-2) !important;
}

.activeTrack {
    background-color: var(--secondary-1) !important;
}

.imgContainer {
    padding-top: 5px;
    min-height: 215px;
    overflow: auto;
}

.uploadImgSpinner {
    display: flex;
    margin: 0 !important;
    width: 100% !important;

    &::after {
        border-top-color: var(--black);
        border-bottom-color: var(--black);
        width: 50px;
        height: 50px;
    }
}

.uploadImgWitinglabel {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 600;
}

.closeContainer {
    display: flex;
    align-items: center;
    position: absolute;
    top: 55px;
    left: 10px;
    right: auto;

    [dir=rtl] & {
        right: 10px;
        left: auto;
    }

    @media screen and (max-width: 650px) {
        top: 80px;
    }

    a {
        margin: 0 0 0 20px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        [dir=rtl] & {
            margin: 0 20px 0 0;
        }

        >svg {
            width: 15px;
            height: 15px;
        }

        cursor: pointer;

        &:hover {
            transition: 0.2s;
            transform: scale(1.2);
            border-color: var(--secondary-1);

            svg {
                transition: 0.2s;
                transform: scale(1.2);

                path {
                    stroke: var(--secondary-1);
                }
            }
        }
    }
}

.inputRangeContainer {
    display: flex;
    align-items: center;

    >label {
        display: flex;
        align-items: center;
        white-space: nowrap;
        margin: 0 15px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 15px;
        }
    }

    @media screen and (max-width: 650px) {
        flex-direction: column;
        align-items: flex-start;

        >label {
            display: none;
        }
    }
}

.multiImgNum {
    color: var(--white);
    background-color: var(--black);
    min-width: 20px;
    min-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}