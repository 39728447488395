.estimationsHistorySidePanel {
    >div {
        >div {
            &[class *=contentContainerOne] {
                padding: 0 !important;
            }
        }
    }
}

.map {
    margin-top: 10px;
    height: 400px;
}

.tabContentStyle {
    height: calc(var(--window-height) - var(--side-panel-header-height) - var(--side-panel-footer-height) - 30px - var(--tab-container-height)) !important;
    overflow: auto;
    padding: 10px 10px;
}

.estimationsTabs {
    padding: 0 !important;

    >button {
        width: 50%;
        margin: 0 !important;

        &::before {
            display: none;
        }

        svg {
            display: none;
        }
    }
}

.step3 {
    display: flex;
    justify-content: center;
    width: 100%;

    >div {
        &[class*=imgsContainer] {
            justify-content: center;
        }
    }
}