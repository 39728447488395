$card-Height: 101px;

.cardsMenuPanel {
    background-color: var(--bg) !important;
    position: relative;
}

.title {
    font-weight: 500;
    margin-bottom: 0 !important;
    font-size: 15px !important;

    [dir=rtl] & {
        font-weight: 600;
    }
}

.titleIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 8px 0 0;

    [dir=rtl] & {
        margin: 0 0 0 8px;
    }

    >svg {
        width: 17px;
        height: 17px;
    }
}

.stepContainer {
    height: calc(100vh - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 20px - var(--display-panel-header-height) - 10px);
    overflow: auto;
    padding: 0 5px 0 0;
    scroll-behavior: smooth;

    [dir=rtl] & {
        padding: 0 0 0 5px;
    }

    >div {
        &:last-of-type {
            >div {
                &:nth-of-type(2) {
                    >div {
                        &::before {
                            content: "";
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &[class*=show-hide-menu] {
        padding: 0 !important;
        position: relative;

        &::-webkit-scrollbar {
            display: none;
        }

        scrollbar-width: none;
        -ms-overflow-style: none;

        >div {
            height: var(--menu-trx-width) !important;
            width: var(--menu-trx-width);
            padding: 0 !important;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0 !important;
            box-shadow: none;

            >span {
                display: flex !important;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                color: var(--black);
            }

            >div {
                display: none !important;
            }
        }
    }

    counter-reset: number;
}

.headerCrad {
    position: relative;
    box-shadow: 0 1px 1px rgba(23, 43, 77, 0.2), 0 0 1px rgba(23, 43, 77, 0.2);
    border-radius: 5px;
    padding: 10px 10px 5px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    height: $card-Height;
    width: calc(100% - 5px);
    background-color: var(--white);
    cursor: pointer;
    transition: all 0.3s ease;

    * {
        cursor: pointer;
        pointer-events: none;
    }

    &[class*=active-card] {
        background-color: var(--primary-color-2);

        .cradTitLbl {
            font-weight: 500;
            color: var(--white);
        }

        span,
        label {
            color: #fff !important;
        }

        div {
            &[class*=progress-bar] {
                background-color: #fff;
            }
        }

        &:hover {
            background-color: var(--primary-color-2) !important;
            color: var(--white) !important;
        }
    }

    >span {
        display: none;
    }

    &:nth-child(n + 0) {
        counter-increment: number;

        >span::after {
            content: counter(number)'';
        }
    }

    &:hover {
        background-color: var(--pale-primary) !important;
        color: var(--primary-color-2) !important;
    }
}

.cradDetailes {
    display: flex;
    flex-direction: column;
    width: calc(100%);
}

.cradName {
    display: flex;
    align-items: center;
}

.cradTitLbl {
    font-size: 1rem;

    [dir=rtl] & {
        font-size: 13px;
    }
}

.mainProgressBar {
    width: 100%;
    margin-top: 3px;
}

.blocksTitlePanel {
    justify-content: space-between;

    >div {
        display: flex;
        align-items: center;
    }

    .modePanel {
        margin: 0 40px 0 0;
        border: 1px solid var(--border-colors);
        border-radius: 5px;
        overflow: hidden;

        [dir=rtl] & {
            margin: 0 0 0 40px;
        }

        >button {
            width: 30px;
            height: 30px;
            padding: 5px;
            background-color: var(--white);
            border-radius: 0 !important;

            &:nth-of-type(1) {
                border-right: 1px solid var(--border-colors);
                border-left: 0;

                [dir=rtl] & {
                    border-left: 1px solid var(--border-colors);
                    border-right: 0;
                }
            }

            svg {
                width: 17px;
                height: 17px;
            }
        }

        > {
            svg {
                path {
                    fill: var(--gray);
                }
            }
        }

        .selectedMode {
            path {
                fill: var(--secondary-1);
            }

            border-color: var(--secondary-1);
        }
    }
}

.openCloseBtn {
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    border-radius: 0 5px 0 7px;
    width: var(--menu-trx-width);
    height: var(--menu-trx-width);
    padding: 5px;
    background-color: var(--secondary-1);
    transition: all 0.3s ease;

    [dir=rtl] & {
        border-radius: 5px 0 7px 0;
        left: 0;
        right: auto;
    }

    svg {
        width: 15px;
        height: 15px;
        transform: rotate(0deg);

        path {
            fill: var(--white);
        }

        [dir=rtl] & {
            transform: rotate(180deg);
        }
    }

    &[class*=show-hide-menu] {
        border-radius: 5px;

        svg {
            transform: rotate(180deg);

            [dir=rtl] & {
                transform: rotate(0deg);
            }
        }
    }
}

.attachementsCard {
    padding-top: 5px;
    height: 55px;
}

.d-flex {
    display: flex;
}

.d-block {
    display: block;
}

.d-block {
    display: block;
}

.hiddenBlock {
    display: none !important;
}

.headerSpinner {
    display: none;
    position: absolute;
    z-index: 1;
}

.numOfCard {
    margin-top: 40px;
    width: 100%;
    height: 100%;
    display: block;

    >div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        margin-bottom: 10px;

        &[class*=active] {
            background-color: var(--secondary-1);
        }
    }
}

.doteStatus {
    width: 3px;
    height: 100%;
    border-radius: 5px;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    right: auto;

    [dir=rtl] & {
        left: auto;
        right: 0px;
    }
}