.mainProgress {
    height: 30px;
}

.progressLabels {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ProgLabel {
    margin-bottom: 0.2rem;
    height: 14px;
    color: var(--text-3);
    font-weight: 500;
    line-height: 1.2;
    font-size: 11px;

    @media screen and (max-width: 1100px) {
        line-height: 1.4;
        font-size: 12px;

        [dir=rtl] & {
            font-size: 12px;
        }
    }
}

.progress {
    justify-content: flex-start;
    border-radius: 100px;
    align-items: center;
    display: flex;
    height: 5px;
    width: 100%;
    background-color: var(--border-colors);
}

.bar {
    // animation: progres 3s normal forwards;
    border-radius: 100px;
    height: 5px;
    width: 0;
    transition: .5s ease-in-out;
}