$logo-height: 70px;
$loginContainerHeight: 550px;
$loginContainerWidth: 1000px;
$loginContainerHeightIpad: 500px;
$loginContainerWidthIpad: 800px;
$loginContainerHeightIpadSm: 400px;
$loginContainerWidthIpadSm: 700px;

@mixin Container {
    position: relative;
    width: 100vw;
    height: var(--window-height);
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--black);

    * {
        font-family: var(--font-en);

        [dir=rtl] & {
            font-family: var(--font-ar);
        }
    }
}

.containerEn {
    @include Container();
    font-family: var(--font-en);
}

.containerAr {
    @include Container();
    font-family: var(--font-ar);
}

.loginLang {
    position: absolute;
    left: 30px;
    top: 30px;

    >button {
        font-weight: 500;
        font-size: 1.2rem;
        color: var(--white);
    }

    &:hover {
        >button {
            color: var(--secondary-1);
            transition: 0.2s;
            transform: scale(1.2);
        }
    }
}

.logo {
    width: 180px;
    height: $logo-height;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 0 10px;
    border-radius: var(--common-border-radius);

    >img {
        object-fit: contain;
        max-height: 100%;
    }

    @media screen and (max-width: 1024px) {
        margin-top: 7px;
    }
}

.confirmBtn {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    >button {
        background-color: var(--secondary-2);
        color: var(--white);
        width: 90px;
        height: var(--common-height);
        padding: 10px 0;
        border-radius: 20px;
        font-size: 1.2rem;
        transition: all 0.15s;
    }

    &.processing {
        >button {
            width: 40px !important;
            height: 40px;
            font-size: 0;
            border-radius: 50%;

            &::before {
                content: "";
                width: 20px;
                height: 20px;
                border: 2px dotted var(--white);
                border-radius: 50%;
                border-left: none;
                border-bottom: none;
                opacity: 1;
                animation: rotation 1s infinite linear;
            }
        }
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

.error_message {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--error);
    height: 50px;
    margin: 15px 0;

    @media screen and (max-width: 1024px) {
        height: 40px;
    }

    >label {
        font-size: 1.2rem;
        font-weight: 600;
    }
}

.userInfo {
    display: flex;
    align-items: center;
}

@mixin getStatus($color) {
    font-size: 1.2rem;
    color: var(--white);
    font-weight: 600;
    margin: 0 15px 0 0;

    [dir=rtl] & {
        margin: 0 0 0 15px;
    }

    >label {
        display: flex;
        align-items: center;

        &::before {
            content: "";
            display: flex;
            background-color: $color;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin: 3px 5px 0 0;

            [dir=rtl] & {
                margin: 3px 0 0 5px;
            }
        }
    }
}

.statusLogValid {
    @include getStatus(var(--success))
}

.statusLogNotValid {
    @include getStatus(var(--error))
}

.statusLogWarning {
    @include getStatus(var(--worning))
}

.kindaLogo {
    >svg {
        width: 150px;
        height: 30px;
    }

    @media screen and (max-width: 650px) {
        >svg {
            width: auto !important;
        }
    }
}

.qrData {
    transition: 0.7s ease;

    >div {
        width: 150px;
        height: 150px;

        >div {
            width: 150px;
            height: 150px;
            transition: 0.7s ease;
            position: fixed;
            right: 20px;
            left: auto;
            bottom: 10px;
            z-index: 99;

            [dir=rtl] & {
                left: 20px;
                right: auto;
            }

            >img {
                width: 100%;
                height: 100%;
            }
        }

        @media screen and (max-width: 768px) {
            width: 120px;
            height: 120px;

            >div {
                width: 120px;
                height: 120px;
            }
        }

        @media screen and (max-width: 650px) {
            width: 100px;
            height: 100px;

            >div {
                width: 100px;
                height: 100px;
            }
        }
    }
}

.qrCodeHover {
    transition: 0.7s ease;

    >div {
        width: 240px !important;
        height: 240px !important;
        right: 50% !important;
        left: auto !important;
        bottom: 50% !important;
        transform: translate(50%, 50%);

        [dir=rtl] & {
            transform: translate(-50%, 50%);
            left: 50% !important;
            right: auto !important;
        }
    }
}

.bgQRCode {
    display: none;

    &[class*="active"] {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        backdrop-filter: blur(20px);
        width: 100%;
        height: 100%;
        z-index: 98;
        background-color: rgba(0, 0, 0, .5);
    }
}

.qRCodeCloseBtn {
    display: none;

    &[class*="active"] {
        display: flex;
        position: fixed;
        top: 20px;
        right: 20px;
        left: auto;
        backdrop-filter: blur(20px);
        z-index: 98;

        [dir=rtl] & {
            left: 20px;
            right: auto;
        }

        >svg {
            width: 20px;
            height: 20px;
        }
    }
}

.containerPanel {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }

    @media screen and (max-width: 650px) {
        margin-top: 40px;
        height: calc(var(--window-height) - 20px - 30px - 10px - 88px);
    }
}

.valuationLogoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;

    svg {
        width: 300px;
    }

    @media screen and (max-width: 1024px) {
        width: 100%;
        height: 50px;
        margin-bottom: 20px;

        svg {
            width: 200px;
        }
    }
}

.loginContentContainer {
    display: flex;
    align-items: center;
    width: 50%;
    height: 100%;

    @media screen and (max-width: 1024px) {
        width: 100%;
        height: auto;
        justify-content: center;
    }
}

.loginContent {
    background-color: var(--white);
    border-radius: var(--common-border-radius);
    width: 500px;
    height: 630px;
    padding: 20px;
    z-index: 9;

    @media screen and (max-width: 1400px) {
        width: 450px;
        height: 500px;
    }

    @media screen and (max-width: 1024px) {
        width: 500px;
        height: 500px;
    }

    @media screen and (max-width: 650px) {
        width: calc(100% - 40px);
        margin: 0 20px;
        height: 500px;
    }
}

.logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc($logo-height + 10px);
    width: 100%;
}

.signInContainer {
    padding: 20px 40px 0;
    margin-top: 20px;
    position: relative;
    height: calc(100% - calc($logo-height + 10px) - 20px);

    @media screen and (max-width: 1400px) {
        margin-top: 10px;
        height: calc(100% - calc($logo-height + 10px) - 10px);
        padding: 20px 40px 0;
    }

    @media screen and (max-width: 650px) {
        padding: 10px 20px;
    }
}

.signInLbl {
    font-weight: 700;
    font-size: 2.2rem;
    margin-bottom: 20px;
    text-align: center;
    font-family: var(--font-en);

    [dir=rtl] & {
        font-family: var(--font-ar);
    }

    @media screen and (max-width: 650px) {
        text-align: center;
        font-weight: 600;
    }
}

.signInForm {
    margin: 20px 0 30px;

    label {
        font-size: 1.1rem !important;
        font-weight: 600 !important;
        color: #292d34;
    }

    >div {
        width: 100%;
        display: flex;
        align-items: center;
        border: 1px solid #d6d9de;
        border-radius: 3px !important;
        height: calc(var(--common-height) + 5px);

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 100%;

            svg {
                width: 17px;
                height: 17px;

                path {
                    fill: #b9bec7;
                }
            }
        }

        input {
            border: 0;
            height: 100%;
            font-family: var(--font-en);

            [dir=rtl] & {
                font-family: var(--font-ar);
            }
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
        }

        input:-moz-autofill {
            box-shadow: 0 0 0 30px white inset !important;
        }

        &:focus-within {
            border-color: var(--secondary-1) !important;

            span {
                svg {
                    path {
                        fill: var(--secondary-1);
                    }
                }
            }
        }
    }
}

//--------------------------

.footerLogin {
    position: absolute;
    bottom: 10px;
    margin: 0 20px;
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 40px)
}

.statusContainer {
    display: flex;
    align-items: center;
}