.usersLoginLogDialogContainer {
    height: calc(var(--num-of-logs) * 40px + calc(var(--modal-with-tabs-header-height) + var(--modal-with-tabs-footer-height) + var(--table-header-height) + 40px)) !important;
    min-height: calc(3 * 40px + calc(var(--modal-with-tabs-header-height) + var(--modal-with-tabs-footer-height) + var(--table-header-height) + 40px)) !important;
    max-height: var(--model-dialog-height);

    div[class*=modalBody] {
        height: calc(100% - var(--modal-with-tabs-header-height) - var(--modal-with-tabs-footer-height)) !important;
    }

    @media screen and (max-width: 1024px) {
        height: var(--model-dialog-height-sm) !important;
    }
}

.usersLoginLogTable {

    div[class^=v_table_body] {
        height: calc(var(--num-of-logs) * 40px) !important;
        min-height: calc(3 * 40px) !important;
        max-height: calc(var(--model-dialog-height) - var(--modal-with-tabs-header-height) - var(--modal-with-tabs-footer-height) - var(--table-header-height) - 40px) !important;
    }

    div[class^=v_table_header],
    div[class^=v_table_row] {
        display: flex;
        align-items: center;

        >div {
            text-align: center;
            display: flex;
            justify-content: center;
            position: relative;

            >svg {
                width: 17px;
                height: 17px;
                margin: 0 5px 0 0;

                [dir=rtl] & {
                    margin: 0 0 0 5px;
                }
            }

            &:nth-child(1) {
                width: var(--col-1);
            }

            &:nth-child(2) {
                width: var(--col-2);

                >span>svg>g>path {
                    fill: var(--text-3);
                }
            }

            &:nth-child(3) {
                width: var(--col-3);

                >span>svg {
                    path {
                        fill: var(--text-3);
                    }
                }
            }

            &:nth-child(4) {
                width: var(--col-4);
            }

            &:nth-child(5) {
                width: var(--col-5);

                &[class*=fail] {
                    >span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 17px;
                        height: 17px;
                        background-color: var(--error);
                        border-radius: 50px;
                        margin: 0 5px 0 0;

                        [dir=rtl] & {
                            margin: 0 0 0 5px;
                        }

                        >svg {
                            width: 8px;
                            height: 8px;

                            >g {
                                stroke: white;
                                stroke-width: 2px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.rowIcon {
    position: absolute;
    top: 50%;
    left: 1%;
    right: auto;
    transform: translateY(-50%);

    >svg {
        width: 17px;
        height: 17px;
        margin: 0 5px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 5px;
        }
    }
}