.containerWaiting {
    width: 100%;
    height: 100%;
    background: var(--white);
    left: 0;
    right: 0;
    top: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    min-height: 470px !important;
    display: flex;
    align-items: center;
    justify-content: center;

    >div {
        display: flex;
        align-items: center;
        justify-content: center;

        >svg {
            width: 60px;
            height: 60px;
        }
    }
}
.spinner {
    position: absolute;
    width: 100px !important;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    padding: 0 !important;

    &::after {
        content: "";
        width: 100px;
        height: 100px;
        margin: auto;
        border: 2px solid var(--secondary-1);
        border-bottom-color: transparent;
        border-radius: 50%;
        animation: button-loading-spinner 0.7s ease-out infinite;

        [dir=rtl] & {
            right: 10px;
            left: auto;
        }

    }
}

@keyframes button-loading-spinner {
    0% {
        transform: rotate(0turn);
    }

    100% {
        transform: rotate(1turn);
    }
}