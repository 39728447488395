.valuationContractRow {
    >div {
        display: flex;
        justify-content: center;
    }
}

.valuationContractTable {

    div[class^="table_body"]>div>div {
        min-height: 60px;
    }
    
    div[class^=table_header_checkbox]>span,
    div[class^=table_body]>div>div {
        >div:nth-child(1) {
            width: var(--col-1);
        }

        >div:nth-child(2) {
            width: var(--col-2);
            overflow: visible;
        }

        >div:nth-child(3) {
            width: var(--col-3);
        }

        >div:nth-child(4) {
            width: var(--col-4);
        }

        >div:nth-child(5) {
            width: var(--col-5);
        }

        >div:nth-child(6) {
            width: var(--col-6);
        }

        >div:nth-child(7) {
            width: var(--col-7);
        }

        >div:nth-child(8) {
            width: var(--col-8);
            text-align: center;
        }

        >div:nth-child(9) {
            width: var(--col-9);
        }

        >div:nth-child(10) {
            width: var(--col-10);
        }
    }
}

.notifiction {
    background-color: var(--error);
    position: absolute;
    top: -12px;
    width: 16px;
    height: 16px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    border-radius: 50%;
  }