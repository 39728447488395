.installations {
  width: 80%;
}

// ******************location Table**********************
.locationTableContainer {
  overflow-x: auto;
}

.locationTable {
  div[class^="v_table_body"] {
    height: calc(
      var(--model-dialog-height) - var(--modal-with-steps-header-height) - 57px -
        var(--table-header-height) - var(--modal-footer-height) - 10px
    );
  }

  @media screen and (max-width: 1024px) {
    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      width: 100%;
      min-width: 100% !important;
    }
  }

  div[class^="v_table_header"],
  div[class^="v_table_row"] {
    display: flex;
    align-items: center;

    > div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }
    }
  }
}

// ******************location Table**********************
// ******************banks Table**********************
.banksTableContainer {
  overflow-x: auto;
}

.banksTable {
  div[class^="v_table_body"] {
    height: calc(
      var(--model-dialog-height) - var(--modal-with-steps-header-height) - 57px -
        var(--table-header-height) - var(--modal-footer-height) - 10px
    );
  }

  div[class^="v_table_header"],
  div[class^="v_table_row"] {
    display: flex;
    align-items: center;

    > div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }

      &:nth-child(5) {
        width: var(--col-5);
      }

      &:nth-child(6) {
        width: var(--col-6);
      }
    }
  }
}

// ******************location Table**********************
.uploudSignatur {
  margin-top: 10px;
}
