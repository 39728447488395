$img-width: 50px;
$margin-card: 15px;
$card-width: 400px;


.cardTabPanel{
    height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - var(--header-pages-panel-height) - $margin-card - var(--table-pagination-height) - var(--tab-container-height) - 10px) !important;
}

.cardPanel {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, $card-width), 1fr));
    grid-auto-rows: minmax(min-content, max-content);
    height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - var(--header-pages-panel-height) - 15px - var(--table-pagination-height));
    overflow: auto;
    padding: $margin-card 0;
}

.cardContainer {
    padding: $margin-card;
    box-shadow: rgba(30, 86, 151, 0.12) 0px 7px 29px 0px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: all 0.1s ease-out;

    // &::before {
    //     content: "";
    //     position: absolute;
    //     z-index: -1;
    //     top: -30px;
    //     left: -30px;
    //     right: auto;
    //     background: var(--primary-color-2);
    //     height: 32px;
    //     width: 32px;
    //     border-radius: 32px;
    //     transform: scale(1);
    //     transform-origin: 50% 50%;
    //     transition: transform 0.3s ease-out;

    //     [dir=rtl] & {
    //         right: -30px;
    //         left: auto;
    //     }
    // }

    &:hover {
        background-color: var(--primary-color-2);

        &::after {
            display: none;
        }

        .userName {
            color: var(--white);
        }

        .cardLbl {
            color: rgba(255, 255, 255, 0.8);
        }

        .cardVal {
            color: var(--white);
            background-color: transparent !important;
        }

        .executeAction {
            right: 0;
            left: auto;
            background-color: var(--white);
            color: var(--primary-color-2);

            [dir=rtl] & {
                left: 0;
                right: auto;
            }
        }
    }
}

.createInvoice {
    &::after {
        content: "";
        position: absolute;
        top: 15px;
        width: 30px;
        height: 30px;
        right: 20px;
        left: auto;
        opacity: 0;
        margin: -10px 0 0 -10px;
        background-color: var(--primary-color-2);
        border-radius: 100px;
        -webkit-animation: ripple 1.4s infinite;
        animation: ripple 1.4s infinite;

        [dir=rtl] & {
            left: 20px;
            right: auto;
        }
    }
}

@-webkit-keyframes ripple {
    0% {
        opacity: 1;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes ripple {
    0% {
        opacity: 1;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.userInfoCard {
    display: flex;
    align-items: center;
}

.imgContainer {
    width: $img-width;
    height: $img-width;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 0px 8px;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    border-radius: var(--common-border-radius);
    margin: 0 10px 0 0;

    [dir=rtl] & {
        margin: 0 0 0 10px;
    }

    img {
        width: $img-width !important;
        height: $img-width !important;
        border-radius: 5px;
    }
}

.userName {
    width: calc(100% - $img-width - 10px);
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.1rem;
}

.cardDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: $margin-card;

    >div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: calc(25% - 10px);
        margin: 0 10px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 10px;
        }

        &:last-of-type {
            margin: 0 !important;

            [dir=rtl] & {
                margin: 0 !important;
            }
        }
    }
}

.cardLbl {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    color: var(--text-3);
}

.cardVal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    border-radius: 3px;
    padding: 0 3px;
    background-color: #668fff14;
    height: 30px;

    label {
        width: calc(100% - 6px);
        direction: ltr;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;

        [dir=rtl] & {
            direction: ltr;
        }
    }
}

.executeAction {
    background-color: var(--primary-color-2);
    color: var(--white);
    padding: 7px 10px;
    border-radius: 3px 0 0 3px;
    position: absolute;
    right: calc(100px * -1);
    left: auto;
    transition: all 0.3s ease-out;

    [dir=rtl] & {
        border-radius: 0 3px 3px 0;
        left: calc(100px * -1);
        right: auto;
    }
}