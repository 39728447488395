.optionContainer {
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;

    >div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 60%;

        >label {
            width: 100%;

            &:first-of-type {
                color: var(--text-3);
                font-size: 0.9rem;
            }
        }
    }

}