$model-height-sm: 550px;
$model-height: 650px;

// ******************messagesTasks Table**********************

.messagesTasksRow {
  > div {
    display: flex;
    justify-content: center;
  }
}

.messagesTasksTable {
  div[class^="v_table_body"] {
    height: calc(
      var(--model-dialog-height) - var(--modal-with-tabs-header-height) -
        var(--modal-with-tabs-footer-height) - 20px - 53px -
        var(--table-header-height)
    );
  }

  @media screen and (max-width: 650px) {
    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      width: 100%;
      min-width: 2000px !important;
    }
  }

  div[class^="v_table_header"],
  div[class^="v_table_row"] {
    display: flex;
    align-items: center;

    > div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }

      &:nth-child(5) {
        width: var(--col-5);
      }

      &:nth-child(6) {
        width: var(--col-6);
      }

      &:nth-child(7) {
        width: var(--col-7);
      }

      &:nth-child(8) {
        width: var(--col-8);
      }

      &:nth-child(9) {
        width: var(--col-9);
      }

      &:nth-child(10) {
        width: var(--col-10);
      }

      &:nth-child(11) {
        width: var(--col-11);
      }

      &:nth-child(12) {
        width: var(--col-12);
      }

      &:nth-child(13) {
        width: var(--col-13);
      }

      &:nth-child(14) {
        width: var(--col-14);
      }

      &:nth-child(15) {
        width: var(--col-15);
      }

      &:nth-child(16) {
        width: var(--col-16);
      }

      &:nth-child(17) {
        width: var(--col-17);
      }

      &:nth-child(18) {
        width: var(--col-18);
      }
    }
  }
}

// ******************messagesTasks Table**********************
