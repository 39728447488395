$margin-top: 10px;
$card-width : 375px;
$ContainerStatusWidth : 200px;

.comparisonConfigurationModal {
    height: auto !important;

    >div {
        &[class*="modalBody"] {
            height: calc(100% - var(--modal-with-tabs-header-height) - var(--modal-with-tabs-footer-height)) !important;
        }
    }
}

.estimationMapContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: $margin-top;
    height: calc(100vh - var(--header-height) - var(--master-panel-padding-top) - var(--table-search-panel-height) - $margin-top);

    >div {
        &:nth-child(1) {
            width: $card-width;
            height: 100%;
        }

        &:nth-child(2) {
            width: calc(100% - $card-width);
            height: 100%;
            margin: 0 0 0 10px;

            [dir=rtl] & {
                margin: 0 10px 0 0;
            }

            @media screen and (max-width: 1000px) {
                margin: 0;
            }
        }
    }

    @media screen and (max-width: 1000px) {
        flex-direction: column-reverse;
        height: 100%;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        >div {
            width: 100%;
            margin-bottom: 10px;

            &:nth-child(1) {
                width: 100%;
                margin-bottom: 70px;
            }

            &:nth-child(2) {
                width: 100%;
                min-height: 500px;
                margin-bottom: 20px;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .smCard {
        display: flex;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 635px) {
    .smCard {
        display: flex;
        justify-content: center;
    }
}

.mainMap {
    height: 100%;

    >div {
        height: 100%;
    }
}

.headerButton {
    min-width: 210px;
    display: flex;
    align-items: center;
    justify-content: center;

    >button {
        &:nth-of-type(1) {
            margin: 0 10px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 10px;
            }
        }
    }
}

.cardRow {
    display: block !important;
}

// ************Start Mixins*************

@mixin Date {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 11px;

    [dir=rtl] & {
        font-size: 12px;
    }

    >span {
        color: var(--dark-gray);

        &:nth-of-type(1) {
            margin: 0 5px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 5px;
            }
        }
    }
}

@mixin Price-Card {
    color: #00B4C1;
    background-color: #F0FBFC;
    padding: 3px 10px;
    border-radius: 20px;
    min-width: 100px;
    text-align: center;

    >label {
        font-weight: 500;
        font-size: 10px;

        [dir=rtl] & {
            font-size: 11px;
        }
    }
}

@mixin Status-Card {
    text-align: center;
    background-color: #2935C8;
    color: var(--white);
    min-width: 50px;
    max-width: 80px;
    padding: 4px 8px;
    border-radius: 10px;
    font-size: 10px;

    [dir=rtl] & {
        font-size: 10px
    }
}

@mixin Types-Container {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
}

@mixin Location-Card {
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    >span {
        display: flex;
        align-items: center;
        justify-content: center;

        >svg {
            width: 11px;
            height: 11px;
        }
    }

    >label {
        font-size: 12px;
        color: var(--balck);
        margin: 0 0 0 6px;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 500;

        [dir=rtl] & {
            margin: 0 6px 0 0;
        }
    }
}

@mixin UserCard {
    display: flex;
    align-items: center;

    >span {
        display: flex;
        align-items: center;
        justify-content: center;

        >svg {
            width: 13px;
            height: 13px;
        }
    }

    >label {
        color: var(--blue);
        margin: 0 0 0 6px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: 500;
        font-size: 11px;

        [dir=rtl] & {
            font-size: 10px;
            margin: 0 6px 0 0;
        }
    }
}

.locationLbl {
    color: var(--dark-gray) !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

// ************ُEnd Mixins***************
// ************card*************

.tabContainer {
    >div {
        // padding: 0;

        >div {
            &:nth-last-of-type(1) {
                // height: calc(var(--window-height) - var(--header-height) - var(--master-panel-padding-top) - var(--table-search-panel-height) - 10px - 40px);
                overflow: auto;
                // margin: 0;
                // width: 100%;
                height: calc(var(--window-height) - var(--header-height) - var(--master-panel-padding-top) - var(--table-search-panel-height) - 10px - 40px - 50px);
            }
        }
    }
}

.mapTemplates {
    padding: 0 0 var(--frm-template-panel-padding-top) 0 !important;

    >div {
        >div {
            &:nth-last-of-type(1) {
                // height: calc(var(--window-height) - var(--header-height) - var(--master-panel-padding-top) - var(--table-search-panel-height) - 10px - 40px);
                overflow: auto;
                // margin: 0;
                // width: 100%;
                height: calc(var(--window-height) - var(--header-height) - var(--master-panel-padding-top) - var(--table-search-panel-height) - 10px - 40px - 50px);
            }
        }
    }

    @media screen and (max-width: 1000px) {
        padding: var(--frm-template-panel-padding-top) 10px !important;
    }
}

.tempMap {
    padding: 10px 0 !important;

    @media screen and (max-width: 1000px) {
        padding: var(--frm-template-panel-padding-top) 10px !important;
    }
}

.cardDate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    margin: 0 10px;

    >label {
        background-color: rgb(0, 0, 0, 70%);
        color: var(--white);
        padding: 5px;
        border-radius: 5px;
        font-size: 12px;
    }
}

.cardContainer {
    background-color: var(--white);
    width: calc($card-width - 20px);
    min-height: 180px;
    margin: 10px;
    margin-bottom: 0;
    border-radius: 10px 0 0 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: relative;
    padding: 10px;
    padding-bottom: 0;

    .tempMap {
        @media screen and (max-width: 1000px) {
            width: 255px;
        }

        @media screen and (max-width: 635px) {
            width: 100%;
        }
    }
}

@mixin LineCard {
    width: 4px;
    height: 100%;
    position: absolute;
    border-radius: 8px;
    left: 0;
    top: 0;
    right: auto;

    [dir=rtl] & {
        left: auto;
        right: 0;
    }

}

.lineCard {
    @include LineCard();
    background-color: var(--success);
}

.lineSelectedCard {
    @include LineCard();
    background-color: var(--secondary);
}

.containerCode {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.orderInfo {
    display: flex;
    align-items: center;
    font-size: 11px;

    [dir=rtl] & {
        font-size: 12px;
    }
}

.dateCard {
    @include Date();
    margin: 0 27px 0 0;

    [dir=rtl] & {
        margin: 0 0 0 27px;
    }
}

.dateMapCard {
    @include Date();
    margin: 0 10px 0 0;

    [dir=rtl] & {
        margin: 0 0 0 10px;
    }
}

.priceCard {
    @include Price-Card();
}

.priceMapCard {
    @include Price-Card();
}

.currency {
    font-size: 9px;
    margin: 0 0 0 5px;

    [dir=rtl] & {
        font-size: 8px;
        margin: 0 5px 0 0;
    }
}

.sectionOne {
    display: flex;
    width: 100%;
}

.bgImage {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0 10px 0 10px;
    margin: 0 10px 0 0;
    width: 105px;
    height: 105px;

    [dir=rtl] & {
        border-radius: 10px 0 10px 0;
        margin: 0 0 0 10px;
    }
}

.statusCard {
    @include Status-Card();
    margin: 5px;
}

.statusMapsCard {
    @include Status-Card();
    margin: 0 10px 7px 0;

    [dir=rtl] & {
        margin: 0 0 7px 10px;
    }
}


.contentCard {
    width: calc(100% - 90px - 10px);
}

.contentMapsCard {
    width: calc(100% - $ContainerStatusWidth);
    padding: 0 5px;
    border-right: 1px solid var(--borders-color);
    border-left: 0;

    [dir=rtl] & {
        border-left: 1px solid var(--borders-color);
        border-right: 0;
    }
}

.containerInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.codeCard {
    font-weight: 600;
    font-size: 12px;

    [dir=rtl] & {
        font-size: 13px;
    }
}

.containerPriceCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.typesContainer {
    @include Types-Container();
}

.typesMapContainer {
    @include Types-Container();
    margin: 0;

    >div {
        &:nth-of-type(1) {
            width: 50% !important;
        }

        &:nth-of-type(2) {
            width: 50% !important;
        }
    }
}

.twoPriceMapCard {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 7px;
}

.typeTitle {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    >label {
        font-size: 11px;

        [dir=rtl] & {
            font-size: 10px
        }
    }

    >svg {
        width: 11px;
        height: 11px;
        margin: 0 7px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 7px;
        }
    }
}

.measureNum {
    display: flex;
    align-items: center;
    height: 18px;
    font-size: 10px;

    [dir=rtl] & {
        font-size: 11px;
    }
}

.measureMetaer {
    color: var(--black);
    font-weight: 500;
}

.measurePrice {
    display: flex;
    align-items: center;
    font-weight: 500;
}

.measureLine {
    width: 1px;
    height: 80%;
    background-color: var(--borders-color);
    margin: 0 7px;
}

.pinkColor {
    color: #FE34DE;
}

.purpleColor {
    color: #6634FE;
}



.locationCard {
    @include Location-Card();
    border-bottom: 1px solid var(--borders-color);
}

.locationMapCard {
    @include Location-Card();
    padding-bottom: 5px;

    >label {
        font-size: 11px;

        [dir=rtl] & {
            font-size: 10px
        }
    }
}

.userMapCard {
    @include UserCard();
    height: 30px;
    border-top: 1px solid var(--borders-color);
}

.userPinMap {
    @include UserCard();
    height: 24px;

    >label {
        font-size: 11px;

        [dir=rtl] & {
            font-size: 10px
        }
    }
}

.mapCardContainer {
    background-color: var(--white);
    width: 560px;
    min-height: 130px;
    margin: 0 10px 10px;
    margin-bottom: 0;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 10px;
    padding-bottom: 0;
    position: relative;
    z-index: 2;

    &::after {
        content: "";
        position: absolute;
        top: -20px;
        right: auto;
        left: 50%;
        border: 11px solid transparent;
        border-bottom-color: var(--white);
        transform: translateX(-50%);

        [dir=rtl] & {
            right: 47%;
            left: auto;
        }
    }
}

// ************card*************
// ************Start Map card*************
.estimationMapPin {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    font-family: var(--font-en);
    position: relative;

    [dir=rtl] & {
        font-family: var(--font-ar);
    }

    // >div {
    //     &:nth-of-type(2) {
    //         padding-top: 10px;
    //         display: none;
    //     }
    // }

    // &:hover {
    //     >div {
    //         &:nth-of-type(2) {
    //             display: block;
    //         }
    //     }
    // }
}

.containerNotes {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    border-top: 1px solid var(--borders-color);
    height: 30px;
}

.addNotes {
    display: flex;
    align-items: center;
    width: calc(100% - 90px);
    margin: 0 10px 0 0;

    [dir=rtl] & {
        margin: 0 0 0 10px;
    }
}

.notesBtn {
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0;

    [dir=rtl] & {
        margin: 0 0 0 10px;
    }

    >svg {
        width: 15px;
        height: 15px;
    }

    >label {
        font-weight: 500;
    }
}

.notTextArea {
    height: 30px !important;
}

.printBtn {
    background-color: transparent;
    color: var(--black);
    height: 28px;
    min-width: 90px;
    font-size: 11px;

    [dir=rtl] & {
        font-size: 10px
    }

    >svg {
        width: 14px;
        height: 14px;
        margin: 0 8px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 8px;
        }

        path {
            fill: var(--primary-1);
        }
    }
}

.containerStatus {
    width: $ContainerStatusWidth;
    padding: 0 0 0 10px;
    display: flex;
    flex-direction: column;
    margin-top: 5px;

    [dir=rtl] & {
        padding: 0 10px 0 0;
    }
}

.priceMapsCard {
    display: flex;
    margin-bottom: 5px;

    >div {
        &:nth-of-type(1) {
            margin: 0 7px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 7px;
            }
        }
    }
}

// ************End Map card*************

// ************Paginate Style*************

.pagination {
    display: flex;
    list-style: none;
    outline: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.pagination>.activeButton>a {
    background-color: var(--primary-1) !important;
    color: var(--white) !important;
    font-weight: 600;
    border-radius: 5px;
}

.pagination>li>a {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--secondary);
    cursor: pointer;
    font-family: var(--font-ar);
}

.breakLabel {
    >a {
        cursor: initial !important;

        >label {
            color: var(--secondary) !important;
            font-weight: 600;
            font-size: 18px;
            height: 9px;
            line-height: 0;
        }
    }
}

.next_prev_button {
    width: 30px;
    height: 30px;

    svg {
        width: 15px;
        height: 15px;

        g {
            path {
                stroke: var(--primary-1)
            }
        }

        [dir=rtl] & {
            transform: rotate(180deg);
        }
    }

}

.next_prev_disabled {
    cursor: not-allowed;

    * {
        cursor: not-allowed !important;
    }

    a {
        svg {
            width: 15px;
            height: 15px;

            path {
                stroke: var(--dark-gray) !important;
            }
        }
    }
}

// ************Paginate Style*************

// ************orderBy Buttons Style*************
.orderByButtonContainer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid var(--light-gray);
    width: 100%;

    >button {
        width: 25%;
    }
}

@mixin OrderByButton {
    position: relative;
    transition: all ease .2s;
    transform: translate3d(0, 0, 0);
    height: 35px;
    min-width: 70px;
    padding: 0 10px;
    margin: 0 10px 0 0;
    font-weight: 500;
    font-size: 13px;
    border-radius: 0;

    [dir=rtl] & {
        margin: 0 0 0 10px;
    }

    &:after {
        content: '';
        transition: all .3s cubic-bezier(1, 0, 0, 1);
        will-change: transform, box-shadow, opacity;
        position: absolute;
        height: 3px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        border-radius: 3px 3px 0px 0px;
        background: lighten(rgb(1, 148, 114), 10%);
        opacity: 0;
        transform: scale(0, 0.5);
    }

    &:last-of-type {
        margin: 0;
    }

    @media screen and (max-width: 450px) {
        min-width: 60px;
        font-size: 10px;
        padding: 0 5px;
    }
}

.activeOrderByButton {
    @include OrderByButton();
    color: var(--primary-1);
    font-weight: 500;

    &:after {
        opacity: 1;
        transform: scale(1, 1);
    }
}

.inactiveOrderByButton {
    @include OrderByButton();
    color: var(--black);
}

// ************orderBy Buttons Style*************
// ********loading**************
.mainLoading {
    position: relative;
    height: calc(100% - 10px);
    display: flex;
    align-items: center;
    justify-content: center;
}

// ********loading**************

.clearButton {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    border-bottom: 1px solid var(--white);
    color: var(--secondary-1);

    >svg {
        margin: 0 5px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 5px;
        }
    }

    path {
        fill: var(--secondary-1);
    }

    &:hover {
        border-color: var(--primary-color);
    }
}

.comparisonConfigurationModalContaner {
    position: relative;

    >button {
        position: absolute;
        top: -2px;
        right: 5px;
        left: auto;

        [dir=rtl] & {
            left: 5px;
            right: auto;
        }
    }
}