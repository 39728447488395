.tabContentStyle {
    height: calc(var(--window-height) - var(--side-panel-header-height) - var(--side-panel-footer-height) - 30px - var(--tab-container-height)) !important;
    overflow: auto;
    padding: 10px 10px;
}

.propertiesTypesTabs {
    padding: 0 !important;

    >button {
        width: 50%;
        margin: 0 !important;

        &::before {
            display: none;
        }

        svg {
            display: none;
        }
    }
}