@mixin summary-section {
    display: flex;
    align-items: center;
    width: 100%;
}

.summaryCode {
    @include summary-section();
    height: 50px !important;

    >div {
        display: flex;
        align-items: center;

        &:nth-of-type(1) {
            font-weight: 700;
            font-size: 1.3rem;
        }

        &:nth-of-type(2) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
}

.summaryRef {
    @include summary-section();
    padding: 0;
    height: 100%;

    >div {
        font-weight: 500;
        font-size: 1.22rem;

        >label {
            color: #756f86 !important;
            font-weight: 500 !important;
            padding: 8px 0 !important;
        }

        &:nth-of-type(1) {
            border-right: 1px solid #f1f1f1;
            border-left: 0;

            [dir=rtl] & {
                border-left: 1px solid #f1f1f1;
                border-right: 0;
            }
        }

        &:nth-of-type(2) {
            padding: 0 0 0 10px;

            [dir=rtl] & {
                padding: 0 10px 0 0;
            }
        }
    }
}

.payMethodTitle {
    display: flex;
    align-items: center;
    padding-top: 5px;

    >label {
        font-size: 1.1rem;
        color: #756f86 !important;
        font-weight: 500 !important;
    }
}

@mixin amountCell {
    width: calc(100% - 50px);
    display: flex;
    align-items: center;

    >label {
        font-weight: 600;
        font-size: 1.25rem;
        color: #1183e7;
        max-width: calc(100% - 18px - 10px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    >span {
        margin: 0 5px !important;
        font-weight: 600;
        font-size: 1.25rem;
        color: #1183e7;
    }
}

.descSummaryContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 80px !important;

    >div {
        width: 100% !important;
        height: 100% !important;
    }
}

.descSummary {
    font-weight: 600;
    font-size: 1.1rem;
    margin-top: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.totalsTrx {
    width: calc(100% - 20px);
    background-color: var(--white);
    padding: 0 10px;
    margin: 0 10px;
    margin-bottom: 10px !important;
    border: 1px solid #f1f1f1;

    >div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 10px;
        border-top: 1px solid #f1f1f1;

        &:first-of-type {
            border: none;
        }

        >div {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &:first-of-type {
                width: 30%;
                justify-content: flex-start;

                label {
                    color: var(--black) !important;
                    font-weight: 500 !important;
                    font-size: 1.2rem;
                }
            }

            &:last-of-type {
                width: 70%;

                label {
                    font-size: 1.25rem;
                    color: var(--primary-color);
                }
            }
        }
    }
}