$margin-top-newCard-dialog: 10px;
$dilog-height: var(--model-dialog-height);
$card-width: 470px;

// -***************New card******************
.comperDialog {
    width: 1500px !important;
    height: $dilog-height;
}

.mainComper {
    width: 100%;
    height: 100% !important;
    display: flex;
}

.newCardContainer {
    width: $card-width;
    margin: 0 20px 0 0;
    height: calc($dilog-height - 55px - 60px - 20px - 20px - 20px) !important;
    padding: 10px 20px;
    overflow: hidden auto;

    [dir=rtl] & {
        margin: 0 0 0 20px;
    }
}

.imgSection {
    position: relative;
    border-radius: 10px;
    height: 160px;
    width: 100%;

    img {
        border-radius: 10px;
        width: 100%;
        max-height: 100%;
    }
}

@mixin Type-Card {
    position: absolute;
    background-color: var(--white);
    color: var(--black);
    border-radius: 20px;
    min-width: 80px;
    height: 30px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 5px;

    >label {
        color: #D8A172;
        font-weight: 500;
    }
}

.typeNewCard {
    @include Type-Card();
    left: 5px;
    right: auto;

    [dir=rtl] & {
        right: 5px;
        left: auto;
    }
}

// .typeCard {
//     @include Type-Card();
//     right: 5px;
//     left: auto;

//     [dir=rtl] & {
//         left: 5px;
//         right: auto;
//     }
// }

// .typeSimCard {
//     @include Type-Card();
//     left: 5px;
//     right: auto;

//     [dir=rtl] & {
//         right: 5px;
//         left: auto;
//     }
// }

.newCardCode {
    font-size: 1.6rem;
    color: var(--title-text);
    font-weight: 500;
    margin-top: $margin-top-newCard-dialog;
}

.newCardPriceNum {
    font-size: 1.7rem;
    color: var(--secondary-1);
    font-weight: 500;
    margin-top: $margin-top-newCard-dialog;

    >span {
        margin: 0 0 0 10px;

        [dir=rtl] & {
            margin: 0 10px 0 0;
        }
    }
}

.newCardDate {
    color: var(--text-3);
    font-weight: 500;
    font-size: 1.2rem;
    margin-top: $margin-top-newCard-dialog;
}

@mixin newCard-User-Info {
    display: flex;
    align-items: center;
    margin-top: $margin-top-newCard-dialog;
    font-size: 1.2rem;
    font-weight: 500;

    >svg {
        width: 15px;
        height: 15px;
        margin: 0 10px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 10px;
        }
    }
}

.newCardUser {
    @include newCard-User-Info();
}

.newCardLocation {
    @include newCard-User-Info();
    color: var(--text-3);
}

.userInfoBox {
    width: 100%;
    margin-top: $margin-top-newCard-dialog;
    border-radius: 7px;

    >div {
        display: flex;
        align-items: center;
    }
}

@mixin newCard-Area-Section {
    >div {
        display: flex;
        align-items: center;
        width: 50%;
    }
}

.areaSection {
    @include newCard-Area-Section ();
}

.buildingSection {
    @include newCard-Area-Section ();
    margin-top: 15px;
}

.lblName {
    color: var(--text-3);
    width: 80px;
}

.lblVal {
    width: calc(100% - 80px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    >span {
        margin: 0 0 0 5px;

        [dir=rtl] & {
            margin: 0 5px 0 0;
        }
    }
}

.galleryTitle {
    font-size: 1.6rem;
    color: var(--title-text);
    font-weight: 500;
    margin: 20px 0;
}

.multiImages {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: $margin-top-newCard-dialog;
    width: 100%;
}

.imgs {
    width: calc(33% - 10px);
    margin: 0 10px 0 0;

    [dir=rtl] & {
        margin: 0 0 0 10px;
    }

    img {
        max-width: 100%;
        max-height: 100px;
        border-radius: 5px;
    }
}

// -***************New card******************

.mapContainer {
    width: calc(100% - $card-width - 20px);
    height: calc($dilog-height - 55px - 60px - 20px - 20px) !important;

    >div {
        &:nth-of-type(1) {
            width: 100%;
            height: 100% !important;
        }
    }
}


// .similarMapCardContainer {
//     width: 240px;
//     background-color: var(--white);
//     min-height: 255px;
//     margin: 0 10px 10px;
//     margin-bottom: 0;
//     border-radius: 10px;
//     padding-bottom: 0;
//     position: relative;
//     z-index: 2;
// }

.similarRealState {
    position: absolute;
    background-color: transparent;
    display: flex;
    align-items: center;
    width: calc(100% - 30px - 30px - $card-width);
    height: 180px !important;
    overflow: auto hidden;
    bottom: calc(60px + 20px);
    // padding: 0 40px;

    >div {
        position: relative;
        top: 0px;
    }
}

// .imgBgCard {
//     position: relative;
//     width: 100%;
//     height: 120px;
//     border-radius: 10px 10px 0 0;

//     >img {
//         border-radius: 10px 10px 0 0;
//         width: 100%;
//         max-height: 100%;
//     }
// }

// .mapCardDetails {
//     padding: 10px;
// }

// .mainSimData {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
// }

// .simPrice {
//     color: var(--secondary-1);
// }

@mixin MapCardDetails {
    display: flex;
    align-items: center;
    margin-top: 8px;
    overflow: hidden;

    >span {
        margin: 0 10px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 10px;
        }

        >svg {
            width: 13px;
            height: 13px;
        }
    }

    >label {
        font-size: 0.9rem;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

// .userMapCard {
//     @include MapCardDetails();

//     >label {
//         color: var(--text-2);
//     }
// }

// .locationMapCard {
//     @include MapCardDetails();

//     >label {
//         color: var(--text-3);
//     }
// }

// .contentMapCard {
//     display: flex;
//     align-items: center;
//     margin-top: 8px;

//     >div {
//         width: 50%;
//     }
// }

// .pricesContainer {
//     display: flex;
//     align-items: center;
// }

@mixin Labels {
    font-size: 0.9rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;
}

// .lblMapCardDetails {
//     @include Labels();
//     width: 55px;
//     margin: 0 10px 0 0;

//     [dir=rtl] & {
//         margin: 0 0 0 10px;
//     }
// }

// .landAreaContainer {
//     display: flex;
//     align-items: center;
//     width: calc(100% - 55px);

//     >span {
//         font-size: 0.8rem;
//         font-weight: 500;
//         margin: 0 0 0 3px;

//         [dir=rtl] & {
//             margin: 0 3px 0 0;
//         }
//     }
// }

// .valMapCardDetails {
//     @include Labels();
// }

.currency {
    color: var(--text-3);
    margin: 0 0 0 8px;

    [dir=rtl] & {
        margin: 0 8px 0 0;
    }
}

// .measurePrice {
    //     margin: 0 0 0 5px;

    //     [dir=rtl] & {
    //         margin: 0 5px 0 0;
    //     }

    //     >label {
    //         font-size: 1rem;
    //         color: var(--text-3);
    //     }
    // }