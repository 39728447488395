$dilog-height: 600px;

.mapDialog {
    width: 1000px;
    height: $dilog-height;
}

.mapContainer {
    height: calc($dilog-height - 55px - 60px - 20px - 20px) !important;
    >div{
        height: 100%;
    }
}