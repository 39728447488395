.optionContainer {
    display: flex;
    align-items: center;
    height: 35px;

    >svg {
        width: 18px;
        height: 18px;
        margin: 0 10px;
    }
}