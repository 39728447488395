$image-width: 90px;
$header-height :150px;
$label-width : 100px;

.uploadImgContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;

    img {
        border-radius: 50%;
        height: 100%;
    }

    .camera {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--white);
        color: var(--secondary-1);
        border: 1px solid var(--border-colors);
        padding: 5px;
        border-radius: 5px;
        margin-top: 10px;
        font-size: 0.8rem;
        cursor: pointer;

        >input {
            display: none;
        }

        >label {
            cursor: pointer;
        }

        &:hover {
            background-color: var(--blue);

            svg {
                path {
                    fill: var(--white);
                }
            }
        }
    }
}

.containerUploudImg {
    margin-top: 32px;
}

.tabContentStyle {
    height: calc(var(--window-height) - $header-height - var(--side-panel-footer-height) - 30px - var(--tab-container-height)) !important;
    overflow: auto;
    padding: 10px 10px;
}

.townsTabs {
    padding: 0 !important;

    >button {
        width: 50%;
        margin: 0 !important;

        &::before {
            display: none;
        }

        svg {
            display: none;
        }
    }
}

.profileContainer {
    width: 100%;
    height: $header-height;
    position: relative;

    >div {
        width: 100%;
        height: 50%;
    }

    >button {
        position: absolute;
        width: 25px;
        height: 25px;
        top: 10px;
        right: 10px;
        left: auto;

        [dir=rtl] & {
            right: auto;
            left: 10px;
        }

        svg {
            width: 16px;
            height: 16px;

            g {
                stroke: var(--white);
            }
        }

        &:hover {
            svg {
                transform: scale(1.1);

                g {
                    stroke: var(--red);
                }
            }
        }
    }
}

.profileBg {
    background-image: var(--header-side-panel-bg);
}

.userDetailes {
    margin-top: calc(($image-width / 2) + 15px);
    height: calc(50% - (($image-width / 2) + 15px)) !important;
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: hidden;

    label {
        font-size: 1.2rem;
        font-weight: 600;
        width: calc(100% - 20px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: flex;
        justify-content: center;
        margin: 0 10px;
    }
}

.uploadUserProfileImg {
    width: $image-width;
    height: $image-width;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
    background-color: var(--white);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%);

    >div {
        &[class *=uploadOneImg] {
            width: $image-width;
            height: $image-width;
            border-radius: 50%;
            border: none;
            background-color: var(--white);
            box-shadow: 0px 4px 4px 0px #00000040;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            @media screen and (max-width: 1024px) {
                width: $image-width;
                height: $image-width;
            }

            label {
                display: none;
            }

            >div {
                &[class *=oneImgPanel] {
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;

                    >div {
                        overflow: hidden;
                        max-width: 100%;
                        max-height: 100%;
                    }

                    img {
                        object-fit: contain;
                    }

                    >button {
                        top: 5px;
                        left: auto;
                        right: 5px;

                        [dir=rtl] & {
                            right: 5px;
                            left: auto;
                        }

                    }
                }
            }
        }
    }
}

.containerPanel {
    height: calc(var(--window-height) - $header-height - var(--side-panel-footer-height)) !important;
}

.checkPanel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - $label-width);
}

.settingSection {
    display: flex;
    justify-content: space-between;
    width: 100%;

    >div {
        &:nth-of-type(1) {
            width: 50%;
        }

        &:nth-of-type(2) {
            width: 30%;
        }
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;

        >div {
            width: 100% !important;
        }
    }
}

.uploudSignatur {
    >div {
        &[class*="uploadOneImg"] {
            width: 180px;
            height: 180px;
        }
    }
}

.containerUploudImg {
    margin-top: 32px;
    flex-direction: column !important;

    >div {
        &:nth-of-type(2) {
            margin-top: 20px;
        }
    }
}

.materTable {

    @media screen and (max-width: 1024px) {

        div[class^="v_table_header"],
        div[class^="v_table_body"] {
          width: 100% !important;
          min-width: 900px !important;
        }
      }
    div[class^=v_table_body] {
        height: calc(var(--model-dialog-height) - var(--modal-with-tabs-header-height) - var(--table-header-actions-height) - var(--table-header-actions-margin-bottom) - var(--table-header-height) - var(--modal-footer-height) - 10px - 10px) !important;
    }

    div[class^=v_table_header],
    div[class^=v_table_row] {
        display: flex;
        align-items: center;

        >div {
            text-align: center;
            display: flex;
            justify-content: center;

            &:nth-child(1) {
                width: var(--col-1);
            }

            &:nth-child(2) {
                width: var(--col-2);
            }

            &:nth-child(3) {
                width: var(--col-3);
            }

            &:nth-child(4) {
                width: var(--col-4);
            }

            &:nth-child(5) {
                width: var(--col-5);
            }

            &:nth-child(6) {
                width: var(--col-6);
            }

            &:nth-child(7) {
                width: var(--col-7);
            }

            &:nth-child(8) {
                width: var(--col-8);
            }
        }
    }
}

.timeLimitationCheckbox {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.check {
    background: var(--background, transparent) !important;
}