$modalSidePadding: 30px;
$modalVerticalPadding: 20px;
$modalHeaderHeight: var(--modal-with-tabs-header-height);
$modalFooterHeight: var(--modal-with-tabs-footer-height);
$modalHeight: calc(var(--window-height) - var(--window-height) * 0.1 - 40px - 20px);
// $modalHeight: var(--window-height);
$modalHeightSm: var(--window-height);
$Modal-Close-Btn-width: 50px;

$modal-with-tabs-height: 70vh;
$modal-with-tabs-width: 65vw;
$modal-with-tabs-padding: 20px;
$modal-with-tabs-padding-sm: 10px;
$border-radius-container: 25px;

@mixin modalFooter() {
  .footer {
    height: $modalFooterHeight;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 0 $modal-with-tabs-padding;

    .saveBtn {
      color: var(--white);
      background-color: var(--secondary-2);
      width: 110px;
      height: 38px;
      line-height: 38px;
      padding: 0 20px;
      position: relative;
      overflow: hidden;

      &:hover {
        box-shadow: 0 8px 25px -12px var(--secondary-2);
      }

      svg {
        width: 15px;
        height: 15px;
        position: absolute;
        top: 50%;
        left: 10px;
        right: auto;

        [dir="rtl"] & {
          right: 10px;
          left: auto;
        }

        transform: translateY(-50%);
      }

      &:disabled {
        background-color: var(--disable-colors);
        color: var(--text-4);

        svg {
          path {
            stroke: var(--text-4);
            fill: none !important;
          }
        }

        &:hover {
          box-shadow: none !important;
        }
      }
    }

    .cencelBtn {
      color: var(--text-4);
      background-color: var(--white);
      width: 110px;
      height: 38px;
      line-height: 38px;
      padding: 0 20px;
      position: relative;
      margin: 0 5px;

      span {
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px;
      }

      svg {
        width: 15px;
        height: 15px;
        position: absolute;
        top: 50%;
        left: 10px;
        right: auto;

        [dir="rtl"] & {
          right: 10px;
          left: auto;
        }

        transform: translateY(-50%);

        g {
          stroke: var(--text-4);
        }
      }

      &:hover {
        filter: brightness(0.95);

        svg {
          width: 15px;
          height: 15px;

          g {
            stroke: var(--red);
          }
        }
      }
    }

    @media screen and (max-width: 650px) {
      border-top: 1px solid var(--border-colors);
      box-shadow: rgba(149, 157, 165, 0.2) 8px 0px 24px;
      padding: 0 $modal-with-tabs-padding-sm;

      >button {
        &[class*="cencelBtn"] {
          display: none !important;
        }

        &[class*="saveBtn"] {
          // background-color: var(--blue);
          width: 100% !important;
          font-size: 1.25rem;
        }
      }
    }
  }
}

.modal {
  // display: none;
  position: fixed;
  visibility: hidden;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: -10;

  &.show {
    background-color: rgba(2, 2, 2, 0.35);
    visibility: visible;
    opacity: 1;
    z-index: 11;

    >div {
      opacity: 1;
      top: 50%;
    }
  }

  @include modalFooter();
}

/* Modal Dialog */
.modalDialog {
  overflow: hidden;
  transition: 0.3s top, 0.3s transform;
  position: absolute;
  left: 50%;
  top: -500px;
  transform: translate(-50%, -50%);
  opacity: 0;
  background-color: white;
  margin: auto;
  max-height: $modalHeight;
  border-radius: 4px;
  width: var(--medium-dialog-width);
  height: var(--model-dialog-height);

  &.tableModal {
    height: $modalHeight;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    max-height: $modalHeightSm;
    height: 100%;

    &.tableModal {
      height: $modalHeightSm;
    }
  }

  >div {
    &[class*="modalBody"] {
      height: calc(var(--model-dialog-height) - var(--modal-with-tabs-header-height) - var(--modal-with-tabs-footer-height));
    }
  }

  @media screen and (max-width: 1024px) {
    height: var(--model-dialog-height-sm);

    >div {
      &[class*="modalBody"] {
        height: calc(var(--model-dialog-height-sm) - var(--modal-with-tabs-header-height) - var(--modal-with-tabs-footer-height)) !important;
      }
    }
  }
}

.fitDialog {
  height: auto !important;

  >div {
    &[class *=modalBody] {
      height: calc(100% - var(--modal-with-tabs-header-height) - var(--modal-with-tabs-footer-height)) !important;
    }
  }
}

.fitHeightDialog {
  @media screen and (max-width: 768px) {
    width: 100%;
    max-height: auto !important;
    height: auto !important;

    >div {
      &[class *=modalBody] {
        height: calc(100% - var(--modal-with-tabs-header-height) - var(--modal-with-tabs-footer-height)) !important;
        max-height: calc(100% - var(--modal-with-tabs-header-height) - var(--modal-with-tabs-footer-height)) !important;
      }
    }
  }
}

/* The Close Button */
.modalCloseBtn {
  color: black;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  width: $Modal-Close-Btn-width;
  height: 95%;

  [dir="rtl"] & {
    left: 12px;
    right: auto;
  }

  svg {
    width: 15px;
    height: 15px;

    path {
      stroke: gray;
    }
  }

  @media screen and (max-width: 650px) {
    svg {
      path {
        stroke: var(--white);
      }
    }
  }
}

.modalHeader {
  position: relative;
  background-color: white;
  border-radius: 18px 18px 0px 0px;
  height: $modalHeaderHeight;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-colors);
  padding: 0 5px 0 $modalSidePadding;

  [dir="rtl"] & {
    padding: 0 $modalSidePadding 0 5px;
  }

  >div {
    width: calc(100% - $Modal-Close-Btn-width);
    display: flex;
    align-items: center;
    justify-content: space-between;

    >label {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 1.1rem;
      color: var(--title-text);
    }

    svg {
      width: 20px;
      height: 20px;
      margin: 0 5px 0 0;

      path {
        fill: var(--secondary-2);
      }

      [dir="rtl"] & {
        margin: 0 0 0 5px;
      }

      circle {
        fill: var(--secondary-2);
      }
    }
  }

  @media screen and (max-width: 650px) {
    background-image: var(--header-side-panel-bg);
    border-radius: 0 !important;

    >div {
      label {
        color: var(--white);
      }
    }
  }
}

.modalBody {
  padding: $modalVerticalPadding $modalSidePadding;
  max-height: calc($modalHeight - $modalHeaderHeight - $modalFooterHeight);

  @media screen and (max-width: 1024px) {
    padding: $modalVerticalPadding;
    max-height: calc($modalHeight - var(--modal-with-tabs-header-height) - var(--modal-with-tabs-footer-height)) !important;
  }

  @media screen and (max-width: 768px) {
    height: calc($modalHeightSm - var(--modal-with-tabs-header-height) - var(--modal-with-tabs-footer-height));
    max-height: calc($modalHeightSm - var(--modal-with-tabs-header-height) - var(--modal-with-tabs-footer-height)) !important;
  }

  &.noFooter {
    max-height: calc($modalHeight - $modalHeaderHeight);

    @media screen and (max-width: 768px) {
      max-height: calc($modalHeightSm - $modalHeaderHeight);
    }
  }

  &.tableModalBody {
    padding: 0;
    height: calc($modalHeight - $modalHeaderHeight - $modalFooterHeight);

    @media screen and (max-width: 768px) {
      height: calc($modalHeightSm - $modalHeaderHeight - $modalFooterHeight);
    }

    div[class^="table_body"] {
      height: calc($modalHeight - $modalHeaderHeight - $modalFooterHeight - var(--table-header-height));

      @media screen and (max-width: 768px) {
        height: calc($modalHeightSm - $modalHeaderHeight - $modalFooterHeight - var(--table-header-height));
      }
    }

    &.noFooter {
      height: calc($modalHeight - $modalHeaderHeight);

      @media screen and (max-width: 768px) {
        height: calc($modalHeightSm - $modalHeaderHeight);
      }

      div[class^="table_body"] {
        height: calc($modalHeight - $modalHeaderHeight - var(--table-header-height));

        @media screen and (max-width: 768px) {
          height: calc($modalHeightSm - $modalHeaderHeight - var(--table-header-height));
        }
      }
    }
  }

  overflow: auto;
}

.modalFooter {
  height: $modalFooterHeight;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    width: 140px;
    margin: 0 10px;

    &:hover {
      filter: brightness(0.95);
    }
  }

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
}

@media screen and (max-width: 650px) {
  .rowSmModel {
    >div {
      width: 100%;
    }
  }
}

.rowSmModelFilters {
  >div {
    width: 100%;

    &:nth-of-type(1) {
      width: calc(100% - 50px);
    }

    &:nth-of-type(2) {
      width: 50px;

      >button {
        min-width: 40px !important;
        width: 40px;
        padding: 0;

        >label {
          display: none;
        }
      }
    }
  }
}

//----------------------------------------Modal with steps
.ModalWithTabs {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: -10;

  &.show {
    background-color: rgba(2, 2, 2, 0.35);
    visibility: visible;
    opacity: 1;
    z-index: 11;
    backdrop-filter: blur(1px);

    >div {
      opacity: 1;
      top: 50%;
    }
  }
}

.modalDialogWithSteps {
  overflow: hidden;
  transition: 0.3s top, 0.3s transform;
  position: absolute;
  left: 50%;
  top: -500px;
  transform: translate(-50%, -50%);
  opacity: 0;
  background-color: #3f73b7;
  margin: auto;
  max-height: $modalHeight;
  border-radius: var(--common-border-radius);
  // width: $modal-with-tabs-width;
  // height: $modal-with-tabs-height;
  width: var(--medium-dialog-width);
  height: var(--model-dialog-height);
  display: flex;
  box-shadow: rgba(48, 71, 103, 0.8) 5px 5px 18px 0px;

  @media screen and (max-width: 1024px) {
    width: var(--larg-dialog-width);

    >div {
      &[class*="modalSteps"] {
        width: 20%;
        // overflow: auto !important;
      }

      &[class*="modalContent"] {
        width: 80%;
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    max-height: $modalHeightSm;
    height: 100%;

    >div {
      &[class*="modalSteps"] {
        width: 25%;
      }

      &[class*="modalContent"] {
        width: 75%;
      }
    }
  }

  @media screen and (max-width: 650px) {
    flex-direction: column;
    width: 100% !important;
    max-height: $modalHeightSm !important;
    height: 95% !important;
    border-radius: 0 !important;
    background-color: var(--white) !important;

    >div {
      &[class*="modalSteps"] {
        width: 100%;
      }

      &[class*="modalContent"] {
        width: 100%;
        border-radius: 0 !important;
      }
    }
  }

  >div {
    >div {
      &[class*="body"] {
        height: calc($modalHeight - var(--modal-with-tabs-header-height) - var(--modal-with-tabs-footer-height)) !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    height: var(--model-dialog-height-sm) !important;

    >div {
      >div {
        &[class*="body"] {
          height: calc(var(--model-dialog-height-sm) - var(--modal-with-tabs-header-height) - var(--modal-with-tabs-footer-height)) !important;
        }
      }
    }
  }

  @media screen and (max-width: 650px) {
    >div {
      >div {
        &[class*="body"] {
          height: calc(var(--model-dialog-height-sm) - var(--modal-steps-height-sm) - var(--modal-with-tabs-header-height) - var(--modal-with-tabs-footer-height)) !important;
        }
      }
    }
  }
}

@mixin modal-Steps {
  width: var(--modal-steps-width);
  padding: $modal-with-tabs-padding 0;
  background-color: #3f73b7;

  @media screen and (max-width: 650px) {
    padding: $modal-with-tabs-padding-sm 0;
  }
}

.modalSteps {
  @include modal-Steps();

  @media screen and (max-width: 650px) {
    border-bottom: 1px solid var(--border-colors);
    position: relative;
    display: flex;
    align-items: center;
    padding: 0;
    width: 100% !important;
    height: var(--modal-steps-height-sm);
    background-color: var(--white);
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    scrollbar-width: none;
    -ms-overflow-style: none;

    >button {
      min-width: auto;
      white-space: nowrap;
      width: auto;
      margin: 0 10px !important;
    }
  }
}

.tabCrumbs {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-family: var(--font-en);

  [dir="rtl"] & {
    font-family: var(--font-ar);
  }

  >label {
    color: var(--text-2);
  }

  button {
    height: 20px;
    font-size: 15px;
    color: var(--secondary-1);
    padding: 0;
  }

  .arrow {
    width: 8px;
    height: 20px;
    margin: 0 10px;
    transform: rotate(-180deg);

    [dir="rtl"] & {
      transform: rotate(0deg);
    }

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 8px;
      height: 15px;
    }
  }

  @media screen and (max-width: 650px) {
    font-size: 1.2rem;

    >label {
      color: var(--white);
    }

    button {
      display: none;
    }

    .arrow {
      display: none;
    }
  }
}

@mixin Modal-Content {
  width: calc(100% - var(--modal-steps-width));
  background-color: var(--white);
  border-radius: $border-radius-container var(--common-border-radius) var(--common-border-radius) $border-radius-container !important;

  [dir="rtl"] & {
    border-radius: var(--common-border-radius) $border-radius-container $border-radius-container var(--common-border-radius) !important;
  }

  @media screen and (max-width: 650px) {
    border-radius: var(--common-border-radius) !important;

    [dir="rtl"] & {
      border-radius: var(--common-border-radius) !important;
    }
  }

  .header {
    height: var(--modal-with-tabs-header-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-colors);
    position: relative;
    padding: 0 0 0 $modal-with-tabs-padding;

    [dir="rtl"] & {
      padding: 0 $modal-with-tabs-padding 0 0;
    }

    @media screen and (max-width: 650px) {
      background-image: var(--header-side-panel-bg) !important;
    }
  }

  .body {
    overflow-y: auto;
    height: calc(#{$modal-with-tabs-height} - var(--modal-with-tabs-header-height) - var(--modal-with-tabs-footer-height));
    padding: 10px $modal-with-tabs-padding 0;

    @media screen and (max-width: 650px) {
      padding: 10px $modal-with-tabs-padding-sm 0;
    }
  }

  @media screen and (max-width: 650px) {
    height: 100%;
  }

  @include modalFooter();
}

.largeSize {
  width: var(--larg-dialog-width) !important;

  @media screen and (max-width: 650px) {
    width: 100% !important;
  }
}

.smallSize {
  width: var(--small-dialog-width) !important;

  @media screen and (max-width: 1024px) {
    width: 80% !important;
  }

  @media screen and (max-width: 768px) {
    width: 100% !important;
  }
}

.modalContent {
  @include Modal-Content();

  .body {
    >div {
      display: none;

      &.active {
        display: block;
      }
    }
  }
}

.modalContentSm {
  @include Modal-Content();

  .body {
    >div {
      display: none;

      &.active {
        display: block;
      }
    }
  }

  @media screen and (max-width: 650px) {
    .body {
      >div {
        display: block;

        &.active {
          display: block;
        }
      }
    }
  }
}

.hideModalSteps {
  @include modal-Steps();

  @media screen and (max-width: 650px) {
    display: none !important;
  }
}

.mainMultiSelect {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (max-width: 1400px) {
    bottom: 60px !important;
  }

  >div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    height: 40px;
    padding: 7px;
    border-radius: 5px;

    >button {
      svg {
        transform: rotate(-180deg);

        [dir=rtl] & {
          transform: rotate(0);
        }
      }
    }
  }
}

.multiSelectBtn {
  min-width: 40px;
  height: calc(var(--common-height) - 7px);
  line-height: calc(var(--common-height) - 7px);
  overflow: hidden;
  transition: all 0.15s;
  font-size: 1rem;
  background-color: transparent;
  margin: 0 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;

  [dir="rtl"] & {
    margin: 0 0 0 10px;
  }

  >svg {
    width: 22px;
    height: 22px;

    g {
      fill: var(--white);

      g {
        g {
          path {
            fill: var(--white);
          }
        }
      }
    }
  }
}

.multiSelectLeftArrow {
  >svg {
    transform: rotate(0deg) !important;

    [dir=rtl] & {
      transform: rotate(-180deg) !important;
    }
  }
}

.multiSelectPagination {
  display: flex;
  align-items: center;
  font-family: var(--font-en) !important;
  color: var(--primary-color-2);
  cursor: auto !important;
  min-width: 60px;

  [dir="rtl"] & {
    font-family: var(--font-en) !important;
  }

  >span {
    font-size: 1.1rem;

    &:nth-of-type(2) {
      margin: 0 10px;
    }
  }
}

.disabledPaginationBtn {
  background-color: var(--disable-colors);
  color: var(--text-3);
  border-color: var(--disable-colors);
  cursor: not-allowed;
}

.nextPrevBtnsModel {
  top: auto !important;
  bottom: 15px !important;
}

.hideInMobile {
  display: block !important;

  @media screen and (max-width: 650px) {
    display: none !important;
  }
}

.showInMobile {
  display: none !important;

  @media screen and (max-width: 650px) {
    display: flex !important;
  }
}

.logeBtnContainer {
  display: flex;
  align-items: center;
}

.logeDateBtn {
  width: var(--common-height);
  height: var(--common-height);
  margin: 0 10px 0 0;

  [dir=rtl] & {
    margin: 0 0 0 10px;
  }

  svg {
    width: 20px;
    height: 20px;

    g,
    path {
      fill: var(--primary-color-2) !important;
    }

    @media screen and (max-width: 650px) {

      g,
      path {
        fill: var(--white) !important;
      }
    }
  }

  &:hover {
    transform: scale(1.1);
  }
}