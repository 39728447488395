$content-margin-top: 10px;
$header-title-height: 30px;
$card-height: 100px;

.container {
    // background-color: var(--white);
    height: calc(100% - var(--header-pages-panel-height) - 10px);
    margin-bottom: 10px;
    padding: 10px 0;
}

.headerPage {
    >div {
        &:nth-of-type(1) {
            margin-bottom: 0 !important;
        }
    }
}

.title {
    font-size: 1.2rem !important;
}

.headerContainer {
    display: flex;
    align-items: center;
    width: 75%;

    @media screen and (max-width: 1024px) {
        display: none;
    }

    >div {
        width: 33%;
        display: flex;
        align-items: center;
        margin: 0 10px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 10px;
        }

        >label {
            margin: 0 10px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 10px;
            }
        }

        >div {
            width: calc(100% - 100px);
        }

        &:last-of-type {
            >div {
                width: 100%;
            }
        }
    }

}

.headerContainerSm {
    display: none;

    @media screen and (max-width: 1024px) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;


        >div {
            min-width: calc(100% - 30px);
        }

        @media screen and (max-width: 650px) {
            justify-content: flex-start;

            >div {
                width: calc(100% - 30px - 15px) !important;
                min-width: calc(100% - 30px - 15px) !important;

                >div {
                    width: 100% !important;
                    min-width: 100% !important;
                }
            }
        }

        >button {
            min-width: 30px;
            min-height: 30px;
            background-color: var(--blue);
            margin: 0 0 0 15px;

            [dir=rtl] & {
                margin: 0 15px 0 0;
            }

            svg {
                width: 18px;
                height: 18px;

                path {
                    fill: var(--white);
                }
            }
        }
    }
}

.actionHeaderSm {
    display: none;

    @media screen and (max-width: 1024px) {
        display: block;
        position: absolute;
        top: calc(var(--header-pages-panel-height) + 10px);
        z-index: 999;
        background-color: var(--white);
        width: 400px;
        padding: 40px 30px;
        border-radius: 5px;
        right: calc((400px + 20px) * -1);
        left: auto;
        transition: 0.3s all ease;


        [dir=rtl] & {
            left: calc((400px + 20px) * -1);
            right: auto;
        }

        @media screen and (max-width: 650px) {
            width: 100%;
            right: calc((100% + 20px) * -1);
            left: auto;

            [dir=rtl] & {
                left: calc((100% + 20px) * -1);
                right: auto;
            }
        }

        &[class*=active] {
            right: 0;
            left: auto;

            [dir=rtl] & {
                left: 0;
                right: auto;
            }
        }
    }
}

.bgSm {
    @media screen and (max-width: 1024px) {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1;
        display: none;

        &[class*=active] {
            display: block;
        }
    }
}

.contentContainer {
    display: flex;
    height: 100% !important;
    // margin-top: $content-margin-top;
    // overflow: auto;

    >div {
        width: 50%;
        background-color: var(--white);
        // border: 1px solid var(--border-colors);
        border-radius: var(--common-border-radius);
        margin-bottom: 20px;
        height: 100%;

        &:nth-of-type(1) {
            width: calc(50% - 10px);
            margin: 0 10px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 10px;
            }
        }

        @media screen and (max-width: 1024px) {
            display: none;

            &[class*=active] {
                display: block;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;

        >div {
            width: 100% !important;
            margin: 0 !important;
        }
    }
}

.headerPagesPanel {
    @media screen and (max-width: 1024px) {
        position: relative;
    }
}

.headerTitlePanel {
    font-size: 1.2rem;
    height: $header-title-height;
    margin: 10px 0;
    display: flex;
    align-items: center;
    padding: 0 10px;
}

.headerIconPanel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 17px;
    margin: 0 10px 0 0;

    [dir=rtl] & {
        margin: 0 0 0 10px;
    }
}

.cards {
    margin-bottom: 10px;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 200px), 1fr));
    padding: 0 15px 10px;
    max-height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - 10px - 50px - 20px);
    overflow-y: auto;
    overflow-x: hidden;

    >div {
        &[class*=noDataFoundContainer] {
            position: static !important;
        }
    }
    @media screen and (max-width: 650px) {
        max-height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - 10px - 50px - 20px - 50px) !important;
    }
}

@mixin MainCardContainer {
    width: 100%;
    height: $card-height;
    // border: 1px solid #d0d4e4;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
    padding: 10px;
    overflow: hidden;
    cursor: pointer;

    * {
        cursor: pointer
    }
}

.cardContainer {
    @include MainCardContainer();
    animation: scale-animation 0.5s;

    &:hover {
        transform: scale(1.1);
        transition: all 0.15s;
    }

    >div {
        &:nth-of-type(1) {
            height: 5%;
        }

        &:nth-of-type(2) {
            height: calc(95% - 10px);
        }
    }
}

@keyframes scale-animation {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.waitingCardContainer {
    @include MainCardContainer();
    background-color: lightgray;
}

.imgContainer {
    background-color: #b66cff;
    width: 100%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.cardContent {
    margin-top: 10px;
}

.formTitle {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.favBtn {
    >button {
        >svg {
            width: 16px;
            height: 16px;
        }
    }
}

.formName {
    display: flex;
    align-items: center;
    width: calc(100% - 20px);

    >span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        margin: 0 5px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 5px;
        }

        >svg {
            width: 15px;
            height: 15px;

            path {
                fill: var(--module-color);
            }

            g {
                fill: var(--module-color);
            }
        }
    }

    >label {
        width: calc(100% - 20px - 5px);
        // white-space: nowrap;
        display: flex;
        align-items: center;
        height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
        margin-top: 3px;
    }
}

.crumbs {
    display: flex;
    align-items: center;
    width: 100%;

    >span {
        margin: 0 5px;

        svg {
            width: 10px;
            height: 12px;
        }
    }

    >div {
        max-width: 50%;
        overflow: hidden;

        >label {
            display: block;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: var(--text-3);
            font-size: 0.97rem;
        }
    }
}

.waitingContainer {
    position: relative;
    width: 100% !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.noDataFoundCard {
    grid-template-columns: none !important;
    height: 100%;
}

.tabsSm {
    display: none;

    @media screen and (max-width: 1024px) {
        height: var(--header-tabs-height);
        display: flex;
        width: 100%;
        background-color: var(--white);
        border-radius: 10px 10px 0 0;
        padding: 0 10px;
        border-bottom: 1px solid var(--border-colors);

        >button {
            width: 50%;
            height: 100%;
            position: relative;

            &::before {
                content: "";
                width: 100%;
                height: 2px;
                position: absolute;
                bottom: -1px;
                background-color: var(--secondary-2);
                display: none;
            }

            &[class*=active] {
                &::before {
                    display: block;
                }
            }
        }
    }
}