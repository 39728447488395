$card-height: 110px;
$tab-container-height: 50px;
$tab-container-height-sm: 40px;
$services-chart-width: 50px;

.tabsContainer {
    height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - var(--main-padding-V) - $tab-container-height - 10px - 10px);

    @media screen and (max-width: 650px) {
        height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - var(--main-padding-V) - $tab-container-height-sm - 10px - 10px) !important; 
    }
}

.inboxTitle{
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
}