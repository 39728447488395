$logo-height: 40px;
$logo-width: 120px;
$user-menu-width: 230px;
$user-menu-width-sm: calc(180px - 36px);
$user-menu-width-xs: calc(160px - 36px);

@mixin headerButton {
    width: 32px;
    height: 32px;
    margin: 0 20px 0 0;
    background-color: var(--white);
    border-radius: 50%;

    [dir=rtl] & {
        margin: 0 0 0 20px;
    }

    >svg {
        width: 17px;
        height: 17px;

        g {
            path {
                fill: var(--primary-color);
            }
        }

        path {
            fill: var(--primary-color);
        }
    }

    &:hover {
        transform: scale(1.1);

        >svg {
            transition: 0.2s;
        }
    }
}

.container {
    width: 100%;
    height: var(--header-height);
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--header-margin-bottom);
    padding: 0 0 0 10px;

    [dir=rtl] & {
        padding: 0 10px 0 0;
    }

    &::before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: var(--header-height);
        background-color: var(--white);
        right: 0;
        left: auto;
        z-index: -1;
        top: 0;
    }
}

.headerIcons {
    display: flex;
    align-items: center;

    >div {
        display: flex;
        align-items: center;

        >button {
            width: 32px;
            height: 32px;
            margin: 0 20px 0 0;
            background-color: var(--white);
            border-radius: 50%;

            [dir=rtl] & {
                margin: 0 0 0 20px;
            }

            &:last-of-type {
                margin: 0;
            }

            >svg {
                width: 17px;
                height: 17px;

                g {
                    path {
                        fill: var(--primary-color);
                    }
                }

                path {
                    fill: var(--primary-color);
                }
            }

            &:hover {
                transform: scale(1.1);

                >svg {
                    transition: 0.2s;
                }
            }
        }
    }

    // @media screen and (max-width: 765px) {
    //     position: relative;
    // }
}

// .headerDetailsSm {
//     @media screen and (max-width: 765px) {
//         flex-direction: column;
//         background-color: var(--primary-hover);
//         min-height: 128px;
//         top: 25px;
//         z-index: 9999;
//         width: 50px;
//         position: absolute;
//         border-radius: 5px;
//         padding: 10px;
//         box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

//         >button {
//             margin-bottom: 10px !important;
//         }
//     }
// }

.headerDetails {
    display: flex;
    align-items: center;
}

.notification {
    &::after {
        content: "";
        position: absolute;
        background-color: var(--red);
        width: 8px;
        height: 8px;
        display: block;
        border-radius: 50%;
        top: -1px;
        left: -1px;
    }
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $logo-height;
    width: $logo-width;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    margin: 0 0 0 20px;
    padding: 0 10px;

    [dir=rtl] & {
        margin: 0 20px 0 0;
    }

    >img {
        width: $logo-width !important;
        height: $logo-height !important;
        object-fit: contain;
    }

    @media screen and (max-width: 1025px) {
        margin: 0 0 0 20px;

        [dir=rtl] & {
            margin: 0 20px 0 0;
        }
    }
}

.headerInfo {
    display: flex;
    align-items: center;
}

.backArowInHeader {
    background-color: rgba(255, 255, 255, 0.8);
    width: 25px !important;
    height: 25px !important;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 0;

    [dir=rtl] & {
        margin: 0 0 0 10px;
    }


    >button {
        width: 30px;
        height: 50px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transform: rotate(180deg);

        svg {
            width: 24px;
            height: 24px;

            path {
                fill: none;
            }

            >g {
                >circle {
                    transition: stroke-dashoffset 0.3s ease;
                    stroke-dasharray: 95;
                    stroke-dashoffset: 95;
                }
            }

            [dir=rtl] & {
                transform: rotate(180deg);
            }
        }

        &:hover {
            >svg {
                >g {
                    >circle {
                        stroke-dashoffset: 0;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1025px) {
        display: none;
    }
}

.logOutBtn {
    &:hover {
        background-color: var(--pale-red) !important;
    }

    >svg {
        path {
            fill: var(--red) !important;
        }

        transform: rotate(180deg);

        [dir=rtl] & {
            transform: rotate(0deg);
        }
    }
}


.smMenu {
    display: none;

    @media screen and (max-width: 1025px) {

        display: flex;
        align-items: center;

        >button {
            width: 32px;
            height: 32px;
            background-color: var(--white);
            border-radius: 50%;
            margin: 0 15px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 15px;
            }

            >svg {
                width: 15px;
                height: 15px;

                g {
                    fill: var(--primary-color-2);
                }
            }
        }
    }
}

.title {

    @media screen and (max-width: 1025px) {
        display: none;
    }
}

.logoSm {
    display: none;

    @media screen and (max-width: 1025px) {
        display: block;
        margin: 0 10px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 10px;
        }

        >svg {
            width: 38px;
            height: 38px;
        }
    }
}

.notificationContainer {
    position: absolute;
}

.hideInMobile {
    @media screen and (max-width: 650px) {
        display: none;
    }
}



.hiddenMessagesMenu {
    display: none;
}

.messagesMenu {
    width: 415px;
    max-width: 415px;
    box-shadow: 0px 4px 7px 0 var(--border-colors) !important;
    border: none !important;
    opacity: 1;
    border-radius: 10px;
    font-family: var(--font-en);
    padding: 7px 7px 0;

    [dir=rtl] & {
        font-family: var(--font-ar);
    }

    li {
        width: 100%;
        padding: 2px 5px;
        border-bottom: 1px solid var(--border-colors);

        &:hover {
            background-color: var(--primary-hover) !important;

            * {
                color: var(--text-2) !important;
            }
        }
    }


    div {
        display: flex;
        overflow: hidden;
    }

    label {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1.1rem;
        margin-bottom: 2px;
    }

    * {
        color: var(--black);
        font-weight: 500;
    }

    & [class*=rc-dropdown-menu-item-selected] {
        background-color: transparent;

        &::after {
            display: none;
        }
    }

}

@mixin MsgTitle {
    font-weight: 700 !important;
    font-size: 1.2rem;
    margin-bottom: 10px
}

.msgTitleEn {
    @include MsgTitle();
    direction: ltr;
    font-family: var(--font-en)
}

.msgTitleAr {
    @include MsgTitle();
    direction: rtl;
    font-family: var(--font-ar)
}

@mixin Message {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center
}

.messageEn {
    @include Message();
    font-family: var(--font-en);
}

.messageAr {
    @include Message();
    flex-direction: row-reverse;
    font-family: var(--font-ar);

    label {
        text-align: right;
    }
}

.msgContainer {
    width: calc(100% - 35px);
    display: flex;
    flex-direction: column;
    overflow: hidden;

    >label {
        &:last-of-type {
            color: var(--text-3);
        }
    }
}

.msgLblEn {
    padding: 0 0 0 28px;
}

.msgLblAr {
    padding: 0 28px 0 0;
}

@mixin MessageCode {
    display: flex;
    align-items: center;

    >span {
        display: flex;
        align-items: center;

        >svg {
            width: 18px;
            height: 18px;
        }
    }

    >label {
        font-weight: 700 !important;
        height: 18px;
        line-height: 2;
    }
}

.msgCodeEn {
    @include MessageCode();
    direction: ltr;
    font-family: var(--font-en);

    >span {
        margin: 0 10px 0 0;
    }
}

.msgCodeAr {
    @include MessageCode();
    direction: rtl;
    font-family: var(--font-ar);

    >span {
        margin: 0 0 0 10px;
    }
}

@mixin MsgStatusIcon {
    >span {
        background-color: var(--secondary-1);
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        >svg {
            width: 15px;
            height: 15px;

            path {
                fill: var(--white);
            }

            g {
                fill: var(--white);
            }
        }
    }

}

.msgStatusIconEn {
    @include MsgStatusIcon();
    margin: 0 0 0 10px;
}

.msgStatusIconAr {
    @include MsgStatusIcon();
    margin: 0 10px 0 0;
}

.msgShowMoreEn {
    @include Message();
    color: var(--secondary-1) !important;
    font-family: var(--font-en);

    label {
        font-weight: 700 !important;
        color: var(--secondary-1) !important;
    }
}

.msgShowMoreAr {
    @include Message();
    color: var(--secondary-1) !important;
    font-family: var(--font-ar);

    label {
        font-weight: 700 !important;
        color: var(--secondary-1) !important;
        text-align: right;
    }
}

.dropMenuProf {
    border: none !important;
    box-shadow: none !important;
    background-color: var(--white);
    width: $user-menu-width !important;

    @media screen and (max-width: 650px) {
        width: $user-menu-width-sm;
    }

    @media screen and (max-width: 375px) {
        width: $user-menu-width-xs;
    }

    >div {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        max-height: 75vh;
        max-width: 100%;

        >div {
            display: flex;
            flex-direction: column;

            >li {
                &:last-of-type {
                    // border-top: 1px solid var(--borders-color) !important;

                    >button {
                        >svg {
                            path {
                                fill: var(--red);
                            }
                        }
                    }

                    label {
                        color: var(--red) !important;
                    }
                }

                &:hover {
                    background-color: var(--primary-hover);

                }
            }
        }
    }
}

@mixin dropBtn {
    width: 100%;
    height: 50px;
    display: flex;
    font-size: 0.8rem;
    border-bottom: 1px solid var(--border-colors);
    border-radius: 0;

    >label {
        margin: 0 7px;
        cursor: pointer;
        color: var(--primary-color);
        font-weight: 500;
        font-size: 1rem;

        >span {
            margin: 0 10px;
        }
    }

    >svg {
        width: 18px;
        height: 18px;
        min-width: 16px;
        margin: 0 10px;

        path {
            fill: var(--primary-color)
        }
    }
}

.dropBtnEn {
    @include dropBtn();
    justify-content: flex-start;
    font-family: var(--font-en);
}

.dropBtnAr {
    @include dropBtn();
    justify-content: flex-start;
    direction: rtl;
    font-family: var(--font-ar);
}

.userMenu {
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 20px;
    width: $user-menu-width;
    background-color: rgb(46 9 67 / 8%);
    cursor: pointer;

    * {
        cursor: pointer;
    }

    @media screen and (max-width: 650px) {
        width: $user-menu-width-sm;
    }

    @media screen and (max-width: 375px) {
        width: $user-menu-width-xs;
    }

    >button {
        @include headerButton();
        background-color: transparent !important;
        margin: 0 10px 0 0 !important;

        [dir=rtl] & {
            margin: 0 0 0 10px !important;
        }

        svg {
            g {
                path {
                    fill: var(--primary-color);
                }
            }

            path {
                fill: var(--primary-color);
            }
        }
    }

    >label {
        width: calc(100% - 32px - 32px - 10px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0 10px 0 0;
        font-size: 1.1rem;
        color: var(--primary-color);

        [dir=rtl] & {
            margin: 0 0 0 10px;
        }
    }

    >span {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        >svg {
            transform: rotate(-90deg);

            g {
                g {
                    path {
                        fill: var(--primary-color);
                    }
                }
            }
        }
    }
}