.container {
    display: flex;
    width: 100%;
    height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - var(--main-padding-V));
}

// ******************totals Table**********************
.linesContainer {
    display: flex;
    flex-direction: column;
    width: calc(100% - var(--summary-width-trx));
    background-color: var(--bg);
    border-radius: var(--common-border-radius);
    
    @media screen and (max-width: 1024px) {
        width: 100%;
        margin: 0 !important;
    }
}