.ReactVirtualized__List {
  // overflow: inherit !important;
  overflow-y: scroll !important;

  [dir="rtl"] & {
    direction: rtl !important;
  }

  // &::-webkit-scrollbar {
  //   display: none;
  // }

  // scrollbar-width: none;
  // -ms-overflow-style: none;
}

.ReactVirtualized__Grid {
  // overflow: inherit !important;
  overflow-y: scroll !important;
  overflow-x: auto;

  [dir="rtl"] & {
    direction: rtl !important;
  }

  // &::-webkit-scrollbar {
  //   display: none;
  // }

  // scrollbar-width: none;
  // -ms-overflow-style: none;
}

.table_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  @media screen and (max-width: 1024px) {

    div[class="table_body"],
    div[class="table_header_checkbox"],
    div[class="table-total"] {
      width: 100%;
      min-width: 1400px;
    }
  }
}

.cardInMobile {
  @media screen and (max-width: 650px) {
    >div {
      >div {
        &[class*="table_header"] {
          display: none;
        }

        &[class*="table_body"] {
          >div {
            flex-direction: column;
            padding: 10px 20px;
            border-radius: 5px;
            border: 1px solid var(--border-colors);

            >div {
              &[class*="overflowTableField"] {
                display: flex !important;
                align-items: center;
              }

              width: 100% !important;
              justify-content: flex-start;
              text-align: start;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

.multiLevelTable {
  >div {
    >div {
      &[class*="table_header"] {
        height: var(--table-header-multi-level-height) !important;
      }

      &[class*="table_body"] {
        height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - var(--table-header-multi-level-height) - var(--table-pagination-height) - 1px);
      }
    }
  }
}

@mixin reportsTableCell {
  border-right: 1px solid var(--border-reports-table);
  border-left: none;
  align-items: center;
  overflow: hidden;

  [dir="rtl"] & {
    border-left: 1px solid var(--border-reports-table);
    border-right: none;
  }
}

.reportsTable {
  >div {
    &[class*="table_hb_container"] {
      border-top: 1px solid var(--border-reports-table);
      border-left: 1px solid var(--border-reports-table);
      border-right: 1px solid var(--border-reports-table);

      >button {
        &[class*="settingsBtn"] {
          top: 3px;
          border: 0px !important;
          background-color: transparent !important;

          svg {
            path {
              fill: var(--white) !important;
            }
          }
        }
      }
    }

    >div {
      &[class*="table_header"] {
        border-bottom: 1px solid var(--border-reports-table) !important;
        background-color: var(--header-table-reports) !important;
        overflow-y: scroll !important;

        >div {
          @include reportsTableCell();

          &:last-of-type {
            border: 0;
          }

          &:first-of-type {
            border: 0;
          }

          label {
            font-size: 1rem !important;
            // color: var(--black);
            color: var(--white);
            font-weight: 500 !important;
          }
        }
      }

      &[class*="table_body"] {
        background-color: var(--white) !important;
        overflow-y: scroll !important;
        height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - var(--table-header-height) - var(--table-pagination-height) - 1px - var(--table-total-height));

        >div {
          box-shadow: none !important;
          min-height: 35px !important;
          border-bottom: 1px solid var(--border-reports-table) !important;
          margin-bottom: 0 !important;


          &:hover {
            background-color: var(--white) !important;
          }

          >div {
            min-height: 35px !important;
            align-items: center;
            overflow: hidden;

            >div {
              @include reportsTableCell();
              padding: 0 5px;
              min-height: 35px !important;
              width: 100%;
              display: flex;
              justify-content: center;

              label {
                max-height: 35px !important;
              }
            }

            &:last-of-type {
              >div {
                border: none;
              }
            }
          }
        }
      }

      &[class*="table-total"] {
        background-color: var(--white);
        border-top: 1px solid var(--primary-color-2);
        border-bottom: 1px solid var(--border-reports-table);
        height: 35px !important;
        overflow-y: scroll !important;

        >div {
          height: 100%;
          overflow: hidden;
          color: var(--primary-color) !important;

          >div {
            @include reportsTableCell();
            display: flex;
            justify-content: center;
            height: 100%;
            padding: 0 5px;
            font-weight: 500;
          }

          &:last-of-type {
            >div {
              border: none;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1400px) {

    div[class^="table_header"],
    div[class^="table_body"],
    div[class^="table-total"] {
      width: 100%;
      min-width: 1500px;
    }
  }
}

.v_reportsTable {
  >div {
    >div {
      &[class*="v_table_header"] {
        overflow: hidden;
        background-color: var(--header-table-reports) !important;
        border-top-color: var(--border-reports-table) !important;
        border-bottom-width: 1px !important;
        border-bottom-color: var(--primary-color-2) !important;

        >div {
          @include reportsTableCell();

          // &:last-of-type {
          //     border: none;
          // }

          &:first-of-type {
            border-right: 1px solid var(--border-reports-table);
            border-left: 1px solid var(--border-reports-table);
          }

          label {
            font-size: 1rem !important;
            color: var(--black);
            font-weight: 500 !important;
          }
        }
      }
    }
  }

  >div {
    >div {
      &[class*="v_table_body"] {
        >div {
          >div {
            >div {
              >div {
                &:nth-child(odd) {
                  background-color: var(--td-odd);
                }

                &:nth-child(even) {
                  background-color: var(--td-even);
                }
              }
            }
          }
        }
      }

      >div {
        >div {
          >div {
            >div {
              &[class*="v_table_row"] {
                border-bottom-color: var(--border-reports-table) !important;

                &:hover {
                  background-color: var(--tr-hover-reports) !important;
                }

                >div {
                  &:first-of-type {
                    border-left: 1px solid var(--border-reports-table);
                    border-right: 1px solid var(--border-reports-table);

                    [dir="rtl"] & {
                      border-left: 1px solid var(--border-reports-table);
                      border-right: 1px solid var(--border-reports-table);
                    }
                  }

                  @include reportsTableCell();
                  padding: 0 5px;
                  min-height: 35px !important;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  font-size: var(--reports-font-size);

                  label {
                    max-height: 35px !important;
                    font-size: var(--reports-font-size);
                  }

                  >div {
                    border-right: 0 !important;
                    border-left: 0 !important;

                    [dir="rtl"] & {
                      border-right: 0 !important;
                      border-left: 0 !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    >div {
      &[class*="table-total"] {
        // background-color: rgb(30  86 151 / 15%) !important;
        background-color: var(--white);
        border-radius: 0 !important;
        border-top: 1px solid var(--primary-color-2);
        border-bottom: 1px solid var(--border-reports-table);

        >div {
          height: 100%;
          overflow: hidden;
          @include reportsTableCell();
          display: flex;
          justify-content: center;
          color: var(--tr-total-color) !important;

          &:first-of-type {
            border-left: 1px solid var(--border-reports-table) !important;
            border-right: 1px solid var(--border-reports-table) !important;
            border-radius: 0 !important;

            [dir="rtl"] & {
              border-left: 1px solid var(--border-reports-table) !important;
              border-right: 1px solid var(--border-reports-table) !important;
            }
          }

          &:last-of-type {
            border-radius: 0 !important;

            >div {
              border: none;
            }
          }
        }
      }
    }
  }
}

.nameLblMobile {
  display: none;

  @media screen and (max-width: 650px) {
    display: flex !important;
    align-items: center;
    margin: 0 5px 0 0;
    color: var(--label-color) !important;

    [dir="rtl"] & {
      margin: 0 0 0 5px;
    }
  }
}

.table_header {
  position: relative;
  background: transparent;
  height: var(--table-header-height);
  color: var(--white);
  font-weight: 500;
  display: flex;
  align-items: flex-end;
  border-bottom: 2px solid var(--primary-color-2);
  position: relative;
  width: var(--table-width);

  >div[class*="hided-column"] {
    border: none !important;
    overflow: hidden !important;
    visibility: hidden !important;
  }

  >div {
    height: 100%;
    display: flex;
    align-items: center;

    >label {
      width: 100%;
      color: var(--text-4);
      text-align: center;
      font-weight: 600;
      font-family: var(--font-en);
      font-size: 1rem;

      [dir="rtl"] & {
        font-family: var(--font-ar);
      }
    }
  }

  button {
    width: 100%;
    height: 100%;
    line-height: var(--table-header-height);
    color: var(--th-color);
    text-align: center;
    cursor: default;

    label {
      width: 100%;
    }

    >svg {
      margin: 0 0 0 5px;

      [dir="rtl"] & {
        margin: 0 5px 0 0;
      }
    }

    * {
      pointer-events: none;
    }
  }
}

.v_table_header {
  position: relative;
  background: transparent;
  height: var(--table-header-height);
  color: var(--white);
  font-weight: 500;
  display: flex;
  align-items: flex-end;
  border-bottom: 2px solid var(--secondary-1);
  width: var(--table-width);
  background-color: var(--white);
  border-radius: 10px 10px 0 0;
  overflow-y: scroll !important;

  >div[class*="hided-column"] {
    border: none !important;
    overflow: hidden !important;
    visibility: hidden !important;
  }

  >div {
    height: 100%;
    display: flex;
    align-items: center;
    border-top: 1px solid var(--border-table);
    border-left: 1px solid var(--border-table);
    border-right: 0;

    [dir="rtl"] & {
      border-right: 1px solid var(--border-table);
      border-left: 0;
    }

    &:first-of-type {
      border-radius: 10px 0 0 0;

      [dir="rtl"] & {
        border-radius: 0 10px 0 0;
      }
    }

    &:last-of-type {
      border-left: 1px solid var(--border-table);
      border-right: 1px solid var(--border-table);
      border-radius: 0 10px 0 0;

      [dir="rtl"] & {
        border-radius: 10px 0 0 0;
        border-right: 1px solid var(--border-table);
        border-left: 1px solid var(--border-table);
      }
    }

    >label {
      width: 100%;
      color: var(--black);
      text-align: center;
      font-weight: 500;
      font-family: var(--font-en);
      font-size: 1rem;

      [dir="rtl"] & {
        font-weight: 500;
        font-family: var(--font-ar);
      }
    }
  }

  button {
    width: 100%;
    height: 100%;
    line-height: var(--table-header-height);
    color: var(--th-color);
    text-align: center;
    cursor: default;

    label {
      width: 100%;
    }

    >svg {
      margin: 0 0 0 5px;

      [dir="rtl"] & {
        margin: 0 5px 0 0;
      }
    }

    * {
      pointer-events: none;
    }
  }
}

.table_header_checkbox {
  position: relative;
  background: transparent;
  height: var(--table-header-height);
  color: var(--white);
  font-weight: 500;
  display: flex;
  align-items: center;
  border-bottom: 2px solid var(--secondary-2);
  overflow-y: scroll;
  width: var(--table-width);

  // &::-webkit-scrollbar {
  //     display: none;
  // }

  // scrollbar-width: none;
  // -ms-overflow-style: none;

  >div {
    &:nth-of-type(1) {
      width: var(--checkbox-table-width) !important;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      padding: 0 5px;

      >div {
        >label {
          margin: 0 !important;
        }
      }
    }
  }

  >span {
    display: flex;
    width: calc(100% - var(--checkbox-table-width));

    >div {
      max-height: 100%;
      display: flex;
      align-items: center;
      overflow: hidden;

      >label {
        width: 100%;
        color: var(--text-4);
        text-align: center;
        font-weight: 600;
        font-family: var(--font-en);
        font-size: 1rem;

        [dir="rtl"] & {
          font-family: var(--font-ar);
        }
      }

      &:hover {
        >div[class*=sortingButtonsContainer] {
          display: flex;
        }
      }
    }
  }

  button {
    height: 100%;
    line-height: var(--table-header-height);
    color: var(--th-color);
    text-align: center;
    cursor: default;

    label {
      width: 100%;
    }

    >svg {
      margin: 0 0 0 5px;

      [dir="rtl"] & {
        margin: 0 5px 0 0;
      }
    }

    >div {
      margin: 0 !important;
    }

    * {
      pointer-events: none;
    }
  }
}

.table_footer {
  background: var(--white);
  height: var(--table-footer-height);
  color: var(--black);
  font-weight: 500;
  display: flex;
  align-items: center;
  border-top: 2px solid var(--light-gray);

  >div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: var(--blue);
    font-family: var(--font-en);

    [dir="rtl"] & {
      font-family: var(--font-ar);
    }
  }
}

.table_hb_container {
  overflow-y: auto;

  @media screen and (max-width: 1024px) {

    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      width: 100%;
      min-width: 1200px;
    }
  }
}

.table_header_selected {
  background-color: var(--primary-color);
}

.table_body {
  width: var(--table-width);
  height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - var(--table-header-height) - var(--table-pagination-height) - 1px);
  overflow: auto;
  scroll-behavior: smooth;
  background-color: var(--table-bg-color);
  font-weight: 500;
  font-family: var(--font-ar);

  [dir="rtl"] & {
    font-family: var(--font-ar);
  }

  @media screen and (max-width: 650px) {
    height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - var(--table-pagination-height) - 1px - 10px);
  }

  >div {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 50px;
    background-color: var(--table-row-bg);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.03);
    margin-bottom: 7px;

    >div>div {
      overflow: hidden;
    }

    &:hover {
      background-color: var(--bg) !important;
    }

    &:not(&[class*="noData"]):hover {
      >div {
        &[class*="tableActionsButtonContainer"] {
          >button {
            &[class*="secondary-button"] {
              border-color: var(--blue);
            }

            &:nth-of-type(2) {
              background-color: var(--blue);

              >svg {
                * {
                  fill: var(--white);
                }
              }
            }
          }
        }
      }

      @media screen and (max-width: 650px) {
        background-color: var(--white) !important;
      }
    }

    label {
      max-height: 40px;
      line-height: 1.55;
      max-width: 95%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      color: var(--td-color);
      font-family: var(--font-en);

      [dir="rtl"] & {
        font-family: var(--font-ar);
      }

      &.table-cell-money {
        min-width: 65px;
        text-align: right;
      }
    }
  }

  .selected_row {
    background-color: #5c55f92b !important;

    input:not([type="checkbox"]) {
      background-color: transparent !important;
    }

    &:hover {
      background-color: #5c55f92b !important;
    }
  }
}

.v_table_body {
  width: var(--table-width);
  height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 20px - var(--header-pages-panel-height) - var(--table-header-height) - var(--table-pagination-height) - 1px);
  overflow: auto;
  position: relative;
  scroll-behavior: smooth;
  background-color: var(--table-bg-color);
  font-weight: 500;
  font-family: var(--font-en);

  [dir="rtl"] & {
    font-family: var(--font-ar);
  }

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;

  .selected_row {
    background-color: var(--menu-bg-color-active) !important;

    &:hover {
      background-color: var(--menu-bg-color-active) !important;
    }
  }

  label {
    color: var(--td-color);
    max-height: 40px;
    max-width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    color: var(--td-color);
    font-family: var(--font-en);
    font-weight: 500;

    [dir="rtl"] & {
      font-weight: 500;
      font-family: var(--font-ar);
    }
  }
}

.v_table_row {
  position: relative;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid var(--border-table) !important;
  margin-bottom: 20px !important;

  &:hover {
    background-color: rgba(102, 143, 255, 0.1) !important;

    >button[class*="delete-row-btn"] {
      right: 0;
      left: auto;

      [dir="rtl"] & {
        left: 0;
        right: auto;
      }
    }
  }

  >div {
    display: flex;
    align-items: center;
    height: 100%;
    overflow: hidden;

    >div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid var(--border-table);
      border-right: 0;

      [dir="rtl"] & {
        border-right: 1px solid var(--border-table);
        border-left: 0;
      }
    }

    &:last-of-type {
      >div {
        margin-inline-end: 1px;
        border-left: 1px solid var(--border-table);
        border-right: 1px solid var(--border-table);

        [dir="rtl"] & {
          border-left: 1px solid var(--border-table);
          border-right: 1px solid var(--border-table);
        }
      }
    }
  }
}

.table_search_panel {
  height: var(--header-pages-panel-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  width: 100%;
  border-bottom: 1px solid var(--borders-color);
  justify-content: space-between;
  align-items: "center";

  >div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  >button {
    >svg {
      margin: 0 10px 0 0;

      [dir="rtl"] & {
        margin: 0 0 0 10px;
      }
    }
  }

  .search_button {
    background-color: var(--secondary);
    padding: 9px 15px;
    border-radius: 40px;
    color: white;
    font-weight: 500;

    svg {
      margin: 0 0 0 10px;

      [dir="rtl"] & {
        margin: 0 10px 0 0;
      }

      g {
        stroke: white;
      }
    }
  }

  .filter_button {
    border: 1px solid var(--borders-color);
    background-color: var(--background-1);
    width: 40px;
    height: var(--new-common-height);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: white;
    position: relative;

    [dir="rtl"] & {
      margin: 0 0 0 10px;
    }

    >button {
      width: 100%;
      height: 100%;

      >svg {
        width: 20px;
        height: 20px;

        path {
          fill: var(--blue);
        }
      }
    }

    .existFilterData {
      position: absolute;
      top: -3px;
      left: -3px;
      right: auto;
      width: 12px;
      height: 12px;
      border-radius: 50px;
      background-color: var(--secondary);

      [dir="rtl"] & {
        left: auto;
        right: -3px;
      }
    }
  }
}

.table_pagination {
  height: var(--table-pagination-height);
  background-color: white !important;
  display: flex;
  justify-content: space-between;
  align-items: center;

  >div {
    display: flex;

    &:nth-of-type(1) {
      color: var(--secondary);
      font-weight: bold;
    }

    &:nth-of-type(2) {
      flex-direction: row-reverse;

      >button {
        border-width: 1px;
        border-style: solid;
        width: 35px;
        height: 35px;
        margin: 0 5px;
        border-radius: 20px;

        >svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  .activeArrow {
    background-color: var(--white);
    border-color: var(--primary-color);

    >svg {
      path {
        stroke: var(--primary-color);
      }
    }
  }

  .inActiveArrow {
    background-color: var(--light-gray);
    border-color: var(--dark-gray);

    >svg {
      path {
        stroke: var(--dark-gray);
      }
    }
  }

  .activeButton {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: white;
  }

  .inActiveButton {
    background-color: var(--white);
    border-color: var(--light-gray);
    color: var(--primary-color);
  }
}

.tabel_button {
  padding: 2px;
  width: 30px;
  height: 30px;
}

.actionButton {
  line-height: 1;
  border-radius: 3px;
  background-color: var(--white);
  width: calc(90% - 20px);
  margin: 0 3px 0 0;
  border-color: var(--light-gray);

  [dir="rtl"] & {
    margin: 0 0 0 3px;
  }

  font-weight: 500;

  &:hover {
    box-shadow: none !important;
  }

  * {
    cursor: pointer;
  }
}

.table-total {
  height: var(--table-total-height);
  width: var(--table-width);
  margin-top: var(--table-total-margin-top);
  // background-color: var(--pale-secondary-1);
  border-top: 1px solid var(--secondary-1);
  border-bottom: 1px solid var(--border-table);
  display: flex;
  align-items: center;

  >div {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 1.15rem;
    font-weight: 700;
    color: var(--title-text);
    overflow: hidden;
    height: 100%;

    >div {
      padding: 0 5px;
      border-left: 1px solid var(--border-reports-table);
      border-right: 0;

      [dir="rtl"] & {
        border-right: 1px solid var(--border-reports-table);
        border-left: 0;
      }
    }

    &:first-of-type {
      border: 0 !important;
    }

    &:last-of-type {

      // border-radius: 0 0 0 10px;
      >div {
        width: 100%;
        border-left: 1px solid var(--border-reports-table);
        border-right: 1px solid var(--border-reports-table);

        [dir="rtl"] & {
          border-right: 1px solid var(--border-reports-table);
          border-left: 1px solid var(--border-reports-table);
        }
      }
    }
  }

  .tableTotalLabel {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--primary-color);
  }
}

.treeColumn {
  display: flex;
  justify-content: flex-start !important;
  width: 100%;

  &,
  >label {
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.th-multi-level {
  display: flex;
  height: 50% !important;
  position: relative;

  >div {
    width: 100%;
    // color: var(--black);
    color: var(--white);
    font-weight: 500;
    font-family: var(--font-en);
    font-size: 1rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    [dir="rtl"] & {
      font-family: var(--font-ar);
    }
  }
}

.title-group-column {
  // color: var(--black);
  // background-color: var(--white);
  background-color: var(--header-table-reports);
  color: var(--white);
  text-align: center;
  font-weight: 600;
  font-family: var(--font-en);
  font-size: 1rem;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  top: 0;
  border-bottom: 1px solid var(--border-colors);
  border-top: none;
  border-right: 1px solid var(--border-colors);
  border-left: none;

  [dir="rtl"] & {
    border-right: none;
    border-left: 1px solid var(--border-colors);
    font-family: var(--font-ar);
  }
}

.td-money-format {
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
  direction: ltr;
  width: 100% !important;
}

.td-desc-report {
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  width: 100% !important;
}

.hideReports {
  display: none !important;
}

.noDataFoundReports {
  width: 100% !important;
  height: calc(100% - var(--header-height) - var(--header-margin-bottom) - var(--header-pages-panel-height)) !important;

  >div {
    &[class*="noDataFoundContainer"] {
      position: static !important;
    }
  }
}

.table_body_label {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: 1.7;

  >label {
    text-align: start !important;
  }

  @media screen and (max-width: 650px) {
    display: flex !important;
  }
}

.table_body_label_start {
  text-align: start !important;
}

.gjhCode {
  text-decoration: underline;
  cursor: pointer;
  color: var(--secondary-1);
}

.detailsMode {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  label {
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.v_table_header_radio {
  position: relative;
  background: transparent;
  height: var(--table-header-height);
  color: var(--white);
  font-weight: 500;
  display: flex;
  align-items: flex-end;
  border-top: 1px solid var(--border-table);
  border-bottom: 2px solid var(--secondary-1);
  width: var(--table-width);
  background-color: var(--white);
  border-radius: 10px 10px 0 0;

  >div {
    height: 100%;
    width: var(--checkbox-table-width);
    border-radius: 10px 0 0 0;
    border-left: 1px solid var(--border-table);
    border-right: 0;

    [dir="rtl"] & {
      border-radius: 0 10px 0 0;
      border-right: 1px solid var(--border-table);
      border-left: 0;
    }
  }

  >span {
    display: flex;
    height: 100%;
    width: calc(100% - var(--checkbox-table-width));

    >div[class*="hided-column"] {
      border: none !important;
      overflow: hidden !important;
      visibility: hidden !important;
    }

    >div {
      height: 100%;
      display: flex;
      align-items: center;
      border-left: 1px solid var(--border-table);
      border-right: 0;

      [dir="rtl"] & {
        border-right: 1px solid var(--border-table);
        border-left: 0;
      }

      &:last-of-type {
        border-left: 1px solid var(--border-table);
        border-right: 1px solid var(--border-table);
        border-radius: 0 10px 0 0;

        [dir="rtl"] & {
          border-radius: 10px 0 0 0;
          border-right: 1px solid var(--border-table);
          border-left: 1px solid var(--border-table);
        }
      }

      >label {
        width: 100%;
        color: var(--black);
        text-align: center;
        font-weight: 500;
        font-family: var(--font-en);
        font-size: 1rem;

        [dir="rtl"] & {
          font-weight: 500;
          font-family: var(--font-ar);
        }
      }
    }
  }

  button {
    width: 100%;
    height: 100%;
    line-height: var(--table-header-height);
    color: var(--th-color);
    text-align: center;
    cursor: default;

    label {
      width: 100%;
    }

    >svg {
      margin: 0 0 0 5px;

      [dir="rtl"] & {
        margin: 0 5px 0 0;
      }
    }

    * {
      pointer-events: none;
    }
  }
}

.v_table_row_radio {
  position: relative;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid var(--border-table) !important;
  margin-bottom: 20px !important;

  >div {
    display: flex;
    align-items: center;
    border-left: 1px solid var(--border-table);
    border-right: 0;
    height: 100%;
    color: var(--black);
    overflow: hidden;

    [dir="rtl"] & {
      border-right: 1px solid var(--border-table);
      border-left: 0;
    }

    &:last-of-type {
      border-left: 1px solid var(--border-table);
      border-right: 1px solid var(--border-table);

      [dir="rtl"] & {
        border-left: 1px solid var(--border-table);
        border-right: 1px solid var(--border-table);
      }
    }
  }
}

.tableImgContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  >span {
    width: 40px;
    height: 40px;
    margin: 0 10px 0 0;

    [dir="rtl"] & {
      margin: 0 0 0 10px;
    }

    >svg {
      height: 40px;
      width: 40px;

      g {
        fill: var(--primary-color);
      }
    }

    >img {
      max-width: 90%;
      max-height: 90%;
    }

    @media screen and (max-width: 1024px) {
      &:nth-of-type(2) {
        width: calc(100% - 40px) !important;
      }
    }

    @media screen and (max-width: 650px) {
      >svg {
        height: 25px;
        width: 25px;
      }
    }
  }

  &[class*="without-label"] {
    width: 100%;

    >span {
      width: 40px;
      height: 40px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  >label {
    text-align: start !important;
    width: calc(100% - 40px - 10px - 10px) !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.tableImg {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}

.hideActionMobile {
  width: 100%;
  display: flex !important;
  align-items: center;
  justify-content: space-around !important;

  @media screen and (max-width: 650px) {
    display: none !important;
  }
}

.actionTableMobile {
  display: none !important;

  @media screen and (max-width: 650px) {
    position: absolute;
    top: 5px;
    right: 20px;
    left: auto;
    width: 100%;
    overflow: visible !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
    margin-top: 10px;

    [dir="rtl"] & {
      left: 20px;
      right: auto;
    }

    >button {
      margin: 0 20px 0 0;

      [dir="rtl"] & {
        margin: 0 0 0 20px;
      }
    }
  }
}

.show-total-table-mobile {
  >label {
    display: none;
  }

  @media screen and (max-width: 650px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    >label {
      display: flex;
      width: 50% !important;
      text-align: start !important;
      justify-content: flex-start !important;
    }

    >div {
      width: 50% !important;
      justify-content: flex-end !important;
    }
  }
}

.hide-total-table-mobile {
  display: flex;

  @media screen and (max-width: 650px) {
    display: none !important;
  }
}

.closeTotalMobile {
  display: none !important;

  @media screen and (max-width: 650px) {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 5px;
    right: 5px;
    left: auto;
    background-color: red;

    [dir="rtl"] & {
      left: 10px;
      right: auto;
    }

    svg {
      width: 13px;
      height: 13px;

      g {
        stroke: var(--white);
      }
    }
  }
}

.totalMobileBg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);
  display: none;
  z-index: 1;

  &[class*="active"] {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .deleteBtnSm {
    justify-content: center;

    button {
      border: 0 !important;
      min-width: 40px !important;
      margin: 0 !important;

      span {
        display: none !important;
      }
    }
  }
}

.cardRowTrxMode,
.estimation-trx-status {
  display: none;
}

.transaction-frm-action {
  >div {
    width: 100%;
  }
}

// ***************cardTableMode********************
.cardTableMode {
  >div {
    &[class*="table_hb_container"] {

      div[class^="v_table_header"],
      div[class^="v_table_body"] {
        width: 100% !important;
        min-width: 100% !important;
      }
    }
  }

  div[class^="table_body"],
  div[class^="table_header_checkbox"],
  div[class^="table-total"] {
    width: 100% !important;
    min-width: 100% !important;
  }

  >div {
    >button {
      &[class*="settingsBtn"] {
        display: none !important;
      }
    }

    >div {
      &[class*="table_header"] {
        display: none;
      }

      &[class*="table_body"] {
        height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - var(--table-pagination-height) - 1px - 10px) !important;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;

        @media screen and (max-width: 1024px) {
          display: block;
        }

        >div {
          width: calc(50% - 10px);
          height: fit-content;
          border-radius: 5px;
          margin: 0 10px 10px 0;

          [dir="rtl"] & {
            margin: 0 0 10px 10px;
          }

          &[class*="noDataFoundContainer"] {
            width: 100% !important;
            height: 100% !important;
            min-height: 500px !important;

            >div {
              border: 0 !important;
            }
          }

          &[class*="containerWaiting"] {
            width: 100% !important;
            height: 100% !important;
            min-height: 500px !important;
            background-color: var(--white) !important;

            >div {
              width: 100% !important;
              height: 100% !important;
              border: 0 !important;

              >div {
                margin-bottom: 0 !important;
              }
            }
          }

          @media screen and (max-width: 1024px) {
            width: 100%;
            margin: 0 0 10px 0;

            [dir="rtl"] & {
              margin: 0 0 10px 0;
            }
          }

          &:hover {
            background-color: var(--white) !important;
          }

          >button {
            display: none !important;
          }

          >div {
            width: 100% !important;
            height: fit-content;
            flex-direction: column;
            padding: 10px 20px;
            border-radius: 5px;
            // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            border: 1px solid var(--border-colors);

            &[class*="containerChat"] {
              >div {

                &[class*="noData"] {
                  justify-content: center !important;

                  >div {
                    width: 100%;

                    @media screen and (max-width: 650px) {
                      svg {
                        width: 80px !important;
                      }
                    }

                  }
                }

                &[class*="chat"] {
                  >div {
                    &[class*="containerSeander"] {
                      >div {
                        >div {
                          &[class*="sender"] {
                            max-width: 100% !important;
                          }

                          &[class*="recipient"] {
                            max-width: 100% !important;
                          }
                        }
                      }
                    }

                    &[class*="containerRecipient"] {
                      >div {
                        >div {
                          &[class*="recipient"] {
                            max-width: 100% !important;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }


            &[class*="trxRow"] {
              padding: 50px 20px !important;
            }

            &[class*="priorityStatusRow"] {
              &::before {
                width: 100% !important;
                height: 2px !important;
                top: 0;
                left: 0 !important;
                right: 0 !important;

                [dir=rtl] & {
                  left: 0 !important;
                  right: 0 !important;
                }

              }
            }

            >div {
              width: 100% !important;
              justify-content: flex-start;
              text-align: start;
              margin-bottom: 15px;


              &[class*="inboxAction"] {
                flex-direction: column-reverse;
                justify-content: flex-start !important;

                >div {
                  justify-content: flex-start !important;
                  width: 100%;
                  margin: 0 0 10px 0 !important;

                  [dir=rtl] & {
                    margin: 0 0 10px 0 !important;
                  }

                }
              }

              &[class*="table_body_label"] {
                display: flex !important;
              }

              &[class*="actionRowContainer"] {
                height: auto !important;
                margin-bottom: 0 !important;
              }

              &[class*="columnSm"] {
                flex-direction: column;

                >div {
                  &:last-of-type {
                    margin-bottom: 0 !important;
                  }
                }
              }


              &[class*=transaction-frm-action] {
                width: 100%;


                >div {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;


                  >div {
                    border: 1px solid var(--border-colors);
                    border-radius: 5px;
                    width: 100%;

                    >div {
                      height: 100%;

                      >div {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      }

                      &:nth-of-type(1) {
                        margin: 0 !important;

                        >button,
                        >div {
                          border-right: 1px solid var(--border-colors);
                          border-left: 0;
                          height: 100% !important;
                          border-radius: 0 !important;
                          margin: 0 !important;
                          width: 37px;

                          [dir=rtl] & {
                            border-left: 1px solid var(--border-colors);
                            border-right: 0;
                          }

                          >span {
                            top: 20%;
                            left: 9%;
                            right: auto;

                            [dir=rtl] & {
                              right: 9%;
                              left: auto;
                            }
                          }
                        }
                      }

                      &:nth-of-type(2) {
                        width: calc(100% - 37px - 37px - 37px);

                        >button {
                          &[class*=mainbutton] {
                            width: calc(100% - 23px - 10px - 10px);
                            border-right: 0;
                            border-left: 0;
                            justify-content: center !important;

                            [dir=rtl] & {
                              border-right: 0;
                              border-left: 0;
                            }
                          }

                          &:last-of-type {
                            border: none;
                          }
                        }
                      }
                    }

                  }
                }
              }

              &[class*="hideRowActionMobile"] {
                margin: 0 !important;
              }

              >div {
                &[class*="tableActionsButtonContainer"] {
                  border: 1px solid var(--border-colors);

                  >button {
                    &:last-of-type {
                      border-left: 1px solid var(--border-colors);
                      border-right: 0;
                      border-radius: 0 !important;

                      [dir="rtl"] & {
                        border-right: 1px solid var(--border-colors);
                        border-left: 0;
                      }
                    }
                  }
                }
              }

              &[class*="cardRowMode"] {
                display: flex !important;
                width: 100%;

                >label {
                  &:nth-of-type(1) {
                    width: var(--label-card-width) !important;
                  }

                  &:nth-of-type(2) {
                    width: calc(100% - var(--label-card-width) - 5px) !important;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    text-align: start !important;

                    &[class*="cardRowDesc"] {
                      display: -webkit-box !important;
                      -webkit-box-orient: vertical;
                      -webkit-line-clamp: 2;
                      overflow: hidden;
                      white-space: wrap !important;

                      >label {
                        max-height: 28px;
                        overflow: hidden;
                      }
                    }

                    &[class*="tableImgContainer"] {
                      span {
                        justify-content: flex-start;
                      }
                    }
                  }
                }
              }

              &[class*="cardRowStatusMode"] {
                display: flex;
                width: 100%;

                >label {
                  &:nth-of-type(1) {
                    width: var(--label-card-width);
                  }
                }
              }

              &[class*="hideActionMobile"] {
                display: none !important;
              }

              label {
                &[class*="nameLblMobile"] {
                  display: flex !important;
                  align-items: center;
                  margin: 0 5px 0 0;
                  color: var(--label-color);

                  [dir="rtl"] & {
                    margin: 0 0 0 5px;
                  }
                }
              }

              >div {
                &[class*="cardRowTrxMode"] {
                  display: flex !important;
                  width: 100% !important;
                  margin-bottom: 15px;

                  >label {
                    width: var(--label-card-width) !important;
                  }

                  >div {
                    width: calc(100% - var(--label-card-width) - 5px) !important;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    text-align: start !important;

                    &[class*="cardRowDesc"] {
                      display: -webkit-box !important;
                      -webkit-box-orient: vertical;
                      -webkit-line-clamp: 2;
                      overflow: hidden;
                      white-space: wrap !important;

                      >label {
                        max-height: 28px;
                        overflow: hidden;
                      }
                    }

                    &[class*="dotStatus"] {
                      justify-content: flex-start;
                      padding: 0 0 0 14px;

                      [dir="rtl"] & {
                        padding: 0 14px 0 0;
                      }
                    }
                  }
                }

                &[class*="estimation-trx-status"] {
                  display: flex !important;

                  >label {
                    width: var(--label-card-width) !important;
                  }

                  >div {
                    width: auto !important;

                    @media screen and (max-width: 650px) {
                      width: calc(100% - var(--label-card-width) - 5px) !important;
                    }

                    >div {
                      >label {
                        &[class*="active-card-trx"] {
                          position: relative;
                          flex-direction: column !important;
                          align-items: flex-start !important;
                          min-width: 100% !important;
                          max-height: 100% !important;
                          margin-bottom: 0 !important;

                          @media screen and (max-width: 650px) {
                            padding: 10px 0 !important;
                          }

                          >span {
                            display: flex;
                            justify-content: center !important;
                            align-items: center !important;
                            width: auto !important;
                            min-width: 80px;
                            text-align: center;
                            font-weight: 500;
                            border-radius: 5px;

                            svg {
                              width: 17px;
                              height: 17px;
                              margin: 0 10px 0 0;

                              [dir=rtl] & {
                                margin: 0 0 0 10px;
                              }

                              path {
                                fill: #fff;
                              }
                            }

                            @media screen and (max-width: 650px) {
                              padding: 7px 10px;
                              min-width: auto;
                              white-space: nowrap;

                              svg {
                                width: 14px;
                                height: 14px;
                              }
                            }

                            &[class*="withRipple"] {
                              padding: 5px 12px 5px 30px !important;

                              [dir=rtl] & {
                                padding: 5px 30px 5px 12px !important;
                              }
                            }

                            &:nth-of-type(1) {
                              padding: 5px 12px;

                              [dir=rtl] & {
                                padding: 5px 12px;
                              }
                            }

                            &:nth-of-type(2) {
                              background-color: #2e7d32 !important;
                              color: var(--white);
                              min-width: 100px;
                              padding: 7px 12px;
                              margin-top: 7px;
                              position: relative;

                              @media screen and (max-width: 425px) {
                                min-width: auto;
                              }

                              span {
                                &:last-of-type {
                                  align-items: center;
                                  justify-content: center;
                                }
                              }
                            }
                          }

                          >div {
                            &[class*="introBannerVdoPlayBtn"] {
                              position: absolute;
                              top: 10px;
                              left: 10px;
                              right: auto;

                              [dir=rtl] & {
                                right: 10px;
                                left: auto;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }

                &[class*="containerButtonTable"] {
                  width: 100% !important;

                  >button {
                    width: 50% !important;
                    border: 1px solid var(--border-colors);
                  }
                }

                &[class*="updateValuationFieldsBtnStyle"] {
                  width: 100% !important;

                  >button {
                    width: 100% !important;
                  }
                }

                &[class*="columnRowNew"] {
                  display: none;
                }

                &[class*="hideActionMobile"] {
                  display: none !important;
                }

                &[class*="actionTableMobile"] {
                  display: flex !important;
                  position: absolute;
                  top: 5px;
                  right: 20px;
                  left: auto;
                  width: calc(100% - 20px);
                  overflow: visible !important;
                  display: flex !important;
                  align-items: center !important;
                  justify-content: flex-end !important;
                  margin-top: 10px;

                  [dir="rtl"] & {
                    left: 20px;
                    right: auto;
                  }

                  >button {
                    margin: 0 20px 0 0;

                    [dir="rtl"] & {
                      margin: 0 0 0 20px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// ***************cardTableMode********************
.reportsRowHeight {
  min-height: 45px !important;
  margin-bottom: 0 !important;
}