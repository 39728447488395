.estimationFeesCommissionsContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;


    @media screen and (max-width: 768px) {
        flex-direction: column;

        >div {
            width: 100% !important;

            &:nth-of-type(1) {
                margin-bottom: 20px;
            }
        }
    }

}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;

    >div {
        &[class*=tableHeaderActions] {
            margin-bottom: 0 !important;
            border-bottom: 0 !important;
            padding: 10px !important;
        }
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
        height: auto !important;
        align-items: flex-start;
    }
}

.title {
    display: flex;
    align-items: center;

    >label {
        color: var(--primary-color-2);
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.30;
    }
}

.containerTabs {
    width: 250px;
    height: 100%;
    border-radius: 10px;
    background-color: var(--white);
    padding: 20px 10px;

    @media screen and (max-width: 768px) {
        height: auto !important;
        display: flex;
        align-items: center;

        >button {
            justify-content: center !important;
            width: calc(33.3333% - 10px) !important;
            margin: 0 10px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 10px;
            }
        }
    }

    >button {
        width: 100%;
        height: 40px;
        margin-bottom: 10px;
        justify-content: flex-start !important;
        padding: 0 10px;

        &.activeBtn {
            background-color: var(--primary-hover);
            color: var(--primary-color-2);
        }
    }
}

.estimationFeesCommissionsPages {
    width: calc(100% - 250px);
    height: 100%;
    background-color: var(--white);
    border-radius: 10px;
    padding: 20px;
    margin: 0 0 0 10px;

    [dir=rtl] & {
        margin: 0 10px 0 0;
    }
}

.inActiveEstimation {
    display: none;
}

.activeEstimation {
    display: block;
}

// **********************content tabs**********************
// ******************customersList Table**********************
.customersListRow {
    >div {
        display: flex;
        justify-content: center;
    }
}

.customersListTable {

    @media screen and (max-width: 1024px) {
        div[class^="v_table_header"],
        div[class^="v_table_body"] {
            width: 100%;
            min-width: 120% !important;
        }
    }

    div[class^=v_table_body] {
        height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - 20px - 37px - 10px - var(--table-header-height) - 40px);
    }

    div[class^=v_table_header],
    div[class^=v_table_row] {
        display: flex;
        align-items: center;

        >div {
            text-align: center;
            display: flex;
            justify-content: center;

            &:nth-child(1) {
                width: var(--col-1);
            }

            &:nth-child(2) {
                width: var(--col-2);
            }

            &:nth-child(3) {
                width: var(--col-3);
            }

            &:nth-child(4) {
                width: var(--col-4);
            }
        }
    }
}

// ******************customersList Table**********************
// ******************partiesList Table**********************
.partiesListTable {

    @media screen and (max-width: 1024px) {

        div[class^="v_table_header"],
        div[class^="v_table_body"] {
            width: 100%;
            min-width: 120% !important;
        }
    }

    div[class^=v_table_body] {
        height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - 20px - 37px - 10px - var(--table-header-height) - 40px);
    }

    div[class^=v_table_header],
    div[class^=v_table_row] {
        display: flex;
        align-items: center;

        >div {
            text-align: center;
            display: flex;
            justify-content: center;

            &:nth-child(1) {
                width: var(--col-1);
            }

            &:nth-child(2) {
                width: var(--col-2);
            }

            &:nth-child(3) {
                width: var(--col-3);
            }

            &:nth-child(4) {
                width: var(--col-4);
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .containerRow {
        max-height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - 80px - 20px - 20px - 20px - 50px) !important;
        overflow: auto;
    }
}