$cardWidth: 400px;

.mainCountDwon {
    position: fixed;
    top: calc(5px*-1);
    left: 0;
    width: 100%;
    height: calc(100% + 5px);
    overflow: hidden;
    transition: .4s all;
    z-index: 101;
    display: none;
}

.fontAr {
    * {
        font-family: var(--font-ar);
    }
}

.fontEn {
    * {
        font-family: var(--font-en);
    }
}

.closeCountDwon {
    display: none;
}

@mixin Container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    transition: .4s all;
}

.countDwonContaer {
    @include Container();
    overflow: hidden
}

.expiredContaer {
    @include Container();
    top: -$cardWidth;

    &.expiredActive {
        top: 50%;
    }
}

@mixin Counter-Lbl {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.counterLbl {
    @include Counter-Lbl();
    margin: 20px 0;
    color: var(--text-2);
}

.expiredLbl {
    @include Counter-Lbl();
    margin: 20px 0;
    font-weight: 500;
}

.counterNum {
    display: flex;
    align-items: center;
    justify-content: center;

    >div {
        font-size: 25px;
        font-weight: 500;
    }

    >span {
        font-size: 12px;
        margin-top: 20px;
    }
}

.sessionValidity {
    width: $cardWidth;
    height: 380px;
    background-color: var(--white);
    border: 1px solid var(--border-colors);
    border-radius: 10px;
    // padding: 0 20px 10px;
}

@mixin Icons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 150px;
    border-radius: 10px 10px 0 0;

    >svg {
        width: 100px;
        height: 100px;

        g {
            fill: var(--primary-color);
        }
    }
}

.icons {
    @include Icons();
    background-color: #f0f1ff;
}

.expiredIcons {
    @include Icons();
    background-color: #f0f1ff;
}

@mixin SessionBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 30px;

    >button {
        font-size: 14px;
        min-width: 160px;
    }
}

.sessionValidityBtn {
    @include SessionBtn();
    margin-top: 30px;
}

.expiredContaerBtn {
    @include SessionBtn();
    margin-top: 85px;
}