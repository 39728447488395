.card {
    margin-bottom: 0 !important;
    transition: all 0.5s;
    padding-left: 0 !important;
    padding-right: 0 !important;

    &.fullCard {
        >div {
            width: 90%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: all 0.5s;
            z-index: 999;
            height: 600px;

            >div:nth-child(2) {
                height: 70vh;
                max-height: 530px;
            }
        }
    }
}

.dashCard {
    // box-shadow: rgb(0 0 0 / 16%) 0px 1px 6px;
    // margin: 5px;
    background-color: var(--white);
    border-radius: 7px;
    padding: 5px 15px;
    margin: 0 10px 10px 0;

    [dir=rtl] & {
        margin: 0 0 10px 10px;
    }
    @media screen and (max-width: 768px) {
        margin: 0 0 10px 0 !important;

        [dir=rtl] & {
            margin: 0 0 10px 0 !important;
        }
    }

    >div:nth-child(2) {
        height: 40vh;
        max-height: 280px;

        >div {
            height: 95%;
        }

        @media screen and (max-width: 1200px) {
            height: auto;
            max-height: fit-content;
            min-height: 400px;

            >div {
                canvas {
                    min-height: 400px;
                    height: auto;
                }
            }
        }

        @media only screen and (min-width: 650px) and (max-width: 1024px) {
            >div {
                canvas {
                    min-height: 500px;
                }
            }
        }

        @media screen and (max-width: 650px) {
            >div {
                canvas {
                    min-height: 500px;
                }
            }
        }

        @media screen and (max-width: 450px) {
            >div {
                canvas {
                    min-height: 400px;
                }
            }
        }
    }
}

.headerCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    margin-bottom: 10px;
    position: relative;

    .headerActions {
        display: flex;
    }

    .titCard {
        display: flex;
        align-items: center;

        >div {
            display: flex;
            align-items: center;
            margin: 0 10px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 10px;
            }

            >svg {
                width: 20px;
                height: 20px;
                margin: 0 10px 0 0;

                path,
                circle {
                    fill: var(--secondary-2);
                }

                [dir=rtl] & {
                    margin: 0 0 0 10px;
                }
            }

            >label {
                font-size: 1.2rem;
                font-weight: 600;

                &:nth-of-type(2) {
                    color: var(--dark-gray);
                    margin: 0 5px;
                }
            }
        }
    }

    >div {

        button {
            display: flex;
            align-items: center;
            font-weight: 500;
            width: 20px;
            height: 20px;
            margin: 0 10px 0 0;

            >svg {
                width: 20px;
                height: 20px;
            }

            &:last-of-type {
                >svg {
                    path {
                        fill: var(--secondary-1)
                    }
                }
            }

            [dir=rtl] & {
                margin: 0 0 0 10px;
            }
        }
    }
}

.reloadIcon {
    >svg {
        g {
            fill: var(--primary-1);
        }
    }
}

@mixin BG {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 8;
}

.colorPaletteBg {
    @include BG();
    z-index: 8;
    display: block;
}

.fullCardBg {
    @include BG();
    display: none;
    background-color: var(--black);
    opacity: 0.1;
    z-index: 99;

    &.fullCardActive {
        display: block;
    }
}

.colorPalette {
    width: 230px !important;
    min-height: 280px;
    max-height: 320px;

    .colorsRows {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        cursor: pointer;

        &.selected {
            border: 1px solid var(--primary-1);
        }

        >div {
            width: 20px;
            height: 20px;
        }
    }

    & [class*=rc-dropdown-menu-item-selected] {
        &::after {
            display: none;
        }
    }
}

.customSpinner {
    display: flex !important;
    position: relative !important;
    // width: 30px;
    margin-bottom: 5px;
    height: 24px;
    display: none;
    align-items: center;
    justify-content: flex-start;
    z-index: 2;

    &:after {
        width: 20px !important;
        height: 20px !important;
        border-right-color: var(--primary-color) !important;
        margin: 0;
        border-left-color: var(--primary-color) !important;
    }
}