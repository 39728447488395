.reportsDesignTableRow {
  >div {
    display: flex;
    justify-content: center;
  }
}

.reportsDesignTableContainer {
  overflow-x: auto;
}

.reportsDesignTableContainer {

  div[class^="table_header_checkbox"]>span,
  div[class^="table_body"]>div>div {
    >div:nth-child(1) {
      width: var(--col-1);
    }

    >div:nth-child(2) {
      width: var(--col-2);
    }

    >div:nth-child(3) {
      width: var(--col-3);
    }

    >div:nth-child(4) {
      width: var(--col-4);
    }
  }
}

.modalReportsDesign {
  min-width: 850px;

  @media screen and (max-width: 1024px) {
    min-width: 80vw;
  }
}

.headerSearchPanel {
  @media screen and (max-width: 650px) {
    >div {
      &[class*="inputContainer"] {
        min-width: calc(100% - 30px - 30px - 15px) !important;
        width: calc(100% - 30px - 30px - 15px) !important;
      }
    }
  }
}

.uploudFile {
  margin-top: 23px;
  min-width: 100px;
  background-color: var(--secondary-1) !important;
}

.radioContainer {
  height: var(--common-height);
}

.spinnerButton {
  background-color: var(--primary-color-2);
  margin: 0 !important;
  width: 25px !important;
  height: 25px !important;
  border-radius: 5px;

  &::after {
    width: 20px !important;
    height: 20px !important;
  }
}

.actionButtons {
  position: relative;
}

.imgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}