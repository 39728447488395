.accountOptionContainer {
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;

    >div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 60%;

        >label {
            width: 100%;

            &:first-of-type {
                color: var(--text-3);
                font-size: 0.9rem;
            }
        }
    }
}

.accountLabelContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    >label {
        width: 100%;
        max-width: 100% !important;
        display: block;
        text-align: start !important;
        white-space: nowrap;

        &:first-of-type {
            color: var(--primary-color-2);
            font-size: 0.9rem;
        }
    }
}