.townsTableRefRow {
    >div {
        display: flex;
        justify-content: center;
    }
}

.townsTableContainer {
    overflow-x: auto;
}

.townsTable {

    div[class^=table_header_checkbox]>span,
    div[class^=table_body]>div>div {
        >div:nth-child(1) {
            width: var(--col-1);
        }

        >div:nth-child(2) {
            width: var(--col-2);
        }

        >div:nth-child(3) {
            width: var(--col-3);
        }

        >div:nth-child(4) {
            width: var(--col-4);
        }

        >div:nth-child(5) {
            width: var(--col-5);
        }

        >div:nth-child(6) {
            width: var(--col-6);
        }
    }
}

.lblRowTowns {
    display: none;

    @media screen and (max-width: 650px) {
        display: flex;
        width: 100%;

        >label {
            &:nth-of-type(1) {
                width: 70px;
            }

            &:nth-of-type(2) {
                width: calc(100% - 70px - 5px);
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                text-align: start !important;
            }
        }
    }
}