.mapCardContainer {
    background-color: var(--white);
    width: 300px;
    min-width: 300px;
    min-height: 135px;
    margin: 0 10px 10px;
    margin-bottom: 0;
    border-radius: 10px;
    padding-bottom: 0;
    z-index: 2;
    position: absolute;
    top: 40px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    font-family: var(--font-en);
    display: none;

    [dir=rtl] & {
        font-family: var(--font-ar);
    }

    * {
        font-family: var(--font-en);

        [dir=rtl] & {
            font-family: var(--font-ar);
        }
    }

    // &[class*=withActions] {
    //     min-height: 200px;
    // }

    &[class*=active] {
        display: block;
    }
}

@mixin Type-Card {
    position: absolute;
    background-color: var(--white);
    color: var(--black);
    border-radius: 20px;
    min-width: 80px;
    height: 30px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 5px;

    >label {
        color: #D8A172;
        font-weight: 500;
    }
}

.mapCardDetails {
    padding: 10px;
}

.contentMapCardContainer {
    display: flex;
    align-items: center;

    >div {
        &:nth-of-type(1) {
            width: calc(100% - 32px - 5px);
            margin: 0 5px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 5px;
            }
        }
    }
}

.mapCardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.mapCardCode {
    color: var(--text-2);
    font-weight: 800;
    font-size: 1rem;

    @media screen and (max-width: 1025px) {
        font-size: 0.9rem;
    }
}

.mapCardEvaluationPrice {
    color: var(--secondary-1);
    font-weight: 600;
    font-size: 1rem;

    @media screen and (max-width: 1025px) {
        font-size: 0.9rem;
    }
}

@mixin MapCardDetails {
    display: flex;
    align-items: center;
    margin-top: 8px;
    overflow: hidden;

    >span {
        width: 14px;
        height: 14px;
        margin: 0 10px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 10px;
        }

        >svg {
            width: 13px;
            height: 13px;
        }
    }

    >label {
        font-size: 0.9rem;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media screen and (max-width: 1025px) {
            font-size: 0.8rem;
        }
    }
}

.userMapCard {
    @include MapCardDetails();

    >label {
        color: var(--text-2);
    }
}

.locationMapCard {
    @include MapCardDetails();

    >label {
        color: var(--text-3);
    }
}

.pricesContainer {
    display: flex;
    align-items: center;
}

@mixin Labels {
    font-size: 0.9rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;

    @media screen and (max-width: 1025px) {
        font-size: 0.8rem;
    }
}

.lblMapCardDetails {
    @include Labels();
    width: 55px;
    margin: 0 10px 0 0;

    [dir=rtl] & {
        margin: 0 0 0 10px;
    }
}

.valMapCardDetails {
    @include Labels();
    font-weight: 600;
}

.landAreaContainer {
    display: flex;
    align-items: center;
    width: calc(100% - 55px);

    >span {
        font-size: 0.8rem;
        font-weight: 500;
        margin: 0 0 0 3px;

        [dir=rtl] & {
            margin: 0 3px 0 0;
        }
    }
}

.measurePrice {
    >label {
        font-size: 1rem;
        color: var(--text-3);

        &:nth-of-type(1) {
            font-weight: 600;
        }
    }
}

.currency {
    color: var(--text-3);
    margin: 0 0 0 8px;

    [dir=rtl] & {
        margin: 0 8px 0 0;
    }

    @media screen and (max-width: 1025px) {
        font-size: 0.7rem;
    }
}

.contentMapCard {
    display: flex;
    align-items: center;
    margin-top: 6px;

    >div {
        width: 50%;
    }
}

.containerInfoCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 5px 0px;
}

.typeContainer {
    text-align: center;
    background-color: #D8A172;
    color: var(--white);
    min-width: 50px;
    max-width: 70px;
    padding: 4px 8px;
    border-radius: 10px;
    font-size: 10px;

    [dir=rtl] & {
        font-size: 10px
    }
}

.dateCard {
    height: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    min-width: 100px;
    font-size: 10px;
    padding: 4px 8px;
    right: auto;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
}

.office {
    background-color: rgb(26, 0, 190)
}

.appar {
    background-color: rgb(145, 42, 154);
}

.estimationHistoryIcon {
    background-color: rgb(181, 122, 77);
}

.green {
    background-color: green;
}

.cancelBtn {
    position: absolute;
    background-color: var(--white);
    border-radius: 20px;
    width: 25px;
    height: 25px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -10px;
    right: -10px;
    left: auto;

    [dir=rtl] & {
        left: -10px;
        right: auto;
    }

    >svg {
        >g {
            stroke-width: 1.2;
        }
    }
}

.actions {
    padding: 10px 0;

    >button {
        width: 32px;
        height: 32px;
        border: 1px solid var(--secondary-2);
        color: var(--secondary-2);
        justify-content: center;

        svg {
            width: 17px;
            height: 17px;

            path {
                fill: var(--secondary-2);
            }
        }
    }
}