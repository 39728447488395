.toggleMode {
    height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - var(--main-padding-V)) !important;
    padding: 20px !important;
    margin-top: 0 !important;
}

.container {
    display: flex;
    width: 100%;
    height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - var(--main-padding-V));
}

// ******************totals Table**********************

.headerLines {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white);
    width: 100%;
    height: 40px;
    margin-bottom: 20px;

    >div {
        display: flex;
        align-items: center;

        >button {
            min-width: 110px;
            margin: 0 10px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 10px;
            }

            &:last-of-type {
                margin: 0;
            }
        }
    }
}

.containerBtn {
    justify-content: flex-end;
    width: 100%;
}

.marLeft {
    margin: 0 20px 0 0 !important;
    position: relative;

    [dir=rtl] & {
        margin: 0 0 0 20px !important;
    }
}

.marBtn {
    margin: 0 40px 0 0 !important;

    [dir=rtl] & {
        margin: 0 0 0 40px !important;
    }
}

// ******************invoicesList Table**********************

.invoicesListRow {
    >div {
        display: flex;
        justify-content: center;
        align-items: center;

        >span {
            width: 30px;
            height: 1px;
            background-color: var(--black);
            margin: 0 2px;
        }
    }
}

.invoicesListTable {

    div[class^=v_table_body] {
        height: calc(45px * 10);
    }

    @media screen and (max-width: 1024px) {

        div[class^="v_table_header"],
        div[class^="v_table_body"] {
            width: 100% !important;
            min-width: 4000px !important;
        }
    }

    div[class^=v_table_header],
    div[class^=v_table_row] {
        display: flex;
        align-items: center;

        >div {
            text-align: center;
            display: flex;
            justify-content: center;

            &:nth-child(1) {
                width: var(--col-1);
            }

            &:nth-child(2) {
                width: var(--col-2);
            }

            &:nth-child(3) {
                width: var(--col-3);
            }

            &:nth-child(4) {
                width: var(--col-4);
            }

            &:nth-child(5) {
                width: var(--col-5);
            }

            &:nth-child(6) {
                width: var(--col-6);
            }

            &:nth-child(7) {
                width: var(--col-7);
            }

            &:nth-child(8) {
                width: var(--col-8);
            }

            &:nth-child(9) {
                width: var(--col-9);
            }

            &:nth-child(10) {
                width: var(--col-10);
            }

            &:nth-child(11) {
                width: var(--col-11);
            }

            &:nth-child(12) {
                width: var(--col-12);
            }

            &:nth-child(13) {
                width: var(--col-13);
            }

            &:nth-child(14) {
                width: var(--col-14);
            }

            &:nth-child(15) {
                width: var(--col-15);
            }

            &:nth-child(16) {
                width: var(--col-16);
            }

            &:nth-child(17) {
                width: var(--col-17);
            }

            &:nth-child(18) {
                width: var(--col-18);
            }

            &:nth-child(19) {
                width: var(--col-19);
            }

            &:nth-child(20) {
                width: var(--col-20);
            }

            &:nth-child(21) {
                width: var(--col-21);
            }

            &:nth-child(22) {
                width: var(--col-22);
            }

            &:nth-child(23) {
                width: var(--col-23);
            }

            &:nth-child(24) {
                width: var(--col-24);
            }

            &:nth-child(25) {
                width: var(--col-25);
            }

            &:nth-child(26) {
                width: var(--col-26);
            }

            &:nth-child(27) {
                width: var(--col-27);
            }

            &:nth-child(28) {
                width: var(--col-28);
            }

            &:nth-child(29) {
                width: var(--col-29);
            }

            &:nth-child(30) {
                width: var(--col-30);
            }

            &:nth-child(31) {
                width: var(--col-31);
            }

            &:nth-child(32) {
                width: var(--col-32);
            }

            &:nth-child(33) {
                width: var(--col-33);
            }

            &:nth-child(34) {
                width: var(--col-34);
            }

            &:nth-child(35) {
                width: var(--col-35);
            }

            &:nth-child(36) {
                width: var(--col-36);
            }

            &:nth-child(37) {
                width: var(--col-37);
            }

            &:nth-child(38) {
                width: var(--col-38);
            }

            &:nth-child(39) {
                width: var(--col-39);
            }

            &:nth-child(40) {
                width: var(--col-40);
            }

            &:nth-child(41) {
                width: var(--col-41);
            }
        }
    }
}

.invoicePartiesTable {
    div[class^=v_table_body] {
        height: calc(45px * 10);
    }

    div[class^=v_table_header],
    div[class^=v_table_row] {
        display: flex;
        align-items: center;

        >div {
            text-align: center;
            display: flex;
            justify-content: center;

            &:nth-child(1) {
                width: var(--col-1);
            }

            &:nth-child(2) {
                width: var(--col-2);
            }

            &:nth-child(3) {
                width: var(--col-3);
            }

            &:nth-child(4) {
                width: var(--col-4);
            }
        }
    }
}

// ******************invoicesList Table**********************

.spinner {
    background-color: var(--pageination);
    border-radius: 7px;
}

.detailsSection {
    padding-bottom: 80px !important;
}

.headerBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    >div {
        margin: 0 15px 0 0 !important;

        [dir="rtl"] & {
            margin: 0 0 0 15px !important;
        }
    }

    @media screen and (max-width: 768px) {
        overflow-x: auto;
        justify-content: flex-start !important;

        &::-webkit-scrollbar {
            display: none;
        }

        scrollbar-width: none;
        -ms-overflow-style: none;

        >button {
            min-width: fit-content !important;
            box-sizing: initial !important;

            label {
                white-space: wrap;
            }
        }
    }
}

.insertTransactionsTable {

    div[class^=v_table_row] {
        >div:nth-child(7)>div>label {
            color: var(--red) !important;
        }
    }

    @media screen and (max-width: 1024px) {

        div[class^=v_table_header],
        div[class^=v_table_body] {
            width: 100%;
            min-width: 4000px !important;
        }
    }
}