$menu-option-height: 35px;

.valueContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    border-radius: 0;
    cursor: pointer;
    font-size: 1rem;

    >div {
        display: flex;
        align-items: center;
    }

    * {
        cursor: pointer;
    }

    // &:focus {
    //     outline: 1px solid;
    // }

    &:disabled {
        background-color: transparent !important;

        &:hover {
            background-color: transparent !important;
        }
    }

    >span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 11px;

        >svg {
            width: 11px;
            height: 11px;
        }
    }
}

.optionsContainer {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 35px;
    width: 180px;
    background-color: white;
    z-index: 10;
    box-shadow: -2px 6px 20px -2px var(--select-menu-shadow) !important;
    max-height: calc($menu-option-height * 5);
    overflow: auto;

    &[class*=opened] {
        display: flex;
    }

    >button {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        min-height: $menu-option-height;
        padding: 0 10px;
        color: var(--text-4);
        cursor: pointer;
        font-size: 1rem;

        &:hover,
        &:focus {
            background-color: var(--bg);
            outline: none;
        }

        &[class*=selected] {
            color: var(--secondary-1);
            font-weight: 600;
        }
    }
}