$img-width: 130px;

.noDataFoundContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    margin-bottom: 0 !important;

    >div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100% !important;
        height: 100% !important;

        // >img {
        //     max-width: $img-width;
        // }
        svg {
            width: 100px;

            path {
                // fill: var(--secondary-1);
            }
        }


        >label {
            margin-top: 15px;
            font-size: 1.3rem;
            color: var(--secondary-1);
        }
    }

}