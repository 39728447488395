.tabContentStyle {
    height: calc(var(--window-height) - var(--side-panel-header-height) - var(--side-panel-footer-height) - 30px - var(--tab-container-height)) !important;
    overflow: auto;
    padding: 10px 10px;
}

.citiesTabs {
    padding: 0 !important;

    >button {
        width: 33.333%;
        margin: 0 !important;

        &::before {
            display: none;
        }

        svg {
            display: none;
        }
    }
}

.modalCustomers {
    >div {
        &:nth-of-type(2) {
            padding-bottom: 0;
        }
    }
}

.step3 {
    margin-top: 20px;
    display: flex !important;
    align-items: center;

    >div:last-of-type {
        margin: 0 25px;
    }

    @media screen and (max-width: 650px) {
        flex-direction: column;

        >div {
            margin-bottom: 20px;
        }
    }
}


.insertItemsTable {

    div[class^=table_header],
    div[class^=v_table_body] {
        width: 100%;
    }

    div[class^=v_table_body] {
        height: calc(var(--table-body-height) - 55px - var(--common-height) - var(--table-header-height) - 60px - 20px);
    }

    div[class^=v_table_row] {
        min-height: 40px;
    }

    div[class^=table_header],
    div[class^=table_body]>div,
    div[class^=v_table_row] {
        display: flex;
        align-items: center;

        >div {
            text-align: center;
            display: flex;
            justify-content: center;

            &:nth-child(1) {
                width: 5%;
            }

            &:nth-child(2) {
                width: 5%;
            }

            &:nth-child(3) {
                width: 25%;
            }

            &:nth-child(4) {
                width: 65%;
            }
        }
    }
}

.taxToggleBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}