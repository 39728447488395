.optionsMsg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    overflow-x: auto;

    &::-webkit-scrollbar {
        display: none;
    }

    >button {
        min-width: 90px;
        height: var(--common-height);
        color: var(--dark-gray);
        padding: 0 10px;
        border-bottom: 2px solid var(--white);

        &:hover {
            background-color: var(--pale-secondary);
        }

        >span {
            min-width: 17px;
            min-height: 17px;

            >svg {
                width: 17px;
                height: 17px;
                margin: 0 7px 0 0;

                [dir=rtl] & {
                    margin: 0 0 0 7px;
                }
            }
        }

        &.active {
            background-color: var(--pale-secondary);
            border-bottom-color: var(--primary-color);
            border-radius: 0;
            color: var(--black);

            >span {
                >svg {
                    path {
                        fill: var(--primary-color);
                    }

                    g {
                        fill: var(--primary-color);
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1024px) {
        width: 100%;
        overflow-x: auto;

        >button {
            font-size: 0.9rem;

            >span {
                min-width: 14px;
                min-height: 14px;
                padding: 0 5px;

                >svg {
                    width: 14px;
                    height: 14px;
                }
            }
        }

    }
}

.msgSelect {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-colors);

    >div {
        &:nth-of-type(1) {
            width: 70px;

            >label {
                color: var(--placeholder) !important;
            }
        }

        &:nth-of-type(2) {
            height: var(--common-height);
            width: calc(100% - 70px);
        }
    }
}

@mixin Buttons {
    border-radius: 50%;
    background-color: var(--white);
    border: 1px solid var(--light-gray);
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0;

    [dir=rtl] & {
        margin: 0 0 0 10px;
    }
}

.active {
    >button {
        @include Buttons();
        background-color: var(--success);
    }
}

.activePriority {
    border-bottom-color: var(--primary-color) !important;
    border-radius: 0;
}

@mixin PriorityBtn {
    >svg {
        width: 20px !important;
        height: 20px !important;
    }
}

.lowPriority {
    @include PriorityBtn();
    color: var(--success) !important;
}

.normalPriority {
    @include PriorityBtn();
    color: var(--worning) !important;
}

.highPriority {
    @include PriorityBtn();
    color: var(--status-rejected) !important;
}

.criticalPriority {
    @include PriorityBtn();
    color: var(--error) !important;
}


.citiesTabs {
    padding: 0 !important;

    >button {
        width: 50%;
        margin: 0 !important;

        &::before {
            display: none;
        }

        svg {
            display: none;
        }
    }
}

.typeText {
    min-height: 160px !important;
}

.rowContainer {
    >div {
        margin-top: 15px;
    }
}

// *******************workflowTable************************
.workflowRow {
    >div {
        display: flex;
        justify-content: center;
    }
}

.workflowTable {

    div[class^=table_header],
    div[class^=v_table_body] {
        width: 100%;
    }

    div[class^=v_table_body] {
        height: calc(var(--window-height) - 15px - 15px - var(--tab-container-height) - 15px - 53px - var(--table-header-height) - 69px - 10px - 60px) !important;
    }

    div[class^=v_table_header],
    div[class^=v_table_row] {
        display: flex;
        align-items: center;

        >div {
            text-align: center;
            display: flex;
            justify-content: center;

            &:nth-child(1) {
                width: var(--col-1);
            }

            &:nth-child(2) {
                width: var(--col-2);
            }

            &:nth-child(3) {
                width: var(--col-3);
            }
        }
    }
}

// *******************workflowTable************************
.fieldsListContainer {
    width: 100%;
    margin-top: 15px;
}