$cardWidth: 400px;

.mainCountDwon {
    position: fixed;
    top: calc(5px*-1);
    left: 0;
    width: 100%;
    height: calc(100% + 5px);
    overflow: hidden;
    transition: .4s all;
    z-index: 101;

    * {
        font-family: var(--font-en);

        [dir=rtl] & {
            font-family: var(--font-ar);
        }
    }
}

.closeCountDwon {
    display: none !important;
}

.deniedService {
    width: $cardWidth;
    height: auto;
    background-color: var(--white);
    border: 1px solid var(--border-colors);
    border-radius: 10px;
    padding-bottom: 20px;
}

.countDwonContaer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    transition: .4s all;
    overflow: hidden
}

.icons {
    background-color: #fff7d9;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 150px;
    border-radius: 10px 10px 0 0;

    >svg {
        width: 75px;
        height: 75px;

        g {
            fill: var(--text-2);
        }
    }
}

@mixin Counter-Lbl {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.counterLbl {
    @include Counter-Lbl();
    margin-top: 10px 0 20px;
    font-weight: 500;
    font-size: 1.1rem;
    color: var(--text-2);
}

.worningLbl {
    @include Counter-Lbl();
    margin: 20px 0 10px;
    color: var(--text-2);

    >label {
        >span {
            font-weight: 700;
            // margin: 0 5px;
            color: var(--pageination);
            font-size: 20px;
        }
    }
}

.worningContaerBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 30px;

    >button {
        font-size: 14px;
        min-width: 160px;
    }
}