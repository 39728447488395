$margin-top: 10px;
$card-width : max(35%, 350px);
$new-card-width : max(30%, 300px);
$card-height: 111px;
$card-height-sm: 160px;
$ContainerStatusWidth : 200px;
$orderby-button-width: 40px;
$break-label-height: 9px;
$pagination-height: 40px;
$margin-top-newCard-dialog: 10px;

.headerButton {

    margin: 0 30px 0 0;

    [dir=rtl] & {
        margin: 0 0 0 30px;
    }

    >button {
        background-color: white;
        height: 35px;
        min-width: 120px;
        margin: 0 10px 0 0;
        border: 1px solid white;

        [dir=rtl] & {
            margin: 0 0 0 10px;
        }

        &:last-of-type {
            margin: 0;
        }
    }

    .selected {
        border-color: var(--secondary-1);
        color: var(--secondary-1);
    }
}


.toggleBtnSm {
    display: none;

    @media screen and (max-width: 765px) {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 32px;
        height: 32px;
        background-color: var(--white);
        border: 1px solid var(--primary-color);
        border-radius: 50%;
        margin: 0 0 0 20px;
        position: relative;

        [dir=rtl] & {
            margin: 0 20px 0 0;
        }

        >svg {
            width: 14px;
            height: 14px;

            &:nth-of-type(1) {
                display: flex;
            }

            &:nth-of-type(2) {
                display: none;
            }

            g {
                fill: var(--primary-color);
            }
        }

        &::before {
            content: "";
            height: 100%;
            width: 2px;
            background-color: var(--border-colors);
            display: block;
            position: absolute;
            top: 0;
            left: -10px;
            right: auto;

            [dir=rtl] & {
                right: -10px;
                left: auto;
            }
        }
    }
}

.listAndMapBtn {
    >svg {
        &:nth-of-type(1) {
            display: none;
        }

        &:nth-of-type(2) {
            display: flex;
        }
    }
}

@mixin mainContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: $margin-top;

    @media screen and (max-width: 765px) {
        >div {
            width: 100% !important;

            &:nth-of-type(2) {
                display: none;
            }
        }
    }
}

.estimationMapTabContainer {
    @include mainContainer();
    height: calc(var(--window-height) - var(--header-height) - var(--main-padding-H) - var(--header-margin-bottom) - var(--header-margin-bottom) - $margin-top - $margin-top - $margin-top - var(--tab-container-height) - var(--header-pages-panel-height)) !important;
}

.estimationMapContainer {
    @include mainContainer();
    height: calc(var(--window-height) - var(--header-height) - var(--main-padding-H) - var(--main-padding-H) - var(--header-margin-bottom) - var(--header-margin-bottom) - var(--header-pages-panel-height) - $margin-top - $margin-top - $margin-top);
}

.estimationMapContainerSm {
    @media screen and (max-width: 765px) {
        >div {
            width: 100% !important;

            &:nth-of-type(1) {
                display: none;
            }

            &:nth-of-type(2) {
                display: block;
            }
        }
    }
}


.mapTemplates {
    width: $card-width;
    height: 100%;
    padding: 0 0 var(--frm-template-panel-padding-top) 0 !important;

    >div {
        height: 100%;
    }

    @media screen and (max-width: 1000px) {
        padding: var(--frm-template-panel-padding-top) 10px !important;
    }

    @media screen and (max-width: 1025px) {
        >div {
            padding-bottom: 20px !important;
        }
    }
}

.newEstimationMap {
    width: $new-card-width;
    height: 100%;
    padding: 0 0 var(--frm-template-panel-padding-top) 0 !important;

    @media screen and (max-width: 1000px) {
        padding: var(--frm-template-panel-padding-top) 10px !important;
    }
}

.sectionMapContainer {
    width: calc(100% - $card-width);
    height: 100%;
    margin: 0 0 0 10px;

    [dir=rtl] & {
        margin: 0 10px 0 0;
    }

    @media screen and (max-width: 1000px) {
        margin: 0;
    }
}

.tempMap {
    width: calc(100% - $card-width);
    height: 100%;
    margin: 0 0 0 10px;
    padding: 10px 0 !important;

    [dir=rtl] & {
        margin: 0 10px 0 0;
    }


    @media screen and (max-width: 1000px) {
        margin: 0;
        padding: var(--frm-template-panel-padding-top) 10px !important;
    }
}

.newTempMap {
    width: calc(100% - $new-card-width );
    height: 100%;
    margin: 0 0 0 10px;
    padding: 10px 0 !important;

    [dir=rtl] & {
        margin: 0 10px 0 0;
    }


    @media screen and (max-width: 1000px) {
        margin: 0;
        padding: var(--frm-template-panel-padding-top) 10px !important;
    }
}

.mainMap {
    position: relative;
    height: 100%;

    >div {
        height: calc(100% - 20px);
    }
}

.containerArrow {
    >button {
        position: absolute;
        bottom: 120px;
        z-index: 99999;

        &:nth-of-type(1) {
            >svg {
                transform: rotate(180deg);
            }
        }
    }
}

.right {
    right: 20px;
}

.left {
    left: 0px;
}

.mainSimData {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.simPrice {
    color: var(--secondary-1);
}

.estimationMapPin {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    font-family: var(--font-en);
    position: relative;

    [dir=rtl] & {
        font-family: var(--font-ar);
    }

    >div {
        >svg {
            width: 50px;
            height: 50px;
        }
    }
}

.starPinIcon {
    >svg {
        width: 44px;
        height: 44px;
    }
}

.orderByButtonContainer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--light-gray);
    width: 100%;

    >button {
        width: 24.5%;
    }
}

@mixin OrderByButton {
    position: relative;
    transition: all ease .2s;
    transform: translate3d(0, 0, 0);
    height: $orderby-button-width;
    min-width: 70px;
    padding: 0 4px;
    font-weight: 500;
    font-size: 13px;
    border-radius: 0;

    &:after {
        content: '';
        transition: all .2s cubic-bezier(1, 0, 0, 1);
        will-change: transform, box-shadow, opacity;
        position: absolute;
        height: 3px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        border-radius: 3px 3px 0px 0px;
        background: var(--line-color);
        opacity: 0;
        transform: scale(0, 0.5);
    }

    &:last-of-type {
        margin: 0;
    }

    @media screen and (max-width: 450px) {
        min-width: 60px;
        font-size: 10px;
        padding: 0 5px;
    }
}

.activeOrderByButton {
    @include OrderByButton();
    color: var(--primary-1);
    font-weight: 600;

    &:after {
        opacity: 1;
        transform: scale(1, 1);
    }
}

.inactiveOrderByButton {
    @include OrderByButton();
    color: var(--black);
}

.cardsContainer {
    height: calc(100% - 40px - 40px);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden auto;
    position: relative;
    // @media screen and (max-width: 650px) {
    //     height: calc(100% - 40px - 40px - 20px);
    // }
}

.cardsContainerTab {
    height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - 10px - 40px - var(--header-pages-panel-height) - 10px - 40px - 40px - 40px - 10px) !important;
}

@mixin cardContainer {
    background-color: var(--white);
    width: 100%;
    min-height: $card-height;
    margin-bottom: 0px;
    position: relative;
    padding: 10px;
    display: flex;

    @media screen and (max-width: 1024px) {
        display: flex;
        flex-wrap: wrap;
        min-height: $card-height-sm;
    }

    @media screen and (max-width: 635px) {
        justify-content: center;
    }

    .tempMap {
        @media screen and (max-width: 1000px) {
            width: 255px;
        }

        @media screen and (max-width: 635px) {
            width: 100%;
        }
    }
}

.cardContainer {
    @include cardContainer();
    border-bottom: 1px solid var(--border-colors);
}

.comperdCardContainer {
    @include cardContainer();
    border: 1px solid var(--primary-color);
}

.sectionOne {
    display: flex;
    width: 100%;
}

.contentCard {
    width: calc(100% - 105px - 10px);

    @media screen and (max-width: 1025px) {
        width: 100%;
        margin-top: 20px;
    }
}

.bgImage {
    position: relative;
    border-radius: 10px;
    margin: 0 10px 0 0;
    width: 105px;
    height: 90px;

    [dir=rtl] & {
        border-radius: 10px;
        margin: 0 0 0 10px;
    }

    .cardImg {
        width: 100%;
        height: 90%;

        img {
            height: 100% !important;
        }
    }

    @media screen and (max-width: 1025px) {
        display: none;
    }
}

@mixin Status-Card {
    text-align: center;
    background-color: white;
    color: #D8A172;
    min-width: 50px;
    max-width: 80px;
    padding: 4px 8px;
    border-radius: 10px;
    font-size: 10px;

    [dir=rtl] & {
        font-size: 10px
    }
}

.statusCard {
    @include Status-Card();
    margin: 5px;
    position: absolute;
    top: 10px;

    @media screen and (max-width: 1025px) {
        min-width: 40px;
        font-size: 8px;
        top: 2px;
        right: 0;
        left: auto;
        background-color: #D8A172;
        color: var(--white);

        [dir=rtl] & {
            left: 0;
            right: auto;
        }
    }
}

.statusMapsCard {
    @include Status-Card();
    margin: 0 10px 7px 0;

    [dir=rtl] & {
        margin: 0 0 7px 10px;
    }
}

.dateCard {
    position: absolute;
    bottom: 20px;
    margin: 5px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    min-width: 100px;
    font-size: 9px;
    padding: 4px 8px;
    left: 5px;
    right: auto;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;

    [dir=rtl] & {
        right: 5px;
        left: auto;
    }

    @media screen and (max-width: 1025px) {
        background-color: rgba(0, 0, 0, 0.5);
        min-width: 40px;
        font-size: 10px;
        padding: 3px 6px;
        top: 2px;
        bottom: auto;
        right: 80px;
        left: auto;

        [dir=rtl] & {
            left: 80px;
            right: auto;
        }
    }
}

.detailsMapsCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7px;

    >div {
        display: flex;
        align-items: center;

        &:nth-of-type(1) {
            width: 33%;
        }

        &:last-of-type {
            color: var(--text-3);
            width: 67%
        }

        label {
            color: var(--text-2);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @media screen and (max-width: 1025px) {
                font-size: 0.8rem;
            }
        }

        svg {
            margin: 0 5px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 5px;
            }
        }

        >span {
            width: 13px;
            height: 13px;
        }
    }

    .price {
        color: var(--secondary-1);
        font-weight: 500;
        font-size: 13px;

        @media screen and (max-width: 1025px) {
            font-size: 1rem;
            font-weight: 600;
        }
    }

    .mainText {
        font-weight: 500;
        font-size: 0.8rem;
    }
}

.locationLbl {
    font-weight: 500;
    font-size: 0.8rem;
}

.codeCard {
    font-weight: 600 !important;
    font-size: 1rem !important;
}

.UserMap {
    @media screen and (max-width: 1025px) {
        flex-direction: column;

        >div {
            width: 100% !important;
            display: block !important;
            margin-bottom: 7px;
        }
    }
}

.landsDetailsMapsCard {
    display: flex;
    align-items: center;
    justify-content: space-between;

    >div {
        width: 33%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        label {
            margin-top: 6px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 0.9rem;

            &:first-of-type {
                font-weight: 500;
            }

            &:last-of-type {
                color: var(--text-3);
            }
        }
    }
}

.pagination {
    height: $pagination-height;
    display: flex;
    list-style: none;
    outline: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    .next_prev_button {
        width: 30px;
        height: 30px;
        border: 1px solid #c9ced6;

        svg {
            width: 10px;
            height: 10px;
            transform: rotate(180deg);

            [dir=rtl] & {
                transform: rotate(0deg);
            }
        }

    }

    .breakLabel {
        >a {
            cursor: initial !important;

            >label {
                color: var(--blue) !important;
                font-weight: 600;
                height: $break-label-height;
                line-height: 0;
            }
        }
    }
}

.pagination>.activeButton>a {
    background-color: var(--pageination) !important;
    color: var(--white) !important;
    font-weight: bold;
}

.pagination>li>a {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--th-color);
    border-radius: 5px;
    cursor: pointer;
    font-family: var(--font-en);

    [dir=rtl] & {
        font-family: var(--font-ar);
    }
}


// -***************map card******************
@mixin Map-Card-Container {
    background-color: var(--white);
    width: 240px;
    min-height: 235px;
    margin: 0 10px 10px;
    margin-bottom: 0;
    border-radius: 10px;
    padding-bottom: 0;
    position: relative;
    z-index: 2;
}

.mapCardContainer {
    @include Map-Card-Container();
}

.imgBgCard {
    position: relative;
    width: 100%;
    height: 100px;
    border-radius: 10px 10px 0 0;

    >img {
        border-radius: 10px 10px 0 0;
        width: 100%;
        max-height: 100%;
    }

    @media screen and (max-width: 1025px) {
        display: none;
    }
}

@mixin Type-Card {
    position: absolute;
    background-color: var(--white);
    color: var(--black);
    border-radius: 20px;
    min-width: 80px;
    height: 30px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 5px;

    >label {
        color: #D8A172;
        font-weight: 500;
    }
}

.typeCard {
    @include Type-Card();
    right: 5px;
    left: auto;

    [dir=rtl] & {
        left: 5px;
        right: auto;
    }
}

.typeSimCard {
    @include Type-Card();
    left: 5px;
    right: auto;

    [dir=rtl] & {
        right: 5px;
        left: auto;
    }
}

.mapCardDetails {
    padding: 10px;
}

.mapCardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.mapCardCode {
    color: var(--text-2);
    font-weight: 500;
    font-size: 1rem;

    @media screen and (max-width: 1025px) {
        font-size: 0.9rem;
    }
}

.mapCardEvaluationPrice {
    color: var(--secondary-1);
    font-weight: 500;
    font-size: 1rem;

    @media screen and (max-width: 1025px) {
        font-size: 0.9rem;
    }
}

@mixin MapCardDetails {
    display: flex;
    align-items: center;
    margin-top: 8px;
    overflow: hidden;

    >span {
        margin: 0 10px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 10px;
        }

        >svg {
            width: 13px;
            height: 13px;
        }
    }

    >label {
        font-size: 0.9rem;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media screen and (max-width: 1025px) {
            font-size: 0.8rem;
        }
    }
}

.userMapCard {
    @include MapCardDetails();

    >label {
        color: var(--text-2);
    }
}

.locationMapCard {
    @include MapCardDetails();

    >label {
        color: var(--text-3);
    }
}

.pricesContainer {
    display: flex;
    align-items: center;
}

@mixin Labels {
    font-size: 0.9rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;

    @media screen and (max-width: 1025px) {
        font-size: 0.8rem;
    }
}

.lblMapCardDetails {
    @include Labels();
    width: 55px;
    margin: 0 10px 0 0;

    [dir=rtl] & {
        margin: 0 0 0 10px;
    }
}

.valMapCardDetails {
    @include Labels();
}

.landAreaContainer {
    display: flex;
    align-items: center;
    width: calc(100% - 55px);

    >span {
        font-size: 0.8rem;
        font-weight: 500;
        margin: 0 0 0 3px;

        [dir=rtl] & {
            margin: 0 3px 0 0;
        }
    }
}

.measurePrice {
    >label {
        font-size: 1rem;
        color: var(--text-3);
    }
}

.currency {
    color: var(--text-3);
    margin: 0 0 0 8px;

    [dir=rtl] & {
        margin: 0 8px 0 0;
    }

    @media screen and (max-width: 1025px) {
        font-size: 0.7rem;
    }
}

.contentMapCard {
    display: flex;
    align-items: center;
    margin-top: 8px;

    >div {
        width: 50%;
    }
}

// -***************map card******************

.next_prev_disabled {
    cursor: not-allowed;

    * {
        cursor: not-allowed !important;
    }

    a {
        svg {
            width: 10px;
            height: 10px;

            g {
                path {
                    fill: var(--text-3);
                }
            }

        }
    }
}

.compareCadrPin {
    width: 2px;
    height: 100%;
    background-color: var(--primary-color);
}

.office {
    color: rgb(26, 0, 190) !important;
    font-weight: 600 !important;

    @media screen and (max-width: 1024px) {
        background-color: rgb(26, 0, 190) !important;
        color: var(--white) !important;
    }
}

.appar {
    color: rgb(145, 42, 154) !important;
    font-weight: 600 !important;

    @media screen and (max-width: 1024px) {
        background-color: rgb(145, 42, 154) !important;
        color: var(--white) !important;
    }
}

.containerNoDataFound {
    position: relative;
    height: 100%;
    width: 100%;
}

.headerSearchSm {
    @media screen and (max-width: 650px) {
        >div {
            width: calc(100% - 70px) !important;
            min-width: calc(100% - 70px) !important;
        }

        >button {
            &:last-of-type {
                min-width: 30px;
                width: 30px;
                height: 30px;
            }
        }
    }
}