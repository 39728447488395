$model-height-sm: 550px;
$model-height: 650px;
$org-dialog-height: 580px;

.installations {
  width: 80%;

  @media screen and (max-width: 650px) {
    margin-top: 10px !important;
  }
}

.insertBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  >button {
    &:nth-of-type(1) {
      margin: 0 10px 0 0;

      [dir="rtl"] & {
        margin: 0 0 0 10px;
      }
    }
  }
}

.organizationDialog {
  height: $org-dialog-height;
}

.organizationsTableDialog {
  div[class^="v_table_body"] {
    position: relative;
    height: calc($org-dialog-height - var(--common-height) - 55px - 20px - var(--table-header-height) - 60px);

    >div {
      &[class*="noDataFoundContainer"] {
        >div {
          width: auto !important;
          position: static !important;
          left: auto !important;
          right: auto !important;

          [dir="rtl"] & {
            right: auto !important;
            left: auto !important;
          }
        }
      }
    }
  }

  div[class^="v_table_header"],
  div[class^="v_table_body"] {
    width: 100%;
  }

  @media screen and (max-width: 1024px) {

    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      width: 100%;
      min-width: 100% !important;
    }
  }

  @media screen and (max-width: 650px) {

    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      width: 100%;
      min-width: 100% !important;
    }
  }
}

// ******************organization Table**********************
.organizationRow {
  >div {
    display: flex;
    justify-content: center;
  }

  button {
    min-width: auto !important;
  }
}

.organizationTableContainer {
  overflow-x: auto;
}

.organizationTable {

  div[class^="v_table_header"],
  div[class^="v_table_body"] {
    width: 100%;
  }

  @media screen and (max-width: 1024px) {

    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      width: 100%;
      min-width: 100% !important;
    }
  }

  div[class^="v_table_body"] {
    position: relative;
    height: calc($model-height - var(--modal-with-steps-header-height) - 57px - var(--table-header-height) - var(--modal-footer-height) - 10px);

    @media screen and (max-width: 1400px) {
      --table-body-height: #{$model-height-sm};
      height: calc($model-height-sm - var(--modal-with-steps-header-height) - 57px - var(--table-header-height) - var(--modal-footer-height) - 10px) !important;
    }

    @media screen and (max-width: 650px) {
      height: calc($model-height-sm - var(--modal-with-steps-header-height) - 70px - var(--table-header-height) - var(--modal-footer-height) - 10px - 10px) !important;
    }
  }

  div[class^="v_table_header"],
  div[class^="v_table_row"] {
    display: flex;
    align-items: center;

    >div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }
    }
  }
}

.organizationTableSidePanel {

  div[class^="v_table_header"],
  div[class^="v_table_body"] {
    width: 100%;
  }

  @media screen and (max-width: 1024px) {

    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      width: 100%;
      min-width: 100% !important;
    }
  }

  div[class^="v_table_body"] {
    position: relative;
    height: calc(var(--window-height) - var(--side-panel-header-height) - var(--side-panel-footer-height) - 15px - 15px - var(--tab-container-height) - 20px - 127px - 15px - var(--table-header-height));
  }

  div[class^="v_table_header"],
  div[class^="v_table_row"] {
    display: flex;
    align-items: center;

    >div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }
    }
  }
}

// ******************organization Table**********************

.insertBtnSidePanel {
  justify-content: flex-start;
  width: 80% !important;

  button {
    flex: 1 1;
    min-width: 100px !important;
    margin: 0 !important;

    label {
      width: auto !important;
    }
  }
}

.organizationListInsert {
  @media screen and (max-width: 650px) {
    justify-content: flex-start !important;
  }
}

.tableHeaderActionsSm {

  @media screen and (max-width: 650px) {
    justify-content: flex-start !important;
  }
}