$model-height-sm : 550px;
$model-height : 650px;

.financialIntegrationRow {
    >div {
        display: flex;
        justify-content: center;
    }
}

.financialIntegrationTableContainer {
    overflow-x: auto;
}
.financialIntegrationMultiTable{
    @media screen and (max-width: 1024px) {

        div[class^=v_table_header],
        div[class^=v_table_body] {
            width: 100%;
            min-width: 100% !important;
        }
    }
}

.financialIntegrationTable {

    div[class^=v_table_header],
    div[class^=v_table_body] {
        width: 100%;
    }

    @media screen and (max-width: 650px) {

        div[class^=v_table_header],
        div[class^=v_table_body] {
            width: 100%;
            min-width: 900px !important;
        }
    }

    div[class^=v_table_body] {
        height: calc(var(--model-dialog-height) - var(--modal-with-tabs-header-height) - var(--table-header-actions-height) - var(--table-header-actions-margin-bottom) - var(--table-header-height) - var(--modal-footer-height) - 10px - 10px) !important;
    }

    div[class^=v_table_header],
    div[class^=v_table_row] {
        display: flex;
        align-items: center;

        >div {
            text-align: center;
            display: flex;
            justify-content: center;

            &:nth-child(1) {
                width: var(--col-1);
            }

            &:nth-child(2) {
                width: var(--col-2);
            }

            &:nth-child(3) {
                width: var(--col-3);
            }

            &:nth-child(4) {
                width: var(--col-4);
            }

            &:nth-child(5) {
                width: var(--col-5);
            }

            &:nth-child(6) {
                width: var(--col-6);
            }
        }
    }
}