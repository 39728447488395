$container-chat-height: 200px;

// *************Chat***********
.containerChat {
    padding: var(--table-container-padding);
    width: 100%;
    background-color: #FAFAFA;
    overflow: hidden;
    min-height: $container-chat-height !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;

    @media screen and (max-width: 1024px) {
        justify-content: center !important;
    }
}

.chat {
    background-color: var(--white);
    width: 90% !important;
    min-height: $container-chat-height !important;
    padding: 0 40px 20px;
    border-radius: 10px;

    // @media screen and (max-width: 1024px) {
    //     width: 400px !important;
    // }

    @media screen and (max-width: 650px) {
        padding: 0 0 20px;
    }

    >div {
        &[class*=noDataFoundContainer] {
            width: auto;
            height: auto;

            >div {
                position: static;
                width: auto;
                height: auto;
            }
        }
    }
}

.noData {
    display: flex;
    align-items: center;
    justify-content: center;
}

.chatDate {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    position: sticky;
    top: 0;
}

.containerSeander {
    margin: 20px 0;
    display: flex;

    @media screen and (max-width: 1024px) {
        margin-bottom: 20px;
    }
}

.containerRecipient {
    display: flex;
    direction: rtl;
    margin: 20px 0;

    [dir=rtl] & {
        direction: ltr;
    }
}

@mixin SenderRecipient {
    max-width: 50%;
    min-width: 220px;
    padding: 10px;

    @media screen and (max-width: 375px) {
        min-width: 100%;
    }
}

.sender {
    @include SenderRecipient();
    background-color: var(--white);
    border: 1px solid var(--border-table);
    border-radius: 0px 15px 15px 15px;

    [dir=rtl] & {
        border-radius: 15px 0 15px 15px;
    }
}

.recipient {
    @include SenderRecipient();
    background-color: #EFF1FE;
    border: 1px solid var(--border-table);
    border-radius: 15px 0 15px 15px;

    [dir=rtl] & {
        border-radius: 0px 15px 15px 15px;
    }
}

@mixin ImgChat {
    min-width: 30px;
    height: 30px;
    border-radius: 50%;
    color: var(--white);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 375px) {
        min-width: 25px;
        height: 25px;
    }
}

.senderImg {
    @include ImgChat();
    background-color: var(--text-2);
    margin: 0 10px 0 0;

    [dir=rtl] & {
        margin: 0 0 0 10px;
    }
}

.recipientImg {
    @include ImgChat();
    background-color: #8292C0;
    margin: 0 0 0 10px;

    [dir=rtl] & {
        margin: 0 10px 0 0;
    }
}

@mixin ChatTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    >label {
        font-size: 14px;
        white-space: wrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: start !important;
        max-height: 90% !important;

        &:nth-of-type(1) {
            color: #8057F5;
            margin: 0 10px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 10px
            }
        }
    }

    @media screen and (max-width: 650px) {
        align-items: flex-start;
    }

}

.senderTitle {
    @include ChatTitle();
    width: 100%;

    >label {

        &:nth-of-type(1) {
            width:  fit-content;
        }

        &:nth-of-type(2) {
            color: var(--secondary-1);
            width: 60px;
        }
    }
}

.recipientTitle {
    @include ChatTitle();

    >label {
        &:nth-of-type(1) {
            margin: 0 0 0 10px;

            [dir=rtl] & {
                margin: 0 10px 0 0;
            }
        }

        @media screen and (max-width: 650px) {
            font-size: 1.02rem;
        }
    }
}

.chatDesc {
    font-size: 14px;
    font-family: var(--font-en);

    [dir=rtl] & {
        font-family: var(--font-ar);
    }

    @media screen and (max-width: 650px) {
        font-size: 1rem;
    }
}

@mixin DetailsMsg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;

    >svg {
        width: 16px;
        height: 16px;
    }

    >label {
        font-size: 11px;
    }
}

.senderDetails {
    @include DetailsMsg();
}

.executedMsg {
    >svg {
        path {
            fill: #668fff;
        }
    }
}

.recipientDetails {
    @include DetailsMsg();
    flex-direction: row-reverse;
}

.mention {
    font-size: 11px;
    display: flex;
    align-items: center;

    [dir=rtl] & {
        font-size: 12px;
    }

    >label {
        display: flex;
        align-items: center;
        color: var(--text-2) !important;
        margin: 0 10px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 10px;
        }

        >span {
            color: var(--secondary-1);
            font-size: 14px;
        }
    }
}

// *************Chat***********