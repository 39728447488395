// ******************organization Table**********************

.organizationRow {
    >div {
        display: flex;
        justify-content: center;
    }
}

.organizationTableContainer {
    overflow-x: auto;
}


.organizationTable {

    div[class^=table_header],
    div[class^=v_table_body] {
        width: 100%;
    }

    div[class^=v_table_body] {
        height: calc(var(--model-dialog-height) - var(--modal-with-tabs-header-height) - 57px - var(--table-header-height) - var(--modal-footer-height) - 10px);

        @media screen and (max-width: 1400px) {
            height: calc(var(--model-dialog-height-sm) - var(--modal-with-tabs-header-height) - 57px - var(--table-header-height) - var(--modal-footer-height) - 10px) !important;
        }
    }

    div[class^=table_header],
    .organizationRow,
    div[class^=table_body]>div {
        display: flex;
        align-items: center;

        >div {
            text-align: center;
            display: flex;
            justify-content: center;

            &:nth-child(1) {
                width: 5%;
            }

            &:nth-child(2) {
                width: 85%;
            }

            &:nth-child(3) {
                width: 10%;
            }
        }
    }
}

.installations {
    width: 80%;
    height: 37px;
}

// ******************organization Table**********************

// ******************financialIntegration Table**********************

.financialIntegrationRow {
    >div {
        display: flex;
        justify-content: center;
    }
}

.financialIntegrationTableContainer {
    overflow-x: auto;
}


.financialIntegrationTable {

    div[class^=table_header],
    div[class^=v_table_body] {
        width: 100%;
    }


    div[class^=v_table_body] {
        height: calc(var(--model-dialog-height-sm) - var(--modal-with-tabs-header-height) - 57px - var(--table-header-height) - var(--modal-footer-height) - 10px);

        @media screen and (max-width: 1400px) {
            height: calc(var(--model-dialog-height-sm) - var(--modal-with-tabs-header-height) - 57px - var(--table-header-height) - var(--modal-footer-height) - 10px) !important;
        }
    }

    div[class^=table_header],
    .financialIntegrationRow,
    div[class^=table_body]>div {
        display: flex;
        align-items: center;

        >div {
            text-align: center;
            display: flex;
            justify-content: center;

            &:nth-child(1) {
                width: 5%;
            }

            &:nth-child(2) {
                width: 25%;
            }

            &:nth-child(3) {
                width: 30%;
            }

            &:nth-child(4) {
                width: 30%;
            }

            &:nth-child(5) {
                width: 10%;
            }
        }
    }
}

// ******************linkingGroups Table**********************


.organizationDialog {

    div[class^=table_header],
    div[class^=v_table_body] {
        width: 100%;
    }

    div[class^=table_header],
    div[class^=table_body]>div,
    div[class^=v_table_row] {
        display: flex;
        align-items: center;

        >div {
            text-align: center;
            display: flex;
            justify-content: center;

            &:nth-child(1) {
                width: 5%;
            }

            &:nth-child(2) {
                width: 5%;
            }

            &:nth-child(3) {
                width: 20%;
            }

            &:nth-child(4) {
                width: 70%;
            }
        }
    }
}

.financialIntegrationDialog {

    div[class^=table_header],
    div[class^=v_table_body] {
        width: 100%;
    }

    div[class^=table_header],
    div[class^=table_body]>div,
    div[class^=v_table_row] {
        display: flex;
        align-items: center;

        >div {
            text-align: center;
            display: flex;
            justify-content: center;

            &:nth-child(1) {
                width: 5%;
            }

            &:nth-child(2) {
                width: 5%;
            }

            &:nth-child(3) {
                width: 25%;
            }

            &:nth-child(4) {
                width: 40%;
            }

            &:nth-child(5) {
                width: 25%;
            }
        }
    }
}