// ******************value Table**********************
.frmPadding {
    padding: 20px 10px;
}

.valueRow {
    >div {
        display: flex;
        justify-content: center;
    }
}

.valueTable {
    div[class^=v_table_body] {
        height: calc(var(--model-dialog-height) - var(--modal-with-tabs-header-height) - var(--modal-with-tabs-footer-height) - 10px - var(--table-header-height) - 40px);
    }

    div[class^=v_table_header],
    div[class^=v_table_row] {
        display: flex;
        align-items: center;

        >div {
            text-align: center;
            display: flex;
            justify-content: center;

            &:nth-child(1) {
                width: var(--col-1);
            }

            &:nth-child(2) {
                width: var(--col-2);
            }

            &:nth-child(3) {
                width: var(--col-3);
            }

            &:nth-child(4) {
                width: var(--col-4);
            }

            &:nth-child(5) {
                width: var(--col-5);
            }

            &:nth-child(6) {
                width: var(--col-6);
            }

            &:nth-child(7) {
                width: var(--col-7);
            }

        }
    }
}

// ******************value Table**********************