$tabsBoxShadow: 0px 2px 5px #FDFDFD;
$frm-border-radius : 5px;
$frm-display-header-margin-bottom : 10px;

@mixin focusedInput {
    border-color: var(--secondary-1);
}

@mixin formTemplatePanel {
    background-color: var(--white);
    padding: 15px 30px;
    box-shadow: $tabsBoxShadow;
    border-radius: 5px;
}

.form-templates-panel {
    @include formTemplatePanel();

    @media screen and (max-width: 650px) {
        padding: 10px !important;
    }
}

@mixin formLabel {
    color: var(--label-color);
    font-weight: 500;
    font-size: 1.1rem;
    display: block;
    line-height: 1.2;
    font-family: var(--font-en);
    padding: 8px 0 8px 5px;

    [dir=rtl] & {
        padding: 8px 5px 8px 0;
    }

    [dir=rtl] & {
        font-family: var(--font-ar);
    }
}

.form-label {
    @include formLabel();
}

@mixin Frm-Entry-Input {
    width: 100%;
    height: var(--common-height);
    border: 1px solid #c2c4c6b5;
    border-radius: 5px;
    background-color: var(--white);
    font-weight: 500;
    // box-shadow: 0 4px 8px 0 rgba(44, 39, 56, 0.04);

    &:focus-within {
        @include focusedInput();
    }

    &:disabled,
    &.disabled {
        background-color: var(--disable-colors);
        cursor: not-allowed
    }

    &::placeholder {
        font-size: 1rem;
        color: var(--placeholder) !important;
        box-sizing: border-box;
        font-weight: 500 !important;
    }

    @media screen and (max-width: 650px) {
        font-size: 1.2rem !important;

        &::placeholder {
            font-size: 1rem;
        }
    }
}

.frm-entry-input {
    @include Frm-Entry-Input();
    overflow: hidden;
    padding: 2px 10px !important;
    color: var(--black);

    &[type=number] {
        outline: none
    }
}

.readOnly {
    @include Frm-Entry-Input();
    background-color: var(--disable-colors) !important;
    overflow: hidden;
    padding: 2px 10px !important;
    color: var(--black);
    cursor: pointer;
}

.frm-entry-date {
    font-family: var(--font-en) !important;
    font-weight: 600;
}

.frm-entry-combo {
    @include Frm-Entry-Input();

    @media screen and (max-width: 650px) {
        font-size: 1.1rem !important;
    }

    input[type=text] {
        color: var(--black) !important;
    }

    &:disabled,
    &.disabled {
        svg {
            path {
                fill: var(--text-4);
            }
        }
    }

    &:hover {
        >div {
            >div {
                >div {
                    >div {
                        &[class*=indicatorContainer] {
                            visibility: visible !important;
                        }
                    }
                }
            }
        }
    }
}

.frm-entry-textArea {
    @include Frm-Entry-Input ();
    width: 100%;
    height: 80px;
    border: 1px solid var(--border-colors);
    border-radius: 5px;
    padding: 10px;
    resize: none;
    font-weight: 500;

    &:focus-within {
        @include focusedInput();
    }
}

.radio-options {
    display: flex;
    align-items: center;

    button {
        background-color: var(--white) !important;
        flex: 1;
        justify-content: flex-start;
        font-weight: 500;

        label {
            cursor: inherit;
            white-space: nowrap;
            color: var(--black);
        }

        >div {
            transition: .2s;
            border-radius: 50%;
            height: 17px;
            width: 17px;
            border: 1px solid var(--border-colors);
            margin: 0 10px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 10px;
            }
        }

        &.option {
            >div {
                background: var(--white);
            }
        }

        &.selected-option {
            >div {
                display: flex;
                align-items: center;
                justify-content: center;
                border-color: var(--secondary-2);

                >span {
                    width: 7px;
                    height: 7px;
                    border-radius: 50%;
                    background-color: var(--secondary-2);
                }
            }
        }

        &:disabled {
            &:hover {
                background-color: transparent !important;
            }

            >div {
                background-color: var(--disable-colors);
                border-color: var(--disable-colors);

            }

            >label {
                color: var(--text-2);
            }
        }
    }

    @media screen and (max-width: 650px) {
        // width: 100%;

        button {
            // width: 50% !important;
            border: 1px solid var(--border-colors);
            height: var(--common-height);
            padding: 0 10px;
            justify-content: center;

            >div {
                display: none !important;
            }

            &.selected-option {
                border-color: var(--secondary-2);
                color: var(--secondary-2);
            }

            &:nth-of-type(1) {
                margin: 0 10px 0 0;

                [dir=rtl] & {
                    margin: 0 0 0 10px;
                }
            }
        }
    }
}

.readOnly {
    height: var(--common-height);
    border: 1px solid var(--border-colors);
    border-radius: 5px;
    background-color: var(--white);
    border: 1px solid var(--border-colors);
    font-size: 1.15rem;
    display: flex;
    align-items: center;
    padding: 0 10px;

    >label {
        width: 150px;
        color: #7F7F7F;
    }

    .lblValue {
        width: calc(100% - 150px);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

    }

    @media screen and (max-width: 1025px) {
        height: 32px;
        font-size: 1rem;

        >label {
            margin-bottom: 5px;
            padding-top: 5px;
            font-size: 12px !important;
        }
    }
}

.frm-container {
    display: flex;
    justify-content: space-between;
    height: calc(100% - 10px);
}

.frm-entry-panel {
    width: var(--entry-panel-width);
    min-width: var(--entry-panel-width);
    height: 100%;
    border-radius: $frm-border-radius;
    background-color: var(--white);
    position: relative;
    padding: 0 0 10px 15px;
    transition: width 0.3s ease;

    [dir=rtl] & {
        padding: 0 15px 10px 0;
    }


    &[class*=show-hide-menu] {
        width: var(--menu-trx-width) !important;
        min-width: 0 !important;
        padding: 0 !important;
    }
}

.frm-display-panel {
    width: calc(100% - var(--entry-panel-width) - 10px);
    transition: width 0.3s ease;
    margin: 0 0 0 10px;

    [dir=rtl] & {
        margin: 0 10px 0 0;
    }

    &[class*=show-hide-menu] {
        width: calc(100% - var(--menu-trx-width) - 10px);
    }
}

.frm-header-title-panel {
    height: var(--display-panel-header-height);
    display: flex;
    align-items: center;
}

.frm-title {
    font-size: 1.2rem;
    line-height: 1.4;
    position: relative;
    margin-bottom: 10px;
    color: var(--frm-title);
}

.frm-display-panel-header {
    height: var(--display-panel-header-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white);
    border-radius: $frm-border-radius;
    margin-bottom: $frm-display-header-margin-bottom;
    padding: 0 15px;
}

.form-checkbox {
    display: flex;
    align-items: center;

    >label {
        color: var(--title-text);

        &:first-of-type {
            margin: 0 7px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 7px;
            }
        }
    }
}

.scrollShadow {
    height: 30px;
    width: 100%;
    top: 0;
    position: sticky;
    z-index: 5;
    box-shadow: inset 0 5px 13px -6px rgb(144 144 144 / 28%)
}

.scrollShadowBottom {
    height: 30px;
    width: 100%;
    bottom: 0;
    position: sticky;
    z-index: 5;
    box-shadow: 0px 15px 10px -15px rgb(0 0 0)
}

.inputRow {
    width: 100%;
    height: 100%;

    >div,
    input {
        border: none !important;
        height: 100%;
        background-color: transparent !important;

        &[class*=disabled] {
            background-color: var(--disable-colors) !important;
        }

        &:disabled {
            background-color: var(--disable-colors) !important;
        }
    }
}

.insertBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    >button {
        &:nth-of-type(1) {
            margin: 0 10px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 10px;
            }
        }
    }
}

.tabsContentContainer {
    background-color: var(--white);
    height: calc(100% - var(--tab-container-height));
    border-radius: 0 var(--common-border-radius) var(--common-border-radius) var(--common-border-radius);

    [dir=rtl] & {
        border-radius: var(--common-border-radius) 0 var(--common-border-radius) var(--common-border-radius);
    }

    >div {
        display: none;

        &.active {
            display: block;
        }
    }
}

// ************filter input**************
.filter-panel-row {
    padding-top: 20px;

    >div {
        margin-bottom: 33px;

        @media screen and (max-width: 650px) {
            width: 100%;
        }
    }
}

.filter-panel {
    position: relative;

    >label {
        position: absolute;
        top: -10px;
        left: 20px;
        right: auto;
        background-color: var(--white);
        padding: 0 12px !important;
        z-index: 2;
        font-size: 1.05rem;

        [dir=rtl] & {
            right: 20px;
            left: auto;
        }
    }
}

.filter-panel-Radio-options {
    position: relative;

    >label {
        position: absolute;
        top: -17px;
        left: 0px;
        right: auto;
        background-color: var(--white);
        padding: 0 12px !important;
        z-index: 2;

        [dir=rtl] & {
            right: 0px;
            left: auto;
        }
    }

    >div {
        height: 25px;
    }
}

// ************filter input**************

// **********Title************
.frm-title-panel {
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.frm-title-form {
    position: relative;
    margin-bottom: 10px;
    color: var(--black);
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.2;

    &::after,
    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: auto;
        background-color: var(--secondary-2);
        height: 2px;


        [dir=rtl] & {
            right: 0;
            left: auto;
        }
    }

    &::after {
        bottom: -3px;
        width: 35px;
    }

    &::before {
        bottom: -8px;
        width: 55px;
    }
}

// **********Title************
.formOptionsHeader {
    width: 600px;
    display: flex;
    align-items: center;

    >div {
        width: 50%;
        display: flex;
        align-items: center;
        margin: 0 20px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 20px;
        }

        >label {
            margin: 0 10px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 10px;
            }
        }

        >div {
            width: calc(100% - 100px - 10px);

            &[class *=radio-options] {
                >button {
                    background-color: transparent !important;
                }
            }
        }
    }
}

.form-options-report-header {
    display: flex;
    align-items: center;

    >div {
        margin: 0 20px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 20px;
        }
    }
}

.main-price-panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    margin-top: 0px;
    height: 36px;
    width: 100%;

    >div {
        display: flex;
        align-items: center;
        width: 49%;
        height: 100%;

        >div {
            width: 50% !important;

            &:last-of-type {
                margin: 0 !important;
            }
        }
    }
}

.price-container {
    display: flex;
    align-items: center;
    width: 50%;
    height: 100%;
    background-color: var(--white);
    border: 1px solid var(--border-colors);
    border-radius: 7px;
    width: 300px;
    margin: 0 10px 0 0;
    padding: 0 10px;

    [dir=rtl] & {
        margin: 0 0 0 10px;
    }



    >label {
        font-weight: 500;
        font-size: 1.1rem;
        display: block;
        line-height: 1;
        height: 100%;
        padding: 0 !important;
        display: flex;
        align-items: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @media screen and (max-width: 1400px) {
            font-size: 1rem;
        }


        &:nth-of-type(1) {
            width: 125px;

            &::after {
                content: " : ";
                font-size: 1.2rem;
                margin: 0 5px;
            }

            @media screen and (max-width: 1400px) {
                width: 100px;
            }
        }

        &:nth-of-type(2) {
            direction: ltr;
            margin: 2px 20px 0 20px;
        }

        &:nth-of-type(3) {
            margin: 2px 0 0 0;
        }
    }
}

.balance-lbl {

    >label {
        &:nth-of-type(2) {
            color: var(--secondary-1);
        }
    }
}

@mixin Column-Row {
    display: flex;
    flex-direction: column;

    >label {

        &:nth-of-type(1) {
            max-height: 35px;
            font-weight: 600;
            margin-bottom: 7px;
            line-height: 1.5;
            color: var(--text-2);
        }

        &:nth-of-type(2) {
            color: var(--text-3);
            width: 100%;
        }

        @media screen and (max-width: 650px) {
            text-align: start !important;
        }
    }
}

.columnRow {
    @include Column-Row();

    @media screen and (max-width: 650px) {
        display: none !important;
    }
}

.columnRowNew {
    width: 100%;
    @include Column-Row();

    @media screen and (max-width: 650px) {
        display: none !important;
    }
}

.columnRowSm {
    display: none;

    @media screen and (max-width: 650px) {
        @include Column-Row();
    }
}

.stepWithTotal {
    height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - var(--main-padding-V) - var(--tab-container-height) - var(--totals-container-height) - 20px) !important;
    overflow-x: auto;
}

@mixin form-title-panel {
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

@mixin frm-title-form {
    margin-bottom: 10px;
    color: var(--black);
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.2;
}

.frm-title-panel {
    @include form-title-panel();
}

.frm-title-form {
    position: relative;
    @include frm-title-form();

    &::after,
    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: auto;
        background-color: var(--primary-color);
        height: 2px;

        [dir=rtl] & {
            right: 0;
            left: auto;
        }
    }

    &::after {
        bottom: -3px;
        width: 35px;
    }

    &::before {
        bottom: -8px;
        width: 55px;
    }
}

.frm-title-panel-border {
    @include form-title-panel();
    border-bottom: 1px solid var(--border-colors);
    height: 43px;
    padding: 10px
}

.frm-title-form-border {
    @include frm-title-form();
    margin-bottom: 0px;
}

.frm-title-panel-border-toggle {
    @include form-title-panel();
    border-bottom: 1px solid var(--border-colors);
    padding: 10px;
    flex-direction: column;
    height: auto !important;

    >div {
        width: 100%;

        &:nth-of-type(1) {
            display: flex;
            align-items: center;
            justify-content: space-between !important;
        }
    }
}

.frm-title-panel-border-toggle-table {
    @include form-title-panel();
    padding: 10px;
    flex-direction: column;
    height: auto !important;

    >div {
        width: 100%;

        &:nth-of-type(1) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

.desc-title-toggle {
    margin: 10px 0;
    line-height: 1.4;
    color: var(--text-4);
}

.toggle-mode {
    margin-top: 20px;
    height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - var(--header-tabs-height) - 20px);
    overflow-y: auto;
    background-color: var(--bg);
    padding: 0 20px;

    &::-webkit-scrollbar {
        display: none;
    }

    scrollbar-width: none;
    -ms-overflow-style: none;

    >div {
        background-color: var(--white);
        padding: 10px 20px;
        border-radius: var(--common-border-radius);
        margin-top: 15px !important;

        &:first-of-type {
            margin-top: 0 !important;
        }

        &:last-of-type {
            margin-bottom: 15px;
            padding-bottom: 80px !important;
        }
    }
}

.mobileMode {
    display: none !important;

    @media screen and (max-width: 650px) {
        display: flex !important;

        label {
            color: var(--blue) !important;
        }
    }
}

.summary-container {
    width: var(--summary-width-trx);
    // height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - var(--main-padding-V)) !important;
    height: 100%;
    background-color: var(--white);
    margin: 0 0 0 20px;
    border-radius: var(--common-border-radius);
    overflow: auto;

    [dir=rtl] & {
        margin: 0 20px 0 0;
    }

    @media screen and (max-width: 1024px) {
        margin: 0 !important;
        width: 100% !important;
        border-radius: 0 !important;
    }
}

.paddingFrm {
    padding: 10px 20px;
}

.tabsBg {
    background-color: var(--white);
    height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - var(--main-padding-V) - var(--tab-container-height)) !important;
}

.header-title-panel {
    display: flex;
    align-items: center;

    >label {
        font-size: 1.22rem;
        line-height: 1.22rem;
        color: var(--text-2);
        font-weight: 600;
    }

    >span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 17px;
        margin: 0 10px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 10px;
        }

        >svg {
            width: 17px;
            height: 17px;

            path {
                & [class^=white] {
                    fill: var(--secondary-2);
                }
            }
        }
    }
}

.frm-summary-card {
    width: 100%;
    padding: 0 10px;

    >div {
        height: 70px;
        border: 1px solid #f0f5fb;
        border-bottom: 0;
        padding: 0 10px;

        &:last-of-type {
            border-bottom: 1px solid #f1f1f1;
        }

        >div {
            min-width: 50%;
            height: 100%;
        }
    }
}

@mixin Summary-header-title {
    display: flex;
    align-items: center;
    width: 100%;
    background-image: var(--header-side-panel-bg);
    height: 40px !important;

    >div {
        display: flex;
        align-items: center;
        color: var(--white);

        span {
            svg {
                g {
                    fill: var(--white);
                }
            }
        }

        label {
            color: var(--white);
        }
    }
}

.summary-header-title {
    @include Summary-header-title();
    border-radius: var(--common-border-radius) var(--common-border-radius) 0 0;

    @media screen and (max-width: 1024px) {
        display: none;
    }
}

.summary-header-title-open-close {
    @include Summary-header-title();
    justify-content: space-between;
    padding: 0 10px;
}

.lines-container-open-close {
    display: flex;
    flex-direction: column;
    width: calc(100% - calc(var(--common-height) / 2) - var(--header-margin-bottom));
    transition: all 0.3s ease-out !important;
    margin: 0 var(--header-margin-bottom) 0 0;

    [dir=rtl] & {
        margin: 0 0 0 var(--header-margin-bottom);
    }

    @media screen and (max-width: 1024px) {
        width: calc(100% - calc(var(--common-height) / 2) - var(--header-margin-bottom) + var(--main-padding-H));
    }

    @media screen and (max-width: 650px) {
        width: calc(100% - calc(var(--common-height) / 2) - var(--header-margin-bottom));
    }

    &[class*="active"] {
        width: calc(100% - var(--summary-width-trx-open) - var(--header-margin-bottom) + var(--main-padding-H) + var(--main-padding-H));

        @media screen and (max-width: 1024px) {
            width: 100%;
        }
    }
}
.summary-container-panel {
    position: fixed;
    right: 0;
    left: auto;
    z-index: 2;
    width: var(--summary-width-trx-close);
    height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - var(--main-padding-V)) !important;
    transition: all 0.3s ease-out !important;
    background-color: var(--white);
    border-radius: var(--common-border-radius);

    [dir=rtl] & {
        left: 0;
        right: auto;
    }

    >div {
        width: 100% !important;
        margin: 0 !important;
        visibility: hidden;

        >div {
            &[class*="headerTitle"] {
                border-radius: 0 !important;
            }
        }
    }

    &[class*="active"] {
        width: calc(var(--summary-width-trx-open) - var(--main-padding-H));

        >div {
            visibility: visible;
        }

        >button {
            display: none;
        }

        @media screen and (max-width: 650px) {
            width: var(--summary-width-trx-open) !important;
            height: var(--window-height) !important;
            top: 0 !important;
            border-radius: 0;

            >div {
                border-radius: 0 !important;
            }
        }
    }

}

.open-close-summary-btn {
    background-image: var(--header-side-panel-bg);
    width: 100%;
    height: var(--actions-btn-height);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    border-radius: var(--common-border-radius) 0 0 var(--common-border-radius) !important;

    [dir=rtl] & {
        border-radius: 0 var(--common-border-radius) var(--common-border-radius) 0 !important;
    }

    svg {
        width: 18px;
        height: 18px;

        path {
            fill: var(--white);
        }

        [dir=rtl] & {
            transform: rotate(-180deg);
        }
    }
}

.attach-summary-container {
    display: flex;
    align-items: center;

    >button {
        width: 14px;
        height: 14px;
        margin: 0 10px;

        svg {
            width: 14px;
            height: 14px;

            g {
                stroke: var(--white);
            }
        }

        &:hover {
            svg {
                transform: scale(1.1);
            }
        }
    }
}

.td-column {
    border-right: 1px solid #f1f1f1;
    border-left: 0;

    [dir=rtl] & {
        border-left: 1px solid #f1f1f1;
        border-right: 0;
    }
}

.summary-table {
    background-color: var(--white);
    border-radius: var(--common-border-radius);
    padding: 10px 0;
    margin-bottom: 10px;
}

.summaryContainerWithTotal {
    height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - var(--main-padding-V) - 40px) !important;
    overflow: auto;
}

.attachment-summary {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 15px 0 0;
    font-weight: 500;
    font-size: 1.2rem;

    [dir=rtl] & {
        margin: 0 0 0 15px;
    }

    >div {
        margin-top: 2px;
    }

    span {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 14px;
            height: 14px;
            margin: 0 5px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 5px;
            }
        }
    }
}

.tdSections {
    display: flex;
    align-items: center;
    height: 63px !important;

    >div {
        display: flex;
        flex-direction: column;
        padding: 0 10px 10px;

        >label {
            display: -webkit-box !important;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            padding: 10px 0;
            color: #756f86;
            font-size: 1.05rem;
            line-height: 1;
        }

        >div {
            display: -webkit-box !important;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            font-size: 1.15rem;
        }
    }
}

.tdDesc {
    display: flex;
    align-items: center;
    width: 100% !important;
    height: fit-content !important;
    padding: 0 0 0 20px;

    [dir=rtl] & {
        padding: 0 20px 0 0;
    }

    >div {
        width: 100% !important;

        >label {
            display: -webkit-box !important;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            padding: 10px 0;
            color: #756f86;
            font-size: 1.05rem;
            line-height: 1;
        }

        >p {
            font-size: 1.05rem;
            line-height: 1.7;
            white-space: pre-wrap;
            padding-bottom: 5px;
        }
    }
}

@mixin Summary-Section {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #f6f6f6;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
}

.summarySection {
    @include Summary-Section();

    >div {
        width: 50%;
    }
}

.summarySectionFull {
    @include Summary-Section();

    >div {
        width: 100%;
    }
}

.summary-ref {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0;
    height: 100%;

    >div {
        font-weight: 500;
        font-size: 1.1rem;
        color: var(--black);

        label {
            color: var(--label-color) !important;
            font-weight: 500 !important;
            padding: 8px 0 !important;
        }

        &:nth-of-type(1) {
            border-right: 1px solid #f1f1f1;
            border-left: 0;

            [dir=rtl] & {
                border-left: 1px solid #f1f1f1;
                border-right: 0;
            }
        }

        &:nth-of-type(2) {
            padding: 0 0 0 10px;

            [dir=rtl] & {
                padding: 0 10px 0 0;
            }
        }
    }
}

.summary-ref-full {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0;
    height: 100%;

    >div {
        width: 100% !important;
        font-weight: 500;
        font-size: 1.1rem;
        color: var(--black);

        label {
            color: var(--label-color) !important;
            font-weight: 500 !important;
            padding: 8px 0 !important;
        }

        &:nth-of-type(1) {
            border-right: 1px solid #f1f1f1;
            border-left: 0;

            [dir=rtl] & {
                border-left: 1px solid #f1f1f1;
                border-right: 0;
            }
        }

        &:nth-of-type(2) {
            padding: 0 0 0 10px;

            [dir=rtl] & {
                padding: 0 10px 0 0;
            }
        }
    }
}


.desc-summary-container {
    display: flex;
    flex-direction: column;
    min-height: 85px !important;
    height: fit-content !important;
    width: 100%;

    >label {
        display: flex;
        align-items: center;
        padding: 7px 0;
        font-size: 1.1rem;
        color: var(--label-color) !important;
        font-weight: 500 !important;
    }
}

.desc-summary {
    width: 100% !important;
    overflow: hidden;
    color: var(--black);
    font-size: 1.05rem;
    line-height: 1.7;
    white-space: pre-wrap;
    padding-bottom: 5px;
    height: fit-content !important;
}

.totalsTrx {
    border-top: 2px solid var(--border-colors);
    background-color: var(--bg);
    height: var(--totals-container-height);
    border-radius: 0 0 10px 10px;
    width: 100%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    >div {
        position: relative;
        width: calc(100% - 10px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: var(--white);
        padding: 5px;
        border-radius: 7px;
        margin: 0 10px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 10px;
        }

        &::after {
            content: "";
            width: 100%;
            height: 2px;
            display: flex;
            background-color: var(--secondary-1);
            position: absolute;
            border-radius: 7px;
            bottom: 0;
        }

        &:last-of-type {
            &::after {
                background-color: var(--secondary-2);
            }
        }
    }
}

.totalsLbl {
    color: var(--label-color);
    font-weight: 600;
    font-size: 1.1rem;
    margin: 7px 0;
}

.totalsVal {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 5px;

    >label {
        font-weight: 600;
    }
}

.netPrice {
    color: var(--action-save) !important;
}

.discountPrice {
    color: var(--action-delete-bg) !important;
}

.mobileSummary {
    width: var(--summary-width-trx);

    >div {
        &[class*=summary-container] {
            width: calc(100% - 20px);
        }
    }

    .customHeaderSummarySm {
        display: none;
    }

    @media screen and (max-width: 1024px) {
        position: fixed;
        width: var(--summary-width-trx);
        height: var(--window-height);
        top: 0;
        padding: 0;
        right: calc((var(--summary-width-trx) + 20px) * -1);
        left: auto;
        transition: 0.25s all;
        z-index: 10;
        border-radius: 0 !important;

        [dir=rtl] & {
            left: calc((var(--summary-width-trx) + 20px) * -1);
            right: auto;
        }

        >div {
            >div {
                &[class*=summaryContainerWithTotal] {
                    height: calc(var(--window-height) - var(--side-panel-header-height)) !important;
                }
            }
        }

        .customHeaderSummarySm {
            display: flex;
            align-items: center;
            width: 100%;
            height: var(--side-panel-header-height);
            background-image: var(--header-side-panel-bg);
            padding: 0 10px;

            >label {
                width: calc(100% - 30px - 10px);
                color: var(--white);
                font-size: 1.2rem;
            }

            >button {
                width: 30px;
                height: 30px;
                margin: 0 0 0 10px;

                [dir=rtl] & {
                    margin: 0 10px 0 0;
                }

                svg {
                    width: 14px;
                    height: 14px;

                    g {
                        stroke: var(--white);
                    }
                }
            }
        }

        &[class*=active] {
            right: 0;
            left: auto;
            // box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;

            [dir=rtl] & {
                left: 0;
                right: auto;
            }
        }
    }

    @media screen and (max-width: 650px) {
        width: 100%;
        right: calc(100% * -1);
        left: auto;

        [dir=rtl] & {
            left: calc(100% * -1);
            right: auto;
        }
    }
}

.mobileBg {
    display: none;

    @media screen and (max-width: 1024px) {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 0;

        &[class*=active] {
            display: block;
        }
    }
}

.summaryActionBtn {
    display: none !important;

    @media screen and (max-width: 1024px) {
        display: flex !important;
        // z-index: 0;
        // position: relative;
        // outline: none;

        // &::before {
        //     content: "";
        //     width: calc(100% + 4px);
        //     height: calc(100% + 4px);
        //     position: absolute;
        //     background-image: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
        //     background-size: 400%;
        //     top: -2px;
        //     left: -2px;
        //     filter: blur(5px);
        //     animation: glowing 20s linear infinite;
        //     transition: opacity .3s ease-in-out;
        //     z-index: -1;
        // }

    }
}

@keyframes glowing {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.generated-jv-summary {
    display: flex;
    align-items: center;
    overflow: hidden;
    border-bottom: 1px solid #f1f1f1 !important;
    height: 60px !important;
    width: 100% !important;

    >span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        margin: 0 10px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 10px;
        }

        svg {
            width: 14px;
            height: 20px;
        }
    }

    >div {
        display: flex;
        align-items: center;
        width: calc(100% - 20px - 10px) !important;

        >div {
            color: #67748d;
            font-weight: 500;
            font-size: 1.1rem;
            margin: 0 10px 0 0;
            line-height: 1.2;
            text-overflow: ellipsis;
            overflow: hidden;
            display: flex;
            align-items: center;
            height: 30px;

            [dir=rtl] & {
                margin: 0 0 0 10px;
            }
        }

        >span {
            color: #0f98de;
            font-weight: 500;
            font-size: 1.25rem;
            text-decoration: underline;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            cursor: pointer;

            &[class=notFound] {
                text-decoration: none;
                cursor: initial;
            }
        }
    }
}

.frm-header-actions {
    display: flex;
    align-items: center;

    >button {
        margin: 0 15px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 15px;
        }

        svg {
            width: 22px;
            height: 22px;
        }
    }
}

.frm-title-container-status {
    display: flex;
    flex-direction: column;
  
    >label {
      &:nth-of-type(1) {
        margin-bottom: 8px;
      }
    }
}