.logTable {

    div[class^=table_body] {
        height: calc(var(--model-dialog-height) - var(--table-header-height) - var(--modal-with-tabs-footer-height) - var(--table-header-height) - var(--table-pagination-height) - 10px) !important;
    }

    div[class^=table_header],
    div[class^=table_body]>div {
        display: flex;
        align-items: center;

        >div {
            text-align: center;
            display: flex;
            justify-content: center;

            &:nth-child(1) {
                width: var(--col-1);
            }

            &:nth-child(2) {
                width: var(--col-2);
            }

            &:nth-child(3) {
                width: var(--col-3);
            }
        }
    }
}