.actionDetailsTableContainer {
  overflow-x: auto;
}

.actionDetailsTable {
  div[class^="v_table_body"] {
    height: calc(40px * 6);
  }

  div[class^="v_table_header"],
  div[class^="v_table_row"] {
    display: flex;
    align-items: center;

    >div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }
    }
  }
}

.logDateTableContainer {
  overflow-x: auto;
}

.logDateTable {
  div[class^="v_table_body"] {
    height: calc(40px * 3);
  }

  div[class^="v_table_row_radio"] {
    cursor: pointer !important;

    * {
      cursor: pointer !important;
    }
  }

  div[class^="v_table_header_radio"]>span,
  div[class^="v_table_row_radio"] {
    display: flex;
    align-items: center;

    >div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }

      &:nth-child(5) {
        width: var(--col-5);
      }

      &:nth-child(6) {
        width: var(--col-6);
      }
    }
  }
}

.containerDailog {
  >div {
    &[class*="modalBody"] {
      background-color: var(--bg) !important;
      padding: 0 !important;
    }
  }
}

.container {
  background-color: var(--bg) !important;
  padding: 0 !important;

  >div {
    background-color: var(--white);
    margin-bottom: 15px;
    padding: 20px !important;

    &:nth-of-type(1) {
      height: calc(40% - 15px);
    }

    &:nth-of-type(2) {
      height: calc(60% - 15px);
    }
  }
}

.hideWaitting {
  display: none;
}