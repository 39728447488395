html {
    --primary-color: #410060;
    --primary-color-2: #7819A5;

    --secondary-1: #668fff;
    --secondary-2: #1facae;

    --pale-primary: #F6F3F7;
    --pale-secondary-1: #668fff2b;
    --pale-red: #FCF4F4;
    --skiy-blue: #E7F3FF;
    --pale-blue: #EEF5FF;
    --bg-header: #fff;

    --primary-hover: #F6F3F7;
    --form-code-color: var(--secondary-1);
    --form-title-color: var(--primary-color-2);

    --btn-font-color: #fff;
    --title-text: #000000;
    --text-2: #344767;
    --text-3: #8392ab;
    --text-4: #67748e;
    --menu-label-color-unActive: #ffffffb3;
    --line-color: #522A66;
    --user-header-bg: rgb(46 9 67 / 8%);

    --active-filter-btn: #6d84f3;
    --border-table: var(--pale-secondary-1);
    --border-reports-table: rgba(30, 86, 151, 0.3);
    --tab--bg-color: var(--secondary-2);
    --header-action-color: var(--primary-color-2);

    --td-color: #000000;
    --th-color: #2e2e38;
    --td-odd: rgba(102, 143, 255, 0.1);
    --td-even: var(--white);
    --tr-hover: rgba(102, 143, 255, 0.1) !important;
    --tr-hover-reports: rgba(185, 201, 244, 0.9) !important;
    --tr-total-color: var(--primary-color);
    --tr-selected: rgba(102, 143, 255, 0.05) !important;
    --sorting-arrow-color: #8392ab;
    --sorting-arrow-color-active: #544dc9;
    --sorting-arrow-bg-color-active: #f2f1fe;
    --column-search-bg-color: #eaeaec83;

    --bg: #fbfbfb;
    --bg-2: #EAEAEA;

    --success: #37BA32;
    --worning: #F69576;
    --error: #E84A4D;

    --blue: #2c6ecb;
    --table-row-bg: #fff;
    --td-color: #000000;
    --th-color: #2e2e38;
    --tr-hover: #F8F8F8;

    --label-color: #756f86;
    --placeholder: #7c9cbf;
    --link-label-color: #0d87f2;
    --select-menu-arrow-color: #668fff;
    --select-menu-bg: var(--white);

    --header-side-panel-bg: linear-gradient(95deg, #552586 -29%, #804FB3 103%);
    --pageination: #ab205a;
    --border-colors: #dbe2ea;
    --border-color-2: #e7e7e7;
    --radio-colors: #088587;
    --disable-colors: #FAFAFA;
    --x-button-color: #707070;
    --white: #ffffff;
    --black: #000000;
    --red: #FF0000;
    --green: #81C548;
    --select-menu-shadow: rgba(65, 0, 96, 0.3);
    --modal-steps-bg: #3f73b7;
    //status
    --status-active: #4caf50;
    --status-active-bg: #4caf5026;
    --status-incomplete: #696B78;
    --status-incomplete-bg: #dbdeeb8a;
    --status-draft: #696B78;
    --status-draft-bg: #dbdeeb8a;
    --status-new: #1853A7;
    --status-new-bg: #bbd6fd5b;
    --status-rejected-bg: #ff000024;
    --status-rejected: var(--red);
    --status-cofirmed: #198038;
    --status-cofirmed-bg: #1980381f;
    --status-approved: #2dcebe;
    --status-approved-bg: #2dcebe2d;
    --status-suspend: #F1C21B;
    --status-suspend-bg: #f1c31b21;
    --status-submitted: #0F62FE;
    --status-submitted-bg: #0f63fe21;
    --status-prepared: #EB6200;
    --status-prepared-bg: #eb620021;
    --status-posted: #8A3FFC;
    --status-posted-bg: #8b3ffc33;
    --status-completed-bg: #4ba33b1e;
    --status-completed: #4BA33B;
    --status-cancelled-bg: #c7092217;
    --status-cancelled: #c70922;
    --status-hanged-up: #DB5383;
    --status-closed-bg: #DBDCDF;
    --status-closed: #696B78;

    --menu-bg-color: #300444;
    --menu-search-bg: #410060;
    --menu-search-bg-hover: rgba(255, 255, 255, 0.1);
    --menu-btn-hover: rgba(255, 255, 255, 0.2);
    --menu-btn-active: rgba(255, 255, 255, 0.2);

    --menu-btns-separator: #5e2976;
    --menu-search-item-bg-hover: rgba(255, 255, 255, 0.07);
    --menu-search-item-icon-color: var(--white);
    --menu-icon: var(--white);
    --card-mode-table: #6d84f3 !important;
    --hover-table-row: #fbfbfb;

    --action-save: #11CD66;
    --action-copy: #2C8EFF;
    --action-unpost: #FFCB3D;
    --action-delete: #FFD7D7;
    --action-delete-bg: #FF4848;
    --action-incomplete: #CCCCCC;

    --status-duty: #4caf50;
    --status-duty-bg: #4caf5026;

    --status-vacation: #f1c21b;
    --status-vacation-bg: #f1c31b21;

    --status-leave: #0f62fe;
    --status-leave-bg: #0f63fe21;

    --status-absent: #eb6200;
    --status-absent-bg: #eb620021;

    --status-terminated: #ff000024;

    --status-192801: #2C8EFF;
    --status-192802: #FFCB3D;
    --status-192803: #11CD66;
    --status-192804: #FF4848;
    --status-192805: #EB6200;

    --status-131101: #1853A7;
    --status-131102: #198038;
    --status-131103: var(--red);
    --status-131104: var(--red);
    --status-131105: #2dcebe;

    --priority-status-103401: #11CD66;
    --priority-status-103402: #FFCB3D;
    --priority-status-103403: #ffa733;
    --priority-status-103404: #FF4848;

    --module-color-definitions: #c05780;
    --module-color-gl: #06d3d4;
    --module-color-ap: #a8de4f;
    --module-color-ar: #48b8e0;

    // ******************Proparties Actions******************
    --prop-action-1-lbl: #247edc;
    --prop-action-1-bg: rgba(36, 126, 220, 0.15);

    --prop-action-2-lbl: #7a5ded;
    --prop-action-2-bg: rgba(122, 93, 237, 0.15);

    --prop-action-3-lbl: #8cc63f;
    --prop-action-3-bg: rgba(140, 198, 63, 0.15);

    --prop-action-4-lbl: #f6412d;
    --prop-action-4-bg: rgba(246, 65, 45, 0.15);

    --prop-action-5-lbl: rgb(255, 193, 7);
    --prop-action-5-bg: rgba(255, 193, 7, 0.1);

    --prop-action-6-lbl: #13a89e;
    --prop-action-6-bg: rgba(19, 168, 158, 0.15);

    --prop-action-7-lbl: #ff9800;
    --prop-action-7-bg: rgba(255, 152, 0, 0.15);

    --prop-action-8-lbl: #0b9444;
    --prop-action-8-bg: rgba(11, 148, 68, 0.15);

    --prop-action-9-lbl: #82aecc;
    --prop-action-9-bg: rgba(130, 174, 204, 0.15);

    --prop-action-10-lbl: #003b89;
    --prop-action-10-bg: rgba(0, 59, 137, 0.15);
    // ******************Proparties Actions******************
}


// html.dark-mode {
//     --white: #181A20;
//     --black: #fff;
//     --text-2: #cfcde4;
//     --title-text: #cfcde4;
//     --td-color: #fff;
//     --th-color: #d9d9ff;
//     --blue: #2c6ecb;
//     --form-title-color: #d5d8df;
//     --modal-steps-bg: #253955;
//     --label-color: #e7f0ff;
//     --btn-font-color: #fff;

//     --menu-bg-color: #1E2329;
//     --bg-header: #181A20;
//     --bg: #181A20;
//     --bg-2: #0B0E11;
//     --table-row-bg: #181A20;
//     --text-4: #848D9B;
//     --text-2: #A1AFC6;
//     --menu-btn-active: #2B3139;
//     --menu-btns-separator: transparent;
//     --hover-table-row: #2B3139;
//     --header-action-color: #fff;
//     --primary-color: #fff;
//     --primary-color-2: #fff;
//     --user-header-bg: rgb(255 255 255 / 8%);
//     --menu-search-bg: #1E2329;
//     --menu-search-bg-hover: rgba(255, 255, 255, 0.5);
//     --menu-icon: #fff;
//     --menu-search-item-icon-color: rgba(255, 255, 255, 0.5);
//     --disable-colors: #2E2E2E;
// }