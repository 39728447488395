.usersLogTableRow {
    >div {
        display: flex;
        justify-content: center;
    }
}

.usersLogTable {

    div[class^=table_header_checkbox]>span,
    div[class^=table_body]>div>div {
        >div:nth-child(1) {
            width: var(--col-1);
        }

        >div:nth-child(2) {
            width: var(--col-2);
        }

        >div:nth-child(3) {
            width: var(--col-3);
        }

        >div:nth-child(4) {
            width: var(--col-4);
        }

        >div:nth-child(5) {
            width: var(--col-5);
        }
    }
}

.logBtn {
    >svg {
        width: 20px;
        height: 20px;
    }
}

.userContainer {
    display: flex;
    align-items: center;
    // justify-content: center;

    >span {
        width: 32px;
        height: 32px;
        margin: 0 15px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 15px;
        }

        img {
            max-width: 100%;
            height: 100%;
        }
    }

    >label {
        max-width: calc(100% - 32px - 15px);
        text-align: start !important;
    }
}