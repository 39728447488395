.workflowProcedureMessagesRow {
    >div {
        display: flex;
        justify-content: center;
    }
}

.workflowProcedureMessages {

    div[class^=table_header_checkbox]>span,
    div[class^=table_body]>div>div {
        >div:nth-child(1) {
            width: var(--col-1);
        }

        >div:nth-child(2) {
            width: var(--col-2);
        }

        >div:nth-child(3) {
            width: var(--col-3);
        }

        >div:nth-child(4) {
            width: var(--col-4);
        }

    }
}

.tableButton {
    display: flex;
    align-items: center;
    min-width: 85px;
    height: var(--common-height);
    padding: 10px 5px;
    background-color: var(--white);
    color: var(--text-4) !important;

    >svg {
        width: 16px;
        height: 16px;

        path {
            fill: var(--primary-color);
        }
    }

    >span {
        color: var(--text-2);
        line-height: 1rem;
        margin: 0 0 0 7px;

        [dir=rtl] & {
            margin: 0 7px 0 0;
        }
    }

    &:hover {
        border: 1px solid var(--border-colors);
    }
}

.tableActions {
    >button {
        &:nth-of-type(2) {
            margin-inline-end: 5px;
        }
    }
}