.msgCounter {
    min-width: 15px;
    min-height: 15px;
    background-color: var(--red);
    position: absolute;
    top: -10px;
    left: -10px;
    padding: 3px;
    border-radius: 5px;

    label {
        color: white;
        font-weight: 700;
        font-size: 0.9rem;
        line-height: 0.9rem;
        color: var(--white);
    }
}

.notificationBtn {
    position: relative;

    @media screen and (max-width: 650px) {
        display: none;
    }

    span {
        width: 30px;
        height: 30px;
        display: block;

        div {
            width: 30px;
            height: 30px;
        }
    }
}