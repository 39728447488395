.estimationTransactionsRegisterRow {
  > div {
    display: flex;
    justify-content: center;
  }
}

.estimationTransactionsRegisterTable {
  @media screen and (max-width: 1024px) {
    div[class^="table_header"],
    div[class^="table_body"],
    div[class^="table-total"] {
      min-width: 6000px !important;
    }

    div[class^="table_body"] {
      @media screen and (max-width: 650px) {
        height: calc(
          var(--window-height) - var(--main-padding-V) - var(--header-height) -
            var(--header-margin-bottom) - 10px -
            var(--header-pages-panel-height) - 30px - var(--table-header-height) -
            var(--table-pagination-height)
        ) !important;
      }
    }
  }

  @media screen and (max-width: 650px) {
    div[class^="table_header"],
    div[class^="table_body"],
    div[class^="table-total"] {
      min-width: 100% !important;
    }
  }

  div[class^="table_header"],
  div[class^="table_body"] > div,
  div[class^="table-total"] {
    > div:nth-child(1) {
      width: var(--col-1);
    }

    > div:nth-child(2) {
      width: var(--col-2);
    }

    > div:nth-child(3) {
      width: var(--col-3);
    }

    > div:nth-child(4) {
      width: var(--col-4);
    }

    > div:nth-child(5) {
      width: var(--col-5);
    }

    > div:nth-child(6) {
      width: var(--col-6);
    }

    > div:nth-child(7) {
      width: var(--col-7);
    }

    > div:nth-child(8) {
      width: var(--col-8);
    }

    > div:nth-child(9) {
      width: var(--col-9);
    }

    > div:nth-child(10) {
      width: var(--col-10);
    }

    > div:nth-child(11) {
      width: var(--col-11);
    }

    > div:nth-child(12) {
      width: var(--col-12);
    }

    > div:nth-child(13) {
      width: var(--col-13);
    }

    > div:nth-child(14) {
      width: var(--col-14);
    }

    > div:nth-child(15) {
      width: var(--col-15);
    }

    > div:nth-child(16) {
      width: var(--col-16);
    }

    > div:nth-child(17) {
      width: var(--col-17);
    }

    > div:nth-child(18) {
      width: var(--col-18);
    }

    > div:nth-child(19) {
      width: var(--col-19);
    }

    > div:nth-child(20) {
      width: var(--col-20);
    }

    > div:nth-child(21) {
      width: var(--col-21);
    }

    > div:nth-child(22) {
      width: var(--col-22);
    }

    > div:nth-child(23) {
      width: var(--col-23);
    }

    > div:nth-child(24) {
      width: var(--col-24);
    }

    > div:nth-child(25) {
      width: var(--col-25);
    }

    > div:nth-child(26) {
      width: var(--col-26);
    }

    > div:nth-child(27) {
      width: var(--col-27);
    }

    > div:nth-child(28) {
      width: var(--col-28);
    }

    > div:nth-child(29) {
      width: var(--col-29);
    }

    > div:nth-child(30) {
      width: var(--col-30);
    }

    > div:nth-child(31) {
      width: var(--col-31);
    }

    > div:nth-child(32) {
      width: var(--col-32);
    }

    > div:nth-child(33) {
      width: var(--col-33);
    }

    > div:nth-child(34) {
      width: var(--col-34);
    }

    > div:nth-child(35) {
      width: var(--col-35);
    }

    > div:nth-child(36) {
      width: var(--col-36);
    }

    > div:nth-child(37) {
      width: var(--col-37);
    }

    > div:nth-child(38) {
      width: var(--col-38);
    }

    > div:nth-child(39) {
      width: var(--col-39);
    }

    > div:nth-child(40) {
      width: var(--col-40);
    }

    > div:nth-child(41) {
      width: var(--col-41);
    }

    > div:nth-child(42) {
      width: var(--col-42);
    }

    > div:nth-child(43) {
      width: var(--col-43);
    }

    > div:nth-child(44) {
      width: var(--col-44);
    }

    > div:nth-child(45) {
      width: var(--col-45);
    }

    > div:nth-child(46) {
      width: var(--col-46);
    }

    > div:nth-child(47) {
      width: var(--col-47);
    }

    > div:nth-child(48) {
      width: var(--col-48);
    }

    > div:nth-child(49) {
      width: var(--col-49);
    }

    > div:nth-child(50) {
      width: var(--col-50);
    }

    > div:nth-child(51) {
      width: var(--col-51);
    }

    > div:nth-child(52) {
      width: var(--col-52);
    }

    > div:nth-child(53) {
      width: var(--col-53);
    }

    > div:nth-child(54) {
      width: var(--col-54);
    }

    > div:nth-child(55) {
      width: var(--col-55);
    }

    > div:nth-child(56) {
      width: var(--col-56);
    }

    > div:nth-child(57) {
      width: var(--col-57);
    }

    > div:nth-child(58) {
      width: var(--col-58);
    }

    > div:nth-child(59) {
      width: var(--col-59);
    }

    > div:nth-child(60) {
      width: var(--col-60);
    }

    > div:nth-child(61) {
      width: var(--col-61);
    }

    > div:nth-child(62) {
      width: var(--col-62);
    }

    > div:nth-child(63) {
      width: var(--col-63);
    }

    > div:nth-child(64) {
      width: var(--col-64);
    }

    > div:nth-child(65) {
      width: var(--col-65);
    }

    > div:nth-child(66) {
      width: var(--col-66);
    }

    > div:nth-child(67) {
      width: var(--col-67);
    }

    > div:nth-child(68) {
      width: var(--col-68);
    }

    > div:nth-child(69) {
      width: var(--col-69);
    }

    > div:nth-child(70) {
      width: var(--col-70);
    }

    > div:nth-child(71) {
      width: var(--col-71);
    }

    > div:nth-child(72) {
      width: var(--col-72);
    }

    > div:nth-child(73) {
      width: var(--col-73);
    }

    > div:nth-child(74) {
      width: var(--col-74);
    }

    > div:nth-child(75) {
      width: var(--col-75);
    }

    > div:nth-child(76) {
      width: var(--col-76);
    }

    > div:nth-child(77) {
      width: var(--col-77);
    }

    > div:nth-child(78) {
      width: var(--col-78);
    }

    > div:nth-child(79) {
      width: var(--col-79);
    }

    > div:nth-child(80) {
      width: var(--col-80);
    }

    > div:nth-child(81) {
      width: var(--col-81);
    }

    > div:nth-child(82) {
      width: var(--col-82);
    }

    > div:nth-child(83) {
      width: var(--col-83);
    }

    > div:nth-child(84) {
      width: var(--col-84);
    }

    > div:nth-child(85) {
      width: var(--col-85);
    }

    > div:nth-child(86) {
      width: var(--col-86);
    }

    > div:nth-child(87) {
      width: var(--col-87);
    }

    > div:nth-child(88) {
      width: var(--col-88);
    }

    > div:nth-child(89) {
      width: var(--col-89);
    }

    > div:nth-child(90) {
      width: var(--col-90);
    }

    > div:nth-child(91) {
      width: var(--col-91);
    }

    > div:nth-child(92) {
      width: var(--col-92);
    }

    > div:nth-child(93) {
      width: var(--col-93);
    }

    > div:nth-child(94) {
      width: var(--col-94);
    }

    > div:nth-child(95) {
      width: var(--col-95);
    }

    > div:nth-child(96) {
      width: var(--col-96);
    }

    > div:nth-child(97) {
      width: var(--col-97);
    }

    > div:nth-child(98) {
      width: var(--col-98);
    }

    > div:nth-child(99) {
      width: var(--col-99);
    }
  }
}
