.actionButtons {
    position: relative;
}

.headerSearchPanel {
    @media screen and (max-width: 650px) {
        >div {
            &[class*="inputContainer"] {
                min-width: calc(100% - 30px - 30px - 15px) !important;
                width: calc(100% - 30px - 30px - 15px) !important;
            }
        }
    }
}

.spinnerButton {
    background-color: green;
    margin: 0 !important;
    width: 25px !important;
    height: 25px !important;
    border-radius: 5px;

    &::after {
        width: 20px !important;
        height: 20px !important;
    }
}