.masterPanel {
    width: 100vw;
    height: var(--window-height);
    top: 0;
    position: fixed;
    overflow: hidden;
    padding: 0 var(--main-padding-H) var(--main-padding-V);
    background-color: #EAEAEA;
}

.mainPanel {
    width: 100%;
    height: 100%;
    padding-left: calc(var(--main-padding-H) + var(--menu-width-close));
    padding-right: 0;
    font-family: var(--font-en) !important;

    [dir=rtl] & {
        font-family: var(--font-ar) !important;
        padding-left: 0;
        padding-right: calc(var(--main-padding-H) + var(--menu-width-close));
    }

    @media screen and (max-width: 1024px) {
        padding-left: var(--main-padding-H);
        padding-right: var(--main-padding-H);

        [dir=rtl] & {
            padding-left: var(--main-padding-H);
            padding-right: var(--main-padding-H);
        }
    }
}

.content {
    overflow: hidden;
    width: 100%;
    height: calc(100% - var(--header-height) - var(--header-margin-bottom));
    background-color: var(--bg);
    border-radius: var(--common-border-radius);
    padding: 10px 20px 0;
    font-family: var(--font-en) !important;

    [dir=rtl] & {
        font-family: var(--font-ar) !important;
    }
}

.trnxContent {
    padding: 0;
    background-color: transparent;
}

.nextPrevBtns {
    bottom: auto !important;
    top: 8px !important;
}
