$margin-container: 11px;
$margin-sm-container: 6px;

// ***************dashboards card****************
.searchPanel {
    margin: 10px 0 10px 0 !important;
    // width: calc(100% - $margin-container - $margin-container) !important;
}

.headerPages {
    @media screen and (max-width: 650px) {
        height: 40px !important;
    }
}

.headerPanel {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0 10px;
    background-color: var(--white);
    margin-bottom: 10px;
    

    @media screen and (max-width: 650px) {
        height: 100% !important;
    }
}

.noTasksStatisticsContainer {
    background-color: transparent !important;
    padding: var(--frm-template-panel-padding-top) $margin-container !important;
    height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - 15px) !important;
    overflow: auto;

    @media screen and (max-width: 765px) {
        padding: 0px !important;
        padding: var(--frm-template-panel-padding-top) $margin-sm-container !important;
    }

    >div {
        padding: 0 1rem;
    }
}

.dashCard {
    background-color: var(--white);
    height: 320px;
    border-radius: 7px;
    padding: 10px 20px;
}

.headerCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    position: relative;

    >button {
        display: flex;
        align-items: center;
        font-weight: 500;

        &:nth-of-type(1) {
            >svg {
                width: 20px;
                height: 20px;
                margin: 0 5px 0 0;

                [dir=rtl] & {
                    margin: 0 0 0 5px;
                }
            }

            >label {
                font-size: 14px;
            }
        }

        &:nth-of-type(2) {
            >svg {
                margin-top: 5px;
                width: 17px;
                height: 17px;
            }
        }
    }
}

.colorPalette {
    position: absolute;
    top: 30px;
    right: 0;

    >div {
        width: 230px !important;
    }
}

// ***************dashboards card****************
.smContainer {
    overflow: auto;
    align-content: baseline;
    height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - 50px) !important;


    @media screen and (max-width: 1200px) {
        >div {
            width: 50%;
        }
    }

    @media screen and (max-width: 765px) {
        >div {
            width: 100%;
        }
    }
}

.card {
    margin-bottom: 16px !important;
}

.headerButton {
    overflow-x: auto;

    &::-webkit-scrollbar {
        display: none;
    }
}