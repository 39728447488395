$headerTrxHeight :60px;
$margin :15px;

.container {
    height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - var(--main-padding-V));
    overflow: hidden;

    @media screen and (max-width: 1024px) {
        height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - var(--main-padding-V));
        overflow: auto;
    }
}

.headerContainer {
    width: 100%;
    height: $headerTrxHeight;
    background-color: var(--white);
    border-radius: var(--common-border-radius);
    padding: 10px 20px;
}

.containerRow {
    height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - var(--main-padding-V) - $headerTrxHeight - $margin - 30px - 20px - 20px - 20px);
    overflow: auto;
    padding: 20px 15px 0 0;

    [dir=rtl] & {
        padding: 20px 0 0 15px;
    }

    @media screen and (max-width: 1024px) {
        height: 100%;
        overflow: visible;
    }

    @media screen and (max-width: 650px) {
        height: 100%;
        overflow: visible;

        >div {
            width: 100% !important;
        }
    }
}

.orderInfoContainer {
    height: calc(100% - $headerTrxHeight - $margin);

    @media screen and (max-width: 1024px) {
        height: auto;
        display: none;
    }
}

.headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.headerDetails {
    display: flex;
    align-items: center;
}

.imgDetails {
    width: 35px;
    height: 35px;
    background-color: var(--secondary-1);
    border-radius: 50%;
    margin: 0 15px 0 0;

    [dir=rtl] & {
        margin: 0 0 0 15px;
    }
}

.orgName {
    >label {
        display: block;

        &:nth-of-type(1) {
            color: #282828;
            font-size: 1.1rem;
            font-weight: 500;
            margin-bottom: 10px;
        }

        &:nth-of-type(2) {
            color: var(--secondary-2);
            font-size: 1.28rem;
            line-height: 1;
            font-weight: 600;
            text-align: end !important;
        }
    }
}

.infoContainer {
    display: flex;
    width: 100%;
    height: calc(100% - $headerTrxHeight - $margin);
    margin-top: $margin;
    border-radius: var(--common-border-radius);


    >div {
        width: 50%;
        height: 100%;
        background-color: var(--white);
        border-radius: var(--common-border-radius);
        padding: 10px 5px 50px 20px;

        [dir=rtl] & {
            padding: 10px 20px 50px 5px;
        }

        &:nth-of-type(1) {
            margin: 0 $margin 0 0;

            [dir=rtl] & {
                margin: 0 0 0 $margin;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        height: auto;

        >div {
            width: 100%;
            margin-bottom: 10px;
            padding: 10px 20px;

            [dir=rtl] & {
                padding: 10px 20px;
            }

            &:nth-of-type(1) {
                margin: 0 0 $margin 0;

                [dir=rtl] & {
                    margin: 0 0 $margin 0;
                }
            }
        }
    }
}

.sectionTitle {
    display: flex;
    align-items: center;
    height: 30px;

    >label {
        color: var(--title-text);
        font-size: 1.3rem;
        font-weight: 600;
    }

    &::before {
        content: "";
        width: 3px;
        border-radius: 5px;
        height: 100%;
        background-color: var(--primary-color-2);
        display: block;
        margin: 0 10px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 10px;
        }
    }
}

.containerOrder {
    margin-top: 20px;
    overflow: visible !important;
}

.formContainer {
    width: 100%;
    height: var(--common-height);
    border: 1px solid var(--border-colors);
    border-radius: 5px;
    background-color: var(--white);
    font-weight: 500;
    box-shadow: 0 4px 8px 0 rgba(44, 39, 56, 0.04);
    padding: 2px 10px !important;
    color: var(--black);
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    @media screen and (max-width: 650px) {
        margin-bottom: 20px;
    }
}

.formLbl {
    position: absolute;
    top: -10px;
    background-color: var(--white);
    padding: 0 12px;
    color: var(--label-color);
    font-weight: 500;
    font-size: 1.1rem;
    display: block;
    line-height: 1.2;
}
.formVal{
    width: 100%;
    height: var(--common-height);
    border: 1px solid var(--border-colors);
    border-radius: 5px;
    background-color: var(--white);
    font-weight: 500;
    box-shadow: 0 4px 8px 0 rgba(44, 39, 56, 0.04);
    padding: 2px 10px !important;
    color: var(--black);
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
}

.chatContainer {
    margin-top: 20px;
    // height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - var(--main-padding-V) - $headerTrxHeight - $margin - 30px - 20px - 20px - 20px);
    // height: 150px;
    overflow: auto;
    padding: 0 15px 0 0;

    [dir=rtl] & {
        padding: 0 0 0 15px;
    }
}

.sendAddNote {
    // height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - var(--main-padding-V) - $headerTrxHeight - $margin - 30px - 20px - 20px - 37px);
    min-height: 100px;
    overflow: auto;
    padding: 0 15px 0 0;

    [dir=rtl] & {
        padding: 0 0 0 15px;
    }
}

.specialNotesContainer {
    margin-top: 20px;
    height: 100%;
}

.senderContainer {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.inputChatContainer {
    width: 100%;
}

.senderIcon {
    margin: 0 10px 0 0;

    [dir=rtl] & {
        margin: 0 0 0 10px;
    }

    >svg {
        width: 40px;
        height: 40px;

        @media screen and (max-width: 500px) {
            width: 30px;
            height: 30px;
        }
    }
}

@mixin Input-Chat {
    width: 100%;
    border: 1px solid var(--border-colors);
    border-radius: 5px;
    font-weight: 500;
    box-shadow: 0 4px 8px 0 rgba(44, 39, 56, 0.04);
    padding: 10px !important;
    color: var(--black);
    position: relative;
    margin-bottom: 10px;
    transition: all 0.5s;
    min-height: 60px;
    overflow-x: hidden;

    &.inputChatActive {
        height: fit-content !important;
    }

    >div {
        margin-bottom: 5px;
        font-size: 1.25rem;
    }
}

.inputChat {
    @include Input-Chat();
}

.inputChatDisabled {
    @include Input-Chat();
    background-color: var(--disable-colors);
    cursor: not-allowed;

    * {
        cursor: not-allowed;
    }
}

.plusBtn {
    position: absolute;
    bottom: 5px;
    right: 5px;
    left: auto;

    >svg {
        width: 16px;
        height: 16px;

        &:nth-of-type(1) {
            display: block;

            g {
                path {
                    &:first-of-type {
                        fill: var(--black);
                    }
                }
            }
        }

        &:nth-of-type(2) {
            display: none;

            g {
                path {
                    fill: var(--white);
                }
            }
        }
    }

    [dir=rtl] & {
        left: 5px;
        right: auto;
    }
}

.minusBtn {
    >svg {
        &:nth-of-type(1) {
            display: none;
        }

        &:nth-of-type(2) {
            display: block;
        }
    }
}

.dataMsg {
    background-color: var(--primary-hover);
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    width: 100%;
    display: none;

    &.dataMsgActive {
        display: block !important;
        overflow: hidden;
    }
}

.dataMsgDisabled {
    text-decoration: line-through !important;
}

.addNote {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 15px 0 0;

    [dir=rtl] & {
        padding: 0 0 0 15px;
    }

    >input {
        width: calc(100% - 100px - 10px);
        margin: 0 10px 0 0;
        font-size: 1.25rem;

        [dir=rtl] & {
            margin: 0 0 0 10px;
        }
    }
}

.chatDataContainer {
    width: calc(100% - 40px - 30px);
}

.price {
    border-color: var(--red) !important;

    >label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        color: var(--red);
        font-weight: 600;
    }
}

.deleteBtn {
    margin: 0 10px 0 5px;

    [dir=rtl] & {
        margin: 0 5px 0 10px;
    }

    >svg {
        width: 15px;
        height: 15px;
    }

    &:hover {
        svg {
            transition: 0.2s;
            transform: scale(1.2);
        }
    }
}

.containerSpecialNotesCard {
    height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - var(--main-padding-V) - $headerTrxHeight - $margin - 30px - 20px - 20px - 20px - 50px);
    overflow: auto;
}

.logCardContainer {
    height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - var(--main-padding-V) - $headerTrxHeight - $margin - 30px - 20px - 20px - 50px);
    overflow: auto;

    @media screen and (max-width: 1024px) {
        height: auto;
    }
}

.userInfo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--pageination);
    font-size: 1rem;
    margin-bottom: 5px;
}

.userInfoName {
    padding: 0 0 0 10px;

    [dir=rtl] & {
        padding: 0 10px 0 0;
    }

    >label {
        font-weight: 700 !important;
    }
}

.userInfoDate {
    >label {
        font-weight: 800;
        font-size: 1rem;
    }
}