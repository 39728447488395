.tabsContainer {
  width: 100%;
  height: 100%;
}

.tabContentStyle {
  background-color: var(--bg) !important;
  height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - var(--main-padding-V) - var(--tab-container-height)) !important;
  overflow-y: auto;
}

.toggleMode {
  padding: 0 50px !important;

  @media screen and (max-width: 1024px) {
    padding: 0 !important;
  }
}

// *******************actionsTable************************
.actionsRow {
  >div {
    display: flex;
    justify-content: center;
  }
}

.actionsTable {

  div[class^=v_table_body] {
    height: calc(40px * 5);
  }
  @media screen and (max-width: 1024px) {

    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      width: 100%;
      min-width: 100% !important;
    }
  }

  div[class^=v_table_header],
  div[class^=v_table_row] {
    display: flex;
    align-items: center;

    >div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }
    }
  }
}

// *******************actionsTable************************
// *******************branchesTable************************
.branchesRow {
  >div {
    display: flex;
    justify-content: center;
  }
}

.branchesTable {

  @media screen and (max-width: 1024px) {

    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      width: 100%;
      min-width: 100% !important;
    }
  }

  div[class^=v_table_body] {
    height: calc(40px * 5);
  }

  div[class^=v_table_header],
  div[class^=v_table_row] {
    display: flex;
    align-items: center;

    >div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }
    }
  }
}

// *******************branchesTable************************

// *******************divisionsTable************************
.divisionsRow {
  >div {
    display: flex;
    justify-content: center;
  }
}

.divisionsTable {

  div[class^=v_table_body] {
    height: calc(40px * 5);
  }
  @media screen and (max-width: 1024px) {

    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      width: 100%;
      min-width: 100% !important;
    }
  }


  div[class^=v_table_header],
  div[class^=v_table_row] {
    display: flex;
    align-items: center;

    >div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }
    }
  }
}

// *******************divisionsTable************************
// *******************propertiesTypesTable************************
.propertiesTypesRow {
  >div {
    display: flex;
    justify-content: center;
  }
}

.propertiesTypesTable {

  div[class^=v_table_body] {
    height: calc(40px * 5);
  }

  @media screen and (max-width: 1024px) {

    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      width: 100%;
      min-width: 100% !important;
    }
  }
  
  div[class^=v_table_header],
  div[class^=v_table_row] {
    display: flex;
    align-items: center;

    >div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }
    }
  }
}

// *******************propertiesTypesTable************************
// *******************regionsTable************************
.regionsRow {
  >div {
    display: flex;
    justify-content: center;
  }
}

.regionsTable {

  @media screen and (max-width: 1024px) {

    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      width: 100%;
      min-width: 100% !important;
    }
  }

  div[class^=v_table_body] {
    height: calc(40px * 5);
  }

  div[class^=v_table_header],
  div[class^=v_table_row] {
    display: flex;
    align-items: center;

    >div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }
    }
  }
}

// *******************regionsTable************************

// *******************citiesTable************************
.citiesRow {
  >div {
    display: flex;
    justify-content: center;
  }
}

.citiesTableContainer {
  overflow-x: auto;
}

.citiesTable {

  div[class^=v_table_header],
  div[class^=v_table_body] {
    width: 100%;
  }

  @media screen and (max-width: 1024px) {

    div[class^=v_table_header],
    div[class^=v_table_body] {
      width: 100% !important;
      min-width: 100% !important;
    }
  }

  div[class^=v_table_body] {
    height: calc(40px * 5);
  }

  div[class^=v_table_header],
  div[class^=v_table_row] {
    display: flex;
    align-items: center;

    >div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }
    }
  }
}

// *******************citiesTable************************
// *******************partiesCategoriesTable************************
.partiesCategoriesRow {
  >div {
    display: flex;
    justify-content: center;
  }
}

.partiesCategoriesTable {

  @media screen and (max-width: 1024px) {

    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      width: 100%;
      min-width: 100% !important;
    }
  }
  
  div[class^=v_table_body] {
    height: calc(40px * 5);
  }

  div[class^=v_table_header],
  div[class^=v_table_row] {
    display: flex;
    align-items: center;

    >div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }

      &:nth-child(5) {
        width: var(--col-5);
      }
    }
  }
}

// *******************partiesCategoriesTable************************
// *******************propertiesPartiesTable************************
.propertiesPartiesRow {
  >div {
    display: flex;
    justify-content: center;
  }
}

.propertiesPartiesTable {

  @media screen and (max-width: 1024px) {

    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      width: 100%;
      min-width: 100% !important;
    }
  }

  div[class^=v_table_body] {
    height: calc(40px * 5);
  }

  div[class^=v_table_header],
  div[class^=v_table_row] {
    display: flex;
    align-items: center;

    >div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }
    }
  }
}

// *******************propertiesPartiesTable************************
// *******************customerCategoriesTable************************
.customerCategoriesRow {
  >div {
    display: flex;
    justify-content: center;
  }
}

.customerCategoriesTable {

  @media screen and (max-width: 1024px) {

    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      width: 100%;
      min-width: 100% !important;
    }
  }

  div[class^=v_table_body] {
    height: calc(40px * 5);
  }

  div[class^=v_table_header],
  div[class^=v_table_row] {
    display: flex;
    align-items: center;

    >div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }
    }
  }
}

// *******************customerCategoriesTable************************
// *******************customersTable************************
.customersRow {
  >div {
    display: flex;
    justify-content: center;
  }
}

.customersTable {

  @media screen and (max-width: 1024px) {

    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      width: 100%;
      min-width: 100% !important;
    }
  }

  div[class^=v_table_body] {
    height: calc(40px * 5);
  }

  div[class^=v_table_header],
  div[class^=v_table_row] {
    display: flex;
    align-items: center;

    >div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }
    }
  }
}

// *******************customersTable************************
// *******************workflowTable************************
.workflowRow {
  >div {
    display: flex;
    justify-content: center;
  }
}

.workflowTable {

  div[class^=v_table_body] {
    height: calc(40px * 5);
  }

  @media screen and (max-width: 1024px) {

    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      width: 100%;
      min-width: 100% !important;
    }
  }

  div[class^=v_table_header],
  div[class^=v_table_row] {
    display: flex;
    align-items: center;

    >div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }
    }
  }
}

// *******************workflowTable************************
// *******************menuFormsTable************************
.menuFormsRow {
  >div {
    display: flex;
    justify-content: center;
  }
}

.menuFormsTable {

  div[class^=v_table_body] {
    height: calc(40px * 5);
  }

  @media screen and (max-width: 1024px) {

    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      width: 100%;
      min-width: 100% !important;
    }
  }

  div[class^=v_table_header],
  div[class^=v_table_row] {
    display: flex;
    align-items: center;

    >div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }
    }
  }
}

// *******************menuFormsTable************************
.estimationStatusesRow {
  >div {
    display: flex;
    justify-content: center;
  }
}

.estimationStatusesTable {

  div[class^=v_table_body] {
    height: calc(40px * 5);
  }

  @media screen and (max-width: 1024px) {

    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      width: 100%;
      min-width: 100% !important;
    }
  }

  div[class^=v_table_header],
  div[class^=v_table_row] {
    display: flex;
    align-items: center;

    >div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }
    }
  }
}

.actionSections {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;

  >div {
    width: 50%;

    &:nth-of-type(2) {
      margin-top: 31.84px;
    }
  }
}

.inputSection {
  >div {
    width: 50%;
  }
}

.restrictionPolicyPanel {
  background-color: var(--bg) !important;
  padding: 0 !important;
}

.restrictionPolicyContainer {
  display: flex;
  gap: 15px;
  width: 100%;

  >div {
    padding: 10px 20px;
    width: 50% !important;
    background-color: var(--white);
    border-radius: var(--common-border-radius);
    margin-top: 10px;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;

    >div {
      width: 100% !important;
    }
  }
}

.restrictionTitle {
  margin-bottom: 0 !important;
  border-radius: 0 !important;
  padding: 20px;
  border-radius: var(--common-border-radius) !important;
  background-color: var(--white);
  border-color: transparent !important;
}

.actionHeaderSm {
  @media screen and (max-width: 650px) {
    .actionSections {
      flex-direction: column !important;
      align-items: flex-start !important;

      >div {
        width: 100%;
      }
    }
  }
}