html {
  --menu-width-close: 60px;
  --menu-width-open: 250px;
  --header-height: 55px;
  --header-margin-bottom: 14px;
  --main-padding-H: 14px;
  --main-padding-V: 14px;
  --common-border-radius: 7px;
  --header-pages-panel-height: 50px;
  --header-pages-panel-height-sm: 50px;
  --common-height: 37px;
  --table-header-height: 40px;
  --table-pagination-height: 60px;
  --table-total-height: 40px;
  --table-container-padding: 0 17px;
  --font-en: 'Segoe UI', sans-serif;
  --font-ar: "Makdessi", sans-serif;
  --font-menu-ar: "Noto Kufi Arabic", sans-serif;
  --modal-with-steps-header-height: 60px;
  --modal-with-steps-footer-height: 60px;
  --entry-panel-width: 275px;
  --display-panel-header-height: 60px;
  --larg-dialog-width: 95vw;
  --medium-dialog-width: 80vw;
  --small-dialog-width: 50vw;
  --actions-btn-height: 40px;
  --modal-footer-height: 60px;
  --tab-container-height: 50px;
  --menu-trx-width: 35px;
  --row-margin-bottom: 10px;
  --main-margin-V: 15px;
  --summary-width-trx-close: 34px;
  --summary-width-trx-open: 450px;
  --modal-steps-width: 200px;
  --model-dialog-height: calc(var(--window-height) - var(--window-height) * 0.1 - 40px - 20px);
  --model-dialog-height-sm: var(--window-height);
  --modal-with-tabs-header-height: 50px;
  --modal-with-tabs-footer-height: 60px;
  --side-panel-header-height: 50px;
  --side-panel-footer-height: 57px;
  --checkbox-table-width: 40px;
  --logo-div-width-open: 140px;
  --logo-div-width-close: 22px;
  --logo-svg-width-open: 140px;
  --logo-svg-width-close: 105px;
  --table-header-actions-margin-bottom: 15px;
  --summary-width-trx: 27%;
  --table-header-actions-height: 60px;
  --header-tabs-height: 50px;
  --modal-steps-height-sm: 50px;
  --reports-font-size: 0.95rem;
  --toast-img-width: 60px;
}

@media screen and (max-width: 650px) {
  html {
    --header-pages-panel-height: 80px;
    --table-pagination-height: 80px;
    --main-padding-H: 5px;
    --actions-btn-height: 45px;
    --summary-width-trx-open: 350px;
  }
}

@media screen and (max-width: 1400px) {
  html {
    --reports-table-filter-height: calc(var(--window-height) - 50px);
    --summary-width-trx: 32%;
    --call-center-header-width: 28%;
  }
}

@media screen and (max-width: 1024px) {
  html {
    --header-pages-panel-height-sm: 100px;
    --summary-width-trx: 380px;
    --call-center-header-width: 33%;
  }
}

@media screen and (max-width: 768px) {
  html {
    --model-dialog-height: var(--window-height);
  }
}

@media screen and (max-width: 650px) {
  html {
    --header-pages-panel-height: 80px;
    --table-pagination-height: 80px;
    --main-padding-H: 5px;
    --actions-btn-height: 45px;
    --side-panel-width-sm: 100%;
    --header-pages-panel-height-sm: 100px;
    --call-center-header-width: 33%;
    --summary-width-trx-open: 100%;
  }
}

.mainPanelPin {
  transition: 0.3s all ease;
  padding-left: calc(var(--main-padding-H) + var(--menu-width-open));
  padding-right: 0;

  [dir="rtl"] & {
    padding-left: 0;
    padding-right: calc(var(--main-padding-H) + var(--menu-width-open));
  }

  @media screen and (max-width: 1024px) {
    padding: 0;

    [dir="rtl"] & {
      padding: 0;
    }
  }
}

.headerPagesPanel {
  width: 100%;
  height: var(--header-pages-panel-height);
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 650px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;

    >div {
      &:nth-of-type(1) {
        height: 30%;
      }

      &:nth-of-type(2) {
        height: 70%;
      }
    }
  }
}

@mixin Header-Pages-Panel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--header-pages-panel-height);
  transition: background-color 0.3s ease;

  @media screen and (max-width: 650px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;

    >div {
      &:nth-of-type(1) {
        height: 30%;
      }

      &:nth-of-type(2) {
        height: 70%;
      }
    }
  }

  &:hover {
    >div {
      >div {
        &[class*="starsContainerPanel"] {
          transition: all 1s ease-out;

          >button {
            width: 34px;
            transition: all 0.3s ease-out;
          }
        }
      }
    }
  }
}

.headerPagesPanel {
  @include Header-Pages-Panel();
}

.headerPagesPanelSm {
  @include Header-Pages-Panel();
  transition: all 0.3s ease-out !important;

  @media screen and (max-width: 1024px) {
    height: var(--header-pages-panel-height-sm);
    align-items: flex-start;
  }
}

.headerPagesPanelReportSm {
  @include Header-Pages-Panel();
  transition: all 0.3s ease-out !important;

  @media screen and (max-width: 650px) {
    height: 136px;
    align-items: flex-start;
  }
}

.headerPagesPanelReportWithOptionsSm {
  @include Header-Pages-Panel();
  transition: all 0.3s ease-out !important;

  @media screen and (max-width: 1024px) {
    display: block !important;
    height: 100px;
    align-items: flex-start;

    >div {
      &[class*="policieHeaderSearchReprtsSm"] {
        justify-content: space-between;
      }
    }
  }

  @media screen and (max-width: 650px) {
    height: 136px;
  }
}

.headerTitlePanel {
  display: flex;
  align-items: center;
}

.headerIconPanel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17px;
  margin: 0 10px 0 0;

  [dir="rtl"] & {
    margin: 0 0 0 10px;
  }

  >svg {
    width: 17px;
    height: 17px;

    path {
      fill: var(--secondary-2);
    }
  }
}

.headerLblPanel {
  >label {
    color: var(--primary-color-2);
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.3;
  }
}

input[type="text"],
input[type="date"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: var(--font-en);

  [dir="rtl"] & {
    font-family: var(--font-ar);
  }
}

input[type="text"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  box-shadow: none !important;
}

// status Label
@mixin statueLabel {
  padding: 5px 12px;
  border-radius: 20px;
  min-width: 80px;
  text-align: center;
  font-weight: 500;
}


.workflow-status {
  @include statueLabel();
}

.status-label {
  @include statueLabel();
}


.status-215402,
.status-100002,
.status-101103 {
  @include statueLabel();
  color: var(--status-rejected) !important;
  background-color: var(--status-rejected-bg) !important;
}

.status-215401,
.status-100001,
.status-285408,
.status-192803,
.status-101101 {
  @include statueLabel();
  background-color: var(--status-active-bg) !important;
  color: var(--status-active) !important;
}

.status-285407,
.status-225905,
.status-131103,
.status-131104 {
  @include statueLabel();
  color: var(--status-rejected) !important;
  background-color: var(--status-rejected-bg) !important;
}

.status-285401 {
  @include statueLabel();
  background-color: var(--status-draft-bg) !important;
  color: var(--status-draft) !important;
}

.status-225901 {
  @include statueLabel();
  background-color: var(--status-incomplete-bg) !important;
  color: var(--status-incomplete) !important;
}

.status-225907,
.status-192804,
.status-285410 {
  @include statueLabel();
  background-color: var(--status-cancelled-bg) !important;
  color: var(--status-cancelled) !important;
}

.status-285411 {
  @include statueLabel();
  background-color: var(--status-hanged-up) !important;
  color: var(--white) !important;
}

.status-131101,
.status-192801 {
  @include statueLabel();
  background-color: var(--status-new-bg) !important;
  color: var(--status-new) !important;
}

.status-131102,
.status-225906 {
  @include statueLabel();
  background-color: var(--status-cofirmed-bg) !important;
  color: var(--status-cofirmed) !important;
}

.status-131105,
.status-225904 {
  @include statueLabel();
  background-color: var(--status-approved-bg) !important;
  color: var(--status-approved) !important;
}

.status-225902,
.status-192805 {
  @include statueLabel();
  background-color: var(--status-prepared-bg) !important;
  color: var(--status-prepared) !important;
}

.status-225903 {
  @include statueLabel();
  background-color: var(--status-submitted-bg) !important;
  color: var(--status-submitted) !important;
}

.status-225908 {
  @include statueLabel();
  background-color: var(--status-closed-bg) !important;
  color: var(--status-closed) !important;
}

.status-225909,
.status-192802,
.status-101102 {
  @include statueLabel();
  background-color: var(--status-suspend-bg) !important;
  color: var(--status-suspend) !important;
}

.status-225910 {
  @include statueLabel();
  background-color: var(--status-posted-bg) !important;
  color: var(--status-posted) !important;
}


@mixin doteStatue {
  font-weight: 500;
  position: relative;
  overflow: visible !important;
  width: calc(100% - 15px);
  text-align: start !important;
  margin: 0 0 0 15px;

  [dir="rtl"] & {
    margin: 0 15px 0 0;
  }

  &::before {
    content: "";
    position: absolute;
    left: -15px;
    top: 50%;
    transform: translateY(-50%);
    right: auto;
    min-height: 8px;
    min-width: 8px;
    border-radius: 50%;
    display: block;

    [dir="rtl"] & {
      right: -15px;
      left: auto;
    }
  }
}

.status-dote-906003 {
  @include doteStatue();
  width: fit-content !important;
  color: var(--red) !important;

  &::before {
    background-color: var(--red) !important;
  }
}

.status-dote-906001 {
  @include doteStatue();
  width: fit-content !important;
  color: #fc7e1e !important;

  &::before {
    background-color: #fc7e1e !important;
  }
}

.status-dote-906002 {
  @include doteStatue();
  width: fit-content !important;
  color: #32cd32 !important;

  &::before {
    background-color: #32cd32 !important;
  }
}

.status-dote-100001 {
  @include doteStatue();
  color: var(--status-active) !important;

  &::before {
    background-color: var(--status-active) !important;
  }
}

.status-dote-100002 {
  @include doteStatue();
  color: var(--status-rejected) !important;

  &::before {
    background-color: var(--status-rejected) !important;
  }
}

.status-dote-192801 {
  @include doteStatue();
  color: var(--status-192801) !important;

  &::before {
    background-color: var(--status-192801) !important;
  }
}

.status-dote-192802 {
  @include doteStatue();
  color: var(--status-192802) !important;

  &::before {
    background-color: var(--status-192802) !important;
  }
}

.status-dote-192803 {
  @include doteStatue();
  color: var(--status-192803) !important;

  &::before {
    background-color: var(--status-192803) !important;
  }
}

.status-dote-192804 {
  @include doteStatue();
  color: var(--status-192804) !important;

  &::before {
    background-color: var(--status-192804) !important;
  }
}

.status-dote-192805 {
  @include doteStatue();
  color: var(--status-192805) !important;

  &::before {
    background-color: var(--status-192805) !important;
  }
}

.status-101001 {
  @include doteStatue();
  color: var(--status-duty) !important;

  &::before {
    background-color: var(--status-duty) !important;
  }
}

.status-101002 {
  @include doteStatue();
  color: var(--status-vacation) !important;

  &::before {
    background-color: var(--status-vacation) !important;
  }
}

.status-101003 {
  @include doteStatue();
  color: var(--red) !important;

  &::before {
    background-color: var(--red) !important;
  }
}

.status-101004 {
  @include doteStatue();
  color: var(--status-leave) !important;

  &::before {
    background-color: var(--status-leave) !important;
  }
}

.status-101005 {
  @include doteStatue();
  color: var(--status-absent) !important;

  &::before {
    background-color: var(--status-absent) !important;
  }
}

.headerSearchPanel {
  display: flex;
  align-items: center;

  @media screen and (max-width: 650px) {
    width: 100%;
    justify-content: space-between;
  }
}

.headerPanelBtn {
  margin: 0 0 0 10px;

  >svg {
    width: 20px;
    height: 20px;
  }

  [dir="rtl"] & {
    margin: 0 10px 0 0;
  }
}

.headerPanelBtnImg {
  margin: 0 20px 0 0;
  width: 22px;
  height: 22px;

  [dir="rtl"] & {
    margin: 0 0 0 20px;
  }

  svg {
    width: 22px;
    height: 22px;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.headerPanelExcelBtn {
  margin: 0 20px 0 0;
  width: 22px;
  height: 22px;

  [dir="rtl"] & {
    margin: 0 0 0 20px;
  }

  svg {
    width: 22px;
    height: 22px;

    >path {
      fill: green;
    }
  }
}

.container {
  width: 100%;
  padding: 0 0.5rem;
  overflow: hidden;
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: -1rem;
  margin-left: -1rem;
  margin-bottom: var(--row-margin-bottom);
}

@mixin Widths-Style {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-bottom: 10px;

  @media screen and (max-width: 768px) {
    width: 100% !important;
  }
}

.w16 {
  width: 16.6%;
  @include Widths-Style();

  @media screen and (max-width: 1024px) {
    width: 50%;
  }
}

.w20 {
  width: 20%;
  @include Widths-Style();

  @media screen and (max-width: 1024px) {
    width: 50%;
  }
}

.w25 {
  width: 25%;
  @include Widths-Style();

  @media screen and (max-width: 1024px) {
    width: 50%;
  }
}

.w33 {
  width: 33.333%;
  @include Widths-Style();

  @media screen and (max-width: 1024px) {
    width: 50%;
  }
}

.w50 {
  width: 50%;
  @include Widths-Style();
}

.w66 {
  width: 66.667%;
  @include Widths-Style();
}

.w75 {
  width: 75%;
  @include Widths-Style();
}

.w83 {
  width: 83.4%;
  @include Widths-Style();
}

.w100 {
  width: 100%;
  @include Widths-Style();
}

.rowSmModelFilters {
  >div {
    width: 100%;

    &:nth-of-type(1) {
      width: calc(100% - 50px);
    }

    &:nth-of-type(2) {
      width: 50px;

      >button {
        min-width: 40px !important;
        width: 40px;
        padding: 0;

        >label {
          display: none;
        }
      }
    }
  }
}

@mixin primary-button {
  min-width: 100px;
  padding: 0 10px;
  height: calc(var(--common-height) - 4px);
  line-height: calc(var(--common-height) - 4px);
  position: relative;
  border-radius: 5px;
  color: var(--btn-font-color);
  overflow: hidden;
  transition: all 0.15s;
  font-size: 1rem;

  >span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 0;

    [dir="rtl"] & {
      margin: 0 0 0 10px;
    }

    >svg {
      width: 20px;
      height: 20px;
    }
  }
}

@mixin cancel-button {
  width: 100px;
  height: calc(var(--common-height) - 4px);
  line-height: calc(var(--common-height) - 4px);
  border-radius: 5px !important;
  border: none !important;
  background-color: var(--white) !important;
  color: var(--dark-gray) !important;
  font-size: 1rem !important;
  font-weight: 500;
  transition: all 0.15s;
  padding: 0px 10px !important;

  &:focus {
    box-shadow: none !important;
  }

  >span {
    margin: 0 10px 0 0;

    [dir="rtl"] & {
      margin: 0 0 0 10px;
    }

    >svg {
      g {
        stroke: var(--dark-gray);
      }
    }
  }

  &:hover {
    >span {
      >svg {
        g {
          stroke: var(--red);
        }
      }
    }
  }
}

.primary-button {
  @include primary-button();
  background-color: var(--secondary-2);

  &:hover {
    box-shadow: 0 8px 25px -12px var(--secondary-2);
  }
}

.secondary-button {
  @include primary-button();
  background-color: var(--primary-color-2);

  &:hover {
    box-shadow: 0 8px 25px -12px var(--primary-color-2);
  }
}

.cancel-button {
  @include cancel-button();
}

.clearButton {
  height: 30px;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  border-bottom: 1px solid var(--white);
  color: var(--secondary-1);

  >svg {
    margin: 0 5px 0 0;

    [dir="rtl"] & {
      margin: 0 0 0 5px;
    }

    path {
      fill: var(--secondary-1);
    }
  }

  &:hover {
    border-color: var(--secondary-1);
  }
}

button:disabled {
  background-color: var(--disable-colors);
  color: var(--text-3);
  border-color: var(--disable-colors);
  cursor: not-allowed;

  &:hover {
    box-shadow: 0 8px 25px -12px var(--disable-colors);
    background-color: var(--disable-colors) !important;
  }

  svg {
    path {
      fill: var(--text-3);
    }

    g {
      stroke: var(--text-3);
    }
  }
}

.close-button {
  &:hover {
    svg {
      transition: 0.2s;
      transform: scale(1.2);

      path {
        stroke: var(--red);
      }
    }
  }
}

.delete-button {
  background-color: var(--white);
  min-width: 100px;
  padding: 0 10px;
  height: calc(var(--common-height) - 4px);
  line-height: calc(var(--common-height) - 4px);
  border-radius: 5px;
  color: var(--red);
  border: 1px solid var(--red);
  overflow: hidden;
  transition: all 0.15s;

  &:hover {
    background-color: var(--pale-red);
  }

  >span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 0;

    [dir="rtl"] & {
      margin: 0 0 0 10px;
    }

    >svg {
      width: 20px;
      height: 20px;
    }
  }
}

.delete-button-filter {
  padding: 0 10px;
  height: var(--common-height);
  position: relative;
  border-radius: 5px;
  color: var(--text-4);
  font-size: 1rem;
  background-color: var(--white);
  min-width: 100px;

  >svg {
    margin: 0 10px 0 0;

    [dir="rtl"] & {
      margin: 0 0 0 10px;
    }

    g {
      stroke: var(--text-4);
    }
  }

  &:hover {
    filter: brightness(0.95);

    svg {
      width: 15px;
      height: 15px;

      g {
        stroke: var(--red);
      }
    }
  }
}

.Toastify__close-button--light {
  &:hover {
    >svg {
      path {
        fill: var(--red);
      }
    }
  }
}

.Toastify__toast-body>div:last-child {
  color: var(--black) !important;
  font-family: var(--font-ar);

  [dir="rtl"] & {
    font-family: var(--font-ar);
  }
}

.notificationButton {
  height: var(--common-height);
  width: var(--common-height);
  margin: 0 10px 0 0;

  [dir="rtl"] & {
    margin: 0 0 0 10px;
  }

  >svg {
    width: 18px;
    height: 18px;
  }
}

.actionBtn {
  display: flex;
  align-items: center;

  * {
    cursor: pointer;
  }

  >svg {
    width: 14px;
    height: 14px;
    margin: 0 5px 0 0;

    [dir="rtl"] & {
      margin: 0 0 0 5px;
    }
  }

  >label {
    color: var(--text-3) !important;
    line-height: 1.25;
  }
}

.actionTable {
  display: flex;
  align-items: center;
  width: 120px;

  >button {
    &:nth-of-type(1) {
      width: calc(100% - 20px);
    }
  }
}

.actionSetting {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;

  >svg {
    width: 4px;
    height: 18px;
  }
}

.containerButtonTable {
  display: flex;
  align-items: center;

  >button {
    &:nth-of-type(1) {
      margin: 0 5px 0 0;

      [dir="rtl"] & {
        margin: 0 0 0 5px;
      }
    }
  }

  @media screen and (max-width: 650px) {
    width: 100%;

    >button {
      width: 50%;
      border: 1px solid var(--border-colors);
    }
  }
}

.swal2-styled.swal2-confirm {
  @include primary-button();
  min-width: 120px;
  height: var(--common-height);
  background-color: var(--secondary-2);
}

.swal2-confirm-ar,
.swal2-deny-ar,
.swal2-cancel-ar {
  font-family: var(--font-ar) !important;
}

.swal2-confirm-en,
.swal2-deny-en,
.swal2-cancel-en {
  font-family: var(--font-en) !important;
}

.swal2-colse-en {
  font-family: var(--font-en) !important;
  background-color: var(--red) !important;
}

.swal2-colse-ar {
  font-family: var(--font-ar) !important;
  background-color: var(--red) !important;
}

.swal2-styled.swal2-deny {
  @include primary-button();
  min-width: 120px;
  height: var(--common-height);
  background-color: var(--secondary-2);
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: none !important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: none !important;
}

.swal2-cancel-button {
  @include cancel-button();
  height: var(--common-height) !important;
}

.swal2-actions-panel-ar {
  flex-direction: row-reverse;
}

.swal2-actions-panel-en {
  flex-direction: row;

  >button {
    font-family: var(--font-en);

    [dir="rtl"] & {
      font-family: var(--font-ar);
    }
  }
}

@mixin swal2-title {
  font-size: 24px;
  color: black;
}

.swal2-title-ar {
  @include swal2-title();
  font-family: var(--font-ar);
}

.swal2-title-en {
  @include swal2-title();
  font-family: var(--font-en);
}

.swal2-html-container-ar {
  font-family: var(--font-ar);
}

.swal2-html-container-en {
  font-family: var(--font-en);
}

.swal2-icon.swal2-warning {
  border: 0px;
  background-color: var(--worning);
  color: var(--white);
  font-family: var(--font-en);
}

.swal2-close:focus {
  box-shadow: none !important;
}

.lineProgress {
  position: fixed;
  top: 2px;
  left: 0;
  right: auto;
  box-shadow: 0 10px 40px -10px rgb(28, 172, 64);
  border-radius: 100px;
  background: var(--waiting-par-color);
  height: 4px;
  width: 0;
  z-index: 9999;

  [dir="rtl"] & {
    right: 0;
    left: auto;
  }
}

.load {
  animation: load 0.7s normal forwards;
}

@keyframes load {
  0% {
    width: 0;
  }

  100% {
    width: 80%;
  }
}

.finish {
  animation: finish 0.7s normal forwards;
}

@keyframes finish {
  0% {
    width: 80%;
  }

  100% {
    width: 100%;
  }
}

.disabledLayer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: transparent;
  display: none;
}

.mandatory {
  &::after {
    content: "*";
    color: var(--error);
    margin: 0 0 0 5px;

    [dir="rtl"] & {
      margin: 0 5px 0 0;
    }
  }
}

.suggestion-item {
  padding: 15px 10px;
}

.suggestion-item--active {
  padding: 15px 10px;
}

.rotate {
  @keyframes reload {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  animation: reload 0.7s infinite linear !important;
}

.searchWithReload {
  position: absolute;
  width: 35px;
  height: 25px;

  >g {
    top: 0;
    left: 0;
    transform: translate(10%, 10%);

    >path {
      &:first-of-type {
        transform: rotate(41deg) translate(5px, 0px);
      }

      &:last-of-type {
        transform: rotate(110deg) translate(1px, -3px);
      }

      &:nth-of-type(2) {
        transform: rotate(137deg) translate(-9px, -9px) !important;
        stroke-width: 4px;
      }
    }

    >circle {
      r: 9px;
    }
  }
}

.overflowTableField {
  display: block !important;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// *****input-range***
.input-range {
  direction: ltr;

  [dir="rtl"] & {
    direction: rtl;
  }
}

.input-range__slider {
  background-color: #b7caff !important;
  border-color: var(--secondary-1) !important;
  transform: scale(1.3);
}

.input-range__track--active {
  background-color: var(--secondary-1) !important;
  height: 2px;
}

.input-range__label-container {
  color: var(--secondary-1);
  font-weight: 500;
  font-size: 1.2rem;
  left: 0;
}

.input-range__label--value {
  top: -2rem !important;
  bottom: auto !important;
}

.input-range__track--background {
  border: 1px solid #cccccc;
}

.input-range__track {
  background-color: #f6f6f6;
}

.input-range__label--bottom-max {
  >span {
    display: none !important;
  }
}

.dashboard-reload-btn {
  svg {
    * {
      fill: var(--red);
    }
  }
}

.dashboard-header-actions {
  display: flex;
  flex-direction: row;
}

// *****input-range***

.slick-dots {
  display: flex !important;
  justify-content: center;
  margin: 0;
  list-style-type: none;
  position: absolute;
  top: 30px !important;
  left: 50%;
  transform: translateX(-50%);

  li {
    margin: 0 0.5rem !important;

    &:first-of-type {
      margin: 0;
    }

    &:last-of-type {
      margin: 0;
    }
  }

  button {
    display: block;
    width: 10px !important;
    height: 10px !important;
    padding: 0 !important;
    border: none !important;
    border-radius: 50%;
    background-color: var(--white) !important;
    text-indent: -9999px !important;
    transition: 0.5s all !important;
  }

  li.slick-active button {
    background-color: var(--primary-color-2) !important;
  }

  li {
    margin: 0 10px;
  }
}

// ********************************
.openBlackScreen {
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--black);
  width: 100%;
  height: 100%;
  z-index: 99;
  opacity: 0.1;
  display: block;
}

@media screen and (max-width: 1025px) {
  .activeMenu {
    left: 0 !important;
    right: auto !important;

    [dir="rtl"] & {
      right: 0 !important;
      left: auto !important;
    }
  }
}

.modulNotification {
  position: relative;
}

.notificationContainer {
  left: 15px;
  right: auto;

  [dir="rtl"] & {
    right: 15px;
    left: auto;
  }

  @media screen and (max-width: 1025px) {
    position: absolute;
    right: 15px;
    left: auto;
    min-width: 22px;
    height: 18px;
    background-color: var(--red);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-family: var(--font-en);

    >label {
      line-height: 12px;
      font-weight: 700;
      font-size: 0.8rem;
      color: var(--white);
    }

    [dir="rtl"] & {
      left: 15px;
      right: auto;
      font-family: var(--font-en);
    }
  }
}

@mixin Toast-Line {
  display: block;
  width: 3px;
  border-radius: 5px;
  height: 100%;
  position: absolute;
  top: 0;
}

@mixin Toast-Icon {
  width: var(--toast-img-width);
  height: var(--toast-img-width);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
  position: relative;

  img {
    height: 100%;
    border-radius: 50%;
  }
}

@mixin Toast-Container {
  height: 100%;
  padding: 15px 0 10px;
  position: relative;

  .msg-number {
    color: var(--link-label-color);
  }

  .toast-numbers {
    position: absolute;
    top: -5px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    >div {
      display: flex;
      align-items: center;
      font-size: 1.1rem;

      >label {
        margin: 0 0 0 5px;
      }
    }
  }

  .toast-container-info {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: 5px;
  }

  .toast-line {
    @include Toast-Line();
  }

  .toast-icon {
    @include Toast-Icon();

    >svg {
      width: 15px;
      height: 15px;

      g {
        path {
          fill: var(--white);
        }
      }

      path {
        fill: var(--white);
      }
    }
  }

  .toast-desc {
    width: calc(100% - var(--toast-img-width) - 30px - 3px);
    white-space: pre-line;

    >label {
      display: block !important;

      &:nth-of-type(1) {
        font-size: 1.1rem;
      }

      &:nth-of-type(2) {
        color: var(--text-3);
        font-size: 0.8rem;
        margin-top: 5px;
      }
    }
  }
}

.toast-en-type-130001 {
  @include Toast-Container();
  font-family: var(--font-en);

  .toast-line {
    background-color: #f7b538;
  }

  // .toast-icon {
  //   background-color: #f7b538;
  // }
}

.toast-ar-type-130001 {
  @include Toast-Container();
  font-family: var(--font-ar);

  .toast-line {
    background-color: #f7b538;
  }

  // .toast-icon {
  //   background-color: #f7b538;
  // }
}

.toast-en-type-130002 {
  @include Toast-Container();
  font-family: var(--font-en);

  .toast-line {
    background-color: #ea4c89;
  }

  // .toast-icon {
  //   background-color: #ea4c89;
  // }
}

.toast-ar-type-130002 {
  @include Toast-Container();
  font-family: var(--font-ar);

  .toast-line {
    background-color: #ea4c89;
  }

  // .toast-icon {
  //   background-color: #ea4c89;
  // }
}

.toast-en-type-130003 {
  @include Toast-Container();
  font-family: var(--font-en);

  .toast-line {
    background-color: #db7c26;
  }

  // .toast-icon {
  //   background-color: #db7c26;
  // }
}

.toast-ar-type-130003 {
  @include Toast-Container();
  font-family: var(--font-ar);

  .toast-line {
    background-color: #db7c26;
  }

  // .toast-icon {
  //   background-color: #db7c26;
  // }
}

.toast-en-type-130004 {
  @include Toast-Container();
  font-family: var(--font-en);

  .toast-line {
    background-color: #0ea218;
  }

  // .toast-icon {
  //   background-color: #0ea218;
  // }
}

.toast-ar-type-130004 {
  @include Toast-Container();
  font-family: var(--font-ar);

  .toast-line {
    background-color: #0ea218;
  }

  // .toast-icon {
  //   background-color: #0ea218;
  // }
}

.toast-en-type-130005 {
  @include Toast-Container();
  font-family: var(--font-en);

  .toast-line {
    background-color: #007bc3;
  }

  // .toast-icon {
  //   background-color: #007bc3;
  // }
}

.toast-ar-type-130005 {
  @include Toast-Container();
  font-family: var(--font-ar);

  .toast-line {
    background-color: #007bc3;
  }

  // .toast-icon {
  //   background-color: #007bc3;
  // }
}

.Toastify__toast-container {
  width: 380px !important;
}

//for google map
.gm-style-mtc {
  >button {
    border-radius: 0;
  }
}

.mandatory-input {
  border-width: 1px !important;
  border-color: var(--error) !important;
  border-style: solid !important;
}

.mandatory-select-input {
  >div {
    outline-width: 1px !important;
    outline-color: var(--error) !important;
    outline-style: solid !important;
  }
}

.err-mand {
  margin-top: 4px;
  list-style: disc;
  margin-right: 13px;
}

.options-btn-25 {
  >button {
    width: calc(25% - 10px) !important;
  }

  @media screen and (max-width: 650px) {
    >button {
      width: calc(50% - 10px) !important;
    }
  }
}

.options-btn-33 {
  >button {
    width: calc(33.333% - 10px) !important;

    &:last-of-type {
      width: 33.333% !important;
    }
  }
}

.options-btn-50 {
  >button {
    width: calc(50% - 10px) !important;

    &:last-of-type {
      width: 50% !important;
    }
  }
}

.frm-buttons-options {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;

  >button {
    width: auto;
    min-width: 100px;
    height: var(--common-height);
    border: 1px solid var(--border-colors);
    padding: 0 18px;
    color: var(--text-2);
    position: relative;

    &:disabled {
      * {
        cursor: not-allowed !important;
      }
    }

    * {
      cursor: pointer;
    }

    >span {
      position: absolute;
      top: 5px;
      left: 5px;
      right: auto;
      z-index: 1;

      [dir="rtl"] & {
        left: auto;
        right: 5px;
      }

      svg {
        width: 12px;
        height: 12px;
      }
    }

    >label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
    }

    &.selected {
      border-color: var(--success);
      color: var(--success);
    }
  }
}

.headerFilter {
  >label {
    margin-right: 10px;
    margin-left: 0;
    white-space: nowrap;

    [dir="rtl"] & {
      margin-left: 10px;
      margin-right: 0;
    }
  }

  display: flex;
  position: absolute;
  align-items: baseline;
  width: 380px;
  transform: translate(50%, 0);
  right: 50%;
  left: auto;
}

@mixin Table-Header-Actions {
  min-width: 80px;
  height: var(--common-height);
  display: flex;
  align-items: center;
  margin: 0 10px 0 0;
  position: relative;
  padding: 0 7px;

  [dir="rtl"] & {
    margin: 0 0 0 10px;
  }

  * {
    cursor: pointer;
  }

  @media screen and (max-width: 650px) {
    min-width: fit-content;
  }

  >span {
    width: 15px;
    height: 100%;
    margin: 0 7px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;

    [dir="rtl"] & {
      margin: 0 0 0 7px;
    }

    svg {
      width: 14px;
      height: 14px;

      path {
        fill: var(--text-4);
      }
    }
  }

  >label {
    width: calc(100% - 15px - 7px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--text-2);
  }

  &:hover {
    background-color: rgb(171 32 90 / 7%);

    label {
      color: var(--pageination);
    }

    span {
      svg {
        path {
          fill: var(--pageination);
        }
      }
    }
  }

  &:disabled {
    background-color: var(--disable-colors);
    color: var(--text-3);
    border-color: var(--disable-colors);
    cursor: not-allowed;

    * {
      cursor: not-allowed;
    }

    &:hover {
      background-color: var(--disable-colors) !important;
    }

    >label {
      color: var(--text-2) !important;
    }

    span {
      svg {
        path {
          fill: var(--text-3);
        }

        g {
          stroke: var(--text-3);
        }
      }
    }
  }
}

.tableHeaderActions {
  display: flex;
  align-items: center;
  padding: 10px 10px 20px;
  height: var(--table-header-actions-height);
  border-bottom: 5px solid var(--bg);
  margin-bottom: var(--table-header-actions-margin-bottom) !important;

  @media screen and (max-width: 650px) {
    padding: 10px;
  }

  >button {
    @include Table-Header-Actions();
  }
}

.tableHeaderActionsOrg {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: 650px) {
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: flex-start;

    &::-webkit-scrollbar {
      display: none;
    }

    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  >button {
    @include Table-Header-Actions();
  }
}

.tableHeaderActionsTable {
  display: flex;
  align-items: center;

  >button {
    @include Table-Header-Actions();
  }
}

.tableHeaderActionsBtn {
  @include Table-Header-Actions();
}

.tableHeaderDeleBtn {

  &:hover {
    background-color: var(--pale-red) !important;

    label {
      color: var(--error);
    }

    span {
      svg {
        path {
          fill: var(--error);
        }

        g {
          fill: var(--error);
          stroke: var(--error);
        }
      }
    }
  }

  @include Table-Header-Actions();
}

.uploadImgProfileContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;

  img {
    border-radius: 50%;
    height: 100%;
    width: 136px;
    height: 136px;
    object-fit: cover;
    outline: 3px solid #304767;
  }

  .camera {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    color: var(--secondary-1);
    border: 1px solid var(--border-colors);
    padding: 5px;
    border-radius: 5px;
    margin-top: 10px;
    font-size: 0.8rem;
    cursor: pointer;

    >input {
      display: none;
    }

    >label {
      cursor: pointer;
    }

    &:hover {
      background-color: var(--blue);

      svg {
        path {
          fill: var(--white);
        }
      }
    }
  }
}

.uploadImgProfileContainerMobile {
  display: none;
}

.uploadUserProfileImg {
  >div {
    &[class*="uploadOneImg"] {
      width: 140px;
      height: 140px;
      border-radius: 50% !important;
      border-color: var(--secondary-2) !important;

      >div {
        border-radius: 50% !important;

        >div {
          overflow: visible !important;
        }

        svg {
          g {
            path {
              fill: var(--white);
            }
          }
        }

        label {
          color: var(--white);
        }
      }
    }
  }
}

.mobileTable {
  >a {
    vertical-align: text-bottom;
  }

  >svg {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    margin-left: 0;

    [dir="rtl"] & {
      margin-left: 5px;
      margin-right: 0;
    }

    >path {
      fill: var(--secondary-2);
    }
  }
}

.linkLabel {
  color: var(--link-label-color) !important;
  cursor: pointer;
  text-decoration: underline;
}

.totalsBtnSm {
  display: none;

  @media screen and (max-width: 650px) {
    display: flex;
    align-items: center;
    justify-content: center;

    label {
      color: var(--text-2);
      margin: 0 0 0 10px;

      [dior="rtl"] & {
        margin: 0 10px 0 0;
      }
    }

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: var(--secondary-2);
      }
    }
  }
}

.reports-header-actions {
  display: flex;
  align-items: center;
}

.ploicyBtn {
  display: none;
}

.ploicyFilterContainer {
  height: var(--common-height);

  @media screen and (max-width: 1024px) {
    height: auto;

    >div {
      margin-top: 0 !important;
    }
  }
}

.policieHeaderSearchReprtsSm {
  @media screen and (max-width: 1024px) {
    >div {
      >div {
        &[class*="headerFilter"] {
          position: static !important;
          transform: translate(0) !important;
          margin-top: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 650px) {
    align-items: flex-start !important;
    flex-direction: column-reverse !important;
    width: 100%;

    >div {
      width: 100% !important;
      height: fit-content !important;

      >div {
        &[class*="headerFilter"] {
          width: 100% !important;
          height: 100% !important;
        }
      }

      &[class*="reports-header-actions"] {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
    }

    // > div {
    //   width: calc(100% - 20px - 10px - var(--common-height) - 10px);
    //   display: flex;
    //   flex-direction: column-reverse;

    //   > div {
    //     &[class*="inputContainer"] {
    //       min-width: 100% !important;
    //     }

    //     &[class*="headerFilter"] {
    //       visibility: hidden;
    //       width: 100% !important;
    //     }
    //   }
    // }

    button {
      &[class*="headerPanelBtn"] {
        height: var(--common-height);
      }

      &[class*="ploicyBtn"] {
        display: flex !important;
        align-items: center;
        justify-content: center;
        width: var(--common-height) !important;
        height: var(--common-height) !important;
        margin: 0 0 0 10px !important;

        [dir="rtl"] & {
          margin: 0 10px 0 0 !important;
        }

        svg {
          width: 24px;
          height: 24px;

          path {
            fill: var(--secondary-2);
          }
        }
      }
    }
  }

  @media screen and (max-width: 650px) {
    >div {
      >div {
        &[class*="headerFilter"] {
          margin: 10px 0;
        }
      }
    }
  }
}

.hotkeysBtn {
  &::after {
    content: attr(data-hotkey);
    position: absolute;
    line-height: 1;
    color: var(--white);
    font-size: 1rem;
    top: 5px;
    right: 5px;
    left: auto;

    [dir=rtl] & {
      left: 5px;
      right: auto;
    }
  }
}

.customerDecision {
  >div {
    height: 320px !important;
    width: 450px !important;

    .swal2-styled,
    .swal2-deny {
      width: 140px;
      height: 50px !important;
      font-size: 1.2rem;
    }

    .swal2-close {
      justify-self: start !important;
      font-size: 3rem;
    }

    .swal2-actions-panel-ar {
      flex-direction: row;
    }

    .swal2-actions-panel-en {
      flex-direction: row;
    }
  }
}

.download-zatca-btn {
  min-width: 60px;
  height: calc(var(--common-height) - 10px);
  line-height: calc(var(--common-height) - 10px);
  border-radius: 5px;
  color: var(--blue);
  text-decoration: underline;
  overflow: hidden;
  transition: all 0.15s;
  font-size: 1rem;

  >span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 0;

    [dir="rtl"] & {
      margin: 0 0 0 10px;
    }

    >svg {
      width: 15px;
      height: 15px;
    }
  }
}

.workflow-status {
  @include statueLabel();
}