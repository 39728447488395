.totalsTrx {
    width: calc(100% - 20px);
    background-color: var(--white);
    padding: 10px;
    margin: 0 10px;
    // border-radius: var(--common-border-radius);
    margin-bottom: 10px !important;
    border: 1px solid #f1f1f1;

    >div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        padding: 5px 10px;

        &:first-of-type {
            border: none;
        }

        >div {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &:nth-of-type(1) {
                width: 30%;
                justify-content: flex-start;

                label {
                    color: var(--black) !important;
                    font-weight: 500 !important;
                    font-size: 1.2rem;
                }
            }

            &:nth-of-type(2) {
                width: 35%;

                label {
                    font-size: 1.25rem;
                }
            }

            &:nth-of-type(3) {
                width: 35%;

                label {
                    font-size: 1.25rem;
                }
            }
        }
    }
}
.headerTitle{
    justify-content: space-between;
    padding: 0 10px;
}
.summaryContainer{
    height: auto !important;
}