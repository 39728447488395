$margin-container: 11px;
$margin-sm-container: 6px;
$triangle-size: 8px;

.filterPanel {
    display: flex;
    height: 100%;

    @media screen and (max-width: 1300px) {
        width: 100%;
    }
}

.optionsMsg {
    display: flex;
    align-items: center;
    justify-content: space-between;

    >button {
        min-width: 110px;
        height: var(--common-height);
        color: var(--text-4);
        padding: 0 10px;
        font-size: 12px;
        margin: 0 10px 0 0;
        border: 1px solid var(--border-colors);

        [dir=rtl] & {
            margin: 0 0 0 10px;
        }

        &:hover {
            background-color: var(--bg);
        }

        >svg {
            width: 20px;
            height: 20px;
            margin: 0 7px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 7px;
            }
        }

        &.active {
            background-color: var(--pale-secondary);
            border: 1px solid var(--active-filter-btn);
            color: var(--active-filter-btn);
        }
    }
}

.searchPanel {
    width: 100%;
    background-color: var(--white);

    @media screen and (max-width: 1300px) {
        margin: 0 $margin-sm-container !important;
        width: calc(100% - $margin-sm-container - $margin-sm-container) !important;
        height: 80px !important;

        >div {
            height: 50%;
        }
    }
}

.searchPanelHeader {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0 10px;
    // padding-right: 10px;
    height: 100%;
    justify-content: space-between;

    @media screen and (max-width: 1300px) {
        flex-direction: column;
        align-items: flex-start;
        height: 100% !important;
    }

    @media screen and (max-width: 500px) {
        padding: 0px !important;
    }
}

// ***************dashboards card****************
.transactionsStatisticsContainer {
    background-color: transparent !important;
    padding: var(--frm-template-panel-padding-top) $margin-container !important;
    height: calc(100vh - var(--header-height) - 20px - var(--header-pages-panel-height) - var(--main-padding-V));
    overflow: auto;

    @media screen and (max-width: 1300px) {
        height: calc(100vh - var(--header-height) - 20px - 80px - var(--main-padding-V));
        padding: var(--frm-template-panel-padding-top) $margin-sm-container !important;
    }

    @media screen and (max-width: 765px) {
        padding: 10px !important;
    }

    @media screen and (max-width: 450px) {
        height: calc(100vh - var(--header-height) - 20px - 80px - var(--main-padding-V) - 40px);
        padding-bottom: 40px !important;
    }

    >div {
        padding: 0 1rem;

        &:first-of-type {
            padding: 0 !important;
        }

        @media screen and (max-width: 500px) {
            padding: 0 !important;
        }
    }
}

// ***************dashboards card****************

.smContainer {

    @media screen and (max-width: 1200px) {
        >div {
            width: 50%;
        }
    }

    @media screen and (max-width: 770px) {
        >div {
            width: 100%;
        }
    }
}

.headerButton {
    overflow-x: auto;
    display: flex;

    &::-webkit-scrollbar {
        display: none;
    }
}

.optionsDate {
    display: flex;
    align-items: center;

    >div {
        min-width: 300px !important;

        &:nth-of-type(1) {
            margin: 0 10px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 10px;
            }
        }
    }
}


.summaryCard {
    height: 125px;
    background: #fff;
    padding: 15px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    >div {
        &:nth-of-type(1) {
            height: calc(100% - 40px - 10px);
        }
    }

    .titlePanel {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .title,
    .value {
        display: block;
        font-size: 1.2rem;
    }

    .value {
        height: 24px;
        display: flex;
        align-items: flex-end;
        font-size: 1.3rem;
        color: var(--text-2);
    }

    .title {
        margin-bottom: 5px;
        font-weight: 600;

        @media only screen and (min-width: 1300px) and (max-width: 1200px) {
            white-space: nowrap;
            width: 120px;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        @media screen and (max-width: 500px) {
            white-space: nowrap;
            width: 120px;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .value {
        font-weight: 600;
    }

    .iconPanel {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background-color: var(--pale-secondary-1);
        animation: pulse 2s infinite;

        svg {
            height: 22px;
            width: 22px;

            // path {
            //     fill: var(--secondary-1);
            // }
        }

        @media only screen and (min-width: 1300px) and (max-width: 1200px) {
            width: 35px;
            height: 35px;

            svg {
                height: 17px;
                width: 17px;
            }
        }

        @media screen and (max-width: 500px) {
            width: 35px;
            height: 35px;

            svg {
                height: 17px;
                width: 17px;
            }
        }
    }

    .iconCard1 {
        background-color: var(--pale-secondary-1);

        svg {
            path {
                fill: var(--secondary-1);
            }
        }
    }

    .iconCard2 {
        svg {
            path {
                fill: var(--secondary-1);
            }
        }
    }

    .iconCard3 {
        svg {
            path {
                fill: var(--secondary-1);
            }
        }
    }

    .iconCard4 {
        svg {
            path {
                fill: var(--secondary-1);
            }
        }
    }

    @media screen and (max-width: 500px) {
        padding: 8px !important;
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(102, 143, 255, 0.6);
        box-shadow: 0 0 0 0 rgba(102, 143, 255, 0.6);
    }

    70% {
        -moz-box-shadow: 0 0 0 10px rgba(102, 143, 255, 0);
        box-shadow: 0 0 0 10px rgba(102, 143, 255, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(102, 143, 255, 0);
        box-shadow: 0 0 0 0 rgba(102, 143, 255, 0);
    }
}

.detailsPanel {
    height: 40px;
}

.percPanel {
    gap: 5px;
    display: flex;
    align-items: center;
    // padding-top: 20px;
    margin-bottom: 10px;
}

@mixin ArrowIncr {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px 0 0;

    [dir=rtl] & {
        margin: 0 0 0 5px;
    }

    >svg {
        width: 18px;
        height: 18px;
    }
}

.increase {
    @include ArrowIncr();

    >svg {
        transform: rotate(180deg)
    }
}

.decrease {
    @include ArrowIncr();

    >svg {
        transform: rotate(180deg)
    }
}

.detailsTwoLvls {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 10px;

    >div {
        flex: 1;
        border-right: 1px solid var(--border-colors);
        border-left: 0;
        margin: 0 10px 0 0;
        width: 33%;
        overflow: hidden;

        [dir=rtl] & {
            border-left: 1px solid var(--border-colors);
            border-right: 0;
            margin: 0 0 0 10px;
        }

        &:last-of-type {
            border: 0;
            margin: 0;
        }
    }

    .lblLvl {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
        gap: 5px;
        font-size: 1rem;

        svg {
            width: 15px;
            height: 15px;
        }

        @media only screen and (min-width: 1300px) and (max-width: 1200px) {
            white-space: nowrap;

            svg {
                width: 12px;
                height: 12px;
            }
        }
    }
}

.cardLbl {
    overflow: hidden;
    margin: 0 5px 0 0;
    text-align: center;

    [dir=rtl] & {
        margin: 0 0 0 5px;
    }

    >label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.summaryValueLabel {
    color: white;
    font-weight: 300;
}


.summaryLbl {
    display: flex;
    align-items: center;

    >label {
        font-weight: 300 !important;
        margin: 0 !important;
    }

    >svg {
        width: 13px;
        height: 13px;
        margin: 0 5px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 5px;
        }
    }
}

.summaryContainer {
    display: flex;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 10px;

    >div {
        width: 25%;
        border-right: 1px solid #eaeaea;
        border-left: 0;

        [dir=rtl] & {
            border-left: 1px solid #eaeaea;
            border-right: 0;
        }

        &:last-of-type {
            border: 0 !important;

            [dir=rtl] & {
                border: 0 !important;
            }
        }
    }

    @media screen and (max-width: 1025px) {
        flex-wrap: wrap;

        >div {
            width: 50% !important;
            border-bottom: 1px solid #eaeaea;

            &:nth-of-type(2) {
                border-bottom: 1px solid #eaeaea !important;
                border-right: 0;
                border-left: 0;

                [dir=rtl] & {
                    border-right: 0;
                    border-left: 0;
                }
            }
        }
    }

    @media screen and (max-width: 867px) {
        flex-wrap: wrap;

        >div {
            width: 100% !important;
            border-right: 0;
            border-left: 0;

            [dir=rtl] & {
                border-right: 0;
                border-left: 0;
            }
        }
    }
}

.summaryIcon {
    >svg {
        width: 18px;
        height: 18px;
    }
}

.hideCard {
    display: none;
}

.currency {
    font-size: 1rem;
    margin: 0 0 0 10px;

    [dir=rtl] & {
        margin: 0 10px 0 0;
    }
}

.customSpinner {
    display: flex !important;
    position: relative !important;
    // width: 30px;
    margin-bottom: 5px;
    height: 24px;
    display: none;
    align-items: center;
    justify-content: flex-start;
    z-index: 2;

    &:after {
        width: 20px !important;
        height: 20px !important;
        border-right-color: var(--primary-color) !important;
        margin: 0;
        border-left-color: var(--primary-color) !important;
    }
}