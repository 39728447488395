.optionContainer {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;

  >div {
    width: 20px;
    height: 20px;
    min-width: auto;
    border-radius: 50%;
    padding: 0;
    margin: 0 10px;

    &[class*="dot-status-225901"],
    &[class*="dot-status-225801"] {
      background-color: var(--status-incomplete);
    }

    &[class*="dot-status-225902"],
    &[class*="dot-status-225802"],
    &[class*="dot-status-118504"],
    &[class*="dot-status-119301"],
    &[class*="dot-status-119401"],
    &[class*="dot-status-101102"] {
      background-color: var(--status-suspend) !important;
    }

    &[class*="dot-status-225903"],
    &[class*="dot-status-225806"] {
      background-color: var(--status-submitted) !important;
    }

    &[class*="dot-status-225904"],
    &[class*="dot-status-225803"],
    &[class*="dot-status-118502"],
    &[class*="dot-status-131105"] {
      background-color: var(--status-approved-bg) !important;
    }

    &[class*="dot-status-225804"],
    &[class*="dot-status-118505"],
    &[class*="dot-status-225908"],
    &[class*="dot-status-123205"],
    &[class*="dot-status-0"] {
      background-color: var(--status-closed) !important;
    }

    &[class*="dot-status-225906"],
    &[class*="dot-status-1"],
    &[class*="dot-status-225805"],
    &[class*="dot-status-131102"] {
      background-color: var(--status-cofirmed) !important;
    }

    &[class*="dot-status-225907"],
    &[class*="dot-status-123204"] {
      background-color: var(--status-cancelled) !important;
    }

    &[class*="dot-status-225909"],
    &[class*="dot-status-101102"] {
      background-color: var(--status-suspend) !important;
    }

    &[class*="dot-status-225910"] {
      background-color: var(--status-posted) !important;
    }

    &[class*="dot-status-123203"] {
      background-color: var(--status-new) !important;
    }

    &[class*="dot-status-215401"],
    &[class*="dot-status-119302"],
    &[class*="dot-status-119404"],
    &[class*="dot-status-123202"],
    &[class*="dot-status-100001"] {
      background-color: var(--status-active) !important;
    }

    &[class*="dot-status-215402"],
    &[class*="dot-status-225905"],
    &[class*="dot-status-100002"],
    &[class*="dot-status-119303"],
    &[class*="dot-status-123201"],
    &[class*="dot-status-119402"],
    &[class*="dot-status-118503"],
    &[class*="dot-status-101003"],
    &[class*="dot-status-101103"],
    &[class*="dot-status-131103"],
    &[class*="dot-status-131104"] {
      background-color: var(--red) !important;
    }

    &[class*="dot-status-117601"],
    &[class*="dot-status-118501"] {
      background-color: var(--requisition-new) !important;
    }

    &[class*="dot-status-117602"],
    &[class*="dot-status-906003"] {
      background-color: var(--red) !important;
    }

    &[class*="dot-status-117603"] {
      background-color: var(--requisition-company-issue) !important;
    }

    &[class*="dot-status-117604"] {
      background-color: var(--requisition-under-progress) !important;
    }

    &[class*="dot-status-117605"] {
      background-color: var(--requisition-completed) !important;
    }

    &[class*="dot-status-117606"] {
      background-color: var(--requisition-customer-issue) !important;
    }

    &[class*="dot-status-117607"] {
      background-color: var(--requisition-cancelled) !important;
    }

    &[class*="dot-status-117608"],
    &[class*="dot-status-119403"] {
      background-color: var(--requisition-terminated) !important;
    }

    &[class*="dot-status-117660"] {
      background-color: var(--status-color-117660) !important;
    }

    &[class*="dot-status-117661"] {
      background-color: var(--status-color-117661) !important;
    }

    &[class*="dot-status-117662"] {
      background-color: var(--status-color-117662) !important;
    }

    &[class*="dot-status-117663"] {
      background-color: var(--status-color-117663) !important;
    }

    &[class*="dot-status-117664"] {
      background-color: var(--status-color-117664) !important;
    }

    &[class*="dot-status-123205"] {
      background-color: var(--status-closed) !important;
    }

    &[class*="dot-status-116901"] {
      background-color: var(--real-estates-structure-color-116901) !important;
    }

    &[class*="dot-status-116902"] {
      background-color: var(--real-estates-structure-color-116902) !important;
    }

    &[class*="dot-status-116903"] {
      background-color: var(--real-estates-structure-color-116903) !important;
    }

    &[class*="dot-status-116904"] {
      background-color: var(--real-estates-structure-color-116904) !important;
    }

    &[class*="dot-status-116905"] {
      background-color: var(--real-estates-structure-color-116905) !important;
    }

    &[class*="dot-status-116906"] {
      background-color: var(--real-estates-structure-color-116906) !important;
    }

    &[class*="dot-status-116907"] {
      background-color: var(--real-estates-structure-color-116907) !important;
    }

    &[class*="dot-status-116908"] {
      background-color: var(--real-estates-structure-color-116908) !important;
    }

    &[class*="dot-status-116909"] {
      background-color: var(--real-estates-structure-color-116909) !important;
    }

    &[class*="dot-status-101001"] {
      background-color: var(--status-duty) !important;
    }

    &[class*="dot-status-101002"] {
      background-color: var(--status-vacation) !important;
    }

    &[class*="dot-status-101004"] {
      background-color: var(--status-leave) !important;
    }

    &[class*="dot-status-101005"] {
      background-color: var(--status-absent) !important;
    }

    &[class*="dot-status-192801"],
    &[class*="dot-status-131101"] {
      background-color: var(--status-192801) !important;
    }

    &[class*="dot-status-192802"] {
      background-color: var(--status-192802) !important;
    }

    &[class*="dot-status-192803"] {
      background-color: var(--status-192803) !important;
    }

    &[class*="dot-status-192804"] {
      background-color: var(--status-192804) !important;
    }

    &[class*="dot-status-192805"] {
      background-color: var(--status-192805) !important;
    }

    &[class*=dot-status-906002] {
      background-color: #32cd32;
    }

    &[class*=dot-status-906001] {
      background-color: #fc7e1e;
    }

  }
}