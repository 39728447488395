.iamgeContainer {
    max-width: 10%;
    height: 40px;
    max-height: 40px;

    >img {
        max-width: 100%;
        max-height: 100%;
    }
}

.usersDefinitionTableRow {
    >div {
        display: flex;
        justify-content: center;
    }
}

.usersDefinitionTable {

    div[class^=table_body] {
        >div {
            &[class *=checkboxRow] {
                min-height: 70px !important;
            }
        }
    }
    
    div[class^=table_header_checkbox]>span,
    div[class^=table_body]>div>div {

        >div:nth-child(1) {
            width: var(--col-1);
        }

        >div:nth-child(2) {
            width: var(--col-2);
        }

        >div:nth-child(3) {
            width: var(--col-3);
        }

        >div:nth-child(4) {
            width: var(--col-4);
        }

        >div:nth-child(5) {
            width: var(--col-5);
        }

        >div:nth-child(6) {
            width: var(--col-6);
        }

        >div:nth-child(7) {
            width: var(--col-7);
        }

        >div:nth-child(8) {
            width: var(--col-8);

            &[class*=clickable] {
                >div {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }

        >div:nth-child(9) {
            width: var(--col-9);
        }

        >div:nth-child(10) {
            width: var(--col-10);
        }

        >div:nth-child(11) {
            width: var(--col-11);
        }
    }
}

.color {
    color: var(--secondary-1) !important;
}