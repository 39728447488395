.ratingContainer {
    line-height: 1;

    >span {
        line-height: 1;

        >span {
            line-height: 1;
            margin: 0 5px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 5px;
            }
        }
    }
}