.createTransactionDialog {
    width: 650px !important;
    height: auto !important;

    >div {
        &[class*="modalBody"] {
            height: calc(100% - var(--modal-with-tabs-header-height) - var(--modal-with-tabs-footer-height)) !important;
        }
    }

    @media screen and (max-width: 768px) {
        width: 100% !important;
    }
}