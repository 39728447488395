// .summaryContainer {
//     width: var(--summary-width-trx);
//     height: 100%;
//     background-color: var(--white);
//     margin: 0 0 0 20px;
//     border-radius: var(--common-border-radius);
//     padding: 20px;

//     [dir=rtl] & {
//         margin: 0 20px 0 0;
//     }
// }
@mixin summary-section {
    display: flex;
    align-items: center;
    width: 100%;
}



.summaryCode {
    @include summary-section();
    height: 50px !important;

    >div {
        display: flex;
        align-items: center;

        &:nth-of-type(1) {
            font-weight: 700;
            font-size: 1.3rem;
        }

        &:nth-of-type(2) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
}
