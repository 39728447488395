$modalSidePadding: 30px;
$modalVerticalPadding: 20px;
$modalHeaderHeight: 70px;
$modalFooterHeight: 60px;
$modalHeight: calc(var(--window-height) - var(--window-height) * 0.1);

.flatpickr-months .flatpickr-month {
    background: var(--primary-color) !important;
    color: var(--white) !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
    background: var(--primary-color) !important;
    font-family: var(--font-en);

    [dir=rtl] & {
        font-family: var(--font-ar);
    }
}

.flatpickr-days {
    font-family: var(--font-en);

    [dir=rtl] & {
        font-family: var(--font-ar);
    }
}

.flatpickr-weekdays {
    background: var(--primary-color) !important;
}

.flatpickr-current-month input.cur-year {
    font-family: var(--font-en);

    [dir=rtl] & {
        font-family: var(--font-ar);
    }
}

.flatpickr-current-month input.cur-year:hover {
    color: var(--bg);
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
    // color: var(--light-blue);
    background-color: var(--black);
}

.numInputWrapper:hover {
    color: var(--bg);
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
    fill: var(--bg) !important;
}

span.flatpickr-weekday {
    background: var(--primary-color) !important;
    font-size: 12px;
    color: var(--white) !important;
    font-family: var(--font-ar);
}

.flatpickr-time .flatpickr-am-pm {
    font-family: var(--font-en);
}

.flatpickr-day.startRange.inRange {
    border-radius: 0 !important;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    background: var(--secondary-2) !important;
    color: var(--white) !important;
    font-weight: 500;
    line-height: 38px !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
    background: var(--white) !important;
    color: var(--black);
    font-size: 16px;
    font-family: var(--font-en);

    [dir=rtl] & {
        font-family: var(--font-ar);
    }
}

input[type="text"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
    box-shadow: none !important;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
    border-bottom-color: var(--dark-gray) !important;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
    border-top-color: var(--dark-gray) !important;
}

.flatpickr-day.today {
    border-color: var(--secondary-2) !important;
}

.flatpickr-day.today:hover {
    color: var(--primary-color) !important;
    font-weight: 600 !important;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
    color: var(--primary-color-2) !important;
    background-color: var(--bg) !important;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    border-color: var(--light-gray) !important;
}

.flatpickr-calendar.arrowTop:after {
    border-bottom-color: var(--light-gray) !important;
}

.flatpickr-calendar.arrowTop:before {
    border-bottom-color: var(--light-gray) !important;
}

.shortcut-buttons-flatpickr-buttons {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: var(--white);
    padding: 5px 10px;
    border-radius: 0 0 5px 5px;
    border: 1px solid rgba(72, 72, 72, 0.2);
    border-top: 0px;

    >button {
        min-width: 60px;
        padding: 0 10px;
        height: calc(var(--common-height) - 4px);
        line-height: calc(var(--common-height) - 4px);
        border-radius: 5px;
        overflow: hidden;
        font-size: 1rem;

        &:first-of-type {
            background-color: var(--primary-color-2);
            color: var(--white);
        }

        &:last-of-type {
            color: var(--text-4);
            background-color: var(--white);
        }

    }
}