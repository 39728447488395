.container {
  display: flex;
  width: 100%;
  height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - var(--main-padding-V));
}

.linesContainer {
  display: flex;
  flex-direction: column;
  width: calc(100% - var(--summary-width-trx) - 20px);
  margin: 0 0 0 20px;

  [dir="rtl"] & {
    margin: 0 20px 0 0;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin: 0 !important;
  }
}

.toggleMode {
  height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - var(--main-padding-V) - var(--tab-container-height)) !important;
  padding: 20px !important;
  margin-top: 0 !important;
}

.statusHeader {
  min-width: 85px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerStatus {
  display: flex;
  align-items: center;

  >div {
    &:nth-of-type(1) {
      margin: 0 10px 0 0;

      [dir="rtl"] & {
        margin: 0 0 0 10px;
      }
    }
  }
}

.cardBtn {
  min-width: 30px !important;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
  background-color: var(--secondary-1) !important;
  margin: 0 0 0 10px;

  [dir="rtl"] & {
    margin: 0 10px 0 0;
  }

  svg {
    width: 15px;
    height: 15px;

    g {

      path,
      circle {
        fill: var(--white);
      }
    }

    rect {
      fill: var(--white);
    }
  }
}

.hideElement {
  display: none;
}

.tableStyle {
  div[class^="v_table_body"] {
    height: calc(40px * 12) !important;
  }

  div[class^="table-total"]>div {
    padding: 0;
  }

  div[class^="v_table_header"],
  div[class^="v_table_row"],
  div[class^="table-total"] {
    display: flex;
    align-items: center;

    >div {
      text-align: center;
      display: flex;
      justify-content: center;
      overflow: hidden;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }

      &:nth-child(5) {
        width: var(--col-5);
      }

      &:nth-child(6) {
        width: var(--col-6);
      }

      &:nth-child(7) {
        width: var(--col-7);
      }

      &:nth-child(8) {
        width: var(--col-8);
      }

      &:nth-child(9) {
        width: var(--col-9);
      }

      &:nth-child(10) {
        width: var(--col-10);
      }

      &:nth-child(11) {
        width: var(--col-11);
      }

      &:nth-child(12) {
        width: var(--col-12);
      }

      &:nth-child(13) {
        width: var(--col-13);
      }

      &:nth-child(14) {
        width: var(--col-14);
      }

      &:nth-child(15) {
        width: var(--col-15);
      }

      &:nth-child(16) {
        width: var(--col-16);
      }

      &:nth-child(17) {
        width: var(--col-17);
      }

      &:nth-child(18) {
        width: var(--col-18);
      }

      &:nth-child(19) {
        width: var(--col-19);
      }

      &:nth-child(20) {
        width: var(--col-20);
      }

      &:nth-child(21) {
        width: var(--col-21);
      }

      &:nth-child(22) {
        width: var(--col-22);
      }

      &:nth-child(23) {
        width: var(--col-23);
      }

      &:nth-child(24) {
        width: var(--col-24);
      }

      &:nth-child(25) {
        width: var(--col-25);
      }

      &:nth-child(26) {
        width: var(--col-26);
      }

      &:nth-child(27) {
        width: var(--col-27);
      }

      &:nth-child(28) {
        width: var(--col-28);
      }

      &:nth-child(29) {
        width: var(--col-29);
      }

      &:nth-child(30) {
        width: var(--col-30);
      }

      &:nth-child(31) {
        width: var(--col-31);
      }

      &:nth-child(32) {
        width: var(--col-32);
      }

      &:nth-child(33) {
        width: var(--col-33);
      }

      &:nth-child(34) {
        width: var(--col-34);
      }

      &:nth-child(35) {
        width: var(--col-35);
      }

      &:nth-child(36) {
        width: var(--col-36);
      }
    }
  }
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;

  >div {
    width: calc(33.3333% - 7px);

    @media screen and (max-width: 1024px) {
      width: calc(50% - 7px);
    }

    @media screen and (max-width: 768px) {
      width: calc(100% - 7px);
    }
  }
}

.tableRow {
  >div {
    border-right: 0 !important;
    border-left: 0 !important;

    [dir="rtl"] & {
      border-right: 0 !important;
      border-left: 0 !important;
    }

    >div {
      border-left: 1px solid var(--border-table);
      border-right: 0;

      [dir="rtl"] & {
        border-right: 1px solid var(--border-table);
        border-left: 0;
      }
    }

    &:last-of-type {
      >div {
        border-right: 1px solid var(--border-table) !important;
        border-left: 1px solid var(--border-table) !important;

        [dir="rtl"] & {
          border-left: 1px solid var(--border-table) !important;
          border-right: 1px solid var(--border-table) !important;
        }
      }
    }
  }
}

.checkboxContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.tableBtns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  >button {
    &:nth-of-type(1) {
      margin: 0 5px 0 0;

      [dir="rtl"] & {
        margin: 0 0 0 5px;
      }
    }
  }
}

.cardPanel {
  border: 1px solid var(--border-colors);
  border-radius: 5px;
  padding: 10px 20px;
  margin-bottom: 15px;
  width: 100%;
}

.card {
  >div {
    width: 100%;
    display: flex;
    align-items: center;
    height: calc(var(--common-height) - 3px);

    >label {
      width: 120px;
      color: var(--text-3);
      margin: 0 10px 0 0;

      [dir="rtl"] & {
        margin: 0 0 0 10px;
      }
    }

    >div {
      width: calc(100% - 120px - 10px);
      overflow: hidden;
      white-space: pre-line;
      text-overflow: ellipsis;
      max-height: calc(var(--common-height) - 3px);

      label {
        max-width: 50%;
        overflow: hidden;
      }

      span {
        margin: 0 5px;
      }
    }
  }
}

.cardDesc {
  max-height: 30px !important;
}

.checkboxLbl {
  height: calc(var(--common-height) + 13px) !important;

  >div {
    max-height: calc(var(--common-height) + 13px) !important;
  }
}

.cardFooter {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px 0;
  gap: 10px;

  >button {
    width: 50%;
    border: 1px solid var(--border-colors);
  }
}

.tabsContainer {
  width: 100%;
  height: 100%;
}

.tabContentStyle {
  background-color: var(--bg) !important;
  height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - var(--main-padding-V) - var(--tab-container-height)) !important;
  overflow-y: auto;
}

.uploadContainer {
  padding-top: 20px;
}