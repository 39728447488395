$margin-container: 11px;
$margin-sm-container: 6px;

.optionsMsg {
    display: flex;
    align-items: center;
    justify-content: space-between;

    >button {
        min-width: 110px;
        height: var(--common-height);
        color: var(--text-4);
        padding: 0 10px;
        font-size: 12px;
        margin: 0 10px 0 0;
        border: 1px solid var(--border-colors);

        [dir=rtl] & {
            margin: 0 0 0 10px;
        }

        &:hover {
            background-color: var(--bg);
        }

        >svg {
            width: 20px;
            height: 20px;
            margin: 0 7px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 7px;
            }
        }

        &.active {
            background-color: var(--pale-secondary);
            border: 1px solid var(--active-filter-btn);
            color: var(--active-filter-btn);
        }
    }
}

.searchPanel {
    width: 100%;
    background-color: var(--white);
    margin-bottom: 10px;

    @media screen and (max-width: 1300px) {
        height: 80px !important;
        margin: 0 $margin-sm-container !important;
        width: calc(100% - $margin-sm-container - $margin-sm-container) !important;

        >div {
            height: 50%;
        }
    }
}

.filterPanel {
    display: flex;
    height: 100%;

    @media screen and (max-width: 1300px) {
        width: 100%;
    }
}

.searchPanelHeader {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0 10px;
    // padding-right: 10px;
    height: 100%;
    justify-content: space-between;

    @media screen and (max-width: 1300px) {
        flex-direction: column;
        align-items: flex-start;
        height: 100% !important;
    }

    @media screen and (max-width: 500px) {
        padding: 0px !important;
    }
}

.tasksStatisticsContainer {
    background-color: transparent !important;
    padding: var(--frm-template-panel-padding-top) $margin-container !important;
    height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - 15px) !important;
    overflow: auto;


    @media screen and (max-width: 765px) {
        padding: 0px !important;
        height: auto !important;
    }

    >div {
        padding: 0 1rem;
    }
}

.smContainer {
    overflow: auto;
    align-content: baseline;
    height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 20px - var(--header-pages-panel-height) - 1px);

    @media screen and (max-width: 1300px) {
        height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 20px - 80px - 1px);
    }

    @media screen and (max-width: 1200px) {
        >div {
            width: 50%;
        }
    }

    @media screen and (max-width: 770px) {
        >div {
            width: 100%;
        }
    }
}

.headerButton {
    overflow-x: auto;
    display: flex;

    &::-webkit-scrollbar {
        display: none;
    }
}

.optionsDate {
    display: flex;
    align-items: center;

    >div {
        min-width: 300px !important;

        &:nth-of-type(1) {
            margin: 0 10px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 10px;
            }
        }
    }
}