.frmContainerSm {
    @media screen and (max-width: 1025px) {
        flex-direction: column;

        >div {
            &:nth-of-type(1) {
                display: none;
            }

            width: 100%;
        }
    }
}