.tasksListRow {
    box-shadow: none;

    >div {
        display: flex;
        justify-content: center;
    }
}

// .textDesc{
//     display: flex;
//     align-items: center;
//     height: 100%;
// }
.tasksListTable {

    div[class^=table_body] {
        >div {
            &[class*=containerWaiting] {
                >div {
                    height: 100% !important;
                }
            }
        }
    }

    @media screen and (max-width: 1024px) {

        div[class^=table_header],
        div[class^=table_body]>div {
            width: 100%;
            min-width: 1300px;
        }

        div[class^=table_body] {
            overflow: unset !important;
        }

        @media screen and (max-width: 650px) {

            div[class^=table_header],
            div[class^=table_body]>div {
                width: 100% !important;
                min-width: 100% !important;
            }
        }
    }

    div[class^=table_body]>div>div {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        min-height: 70px;
        background-color: var(--white);
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.03);
    }

    div[class^=table_header],
    div[class^=table_body]>div>div {
        >div:nth-child(1) {
            width: var(--col-1);
        }

        >div:nth-child(2) {
            width: var(--col-2);
        }

        >div:nth-child(3) {
            width: var(--col-3);
        }

        >div:nth-child(4) {
            width: var(--col-4);
        }

        >div:nth-child(5) {
            width: var(--col-5);
        }

        >div:nth-child(6) {
            width: var(--col-6);
        }

        >div:nth-child(7) {
            width: var(--col-7);
        }

        >div:nth-child(8) {
            width: var(--col-8);
        }
    }
}

.twoLine {
    display: flex;
    align-items: center;
    flex-direction: column;

    >div {
        &:nth-of-type(1) {
            font-size: 1.1rem;
            color: var(--title-text);
            font-weight: 500;
            margin-bottom: 3px;
        }

        &:nth-of-type(2) {
            color: var(--text-3);
            font-weight: 500;
        }
    }
}

.time {
    color: var(--secondary-2) !important;
    font-size: 14px;
}

.excutor {
    color: var(--secondary-1) !important;
}

.timerContainer {
    margin: 0 10px;
    min-height: var(--common-height);
    min-width: var(--common-height);
    display: flex;
    align-items: center;
    justify-content: center;

    >span>svg {
        width: 13px;
        height: 22px;
    }
}