$tabs-button : 20%;

.estimationFormsFieldsBuilderRow {
    >div {
        display: flex;
        justify-content: center;
    }
}

.estimationFormsFieldsBuilderTableContainer {
    overflow-x: auto;
}

.estimationFormsFieldsBuilderTable {

    div[class^="table_header"],
    div[class^="table_body"]>div {
        >div:nth-child(1) {
            width: 5%;
        }

        >div:nth-child(2) {
            width: 25%;
        }

        >div:nth-child(3) {
            width: 25%;
        }

        >div:nth-child(4) {
            width: 25%;
        }

        >div:nth-child(5) {
            width: 20%;
        }
    }
}

@media screen and (max-width: 1024px) {
    .estimationFormsFieldsBuilderTable {

        div[class^="table_header"],
        div[class^="table_body"] {
            width: 100%;
            min-width: 1350px;
        }

        div[class^="table_header"],
        div[class^="table_body"]>div {
            >div:nth-child(1) {
                width: 5%;
            }

            >div:nth-child(2) {
                width: 25%;
            }

            >div:nth-child(3) {
                width: 25%;
            }

            >div:nth-child(4) {
                width: 20%;
            }

            >div:nth-child(5) {
                width: 25%;
            }
        }
    }
}

.viewBtn {
    display: flex;
    align-items: center;
    margin: 0 0 0 20px;

    * {
        cursor: pointer;
    }

    [dir=rtl] & {
        margin: 0 20px 0 0;
    }

    >svg {
        margin: 0 5px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 5px;
        }
    }

    >label {
        color: var(--text-4) !important;
    }
}

.filterContainer {
    margin: 10px 0;
}

.fitlerTitle {
    font-weight: 600;
    font-size: 1.2rem;
}

.filterRow {
    margin: 0 !important;
}

.filterInputWidth {
    padding: 0 0 0 0.5rem !important;

    [dir=rtl] & {
        padding: 0 0.5rem 0 0 !important;
    }
}

.inputSearchWhileType {
    position: relative;
}

.closeBtn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 7px;
    left: auto;

    [dir=rtl] & {
        left: 7px;
        right: auto;
    }
}

.titlePanel {
    display: flex;
    align-items: center;

    label {
        margin: 0 0 0 10px;
        color: var(--primary-color-2);
        font-size: 1.3rem;
        font-weight: 500;
        line-height: 1.3;

        [dir=rtl] & {
            margin: 0 10px 0 0;
        }
    }

    svg {
        width: 17px;
        height: 17px;

        path {
            fill: var(--secondary-2);
        }
    }
}

.dataActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    >button {
        margin: 0 10px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 10px;
        }
    }

    >div {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
    }

    .spinner {
        background-color: var(--pageination);
        border-radius: 7px;
    }
}

.mainTabs {
    padding: 0 !important;

    >button {
        svg {
            display: none;
        }
    }
}