$width: 180px;
$actions-background: #393939;

.modalContainer {
    height: auto !important;

    >div {
        &[class*=modalBody] {
            height: calc($width + 50px) !important;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

@mixin image {
    width: calc($width + 10px);
    height: $width;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.uploadImgContainer {
    @include image();
    border: 2px dashed var(--border-colors);
    cursor: pointer;

    svg {
        width: 64.234px;
        height: 40px;
    }

}

.imageContainer {
    @include image();
    border: 1px solid var(--border-colors);
    overflow: hidden;
    position: relative;

    >img {
        max-width: 100%;
        max-height: 100%;
    }

    &:hover {
        .bottomActionsContainer {
            bottom: 5px;
        }
    }
}

@mixin actionButton {
    height: 30px;
    width: 30px;
    background-color: $actions-background;
    border-radius: 50%;
    box-shadow: #00000070 0px 1px 10px;

    * {
        cursor: pointer;
    }

    >svg {
        width: 16px;
        height: 16px;

        path {
            fill: #fff;
        }
    }
}

.bottomActionsContainer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-around;
    bottom: -35px;
    width: 100%;
    padding: 0 4px;
    transition: .25s;

    >a {
        @include actionButton();
        display: flex;
        align-items: center;
        justify-content: center;
    }

    >button {
        @include actionButton();

        &[class*=delete] {
            background-color: var(--white);

            >svg>g>path {
                fill: var(--red) !important;
            }
        }

        @media screen and (max-width: 650px) {
            height: 22px;
            width: 22px;

            >svg {
                width: 15px;
                height: 15px;
            }
        }
    }
}

.deleteBtn {
    background-color: var(--white);

    >svg>g>path {
        fill: var(--red) !important;
    }
}