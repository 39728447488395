.estimationTransactionsRow {
    >div {
        display: flex;
        justify-content: center;

        &:nth-of-type(10) {
            >div {
                align-items: center;
            }
        }
    }
}

.priorityStatusRow {

    &::before {
        content: "";
        position: absolute;
        width: 3px;
        height: 100%;
        background-color: var(--priority-status) !important;
        left: calc((var(--checkbox-table-width) - 1px) * -1);
        right: auto;

        [dir=rtl] & {
            right: calc((var(--checkbox-table-width) - 1px) * -1);
            left: auto;
        }
    }
}


.estimationTransactionsTable {
    &[class*=cardTableMode] {
        >div {
            div[class^=table_body] {
                height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - var(--table-pagination-height) - 1px - 10px - 50px) !important;

                @media screen and (max-width: 650px) {
                    height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - var(--table-pagination-height) - 1px - 10px) !important;
                }
            }
        }
    }

    div[class^=table_body]>div {
        flex-wrap: wrap;

        >div {
            min-height: 70px;
        }
    }

    @media screen and (max-width: 1024px) {

        div[class^=table_header_checkbox],
        div[class^=table_body] {
            width: 100% !important;
            min-width: 1500px !important;
        }
    }

    @media screen and (max-width: 650px) {

        div[class^=table_header_checkbox],
        div[class^=table_body] {
            width: 100% !important;
            min-width: 100% !important;
        }
    }

    div[class^=table_header_checkbox]>span,
    div[class^=table_body]>div>div {
        >div:nth-child(1) {
            width: var(--col-1);
        }

        >div:nth-child(2) {
            width: var(--col-2);
        }

        >div:nth-child(3) {
            width: var(--col-3);
        }

        >div:nth-child(4) {
            width: var(--col-4);
        }

        >div:nth-child(5) {
            width: var(--col-5);
        }

        >div:nth-child(6) {
            width: var(--col-6);
        }

        >div:nth-child(7) {
            width: var(--col-7);
        }

        >div:nth-child(8) {
            width: var(--col-8);
        }

        >div:nth-child(9) {
            width: var(--col-9);
        }

        >div:nth-child(10) {
            width: var(--col-10);
        }

        >div:nth-child(11) {
            width: var(--col-11);
        }

        >div:nth-child(12) {
            width: var(--col-12);
        }
    }
}

.estimationTransactionsTabTable {
    div[class^=table_body] {
        height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - var(--table-header-height) - var(--table-pagination-height) - 50px - 20px) !important;
    }

    &[class*=cardTableMode] {
        >div {
            div[class^=table_body] {
                @media screen and (max-width: 650px) {
                    height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - var(--table-pagination-height) - 1px - 20px - 40px) !important;
                }
            }
        }
    }
}

.actionRow {
    display: flex;
    align-items: center;
    height: 40px;

    >button {
        position: relative;
        margin: 0 10px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 10px;
        }

        >svg {
            width: 17px;
            height: 17px;
        }

        @media screen and (max-width: 650px) {
            margin: 0 25px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 25px;
            }

            &:last-of-type {
                margin: 0;
            }
        }
    }
}

.mobileAction {
    >div {
        &:nth-of-type(2) {
            display: none !important;

            @media screen and (max-width: 650px) {
                display: flex !important;
                margin-top: 5px !important;
            }
        }

        &:nth-of-type(3) {
            display: none !important;

            @media screen and (max-width: 650px) {
                display: flex !important;
                margin-top: 5px !important;
            }
        }
    }
}

.notifiction {
    background-color: var(--error);
    border-radius: 50%;
    color: var(--white);
    font-size: 10px;
    height: 16px;
    line-height: 19px;
    position: absolute;
    right: -6px;
    top: -12px;
    width: 16px;
}

.column {
    display: flex;
    flex-direction: column;
    width: 100%;

    >label {

        &:nth-of-type(1) {
            max-height: 30px;
            font-weight: 600;
            margin-bottom: 3px;
            color: var(--text-2);
        }

        &:nth-of-type(2) {
            color: var(--text-3);
            width: 100%;
        }

        @media screen and (max-width: 650px) {
            text-align: start !important;
        }
    }

}

.columnSm {
    display: none;

    @media screen and (max-width: 650px) {
        display: flex !important;
        flex-direction: column;
        width: 100%;

        >label {
            text-align: start !important;

            &:nth-of-type(1) {
                max-height: 30px;
                font-weight: 600;
                color: var(--text-2);
            }

            &:nth-of-type(2) {
                color: var(--text-3);
                width: 100%;
            }
        }
    }
}

.code {
    color: var(--secondary-2) !important;
}

.dateRow {
    display: flex;
    align-items: center;
    justify-content: center;
}

.time {
    color: var(--primary-color-2);
}

.days {
    display: flex;
    align-items: center;
    margin: 0 5px;

    >span {
        color: var(--primary-color-2);

        &:nth-of-type(1) {
            margin: 0 3px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 3px;
            }
        }
    }
}

.customer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.customerLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    max-width: 30px;
    max-height: 30px;
    margin: 0 10px 0 0;
    overflow: hidden;

    [dir=rtl] & {
        margin: 0 0 0 10px;
    }

    >img {
        max-width: 100%;
        max-height: 100%;
    }

    >svg {
        width: 17px;
        height: 17px;
    }

    @media screen and (max-width: 650px) {
        display: none;
    }
}

.town {
    align-items: flex-start;
    width: calc(100% - 30px - 10px);

    label {
        width: 100%;
    }
}

.viewBtn {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 8px !important;

    >svg {
        width: 17px;
        height: 17px;
        margin: 0 5px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 5px;
        }

        path {
            fill: var(--secondary-1);
        }
    }

    >label {
        color: var(--text-4) !important;
    }
}

.actionsSpinnerContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
    background-color: var(--white);

    >div {
        height: 100%;
        width: 100%;
    }
}

.actionsSpinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 100;

    &::after {
        border: 3px solid var(--active-filter-btn) !important;
        border-top-color: transparent !important;
    }

    display: flex !important;
}

.actionsEstimationTransactions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;

    >div {
        display: flex;
        align-items: center;

        &:nth-of-type(1) {

            margin: 0 0 0 15px;

            [dir=rtl] & {
                margin: 0 15px 0 0;
            }

            >button {
                margin: 0 8px 0 0;

                [dir=rtl] & {
                    margin: 0 0 0 8px;
                }

                svg {
                    width: 15px;
                    height: 15px;

                    path {
                        fill: #668fff;
                    }
                }
            }

            .specialNoteBtn {
                width: 15px;
                height: 15px;
                margin: 0 8px 0 0;

                [dir=rtl] & {
                    margin: 0 0 0 8px;
                }

                >button {
                    position: relative;

                    svg {
                        width: 15px;
                        height: 15px;
                    }

                    >span {
                        position: absolute;
                        background-color: var(--error);
                        top: -10px;
                        right: -6px;
                        left: auto;
                        width: 17px;
                        height: 17px;
                        font-size: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: var(--white);
                        border-radius: 50%;
                        border: 1px solid var(--white);

                        [dir=rtl] & {
                            left: -6px;
                            right: auto;
                        }
                    }
                }

            }
        }

        &:nth-of-type(2) {
            width: calc(100% - 30px - 30px - 30px);

            >button {
                &[class*=mainbutton] {
                    width: calc(100% - 23px - 10px - 10px);
                    justify-content: flex-start;
                }
            }
        }
    }
}

.executeBtnMobile {
    display: none;

    @media screen and (max-width: 650px) {
        display: flex;
    }

    >div {
        >div {
            &[class *=loadingContainer] {
                margin: 0 3px;

                &::after {
                    border: 3px solid var(--white) !important;
                    border-top-color: transparent !important;
                }
            }
        }
    }
}

.settingBtn {
    margin: 0 10px;

    &:hover {
        >svg {
            g {
                fill: var(--secondary-1);
            }
        }
    }
}

.actionsMenuContainer {
    padding: 10px 15px;

    >li {
        &:first-of-type {
            border-top: 0;
        }
    }
}

.actionsMenuItem {
    padding: 0 !important;
    background-color: var(--white) !important;

    &:hover {
        background-color: var(--primary-hover) !important;
    }

    &:after {
        display: none;
    }
}

@mixin Actions-Btn {
    width: 200px;
    height: 40px;
    margin: 0;
    border-radius: 0;
    display: flex;
    align-items: center;

    * {
        cursor: pointer;
    }

    >label {
        width: calc(100% - 25px);
        display: flex;
        align-items: center;
        justify-content: flex-start !important;
        font-family: var(--font-en);
    }

    >span {
        margin: 0 10px 0 0;

        svg {
            width: 15px;
            height: 15px;
        }
    }
}

.actionBtn {
    @include Actions-Btn();

}

.actionBtnAr {
    @include Actions-Btn();
    flex-direction: row-reverse;

    >label {
        justify-content: flex-end !important;
        margin: 0 10px 0 0;
        font-family: var(--font-ar);
    }
}


.stepsContaner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-bottom: 5px;

    @media screen and (max-width: 650px) {
        display: none;
    }
}

@mixin Step {
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.step {
    @include Step();
    background-color: var(--text-3);
}

.activeStep {
    @include Step();
    background-color: var(--primary-color-2);
}

.clickable {
    cursor: pointer;

    * {
        cursor: pointer;
    }
}

.listingStatus {
    padding: 5px 10px;
    border-radius: 5px;
    max-height: initial !important;
    min-height: 30px;

    &[class*=active],
    &[class*=active-exec] {
        background: #2e7d32 !important;
        min-width: 100px;
        position: relative;

        span {
            &:last-of-type {
                align-items: center;
                justify-content: center;

                svg {
                    width: 17px;
                    height: 17px;
                    margin: 0 10px 0 0;

                    [dir=rtl] & {
                        margin: 0 0 0 10px;
                    }

                    path {
                        fill: #fff;
                    }
                }

                @media screen and (max-width: 650px) {
                    align-items: flex-start;
                    justify-content: flex-start;
                }
            }
        }

        @media screen and (max-width: 650px) {
            background-color: transparent !important;

            span {
                text-align: start !important;
            }

            svg {
                display: none !important;
            }
        }
    }

    &[class*=active-exec] {
        span {
            &:first-of-type {
                display: none !important;
            }

            &:last-of-type {
                display: flex !important;

                >svg {
                    display: none;
                }

                >div {
                    margin: 0 5px;

                    >div::after {
                        border-color: #fff;
                        border-top-color: transparent;
                    }
                }
            }
        }
    }

    >span {
        width: calc(100% - 8px - 3px);

        @media screen and (max-width: 650px) {
            width: calc(100% - 8px - 3px - 3px);
        }
    }
}

.rowEstimationTransactions {
    display: none;
}

.lblRowEstimationTransactions {
    flex-direction: row;
    display: none;

    @media screen and (max-width: 650px) {
        display: flex;
        width: 100%;

        >label {
            &:nth-of-type(1) {
                width: 100px;
            }

            &:nth-of-type(2) {
                width: calc(100% - 100px - 5px);
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                text-align: start !important;
            }
        }

        .rowEstimationTransactions {
            display: flex;
            width: calc(100% - 100px - 5px);
        }
    }
}

.lblRowStatusEstimationTransactions {
    display: none;

    @media screen and (max-width: 650px) {
        display: flex;
        width: 100%;

        >label {
            width: 100px;
        }

        >div {
            width: calc(100% - 100px - 5px);
            align-items: flex-start !important;
        }
    }
}

.mBtoom {
    @media screen and (max-width: 650px) {
        margin-top: 10px;
    }
}

.isMobile {
    @media screen and (max-width: 650px) {
        display: none !important;
    }
}

.executeContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;

    >label {
        max-width: 90% !important;
        min-width: 130px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        font-weight: 600;
        margin-bottom: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    >button {
        >label {
            display: none;
        }
    }

    @media screen and (max-width: 650px) {
        display: flex;
        align-items: flex-start;

        >label {
            width: 100% !important;
            max-width: 100% !important;
            display: block !important;
            padding: 5px 0 !important;

            span {
                &:nth-of-type(1) {
                    margin-bottom: 10px;
                }
            }
        }

        >button {
            background-color: var(--secondary-2);
            padding: 7px 5px;
            min-width: 100px;

            >label {
                display: block;
                margin: 0 0 0 10px;
                color: var(--white) !important;

                [dir=rtl] & {
                    margin: 0 10px 0 0;
                }
            }

            >svg {
                width: 15px;
                height: 15px;

                path {
                    fill: var(--white) !important;
                }
            }
        }
    }
}

.dateTimer {
    font-family: 'digital';
    font-size: 15px;
}

.digitalClockColon {
    animation: colon 1s infinite linear;
}

@keyframes colon {
    from {
        color: inherit;
    }

    to {
        color: transparent;
    }
}

.groupSeperator {
    border-top: 1px solid var(--border-colors);
}

// *******************************

.introBannerVdoPlayBtn {
    position: relative;
    min-height: 8px;
    min-width: 8px;
    text-align: center;
    // margin: -30px 0 0 -30px;
    border-radius: 100px;
    z-index: 1;
    background-color: var(--white) !important;
    background-image: var(--white);
    margin: 0 3px 0 0;

    [dir=rtl] & {
        margin: 0 0 0 3px;
    }

    @media screen and (max-width: 650px) {
        background-color: var(--secondary-1) !important;
        background-image: var(--secondary-1);
        margin: 0 10px;

        [dir=rtl] & {
            margin: 0 10px;
        }
    }
}

@media screen and (max-width: 650px) {
    .statusLblSm {
        padding: 0 !important;
    }
}

.ripple {
    background-color: var(--white) !important;
    background-image: var(--white);

    @media screen and (max-width: 650px) {
        background-color: var(--secondary-1) !important;
        background-image: var(--secondary-1);
    }
}

.introBannerVdoPlayBtn .ripple {
    position: absolute;
    width: 20px;
    height: 20px;
    z-index: -1;
    left: 50%;
    top: 50%;
    opacity: 0;
    margin: -10px 0 0 -10px;
    border-radius: 100px;
    -webkit-animation: ripple 1.8s infinite;
    animation: ripple 1.8s infinite
}

@-webkit-keyframes ripple {
    0% {
        opacity: 1;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes ripple {
    0% {
        opacity: 1;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.introBannerVdoPlayBtn {
    >div {
        &:nth-of-type(2) {
            animation-delay: .3s;
            -webkit-animation-delay: .3s;
        }
    }
}

.introBannerVdoPlayBtn {
    >div {
        &:nth-of-type(3) {
            animation-delay: .6s;
            -webkit-animation-delay: .6s;
        }
    }
}

.spinner {
    background-color: var(--pageination);
    border-radius: 7px;
    margin: 0 !important;
}

// *******************************

.qrCodeButton {
    position: relative;

    >span {
        position: absolute;
        background-color: var(--error);
        top: -3px;
        left: -3px;
        right: auto;
        width: 7px;
        height: 7px;
        background-color: var(--error);
        border-radius: 50%;

        [dir=rtl] & {
            right: -3px;
            left: auto;
        }

        &[class=active] {
            background-color: var(--success);
        }
    }
}

.timerContainer {
    >span>svg {
        width: 13px;
        height: 22px;
    }
}

.clientContainer {
    display: flex;
    align-items: center;

    >span {
        position: absolute;
        top: 10px;
        left: 20px;
        right: auto;
        width: 32px;
        height: 32px;

        [dir=rtl] & {
            right: 20px;
            left: auto;
        }

        img {
            max-width: 100%;
            height: 100%;
        }
    }

    >label {
        width: 100%;
        text-align: start !important;
    }
}

.estimationQualityBtn {
    display: flex;
    align-items: center;

    svg {
        width: 20px;
        height: 20px;
    }

    .starNotifiction {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin: 2px 3px 0;
        color: var(--text-3);
        
        [dir=rtl] & {
            margin: 5px 3px 0;
        }
    }
}