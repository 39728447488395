// ******************locationGroupsFinancial Table**********************

.locationGroupsFinancialRow {
  > div {
    display: flex;
    justify-content: center;
  }
}

.locationGroupsFinancialTableContainer {
  overflow-x: auto;
}

.locationGroupsFinancialTable {
  div[class^="v_table_header"],
  div[class^="v_table_body"] {
    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      min-width: 900px;
    }
  }

  div[class^="v_table_body"] {
    height: calc(
      var(--model-dialog-height) - var(--modal-with-tabs-header-height) -
        var(--table-header-actions-height) -
        var(--table-header-actions-margin-bottom) - var(--table-header-height) -
        10px - var(--modal-with-tabs-footer-height) - 10px
    ) !important;

    @media screen and (max-width: 768px) {
      height: calc(
        var(--model-dialog-height-sm) - var(--modal-with-tabs-header-height) -
          var(--table-header-actions-height) -
          var(--table-header-actions-margin-bottom) - var(--table-header-height) -
          var(--modal-with-tabs-footer-height) - 10px
      ) !important;
    }
  }

  div[class^="v_table_header"],
  div[class^="v_table_row"] {
    display: flex;
    align-items: center;

    > div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }

      &:nth-child(5) {
        width: var(--col-5);
      }
      &:nth-child(6) {
        width: var(--col-6);
      }
    }
  }
}

.installations {
  width: 80%;
  height: 37px;
}

// ******************locationGroupsFinancial Table**********************
// ******************locationsFinancialIntegration Table**********************

.locationsFinancialIntegrationRow {
  > div {
    display: flex;
    justify-content: center;
  }
}

.locationsFinancialIntegrationTableContainer {
  overflow-x: auto;
}

.locationsFinancialIntegrationTable {
  div[class^="v_table_header"],
  div[class^="v_table_body"] {
    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      min-width: 900px;
    }
  }

  div[class^="v_table_body"] {
    height: calc(
      var(--model-dialog-height) - var(--modal-with-tabs-header-height) -
        var(--table-header-actions-height) -
        var(--table-header-actions-margin-bottom) - var(--table-header-height) -
        10px - var(--modal-with-tabs-footer-height) - 10px
    ) !important;

    @media screen and (max-width: 768px) {
      height: calc(
        var(--model-dialog-height-sm) - var(--modal-with-tabs-header-height) -
          var(--table-header-actions-height) -
          var(--table-header-actions-margin-bottom) - var(--table-header-height) -
          var(--modal-with-tabs-footer-height) - 10px
      ) !important;
    }
  }

  div[class^="v_table_header"],
  div[class^="v_table_row"] {
    display: flex;
    align-items: center;

    > div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }

      &:nth-child(5) {
        width: var(--col-5);
      }

      &:nth-child(6) {
        width: var(--col-6);
      }
    }
  }
}

// ******************locationsFinancialIntegration Table**********************

.checkBank {
  margin-top: 31.81px;
}

.additionalItemsMultiInsart {
  @media screen and (max-width: 1024px) {
    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      min-width: 700px !important;
    }
  }
}
