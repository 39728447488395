@import './_common.scss';
@import './_colors.scss';
@import './_table.scss';
@import './_fonts.css';
@import './_form.scss';
@import './_modal.scss';
@import './_date-pickr.scss';


html {
    font-size: 12px;
    overflow: hidden;
    box-sizing: border-box;
    font-weight: 500;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    overflow: hidden;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
    margin: 0;
    padding: 0;
    font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
}

ol,
ul {
    list-style: none;
}

img {
    max-width: 100%;
    height: auto;
}

a {
    color: inherit;
    text-decoration: none;
}

input[type=text],
input[type=password],
input[type=number],
textarea,
select {
    outline: none;
    margin: 0;
    padding: 0;

    &:focus {
        box-shadow: 0 0 0 1px rgba(#2C4FD0, .5);
    }

    &::placeholder {
        font-weight: 500;
        color: var(--text-3);
        font-family: var(--font-en);

        [dir=rtl] & {
            font-family: var(--font-ar);
        }
    }

    &::-moz-placeholder {
        font-weight: 500;
        color: var(--text-3);
        font-family: var(--font-en);

        [dir=rtl] & {
            font-family: var(--font-ar);
        }
    }

    &::-webkit-input-placeholde {
        font-family: var(--font-en);
        font-weight: 500;
        color: var(--text-3);

        [dir=rtl] & {
            font-family: var(--font-ar);
        }
    }

    &::-ms-input-placeholder {
        font-weight: 500;
        color: var(--text-3);
        font-family: var(--font-en);

        [dir=rtl] & {
            font-family: var(--font-ar);
        }
    }
}

textarea {
    font-family: var(--font-en);

    [dir=rtl] & {
        font-family: var(--font-ar);
    }
}

input[type=radio]:focus-visible {
    outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}


button {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border-radius: 5px;
    font-weight: 500;
    font-family: var(--font-en);

    [dir=rtl] & {
        font-family: var(--font-ar);
    }
}

.select-menu {
    >div:first-of-type {
        border-width: 0;
    }

    input {
        &:focus {
            box-shadow: none;
        }
    }
}

// --------------------------------------scrollbar
/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar:horizontal {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
    /*#f1f1f1; */
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #8888883d;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #888;
}

//------------- Lightgallery
.lg-backdrop {
    background-color: rgb(0 0 0 / 50%) !important;
}

.lg-outer .lg-thumb-outer {
    padding: 0 8px;
}

.lg-outer .lg-thumb-item.active {
    border-color: var(--secondary-1) !important;
}

#lg-actual-size,
.lg-download {
    display: none !important;
}

.lg-outer .lg-thumb-item img {
    padding: 2px;
    object-fit: contain !important;
}

label {
    font-weight: 500;
}
