.creditNotesforCustomersEstimationRow {
  >div {
    display: flex;
    justify-content: center;
  }
}

.creditNotesforCustomersEstimationTable {

  div[class^=table_header_checkbox]>span,
  div[class^=table_body]>div>div {
    >div:nth-child(1) {
      width: var(--col-1);
    }

    >div:nth-child(2) {
      width: var(--col-2);
    }

    >div:nth-child(3) {
      width: var(--col-3);
    }

    >div:nth-child(4) {
      width: var(--col-4);
    }

    >div:nth-child(5) {
      width: var(--col-5);
    }

    >div:nth-child(6) {
      width: var(--col-6);
    }

    >div:nth-child(7) {
      width: var(--col-7);
    }

    >div:nth-child(8) {
      width: var(--col-8);
    }

    >div:nth-child(9) {
      width: var(--col-9);
    }

    >div:nth-child(10) {
      width: var(--col-10);
    }
  }
}