.container {
  position: relative;
  display: flex;
  width: 100%;
  height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - var(--main-padding-V));
}

.linesContainer {
  display: flex;
  flex-direction: column;
  transition: 0.3s width;
  width: calc(100% - var(--summary-width-trx));
  background-color: var(--bg);
  border-radius: var(--common-border-radius);

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin: 0 !important;
  }
}

.toggleMode {
  height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - var(--main-padding-V)) !important;
  padding: 20px !important;
  margin-top: 0 !important;
  border-radius: 10px !important;
}

.insertBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  >button {
    &:nth-of-type(1) {
      margin: 0 10px 0 0;

      [dir="rtl"] & {
        margin: 0 0 0 10px;
      }
    }
  }

  @media screen and (max-width: 650px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

// ******************propertyTypeFeesUpon Table**********************
.propertyTypeFeesUponRow {
  >div {
    display: flex;
    justify-content: center;
  }
}

.propertyTypeFeesUponTable {
  div[class^=v_table_body] {
    height: 160px !important;
  }

  div[class^=v_table_header],
  div[class^=v_table_row] {
    display: flex;
    align-items: center;

    >div {
      text-align: center;
      display: flex;
      justify-content: center;
      overflow: hidden;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }

      &:nth-child(5) {
        width: var(--col-5);
      }

      &:nth-child(6) {
        width: var(--col-6);
      }

      &:nth-child(7) {
        width: var(--col-7);
      }
    }
  }
}

// ******************propertyTypeFeesUpon Table**********************

// ******************locationFessUpon Table**********************
.locationFessUponRow {
  >div {
    display: flex;
    justify-content: center;
    margin: 0 5px 0 0;

    [dir="rtl"] & {
      margin: 0 0 0 5px;
    }
  }
}

.locationTableInputs {
  display: flex;
  align-items: center;
  width: calc(100% - 200px);

  >div {
    width: 30%;
    margin: 0 0 0 5px;

    [dir="rtl"] & {
      margin: 0 5px 0 0;
    }
  }

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;

    >div {
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.locationTableBtns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding-top: 31.81px;
}

.locationFessUponTable {
  div[class^=v_table_body] {
    height: 160px !important;
  }

  div[class^=v_table_header],
  div[class^=v_table_row] {
    display: flex;
    align-items: center;

    >div {
      text-align: center;
      display: flex;
      justify-content: center;
      overflow: hidden;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }

      &:nth-child(5) {
        width: var(--col-5);
      }

      &:nth-child(6) {
        width: var(--col-6);
      }

      &:nth-child(7) {
        width: var(--col-7);
      }

      &:nth-child(8) {
        width: var(--col-8);
      }

      &:nth-child(9) {
        width: var(--col-9);
      }
    }
  }
}

// ******************locationFessUpon Table**********************

// ******************customFees Table**********************
.customFeesRow {
  >div {
    display: flex;
    justify-content: center;
    margin: 0 5px 0 0;

    [dir="rtl"] & {
      margin: 0 0 0 5px;
    }
  }
}

.customFeesTable {

  @media screen and (max-width: 1024px) {

    div[class^=v_table_header],
    div[class^=v_table_body] {
      width: 100%;
      min-width: 4000px !important;
    }
  }

  div[class^=v_table_body] {
    height: calc(40px * 14) !important;
  }

  div[class^=v_table_header],
  div[class^=v_table_row] {
    display: flex;
    align-items: center;

    >div {
      overflow: hidden;
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }

      &:nth-child(5) {
        width: var(--col-5);
      }

      &:nth-child(6) {
        width: var(--col-6);
      }

      &:nth-child(7) {
        width: var(--col-7);
      }

      &:nth-child(8) {
        width: var(--col-8);
      }

      &:nth-child(9) {
        width: var(--col-9);
      }

      &:nth-child(10) {
        width: var(--col-10);
      }

      &:nth-child(11) {
        width: var(--col-11);
      }

      &:nth-child(12) {
        width: var(--col-12);
      }

      &:nth-child(13) {
        width: var(--col-13);
      }

      &:nth-child(14) {
        width: var(--col-14);
      }

      &:nth-child(15) {
        width: var(--col-15);
      }

      &:nth-child(16) {
        width: var(--col-16);
      }

      &:nth-child(17) {
        width: var(--col-17);
      }

      &:nth-child(18) {
        width: var(--col-18);
      }

      &:nth-child(19) {
        width: var(--col-19);
      }

      &:nth-child(20) {
        width: var(--col-20);
      }

      &:nth-child(21) {
        width: var(--col-21);
      }

      &:nth-child(22) {
        width: var(--col-22);
      }

      &:nth-child(23) {
        width: var(--col-23);
      }

      &:nth-child(24) {
        width: var(--col-24);
      }

      &:nth-child(25) {
        width: var(--col-25);
      }
    }
  }
}

// ******************customFees Table**********************
.hideElement {
  display: none;
}

.exportButton{
  position: relative;
  width: 25px;
  height: 25px;
}

.spinnerButton {
  background-color: white;
  margin: 0 !important;
  width: 25px !important;
  height: 25px !important;
  border-radius: 5px;

  &::after {
      width: 20px !important;
      height: 20px !important;
      border-left-color: green;
      border-right-color: green;
  }
}