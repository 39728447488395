.homeBtnStyle {
    >div {
        >span {
            background-color: rgba(58, 40, 72, .3) !important;

            >svg {
                g {
                    fill: #C451F5 !important;
                }
            }
        }
    }
}

.favBtnStyle {
    >div {
        >span {
            background-color: rgba(67, 72, 40, .3) !important;

            >svg {
                path {
                    g {
                        fill: #0AE796 !important;
                    }
                }
            }
        }
    }
}

.wfBtnStyle {
    >div {
        >span {
            background-color: rgba(49, 88, 88, .3) !important;

            >svg {
                g {
                    fill: #FFF01E !important;
                }
            }
        }
    }
}

.dashbordStyle {
    >div {
        >span {
            background-color: rgba(239, 3, 135, 0.23) !important;

            >svg {
                g {
                    fill: rgb(239, 3, 135) !important;
                }
            }
        }
    }
}

.inboxStyle {
    >div {
        >span {
            background-color: rgb(73, 143, 255, 0.23) !important;

            >svg {
                g {
                    fill: rgb(73, 143, 255) !important;
                }
            }
        }
    }
}

.outboxStyle {
    >div {
        >span {
            background-color: rgba(250, 185, 52, 0.23) !important;

            >svg {
                g {
                    fill: rgb(250, 185, 52) !important;
                }
            }
        }
    }
}

.moduleOne {
    >span {
        >span {
            background-color: rgba(255, 81, 149, 0.25);

            >svg {
                box-shadow: 0px 0px 20px 0px #ff5195;
            }
        }
    }
}

.moduleTwo {
    >span {
        >span {
            background-color: rgba(6, 211, 212, 0.25);

            >svg {
                box-shadow: 0px 0px 20px 0px #06d3d4;
            }
        }
    }
}

.moduleThree {
    >span {
        >span {
            background-color: rgba(72, 184, 224, 0.25);

            >svg {
                box-shadow: 0px 0px 20px 0px #48b8e0;
            }
        }
    }
}


.moduleFour {
    >span {
        >span {
            background-color: rgba(168, 222, 79, 0.25);

            >svg {
                box-shadow: 0px 0px 20px 0px #a8de4f;
            }
        }
    }
}

.moduleFive {
    >span {
        >span {
            background-color: rgba(0, 113, 255, 0.25);

            >svg {
                box-shadow: 0px 0px 20px 0px #0071ff;
            }
        }
    }
}

.moduleSix {
    >span {
        >span {
            background-color: rgba(255, 182, 7, 0.25);

            >svg {
                box-shadow: 0px 0px 20px 0px #ffb607;
            }
        }
    }
}

.moduleSubSix {
    >span {
        >span {
            background-color: rgba(33, 255, 48, 0.25);

            >svg {
                box-shadow: 0px 0px 20px 0px #21ff30;
            }
        }
    }
}

.moduleSubSixTwo {
    >span {
        >span {
            background-color: rgba(241, 99, 77, 0.25);

            >svg {
                box-shadow: 0px 0px 20px 0px #f1634d;
            }
        }
    }
}

.moduleSeven {
    >span {
        >span {
            background-color: rgba(162, 83, 231, 0.25);

            >svg {
                box-shadow: 0px 0px 20px 0px #a253e7;
            }
        }
    }
}

.valueMateMenu {
    >div {
        &[class*=containerTabs] {
            >button {
                &[class*=moduleBtn] {
                    &[class*=active] {
                        background-color: #47337C !important;
                    }
                }
            }

            >div {
                &[class*=tabContent] {
                    >div {
                        >div {
                            padding: 7px 0 !important;
                        }
                    }
                }
            }
        }
    }
}