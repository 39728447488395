.reportName {
  height: 60px;
  display: flex;
  align-items: center;
  border-top: 1px solid var(--border-colors);
  > div {
    width: 33%;
    @media screen and (max-width: 1024px) {
      width: 75% !important;
    }
    @media screen and (max-width: 650px) {
      width: 100% !important;
    }
  }
}
.headerAction {
  @media screen and (max-width: 650px) {
    height: auto !important;
    > div {
      &[class*="headerSearchPanel"] {
        flex-wrap: wrap;
        > div {
          width: 100% !important;
          margin-bottom: 10px;
        }
      }
    }
  }
}
