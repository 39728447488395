@mixin Row {
  >div {
    display: flex;
    justify-content: center;
  }
}

.employeesInspectorsReportTable {
  div[class^=table_body] {
    height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - 60px - var(--table-header-height) - var(--table-pagination-height) - var(--table-total-height)) !important;

    @media screen and (max-width: 650px) {
      height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - 60px - var(--table-header-height) - var(--table-pagination-height)) !important;
    }
  }

  div[class^=table_header],
  div[class^=table_body]>div,
  div[class^=table-total] {
    >div:nth-child(1) {
      width: var(--col-1);
    }

    >div:nth-child(2) {
      width: var(--col-2);
    }

    >div:nth-child(3) {
      width: var(--col-3);
    }

    >div:nth-child(4) {
      width: var(--col-4);
    }

    >div:nth-child(5) {
      width: var(--col-5);
    }

    >div:nth-child(6) {
      width: var(--col-6);
    }
  }
}

.employeesInspectorsReportRow {
  @include Row();
}

.employeesInspectorsAccordingRegionCityReportTable {

  div[class^=table_body] {
    height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - 60px - var(--table-header-height) - var(--table-pagination-height) - var(--table-total-height)) !important;

    @media screen and (max-width: 650px) {
      height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - 60px - var(--table-header-height) - var(--table-pagination-height)) !important;
    }
  }

  div[class^=table_header],
  div[class^=table_body]>div,
  div[class^=table-total] {
    >div:nth-child(1) {
      width: var(--col-1);
    }

    >div:nth-child(2) {
      width: var(--col-2);
    }

    >div:nth-child(3) {
      width: var(--col-3);
    }

    >div:nth-child(4) {
      width: var(--col-4);
    }

    >div:nth-child(5) {
      width: var(--col-5);
    }

    >div:nth-child(6) {
      width: var(--col-6);
    }

    >div:nth-child(7) {
      width: var(--col-7);
    }

    >div:nth-child(8) {
      width: var(--col-8);
    }
  }
}

.employeesInspectorsAccordingRegionCityReportRow {
  @include Row();
}

.CustomersReportTable {
  div[class^=table_body] {
    height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - 60px - var(--table-header-height) - var(--table-pagination-height) - var(--table-total-height)) !important;

    @media screen and (max-width: 650px) {
      height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - 60px - var(--table-header-height) - var(--table-pagination-height)) !important;
    }
  }

  div[class^=table_header],
  div[class^=table_body]>div,
  div[class^=table-total] {
    >div:nth-child(1) {
      width: var(--col-1);
    }

    >div:nth-child(2) {
      width: var(--col-2);
    }

    >div:nth-child(3) {
      width: var(--col-3);
    }

    >div:nth-child(4) {
      width: var(--col-4);
    }
  }
}

.CustomersReportRow {
  @include Row();
}

.customersAccordingRegionCityTable {
  div[class^=table_body] {
    height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - 60px - var(--table-header-height) - var(--table-pagination-height) - var(--table-total-height)) !important;

    @media screen and (max-width: 650px) {
      height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - 60px - var(--table-header-height) - var(--table-pagination-height)) !important;
    }
  }

  div[class^=table_header],
  div[class^=table_body]>div,
  div[class^=table-total] {
    >div:nth-child(1) {
      width: var(--col-1);
    }

    >div:nth-child(2) {
      width: var(--col-2);
    }

    >div:nth-child(3) {
      width: var(--col-3);
    }

    >div:nth-child(4) {
      width: var(--col-4);
    }

    >div:nth-child(5) {
      width: var(--col-5);
    }

    >div:nth-child(6) {
      width: var(--col-6);
    }
  }
}

.customersAccordingRegionCityRow {
  @include Row();
}

.regionsAccordingPropertysTypeReportTable {
  div[class^=table_body] {
    height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - 60px - var(--table-header-height) - var(--table-pagination-height) - var(--table-total-height)) !important;

    @media screen and (max-width: 650px) {
      height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - 60px - var(--table-header-height) - var(--table-pagination-height)) !important;
    }
  }

  div[class^=table_header],
  div[class^=table_body]>div,
  div[class^=table-total] {
    >div:nth-child(1) {
      width: var(--col-1);
    }

    >div:nth-child(2) {
      width: var(--col-2);
    }

    >div:nth-child(3) {
      width: var(--col-3);
    }

    >div:nth-child(4) {
      width: var(--col-4);
    }

    >div:nth-child(5) {
      width: var(--col-5);
    }

    >div:nth-child(6) {
      width: var(--col-6);
    }

    >div:nth-child(7) {
      width: var(--col-7);
    }

    >div:nth-child(8) {
      width: var(--col-8);
    }
  }
}

.regionsAccordingPropertysTypeReportRow {
  @include Row();
}

.inspectorsEmployeesAccordingTaskReportTable {
  div[class^=table_body] {
    height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - 60px - var(--table-header-height) - var(--table-pagination-height) - var(--table-total-height)) !important;

    @media screen and (max-width: 650px) {
      height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - 60px - var(--table-header-height) - var(--table-pagination-height)) !important;
    }
  }

  div[class^=table_header],
  div[class^=table_body]>div,
  div[class^=table-total] {
    >div:nth-child(1) {
      width: var(--col-1);
    }

    >div:nth-child(2) {
      width: var(--col-2);
    }

    >div:nth-child(3) {
      width: var(--col-3);
    }

    >div:nth-child(4) {
      width: var(--col-4);
    }

    >div:nth-child(5) {
      width: var(--col-5);
    }

    >div:nth-child(6) {
      width: var(--col-6);
    }
  }
}

.inspectorsEmployeesAccordingTaskReportRow {
  @include Row();
}

.employeesInspectorsReportAccordingCustomersReportTable {
  div[class^=table_body] {
    height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - 60px - var(--table-header-height) - var(--table-pagination-height) - var(--table-total-height)) !important;

    @media screen and (max-width: 650px) {
      height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - 60px - var(--table-header-height) - var(--table-pagination-height)) !important;
    }
  }

  div[class^=table_header],
  div[class^=table_body]>div,
  div[class^=table-total] {
    >div:nth-child(1) {
      width: var(--col-1);
    }

    >div:nth-child(2) {
      width: var(--col-2);
    }

    >div:nth-child(3) {
      width: var(--col-3);
    }

    >div:nth-child(4) {
      width: var(--col-4);
    }

    >div:nth-child(5) {
      width: var(--col-5);
    }

    >div:nth-child(6) {
      width: var(--col-6);
    }

    >div:nth-child(7) {
      width: var(--col-7);
    }
  }
}

.employeesInspectorsReportAccordingCustomersReportRow {
  @include Row();
}

.employeesInspectorsExecutionPercentageReportTable {
  div[class^=table_body] {
    height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - 60px - var(--table-header-height) - var(--table-pagination-height) - var(--table-total-height)) !important;

    @media screen and (max-width: 650px) {
      height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - 60px - var(--table-header-height) - var(--table-pagination-height)) !important;
    }
  }

  div[class^=table_header],
  div[class^=table_body]>div,
  div[class^=table-total] {
    >div:nth-child(1) {
      width: var(--col-1);
    }

    >div:nth-child(2) {
      width: var(--col-2);
    }

    >div:nth-child(3) {
      width: var(--col-3);
    }

    >div:nth-child(4) {
      width: var(--col-4);
    }

    >div:nth-child(5) {
      width: var(--col-5);
    }

    >div:nth-child(6) {
      width: var(--col-6);
    }
  }
}

.employeesInspectorsExecutionPercentageReportRow {
  @include Row();
}

.lblRowEstimationReports {
  display: none;

  @media screen and (max-width: 650px) {
    display: flex;
    width: 100%;

    >label {
      &:nth-of-type(1) {
        width: 120px;
      }

      &:nth-of-type(2) {
        width: calc(100% - 120px - 5px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: start !important;
      }
    }
  }
}

.lblRowStatusEstimationReports {
  display: none;

  @media screen and (max-width: 650px) {
    display: flex;
    width: 100%;

    >label {
      &:nth-of-type(1) {
        width: 120px;
      }
    }
  }
}