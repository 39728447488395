$print-dialog-height: 190px;
$action-dialog-height: 200px;
$qrcode-dialog-height: 350px;
$headerTrxHeight: 60px;
$margin: 15px;

.printDialog {
  height: auto !important;

  > div {
    &[class*="modalBody"] {
      height: calc(
        100% - var(--modal-with-tabs-header-height) -
          var(--modal-with-tabs-footer-height)
      ) !important;
    }
  }
}

.actionDailog {
  height: auto !important;

  > div {
    &[class*="modalBody"] {
      height: calc(
        100% - var(--modal-with-tabs-header-height) -
          var(--modal-with-tabs-footer-height)
      ) !important;
    }
  }
}

.recoveryDailog {
  @media screen and (max-width: 650px) {
    height: auto !important;

    > div {
      &[class*="modalBody"] {
        height: calc(
          100% - var(--modal-with-tabs-header-height) -
            var(--modal-with-tabs-footer-height)
        ) !important;
      }
    }
  }
}

.estimationRatingDialog {
  @media screen and (max-width: 650px) {
    height: auto !important;

    > div {
      &[class*="modalBody"] {
        height: calc(
          100% - var(--modal-with-tabs-header-height) -
            var(--modal-with-tabs-footer-height)
        ) !important;
      }
    }
  }
}

.incompleteSaveDialog {
  width: 40vw !important;
  height: auto !important;

  > div {
    &[class*="modalBody"] {
      height: calc(
        100% - var(--modal-with-tabs-header-height) -
          var(--modal-with-tabs-footer-height)
      ) !important;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 60vw !important;
    height: auto !important;

    > div {
      &[class*="modalBody"] {
        height: calc(
          100% - var(--modal-with-tabs-header-height) -
            var(--modal-with-tabs-footer-height)
        ) !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 100% !important;
    height: 100% !important;

    > div {
      &[class*="modalBody"] {
        height: calc(
          100% - var(--modal-with-tabs-header-height) -
            var(--modal-with-tabs-footer-height)
        ) !important;
      }
    }
  }
}

.uploudImgDialog {
  width: 650px;
}

.estimatorContainer {
  border-top: 1px solid var(--border-colors);
}

.lblStatus {
  font-weight: 600 !important;
  font-size: 1.3rem !important;
  display: flex !important;
  align-items: center !important;

  &::before {
    content: "";
    display: flex;
    width: 13px;
    height: 13px;
    background-color: var(--secondary-1);
    border-radius: 50%;
    margin: 0 10px 0 0;

    [dir="rtl"] & {
      margin: 0 0 0 10px;
    }
  }
}

.imgContainer {
  width: 100%;
  height: 140px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  > span {
    width: 33%;
    margin: 0 10px 0 0;

    [dir="rtl"] & {
      margin: 0 0 0 10px;
    }

    img {
      width: 100%;
      max-height: 100%;
    }
  }
}

.attachmentsInfo {
  width: 100%;
  padding: 0 60px;
}

.containerRating {
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 23px;
    height: 23px;
  }
}

// ******************workflowStatus Table**********************

.workflowStatusTableRow {
  > div {
    display: flex;
    justify-content: center;
  }
}

.mobileLbl {
  display: none;

  @media screen and (max-width: 650px) {
    display: flex !important;
    align-items: center;
    margin: 0 5px 0 0;
    color: var(--label-color) !important;
    width: 120px;

    [dir="rtl"] & {
      margin: 0 0 0 5px;
    }
  }
}

.workflowStatusTable {
  @media screen and (max-width: 650px) {
    div[class^="v_table_header"],
    div[class^="v_table_body"] {
      width: 100%;
      min-width: 100% !important;
    }

    div[class^="v_table_header"] {
      display: none !important;
    }

    div[class^="v_table_row"] {
      display: block !important;

      &:hover {
        background-color: transparent !important;
      }

      &:nth-of-type(1) {
        border-top: 1px solid var(--border-table) !important;
      }

      &:last-of-type {
        border-bottom: 0 !important;
      }

      > div {
        border-left: 1px solid var(--border-table) !important;
        border-right: 1px solid var(--border-table) !important;
        width: 100% !important;
        height: 40px !important;

        &:last-of-type {
          border-bottom: 1px solid var(--border-table) !important;
        }

        > div {
          padding: 0 10px;
          border-left: 0 !important;
          border-right: 0 !important;

          [dir="rtl"] & {
            border-left: 0 !important;
            border-right: 0 !important;
          }

          > div {
            width: calc(100% - 120px - 5px);
            text-align: start !important;
            height: 30px;
            display: flex;
            align-items: center;
            overflow: hidden;
          }
        }
      }
    }
  }

  div[class^="v_table_body"] {
    height: calc(
      var(--model-dialog-height) - var(--modal-with-tabs-header-height) -
        var(--table-header-height) - var(--modal-with-tabs-footer-height) - 20px -
        20px
    ) !important;

    @media screen and (max-width: 650px) {
      height: calc(
        var(--model-dialog-height) - var(--modal-with-tabs-header-height) -
          var(--table-header-height) - 20px - 40px
      ) !important;
    }
  }

  div[class^="v_table_header"],
  div[class^="v_table_row"] {
    display: flex;
    align-items: center;

    > div {
      text-align: center;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        width: var(--col-1);
      }

      &:nth-child(2) {
        width: var(--col-2);
      }

      &:nth-child(3) {
        width: var(--col-3);
      }

      &:nth-child(4) {
        width: var(--col-4);
      }

      &:nth-child(5) {
        width: var(--col-5);
      }

      &:nth-child(6) {
        width: var(--col-6);
      }

      &:nth-child(7) {
        width: var(--col-7);
      }

      &:nth-child(8) {
        width: var(--col-8);
      }
    }
  }
}

.success {
  svg {
    width: 15px;
    height: 15px;

    path {
      stroke: var(--success);
    }
  }
}

.info {
  svg {
    width: 15px;
    height: 15px;

    path {
      fill: var(--worning);
    }
  }
}

.dateWorkFlowStatus {
  display: flex;
  align-items: center;
  flex-direction: column;
}

// ******************workflowStatus Table**********************
.lblRowStatusWorkFlowStatus {
  display: none !important;

  @media screen and (max-width: 650px) {
    display: flex !important;
    align-items: flex-start !important;
    flex-direction: column !important;
    width: 100% !important;
    flex-direction: column;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid var(--border-colors);

    > label {
      &:nth-of-type(1) {
        width: 70px !important;
      }
    }
  }
}

.lblRowWorkFlowStatus {
  display: none;

  @media screen and (max-width: 650px) {
    display: flex !important;
    width: 100% !important;
    margin-bottom: 10px !important;

    > label {
      &:nth-of-type(1) {
        width: 100px !important;
      }

      &:nth-of-type(2) {
        width: calc(100% - 100px - 5px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: start !important;
      }
    }
  }
}

.uploadQrCodeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc($qrcode-dialog-height - 140px);
}

.qrCodeDialog {
  height: fit-content !important;

  > div {
    &[class*="modalBody"] {
      height: calc(
        100% - var(--modal-with-tabs-header-height) -
          var(--modal-with-tabs-footer-height)
      ) !important;
    }
  }
}

.userDec {
  max-height: 76px !important;
}

.workFlowStatusDialog {
  > div {
    &[class*="modalBody"] {
      padding: 20px;
    }
  }

  @media screen and (max-width: 650px) {
    > div {
      &[class*="modalBody"] {
        height: calc(100% - var(--modal-with-tabs-header-height)) !important;
        max-height: calc(
          100% - var(--modal-with-tabs-header-height)
        ) !important;
      }
    }
  }
}

.sendAddNote {
  // height: calc(var(--window-height) - var(--header-height) - var(--header-margin-bottom) - var(--main-padding-V) - $headerTrxHeight - $margin - 30px - 20px - 20px - 37px);
  min-height: 100px;
  overflow: auto;
  padding: 0 15px 0 0;

  [dir="rtl"] & {
    padding: 0 0 0 15px;
  }
}

.senderContainer {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.deleteBtn {
  position: relative;
  margin: 0 10px 0 5px;
  height: 44px;

  [dir="rtl"] & {
    margin: 0 5px 0 10px;
  }

  > svg {
    width: 15px;
    height: 15px;
  }

  &:hover {
    svg {
      transition: 0.2s;
      transform: scale(1.2);
    }
  }
}

.senderIcon {
  margin: 0 10px 0 0;

  [dir="rtl"] & {
    margin: 0 0 0 10px;
  }

  > svg {
    width: 40px;
    height: 40px;

    @media screen and (max-width: 500px) {
      width: 30px;
      height: 30px;
    }
  }
}

.chatDataContainer {
  width: calc(100% - 40px - 30px);
}

.userInfo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--pageination);
  font-size: 1rem;
  margin-bottom: 5px;
}

.userInfoName {
  padding: 0 0 0 10px;

  [dir="rtl"] & {
    padding: 0 10px 0 0;
  }

  > label {
    font-weight: 700 !important;
  }
}

.userInfoDate {
  > label {
    font-weight: 800;
    font-size: 1rem;
  }
}

.addNote {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 15px 0 0;

  [dir="rtl"] & {
    padding: 0 0 0 15px;
  }

  > input {
    width: calc(100% - 100px - 10px);
    margin: 0 10px 0 0;
    font-size: 1.25rem;

    [dir="rtl"] & {
      margin: 0 0 0 10px;
    }
  }
}

.containerSpecialNotesCard {
  height: calc(
    var(--model-dialog-height) - var(--modal-with-tabs-header-height) -
      var(--modal-with-tabs-footer-height) - 40px
  );
  overflow: auto;
  position: relative;
}

.specialNotesRating {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  position: relative;

  svg {
    width: 20px;
    height: 20px;
  }

  &[class*=deleted] {
    >div:first-of-type {
      width: 250px;
      height: 1px;
      background-color: var(--label-color);
      position: absolute;
      top: 50%;
      left: 0;
      right: auto;
      z-index: 2;

      [dir=rtl] & {
        left: auto;
        right: 0;
      }
    }
  }
}

@mixin Input-Chat {
  width: 100%;
  border: 1px solid var(--border-colors);
  border-radius: 5px;
  font-weight: 500;
  box-shadow: 0 4px 8px 0 rgba(44, 39, 56, 0.04);
  padding: 10px !important;
  color: var(--black);
  position: relative;
  margin-bottom: 10px;
  transition: all 0.5s;
  min-height: 60px;
  overflow-x: hidden;

  &.inputChatActive {
    height: fit-content !important;
  }

  > div {
    margin-bottom: 5px;
    font-size: 1.25rem;
  }
}

.inputChatDisabled {
  @include Input-Chat();
  background-color: var(--disable-colors);
  cursor: not-allowed;

  * {
    cursor: not-allowed;
  }
}

.inputChatContainer {
  width: 100%;
}

.inputChat {
  @include Input-Chat();
}

.dataMsgDisabled {
  text-decoration: line-through !important;
}

.dataMsg {
  background-color: var(--primary-hover);
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  width: 100%;
  display: none;

  &.dataMsgActive {
    display: block !important;
    overflow: hidden;
  }
}

.deleteSpinner {
  background-color: var(--white);

  &::after {
    width: 15px;
    height: 15px;
    border: 2px solid var(--black);
    border-top-color: transparent;
    border-bottom-color: transparent;
  }
}

.addRateContainer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  padding: 0 15px 0 0;

  [dir=rtl] & {
    padding: 0 0 0 15px;
  }
}
