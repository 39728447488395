$summary-dialog-height: 370px;
$margin-top-summary-dialog: 15px;

// *************************summaryDialog****************************

.summaryDialog {
    height: fit-content !important;

    >div {
        &[class *=modalBody] {
            height: calc(100% - var(--modal-with-tabs-header-height) - var(--modal-with-tabs-footer-height)) !important;
            padding: 15px;
        }
    }
}

.containersummaryDialog {
    >div {
        margin-bottom: 0;

        @media screen and (max-width: 1025px) {
            flex-direction: column-reverse;

            >div {
                width: 100%;

                &:nth-of-type(2) {
                    margin-bottom: 30px;
                }
            }
        }

        @media screen and (max-width: 500px) {
            >div {

                &:nth-of-type(2) {
                    margin-bottom: 0px;
                }
            }
        }
    }
}

.summaryCodeNum {
    font-size: 1.5rem;
    color: var(--text-2);
    font-weight: 600;
}

.summaryPriceNum {
    font-size: 1.7rem;
    color: var(--secondary-1);
    font-weight: 600;
    margin-top: $margin-top-summary-dialog;

    >span {
        margin: 0 0 0 10px;

        [dir=rtl] & {
            margin: 0 10px 0 0;
        }
    }
}

.date {
    color: var(--text-3);
    font-weight: 600;
    font-size: 1.2rem;
    margin-top: $margin-top-summary-dialog;
}

@mixin summary-User-Info {
    display: flex;
    align-items: center;
    margin-top: $margin-top-summary-dialog;
    font-size: 1.2rem;
    font-weight: 500;

    >svg {
        width: 15px;
        height: 15px;
        margin: 0 10px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 10px;
        }
    }
}

.summaryUser {
    @include summary-User-Info();
}

.summaryLocation {
    @include summary-User-Info();
    color: var(--text-3);
}

.userInfoBox {
    width: 100%;
    margin-top: $margin-top-summary-dialog;
    background-color: #F4F5F8;
    padding: 15px 10px;
    border-radius: 7px;

    >div {
        display: flex;
        align-items: center;
    }
}

@mixin summary-Area-Section {
    >div {
        display: flex;
        align-items: center;
        width: 50%;
    }
}

.areaSection {
    @include summary-Area-Section ();
}

.buildingSection {
    @include summary-Area-Section ();
    margin-top: 15px;
}

.lblName {
    color: var(--text-3);
    width: 80px;
}

.lblVal {
    width: calc(100% - 80px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    >span {
        margin: 0 0 0 5px;

        [dir=rtl] & {
            margin: 0 5px 0 0;
        }
    }
}

.containerGallery {
    width: 100%;
}

.galleryImg {
    position: relative;
    border-radius: 10px;
    width: 100%;
    height: 190px;
    border: 1px solid var(--border-colors);
    padding: 10px 0;

    img {
        width: 100% !important;
        max-height: 100%;
        border-radius: 10px;
        object-fit: contain;
    }
}

.multiImages {
    display: flex;
    align-items: center;
    margin-top: $margin-top-summary-dialog;
    overflow-x: auto;
    width: 100%;

    &::-webkit-scrollbar {
        height: 6px !important;
    }
}

.imgs {
    min-width: 80px;
    height: 80px;
    max-width: 80px;
    margin: 0 10px 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border: 1px solid var(--border-colors);
    padding: 5px;
    border-radius: 5px;

    [dir=rtl] & {
        margin: 0 0 5px 10px;
    }

    img {
        max-width: 100%;
        max-height: 100px;
        border-radius: 5px;
    }
}

.mainImgSpinner {
    display: flex;

    &::after {
        border-top-color: var(--black);
        border-bottom-color: var(--black);
    }
}

// *************************summaryDialog****************************