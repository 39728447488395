.container {
    position: relative;
}

.loadingContainer {
    // position: absolute;
    top: -2px;
    left: 0;
    width: 20px;
    height: 20px;
    display: none;
    align-items: center;
    justify-content: center;

    &::after {
        content: "";
        width: 16px;
        height: 16px;
        margin: auto;
        border: 3px solid var(--primary-color);
        border-top-color: transparent;
        border-radius: 50%;
        animation: button-loading-spinner .5s ease infinite;

        [dir=rtl] & {
            right: 10px;
            left: auto;
        }

    }
}

@keyframes button-loading-spinner {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}