.controlWithExcelRow {
    >div {
        display: flex;
        justify-content: center;
    }
}

.controlWithExcelTable {

    div[class^=table_header_checkbox]>span,
    div[class^=table_body]>div>div {
        >div:nth-child(1) {
            width: var(--col-1);
        }

        >div:nth-child(2) {
            width: var(--col-2);
        }

        >div:nth-child(3) {
            width: var(--col-3);
        }

        >div:nth-child(4) {
            width: var(--col-4);
        }

        >div:nth-child(5) {
            width: var(--col-5);
        }

        >div:nth-child(6) {
            width: var(--col-6);
        }

        >div:nth-child(7) {
            width: var(--col-7);
        }
    }
}

.updateValuationFieldsBtnStyle {
    @media screen and (max-width: 1024px) {
        >button {
            width: 100% !important;
        }
    }
}