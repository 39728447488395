$headerProfHeight: 80px;

.container {
    height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom));
}

.profileContent {
    border: 1px solid var(--border-colors);
    border-radius: 10px;
    padding: 20px 10px;
    height: 100%;
    margin: 0 40px;
    background-color: var(--white);

    @media screen and (max-width: 765px) {
        margin: 20px 0px;
    }
}

.headerProf {
    position: relative;
    background: linear-gradient(90deg, var(--secondary-1) 0%, var(--secondary-2) 100%);
    width: 100%;
    height: $headerProfHeight;
    border-radius: 7px 7px 0 0;

    @media screen and (max-width: 1024px) {
        height: 90px;
    }
}

.profileImg {
    position: absolute;
    left: 15px;
    right: auto;
    top: calc($headerProfHeight / 2);
    display: flex;
    align-items: center;

    [dir=rtl] & {
        right: 15px;
        left: auto;
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        left: 50%;
        right: auto;
        transform: translateX(-50%);

        [dir=rtl] & {
            left: 50%;
            right: auto;
        }
    }

    >div {
        >div {
            &[class *=uploadOneImg] {
                width: 110px;
                height: 110px;
                border-radius: 50%;
                border: none;
                background-color: var(--white);
                box-shadow: 0px 4px 4px 0px #00000040;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;

                @media screen and (max-width: 1024px) {
                    width: 100px;
                    height: 100px;
                }

                >div {
                    &[class *=oneImgPanel] {
                        border-radius: 50%;
                        width: 100%;
                        height: 100%;

                        >div {
                            overflow: hidden;
                            max-width: 100%;
                            max-height: 100%;
                        }

                        img {
                            object-fit: contain;
                        }

                        >button {
                            top: 5px;
                            left: auto;
                            right: 5px;

                            [dir=rtl] & {
                                right: 5px;
                                left: auto;
                            }

                        }
                    }
                }
            }
        }
    }

}

.userNameSection {
    margin: 0 20px;

    @media screen and (max-width: 1024px) {
        margin: 10px 0;
    }

    >label {
        width: 100%;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @media screen and (max-width: 1024px) {
            text-align: center;
        }
    }

    .userName {
        margin: 40px 0 5px;
        font-size: 1.4rem;
        font-weight: 600;

        @media screen and (max-width: 1024px) {
            margin: 0;
        }
    }

    .userStatus {
        font-size: 1.1rem;
        color: var(--text-3);

        @media screen and (max-width: 765px) {
            font-size: 0.9rem;
        }
    }
}

.profileInfo {
    margin-top: 80px;
    padding: 0 80px;

    >div {
        &:nth-of-type(1) {
            height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 20px - 20px - $headerProfHeight - 80px - 40px);
            overflow-y: auto;
        }

        >div {
            padding: 0 10px;
        }
    }

    @media screen and (max-width: 1024px) {
        padding: 0 0 0 50px;
        margin-top: 100px;

        [dir=rtl] & {
            padding: 0 50px 0 0;
        }

        >div {
            position: relative;

            &:nth-of-type(1) {
                height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 20px - 20px - $headerProfHeight - 100px - 40px - 40px);
            }

            >div {
                padding: 0 50px 0 0;

                [dir=rtl] & {
                    padding: 0 0 0 50px;
                }

                >div {
                    width: 100% !important;
                }
            }
        }
    }
}

.confirmBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    >button {
        min-width: 140px;
        height: 40px;
    }

    @media screen and (max-width: 1024px) {
        justify-content: center;
        margin-top: 20px;

        >button {
            min-width: 200px;
        }
    }
}

.changepasswordContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F7F7F7;
    padding: 15px 20px;
    font-size: 12px;
    margin-top: 20px;
    border-radius: 5px;

    >button {
        color: var(--secondary-1);
    }

    @media screen and (max-width: 500px) {
        margin: 10px 0;
    }
}

.error {
    width: 98%;
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    >div {
        width: 8px;
        height: 8px;
        background-color: var(--error);
        border-radius: 50%;
        margin: 0 8px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 8px;
        }
    }

    >label {
        font-size: 14px;
        color: var(--error);
    }
}

.passwordReco {
    flex-direction: column;
    width: 49%;

    // padding: 0 10px;
    .passwordRecoLabel {
        width: 98%;
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        >div {
            width: 8px;
            height: 8px;
            background-color: var(--text-3);
            border-radius: 50%;
            margin: 0 8px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 8px;
            }
        }

        >label {
            font-size: 14px;
            color: var(--text-3);
        }
    }
}

.success {
    width: 98%;
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    >div {
        width: 8px;
        height: 8px;
        background-color: var(--success);
        border-radius: 50%;
        margin: 0 8px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 8px;
        }
    }

    >label {
        font-size: 14px;
        // color: var(--success);
    }
}

.saveSpinner {
    background-color: var(--primary-color-2);
}