.estimationFormsFieldsBuilderRow {
  >div {
    display: flex;
    justify-content: center;
  }
}

.estimationFormsFieldsBuilderTable {

  div[class^=table_header_checkbox]>span,
  div[class^=table_body]>div>div {
    >div:nth-child(1) {
      width: var(--col-1);
    }

    >div:nth-child(2) {
      width: var(--col-2);
    }

    >div:nth-child(3) {
      width: var(--col-3);
    }

    >div:nth-child(4) {
      width: var(--col-4);
    }

    >div:nth-child(5) {
      width: var(--col-5);
    }

    >div:nth-child(6) {
      width: var(--col-6);
    }
  }
}

.viewBtn {
  display: flex;
  align-items: center;
  border: 1px solid var(--border-colors);
  height: var(--common-height);
  min-width: 100px;
  padding: 0 10px;

  * {
    cursor: pointer;
  }

  span {
    >svg {
      width: 12px;
      height: 12px;
      margin: 0 5px 0 0;

      [dir="rtl"] & {
        margin: 0 0 0 5px;
      }
    }
  }

  >label {
    color: var(--text-4) !important;
    font-size: 1rem;
  }

  &:hover {
    border-color: var(--secondary-1);

    label {
      color: var(--secondary-1) !important;
    }
  }
}

.estimationFormsFieldsBuilderTableContainer {
  overflow-x: auto;
}