$footer-height: 57px;
$header-height: 70px;
.footer {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    height: $footer-height;
}

.prevBtn,
.nextBtn {
    width: 80px;
    height: var(--common-height);
    padding: 0 5px;

    * {
        cursor: pointer;
    }

    label {
        width: calc(100% - 22px - 10px);
        text-align: start;
    }

    svg {
        width: 22px;
        height: 22px;
    }

    &:disabled {
        * {
            cursor: not-allowed;
        }

        &:hover {
            svg {
                path {
                    fill: var(--text-3);
                }
            }
        }
    }

    &:hover {
        background-color: var(--primary-hover);

        svg {
            path {
                fill: var(--primary-color-2);
            }
        }
    }
}

.nextBtn {
    margin: 0 0 0 10px;
    direction: rtl;

    [dir=rtl] & {
        margin: 0 10px 0 0;
        direction: ltr;
    }

    label {
        margin: 0 10px 0 0;

        [dir=rtl] & {
            margin: 0 0 0 10px;
        }
    }

    svg {
        transform: rotate(0deg);

        [dir=rtl] & {
            transform: rotate(180deg);
        }
    }
}

.prevBtn {
    margin: 0 10px 0 0;
    direction: ltr;

    [dir=rtl] & {
        margin: 0 0 0 10px;
        direction: rtl;
    }

    label {
        margin: 0 0 0 10px;

        [dir=rtl] & {
            margin: 0 10px 0 0;
        }
    }

    svg {
        transform: rotate(180deg);

        [dir=rtl] & {
            transform: rotate(0deg);
        }
    }
}

.okBtn {
    height: var(--common-height);
    color: var(--white);
    background-color: var(--secondary-2);
    width: calc(100% - 80px - 80px - 20px);
    position: relative;
}

.okBtnDef {
    position: relative;
    height: var(--common-height);
    color: var(--white);
    background-color: var(--secondary-2);
    width: 100%;
}

.checkboxContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    >div {
        width: 33%;
        margin-bottom: 10px;
    }
}
.mandatoryInput {
    >div{
        border-color: var(--error) !important;
    }
  }