@font-face {
    font-family: 'Makdessi';
    src: url('../fonts/ar/Makdessi-Thin.eot');
    src: local('Makdessi Thin'), local('Makdessi-Thin'),
        url('../fonts/ar/Makdessi-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ar/Makdessi-Thin.woff2') format('woff2'),
        url('../fonts/ar/Makdessi-Thin.woff') format('woff'),
        url('../fonts/ar/Makdessi-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Makdessi Hairline';
    src: url('../fonts/ar/Makdessi-Hairline.eot');
    src: local('Makdessi Hairline'), local('Makdessi-Hairline'),
        url('../fonts/ar/Makdessi-Hairline.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ar/Makdessi-Hairline.woff2') format('woff2'),
        url('../fonts/ar/Makdessi-Hairline.woff') format('woff'),
        url('../fonts/ar/Makdessi-Hairline.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Makdessi';
    src: url('../fonts/ar/Makdessi-ExtraBold.eot');
    src: local('Makdessi ExtraBold'), local('Makdessi-ExtraBold'),
        url('../fonts/ar/Makdessi-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ar/Makdessi-ExtraBold.woff2') format('woff2'),
        url('../fonts/ar/Makdessi-ExtraBold.woff') format('woff'),
        url('../fonts/ar/Makdessi-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Makdessi';
    src: url('../fonts/ar/Makdessi-Black.eot');
    src: local('Makdessi Black'), local('Makdessi-Black'),
        url('../fonts/ar/Makdessi-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ar/Makdessi-Black.woff2') format('woff2'),
        url('../fonts/ar/Makdessi-Black.woff') format('woff'),
        url('../fonts/ar/Makdessi-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Makdessi';
    src: url('../fonts/ar/Makdessi-Medium.eot');
    src: local('Makdessi Medium'), local('Makdessi-Medium'),
        url('../fonts/ar/Makdessi-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ar/Makdessi-Medium.woff2') format('woff2'),
        url('../fonts/ar/Makdessi-Medium.woff') format('woff'),
        url('../fonts/ar/Makdessi-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Makdessi';
    src: url('../fonts/ar/Makdessi-Bold.eot');
    src: local('Makdessi Bold'), local('Makdessi-Bold'),
        url('../fonts/ar/Makdessi-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ar/Makdessi-Bold.woff2') format('woff2'),
        url('../fonts/ar/Makdessi-Bold.woff') format('woff'),
        url('../fonts/ar/Makdessi-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Makdessi Hairline';
    src: url('../fonts/ar/Makdessi-Light.eot');
    src: local('Makdessi Light'), local('Makdessi-Light'),
        url('../fonts/ar/Makdessi-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ar/Makdessi-Light.woff2') format('woff2'),
        url('../fonts/ar/Makdessi-Light.woff') format('woff'),
        url('../fonts/ar/Makdessi-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Makdessi';
    src: url('../fonts/ar/Makdessi-Regular.eot');
    src: local('Makdessi'), local('Makdessi-Regular'),
        url('../fonts/ar/Makdessi-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ar/Makdessi-Regular.woff2') format('woff2'),
        url('../fonts/ar/Makdessi-Regular.woff') format('woff'),
        url('../fonts/ar/Makdessi-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}



/* -----------------En--------------------------- */
@font-face {
    font-family: 'Segoe UI';
    src: url('../fonts/en/SegoeUI-BoldItalic.eot');
    src: local('Segoe UI Bold Italic'), local('SegoeUI-BoldItalic'),
        url('../fonts/en/SegoeUI-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/en/SegoeUI-BoldItalic.woff2') format('woff2'),
        url('../fonts/en/SegoeUI-BoldItalic.woff') format('woff'),
        url('../fonts/en/SegoeUI-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('../fonts/en/SegoeUI.eot');
    src: local('Segoe UI'), local('SegoeUI'),
        url('../fonts/en/SegoeUI.eot?#iefix') format('embedded-opentype'),
        url('../fonts/en/SegoeUI.woff2') format('woff2'),
        url('../fonts/en/SegoeUI.woff') format('woff'),
        url('../fonts/en/SegoeUI.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('../fonts/en/SegoeUI-SemiBold.eot');
    src: local('Segoe UI Semibold'), local('SegoeUI-SemiBold'),
        url('../fonts/en/SegoeUI-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/en/SegoeUI-SemiBold.woff2') format('woff2'),
        url('../fonts/en/SegoeUI-SemiBold.woff') format('woff'),
        url('../fonts/en/SegoeUI-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('../fonts/en/SegoeUI-Light.eot');
    src: local('Segoe UI Light'), local('SegoeUI-Light'),
        url('../fonts/en/SegoeUI-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/en/SegoeUI-Light.woff2') format('woff2'),
        url('../fonts/en/SegoeUI-Light.woff') format('woff'),
        url('../fonts/en/SegoeUI-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('../fonts/en/SegoeUI-Bold.eot');
    src: local('Segoe UI Bold'), local('SegoeUI-Bold'),
        url('../fonts/en/SegoeUI-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/en/SegoeUI-Bold.woff2') format('woff2'),
        url('../fonts/en/SegoeUI-Bold.woff') format('woff'),
        url('../fonts/en/SegoeUI-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('../fonts/en/SegoeUI-Italic.eot');
    src: local('Segoe UI Italic'), local('SegoeUI-Italic'),
        url('../fonts/en/SegoeUI-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/en/SegoeUI-Italic.woff2') format('woff2'),
        url('../fonts/en/SegoeUI-Italic.woff') format('woff'),
        url('../fonts/en/SegoeUI-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}


/* -----------------En--------------------------- */


/* -----------------digital---------------------- */
@font-face {
    font-family: 'digital';
    src: url('../fonts/digital/digital-7.ttf');
}

/* -----------------digital---------------------- */