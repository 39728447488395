// ******************divisions Table**********************



.divisionsTableContainer {
    overflow-x: auto;
}


.divisionsTable {

    div[class^=v_table_header],
    div[class^=v_table_body] {
        width: 100%;
    }

    div[class^=v_table_body] {
        height: calc(var(--model-dialog-height) - var(--modal-with-tabs-header-height) - var(--table-header-actions-height) - var(--table-header-actions-margin-bottom) - var(--table-header-height) - 10px - var(--modal-with-tabs-footer-height) - 10px) !important;

        @media screen and (max-width: 768px) {
            height: calc(var(--model-dialog-height-sm) - var(--modal-with-tabs-header-height) - var(--table-header-actions-height) - var(--table-header-actions-margin-bottom) - var(--table-header-height) - var(--modal-with-tabs-footer-height) - 10px) !important;
        }
    }

    div[class^=v_table_header],
    div[class^=v_table_row] {
        display: flex;
        align-items: center;

        >div {
            text-align: center;
            display: flex;
            justify-content: center;

            &:nth-child(1) {
                width: var(--col-1);
            }

            &:nth-child(2) {
                width: var(--col-2);
            }

            &:nth-child(3) {
                width: var(--col-3);
            }

            &:nth-child(4) {
                width: var(--col-4);
            }

            &:nth-child(5) {
                width: var(--col-5);
            }

            &:nth-child(6) {
                width: var(--col-6);
            }

            &:nth-child(7) {
                width: var(--col-7);
            }

            &:nth-child(8) {
                width: var(--col-8);
            }
        }
    }
}

.branchesDefinitionMultiInsart {
    @media screen and (max-width: 1024px) {

        div[class^=v_table_header],
        div[class^=v_table_body] {
            width: 100%;
            min-width: 100% !important;
        }
    }

    @media screen and (max-width: 650px) {

        div[class^=v_table_header],
        div[class^=v_table_body] {
            width: 100%;
            min-width: 600px !important;
        }
    }
}
// ******************divisions Table**********************