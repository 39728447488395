.outboxRow {
    flex-wrap: wrap;

    >div {
        display: flex;
        justify-content: center;
    }
}

.notFound {
    color: var(--text-3) !important;
    font-weight: 500 !important;
}

.outboxTable {

    div[class^=table_header_checkbox]>span,
    div[class^=table_body]>div>div {
        >div:nth-child(1) {
            width: var(--col-1);
        }

        >div:nth-child(2) {
            width: var(--col-2);
        }

        >div:nth-child(3) {
            width: var(--col-3);
        }

        >div:nth-child(4) {
            width: var(--col-4);
        }

        >div:nth-child(5) {
            width: var(--col-5);
        }

        >div:nth-child(6) {
            width: var(--col-6);
        }

        >div:nth-child(7) {
            width: var(--col-7);
        }

        >div:nth-child(8) {
            width: var(--col-8);
        }

        >div:nth-child(9) {
            width: var(--col-9);
        }
    }
}

// *****************Messages***************
.messagesContainer {
    display: flex;
    align-items: center;
    height: 50px;

    >span {
        >svg {
            width: 20px;
            height: 20px;
        }

        &:first-of-type {
            margin: 0 10px 0 0;

            >svg {
                width: 15px;
                height: 15px;
                transform: rotate(180deg);
                transition: all 0.5s;

                [dir=rtl] & {
                    transform: rotate(0deg);
                }
            }

            [dir=rtl] & {
                margin: 0 0 0 10px;
            }
        }

        &:nth-of-type(2) {
            >svg {
                width: 18px;
                height: 18px;
            }
        }
    }
}

.arrowMessages {
    >svg {
        transform: rotate(270deg) !important;
        transition: all 0.5s;

        [dir=rtl] & {
            transform: rotate(-90deg) !important;
        }
    }
}

.actionIcon {

    >div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        >div {
            &:nth-of-type(1) {
                min-width: 25px;

                >svg {
                    width: 15px;
                    height: 15px;
                    margin: 0 7px 0 0;

                    [dir=rtl] & {
                        margin: 0 0 0 7px;
                    }
                }
            }

            &:nth-of-type(2) {
                max-width: calc(100% - 25px - 15px);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    @media screen and (max-width: 650px) {
        >label {
            width: 120px;
        }

        >div {
            width: calc(100% - 120px);

            >div {
                &:nth-of-type(1) {
                    >svg {
                        width: 14px;
                        height: 14px;
                    }
                }

                &:nth-of-type(2) {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }
            }
        }
    }
}

.shareButton {
    display: flex;
    align-items: center;
    min-width: 100px;
    height: var(--common-height);
    padding: 0 5px;
    background-color: var(--white);
    color: var(--text-4) !important;
    margin: 0 10px !important;

    >svg {
        width: 16px;
        height: 16px;
    }

    >label {
        margin: 0 0 0 5px;

        [dir=rtl] & {
            margin: 0 5px 0 0;
        }
    }

    &:hover {
        border: 1px solid var(--border-colors);
    }
}

.statusesTable {

    div[class^=v_table_body] {
        height: calc(var(--model-dialog-height) - var(--modal-with-tabs-header-height) - var(--modal-with-tabs-footer-height) - var(--table-header-height) - 20px - 20px);
    }

    div[class^=v_table_header],
    div[class^=v_table_row] {
        display: flex;
        align-items: center;

        >div {
            text-align: center;
            display: flex;
            justify-content: center;

            &:nth-child(1) {
                width: var(--col-1);
            }

            &:nth-child(2) {
                width: var(--col-2);
            }

            &:nth-child(3) {
                width: var(--col-3);
            }
        }
    }
}

.readingDialog {

    >div {
        &[class*="modalBody"] {
            padding: 20px;
        }
    }
}