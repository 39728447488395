.actionIcon {
    >div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        >div {
            &:nth-of-type(1) {
                min-width: 20px;

                >svg {
                    width: 15px;
                    height: 15px;
                    margin: 0 7px 0 0;

                    [dir=rtl] & {
                        margin: 0 0 0 7px;
                    }
                }
            }

            &:nth-of-type(2) {
                max-width: calc(100% - 20px - 15px);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    @media screen and (max-width: 650px) {
        >label {
            width: 120px;
        }

        >div {
            width: calc(100% - 120px);

            >div {
                &:nth-of-type(1) {
                    >svg {
                        width: 14px;
                        height: 14px;
                    }
                }

                &:nth-of-type(2) {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }
            }
        }
    }
}

.inboxRow {
    cursor: pointer;
    flex-wrap: wrap;

    >div {
        display: flex;
        justify-content: center;
    }
}

.inboxTabTable {
    &[class*=cardTableMode] {
        >div {
            div[class^=table_body] {
                height: calc(var(--window-height) - var(--main-padding-V) - var(--header-height) - var(--header-margin-bottom) - 10px - var(--header-pages-panel-height) - var(--table-header-height) - var(--table-pagination-height) - 1px - 20px) !important;
            }
        }
    }

}

.inboxTable {

    div[class^=table_body]>div>div>div {
        &:nth-child(9) {
            justify-content: start;
        }
    }

    div[class^=table_header_checkbox]>span,
    div[class^=table_body]>div>div {
        >div:nth-child(1) {
            width: var(--col-1);
        }

        >div:nth-child(2) {
            width: var(--col-2);
        }

        >div:nth-child(3) {
            width: var(--col-3);
        }

        >div:nth-child(4) {
            width: var(--col-4);
        }

        >div:nth-child(5) {
            width: var(--col-5);
        }

        >div:nth-child(6) {
            width: var(--col-6);
        }

        >div:nth-child(7) {
            width: var(--col-7);
        }

        >div:nth-child(8) {
            width: var(--col-8);
        }

        >div:nth-child(9) {
            width: var(--col-9);
        }
    }
}

// *****************Messages***************
.messagesContainer {
    display: flex;
    align-items: center;
    height: 50px;

    >span {
        >svg {
            width: 20px;
            height: 20px;
        }

        &:first-of-type {
            margin: 0 10px 0 0;

            >svg {
                width: 15px;
                height: 15px;
                transform: rotate(180deg);
                transition: all 0.5s;

                [dir=rtl] & {
                    transform: rotate(0deg);
                }
            }

            [dir=rtl] & {
                margin: 0 0 0 10px;
            }
        }

        &:nth-of-type(2) {
            >svg {
                width: 18px;
                height: 18px;
            }
        }
    }
}

.arrowMessages {
    >svg {
        transform: rotate(270deg) !important;
        transition: all 0.5s;

        [dir=rtl] & {
            transform: rotate(-90deg) !important;
        }
    }
}

// ***********Favorite*****************
.containerFilter {
    position: relative;
}

.containerFav {
    position: absolute;
    top: -493px;
    width: calc(100% - 60px);
    height: 100%;
    transition: all 0.5s;

    &.show {
        top: 0px;
        padding: 0 !important;
    }
}

.line {
    height: 1px;
    width: 100%;
    position: relative;
    background-color: var(--dark-gray);
    margin: 18px 0;

    >label {
        background-color: var(--primary-1);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        font-size: 14px;
        font-weight: 500;
        color: var(--white);
        width: 35px;
        height: 35px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
    }
}

.containerButton {
    .savedButton {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        border-radius: 5px;

        &:hover {
            background-color: var(--tr-hover) !important;
            padding: 0 5px;
        }

        >div {
            display: flex;
            align-items: center;
        }
    }
}

@mixin Buttons {
    border-radius: 50%;
    background-color: var(--white);
    border: 1px solid var(--light-gray);
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0;

    [dir=rtl] & {
        margin: 0 0 0 10px;
    }
}

.chooseButton {
    cursor: pointer;

    >button {
        @include Buttons();
    }
}

.active {
    >button {
        @include Buttons();
        background-color: var(--success);
    }
}

.savedButtonIcons {
    >button {
        &:nth-of-type(1) {
            margin: 0 10px 0 0;

            [dir=rtl] & {
                margin: 0 0 0 10px;
            }
        }

        >svg {
            width: 22px;
            height: 22px;
        }

        &:hover {
            &:nth-of-type(1) {
                >svg {
                    path {
                        fill: var(--blue);
                    }
                }
            }

            &:nth-of-type(2) {
                >svg {
                    path {
                        fill: var(--red);
                    }
                }
            }
        }
    }
}

// ***********Favorite*****************
// ***********Header**********
.modelHeader {
    margin: 0 !important;
    padding: 0 5px !important;
}

// ***********Header**********
.shareButton {
    display: flex;
    align-items: center;
    min-width: 120px;
    height: var(--common-height);
    padding: 0 5px;
    background-color: var(--white);
    color: var(--text-4) !important;
    margin: 0 10px !important;

    >svg {
        width: 16px;
        height: 16px;
    }

    >label {
        margin: 0 0 0 5px;
        cursor: pointer;

        [dir=rtl] & {
            margin: 0 5px 0 0;
        }
    }

    &:hover {
        border: 1px solid var(--border-colors);
    }
}

.notFound {
    color: var(--text-3) !important;
    font-weight: 500 !important;
}

.timerContainer {
    margin: 0 10px;
    min-height: var(--common-height);
    min-width: var(--common-height);
    display: flex;
    align-items: center;
    justify-content: center;

    >span>svg {
        width: 13px;
        height: 22px;
    }

    >div {
        max-width: calc(100% - 15px - 20px) !important;
        width: auto !important;
    }
}

.notFound {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}